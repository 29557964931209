import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import Payment from "components/ten99Prep/Cart//Payment";
import CartDisplay from "components/ten99Prep/Cart/CartDisplay";
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum, IAction } from "sharedInterfaces/ITen99Action";
import { ITen99ActionResponse, ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage, ITen99Dashboard } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Cart } from "sharedInterfaces/ITen99Cart";
import { ITen99CartCharges } from "sharedInterfaces/ITen99CartCharges";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { ITen99PagerInfo } from "sharedInterfaces/ITen99PagerInfo";
import { ApplicationState } from "store";
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigation from 'store/HomeNavigation';
import { ICustomerSummaryActions } from "store/HomeNavigation";
import * as ToastNotifications from 'store/ToastNotifications';
import * as UserStore from 'store/User';
import { Ten99Accept, Ten99PrepCancelIcon, Ten99PrepDeleteIcon, Ten99PrepIrsIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";
import { getEntityPrintableName } from "utilities/EntityNameSummary";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ICartMessage
{
	responseText: string,
	statusCode: string
}

interface ILocalState {
	status: enumComponentStatus,
	cart: ITen99Cart,
	cartSummary: ITen99EntitySummary,
	cartCharges: ITen99CartCharges,
	validationMessages: ITen99ApiResponseMessage[],
	pagerInfo: ITen99PagerInfo
	paymentDialogOpen: boolean,
	cartOutOfSync: boolean,
	cartBlockingIssues: string[],
	cartWarnings: string[],
	mailTypeLookupItems: ITen99LookupItem[]
}

interface IItemToDelete {
	id: string,
	name:string,
	ticks:string,
}

interface ICartProps {
	customer: ITen99Customer,
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,
}

type CartProps =
	& ICartProps
	& UserStore.UserState // ... state we've requested from the Redux store
	& ICustomerSummaryActions // ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	& typeof UserStore.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,
	pagerInfo: { pageNumber: 1, totalCount: -1, pageSize: 25 } as ITen99PagerInfo,
	cart: { cartId: 0 } as ITen99Cart,
	cartSummary: {} as ITen99EntitySummary,
	cartCharges: {} as ITen99CartCharges,
	cartBlockingIssues: [],
	cartWarnings: [],
	paymentDialogOpen: false,
	cartOutOfSync: false,
	validationMessages: [] as ITen99ApiResponseMessage[],
	mailTypeLookupItems: [] as ITen99LookupItem[]
};
class ActiveCart extends React.PureComponent<CartProps, ILocalState> {

	itemsToDelete: IItemToDelete[] = [] as IItemToDelete[];

	constructor(props: CartProps) {
		super(props);
		this.state = initialLocalState;
		this.state.pagerInfo.onPagerClick = this.onPagerClick;

		this.loadCart();
		
	}
	// -----------------
	//Handle User events
	private loadCart() {

		const UnableToLoadCartMsg: string = "We are currently unable to display your cart, please try again. If the issue persists please contact support.";

		if (this.state.status !== enumComponentStatus.IsLoading) {
			//do not set state before component has loaded
			this.setState({ validationMessages: [] as ITen99ApiResponseMessage[] });
        }

		MakeApiCall<ITen99Cart>("api/Carts/Customer/" + this.props.customer.customerId + "/ActiveCart", "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					let tempCart: ITen99Cart = data.payload;
					let tempCartOutOfSync: boolean = false;

					if (!data.payload.cartElementsUpToDate) {
						tempCartOutOfSync = true;
					}
					
					let array = [];
					array.push(MakeApiCall<ITen99CartCharges>("api/Carts/" + data.payload.cartId + "/Charges", "GET"));
					array.push(MakeApiCall<ICartMessage[]>("api/Carts/" + data.payload.cartId + "/Messages", "GET"));
					array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/74/", "GET"));

					Promise.all(array).then(values => {
						let mailTypeLookup: ITen99LookupData = {lookupItems : [], options : {requestedTable : 0}, isSelected: false
						};
						let errors: string[] = [];
						let warnings: string[] = [];
						let cartCharges: ITen99CartCharges = { totalAmount : 0, charges : []};

					
						//charges
						let chargesLoaded: boolean = false;
						if (values[0].isSuccess) {
							if (!isNullOrUndefined(values[0].payload)) {
								if (values[0].isSuccess && !isNullOrUndefined(values[0].payload)) {
									cartCharges = values[0].payload as ITen99CartCharges;
									chargesLoaded = true;
								}
							}
						}

						//messages
						let messagesLoaded: boolean = false;
						if (values[1].isSuccess) {
							if (!isNullOrUndefined(values[1].payload)) {
								if (values[1].isSuccess && !isNullOrUndefined(values[1].payload)) {
									let cartMessagesPayload: ICartMessage[] = values[1].payload as ICartMessage[];
									
									cartMessagesPayload.forEach((value, index) => {
										if (value.statusCode === "error") {
											errors.push(value.responseText);
										}
										else {
											warnings.push(value.responseText);
										}
									});
									messagesLoaded = true;
								}
							}
						}

						//mail types lookup
						let lookupsLoaded: boolean = false;
						if (values[2].isSuccess) {
							if (!isNullOrUndefined(values[2].payload)) {
								if (values[2].isSuccess && !isNullOrUndefined(values[2].payload)) {
									let lookupPayload: ITen99LookupData[] = values[2].payload as ITen99LookupData[];

									const lookup: ITen99LookupData | undefined = lookupPayload.find(x => x.options.requestedTable === 74);
									if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
										mailTypeLookup = lookup;
										lookupsLoaded = true;
									}
								}
							}
						}

						let subObjectsLoaded: boolean = (chargesLoaded && messagesLoaded && lookupsLoaded);

						//set state and search
						this.setState({
							cart: tempCart,
							cartOutOfSync: tempCartOutOfSync,
							cartCharges: cartCharges,
							cartBlockingIssues: errors,
							cartWarnings: warnings,
							mailTypeLookupItems: mailTypeLookup.lookupItems,
							validationMessages: subObjectsLoaded ? [] as ITen99ApiResponseMessage[] : [{ type: "Error", message: UnableToLoadCartMsg }] as ITen99ApiResponseMessage[],
							status: subObjectsLoaded ? enumComponentStatus.IsLoading : enumComponentStatus.Error,
						});

						if (subObjectsLoaded) {
							this.search(this.state.pagerInfo.pageNumber); //get elements
						}
					})
				}
				else if (data.isSuccess && isNullOrUndefined(data.payload)) {
					this.setState({ status: enumComponentStatus.PendingAction });
				}
				else if ((data.httpStatusCode === 404) || (data.isSuccess && isNullOrUndefined(data.payload))) {
					//cart not found, user has not added anything
					this.setState({ status: enumComponentStatus.PendingAction, cart: {} as ITen99Cart, cartSummary: {} as ITen99EntitySummary, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
					//update store for badge
					//this.props.UserActions_SetCartCount(0);
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: UnableToLoadCartMsg }] as ITen99ApiResponseMessage[] });
				}
			})
    }

	private search(pageNumber: number) {

		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/Carts/Customer/" + this.props.customer.customerId + "/ActiveCart/Summary/?PageNumber=" + pageNumber.toString() + "&PageSize=" + this.state.pagerInfo.pageSize.toString(), "GET")
			.then(data => {				
				if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload) && data.payload.rows.length === 1 && !isNullOrUndefined(data.payload.rows[0].nestedEntities)) {
					this.setState({ status: enumComponentStatus.PendingAction, cartSummary: data.payload.rows[0], pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount } });					
					//update store for badge
					//this.props.UserActions_SetCartCount(data.pageData.totalCount);
				}
				else if (data.httpStatusCode === 404)
				{
					//cart not found, user has not added anything
					this.setState({ status: enumComponentStatus.PendingAction, cartSummary: {} as ITen99EntitySummary, pagerInfo: { ...this.state.pagerInfo, pageNumber:0, totalCount: 0 } });
					//update store for badge
					//this.props.UserActions_SetCartCount(0);
				}
				else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
					//empty cart
					this.setState({ status: enumComponentStatus.PendingAction, cartSummary: {} as ITen99EntitySummary, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
					//update store for badge
					//this.props.UserActions_SetCartCount(0);
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the cart summary" }] as ITen99ApiResponseMessage[] });
				}
			})		
	}

	private onCancel = () => {
		this.props.onClose(false, undefined);
	}

	private onCartSubmit = () => {
		if (!isNullOrUndefined(this.state.cart.cartId)) {
			let message: string;

			message = "You are about to submit all the items in the cart to be filed with the IRS.";
			
			this.props.confirmDialog_Display("Submit Cart", this.state.cart.cartId.toString(), message, this.onCartSubmitProceed);
		}
	}

	private onMakePayment = () => {
		this.setState({ paymentDialogOpen: true })
	}

	private onMakePaymentClose = (proceesed: boolean, response: ITen99ActionResponseMessage[]) => {
		//close form
		this.setState({ paymentDialogOpen: false })
		if (proceesed) {
			//send toast notification
			this.props.onClose(false, undefined);
			this.props.CustomerSummaryActions_RefreshActiveCustomerSummary();
			//this.props.UserActions_SetCartCount(0);
	
			response.forEach(x =>
				this.props.toastNotifcations_Display({ id: "CartSubmit" + this.state.cart.cartId, message: x.message, type: ToastNotifications.ConvertEnumToToastType(x.messageType) })
			);
		}
	}

	private onCartSubmitProceed = () => {
		this.setState({ status: enumComponentStatus.Processing });
		MakeApiCall<ITen99ActionResponse>("api/Carts/" + this.state.cart.cartId + "/Actions", "POST", JSON.stringify({ actionTypeId: 7, ticks: this.state.cart.ticks } as IAction))
			.then(data => {
				if (data.isSuccess) {
					//send toast notification
					this.props.onClose(false, undefined);
					this.props.CustomerSummaryActions_RefreshSummaryCounts();
					//this.props.UserActions_SetCartCount(0);
					if (!isNullOrUndefined(data.payload)) {
						data.payload.messages.forEach(x =>
							this.props.toastNotifcations_Display({ id: "CartSubmit" + this.state.cart.cartId, message: x.message, type: ToastNotifications.ConvertEnumToToastType(x.messageType) })
						);
					}
				}
				else {
					//send toast notification
					this.props.toastNotifcations_Display({ id: "CartSubmit" + this.state.cart.cartId, message: "Issue occurred submitting cart", type: "error" });
				}

				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}
		
	private onClearCart = () => {
		if (!isNullOrUndefined(this.state.cart.cartId)) {
			this.props.confirmDialog_Display("Delete Cart", this.state.cart.cartId.toString(), "You are about to clear ALL the items in the cart", this.onClearCartProceed);
		}
	}

	private onClearCartProceed = () => {
		this.setState({ status: enumComponentStatus.Processing });
		MakeApiCall<string>("api/Carts/" + this.state.cart.cartId + "/?ticks=" + this.state.cart.ticks, "DELETE")
			.then(data => {
				if (!data.isSuccess) {
					//send toast notification
					this.props.toastNotifcations_Display({ id: "CartDelete" + this.state.cart.cartId, message: "Issue occurred clearing cart", type: "error" });

					//refresh list
					this.search(this.state.pagerInfo.pageNumber); // let search set processing state
				}
				else {
					//update store for badge
					//this.props.UserActions_SetCartCount(0);
					this.props.CustomerSummaryActions_RefreshSummaryCounts();
					this.props.onClose(false, undefined);
					this.props.toastNotifcations_Display({ id: "CartDelete" + this.state.cart.cartId, message: "Cart Cleared", type: "success" });
                }
			});
    }

	private onDeleteCartItem = (entity: ITen99EntitySummary) => {
		let name: string = getEntityPrintableName(entity);
		this.itemsToDelete.push({ id: entity.id, name: name, ticks:entity.ticks} as IItemToDelete)
		this.props.confirmDialog_Display("Delete Cart item", entity.id, "You are about to delete the following cart item: " + name, this.onDeleteCartItemProceed, false, this.onDeleteCartItemCancel);
	}

	private onDeleteCartItemCancel = (id: string) => {
		//remove item from list tracking deletions
		const cartItemIndex: number = this.itemsToDelete.findIndex((item) => item.id === id)
		this.itemsToDelete.splice(cartItemIndex);
	}

	private onDeleteCartItemProceed = (id: string) => {
		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing) {
			//get the item to make sure it is still there and get name
			const cartItemIndex: number = this.itemsToDelete.findIndex((item) => item.id === id)
			const cartItem: IItemToDelete | undefined = this.itemsToDelete[cartItemIndex];

			if (!isNullOrUndefined(cartItem)) {
				this.setState({ status: enumComponentStatus.Processing });
				//call the api and set the results in the state to reflect on the render
				MakeApiCall<string>("api/CartElements/" + id + "/?ticks=" + cartItem.ticks, "DELETE")
					.then(data => {
						if (data.isSuccess) {
							this.props.CustomerSummaryActions_RefreshSummaryCounts();
							//refresh cart
							this.loadCart();
							//send toast notification
							this.props.toastNotifcations_Display({ id: "CartDelete" + id, message: "Cart item " + cartItem.name + " deleted", type: "success" });
						}
						else {
							//send toast notification
							this.props.toastNotifcations_Display({ id: "CartDelete" + id, message: "Issue occurred deleting cart item " + cartItem.name, type: "error" });
							this.setState({ status: enumComponentStatus.PendingAction });
						}
						
					});
				//remove item from list tracking deletions
				this.itemsToDelete.splice(cartItemIndex);
			}
			else {
				console.error("Unable to locally find CartItem: " + id);
			}
		}
	}

	private onAcceptChanges = () => {
		this.props.confirmDialog_Display("AcceptCart", this.state.cart.cartId.toString(), "You are about to accpet the items marked as 'Updated Since Added' as valid changes.", this.onCartResyncProceed);
	}	

	private onCartResyncProceed = () => {
		this.setState({ status: enumComponentStatus.Processing });
		MakeApiCall<ITen99ActionResponse>("api/Carts/" + this.state.cart.cartId + "/Resync", "POST")
			.then(data => {
				if (data.isSuccess) {					
					this.loadCart();
				}
				else {
					this.setState({ status: enumComponentStatus.PendingAction, validationMessages: [{ type: "Error", message: "Unable to update" }] as ITen99ApiResponseMessage[] });
					
                }
			});

	}
	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private UpdateCart = (mailTypeId: number, electronicDeliveryRecipientConsent: boolean) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: [] as ITen99ApiResponseMessage[] });
		MakeApiCall<string>("api/Carts/" + this.state.cart.cartId, "PUT", JSON.stringify({ mailTypeId: mailTypeId, electronicDeliveryRecipientConsent: electronicDeliveryRecipientConsent, ticks: this.state.cart.ticks } as ITen99Cart))
			.then(response => {
				if (response.isSuccess && !isNullOrUndefined(response.payload)) {
					this.loadCart(); //refresh the cart
				}
				else {
					this.setState({ status: enumComponentStatus.PendingAction, validationMessages: [{ type: "Error", message: "Unable to update" }] as ITen99ApiResponseMessage[] });
				}
			})	
    }

	private GenerateButtonOptions() {

		let retVal: ITen99DynamicButtonProps[] = [] as ITen99DynamicButtonProps[];		
		if (!isNullOrUndefined(this.state.cartSummary.actions) && this.state.cartSummary.actions.includes(ActionTypesEnum.Delete)) {
			retVal.push({ action: ActionTypesEnum.Delete, text: "Clear Cart", variant: "contained", color: "secondary", icon: < Ten99PrepDeleteIcon />, onClick: this.onClearCart } as ITen99DynamicButtonProps);
		}
		if (this.state.cartOutOfSync) {
			retVal.push({
				action: ActionTypesEnum.SubmitWithPayment,
				disabled: !this.state.cartSummary.actions.includes(ActionTypesEnum.Edit),
				text: "Accept Changes",
				variant: "contained",
				color: "secondary",
				icon: < Ten99Accept />,
				onClick: this.onAcceptChanges,
				tooltip: "Acknowledge and Accept the 'Updated Since Added' items as valid"
			} as ITen99DynamicButtonProps);
		}
		let submitDisabled: boolean =
			this.state.status !== enumComponentStatus.PendingAction
			|| this.state.cartOutOfSync
			|| (!isNullOrUndefined(this.state.cartBlockingIssues)
				&& this.state.cartBlockingIssues.length > 0)
			|| (!this.state.cartSummary.actions?.includes(ActionTypesEnum.Submit) && !this.state.cartSummary.actions?.includes(ActionTypesEnum.SubmitWithPayment))
			;

		if (!isNullOrUndefined(this.state.cartSummary.actions) && this.state.cartSummary.actions.includes(ActionTypesEnum.Submit)) 
		{
			retVal.push({
				action: ActionTypesEnum.Submit,
				text: "Submit For Processing",
				variant: "contained",
				color: "primary",
				disabled: submitDisabled,
				tooltip:
					submitDisabled ?
						"Cannot submit while the cart has unaccepted changes or blocking issues" : "",
				icon: < Ten99PrepIrsIcon />,
				onClick: this.onCartSubmit
			} as ITen99DynamicButtonProps);
		}
		if (!isNullOrUndefined(this.state.cartSummary.actions) && this.state.cartSummary.actions.includes(ActionTypesEnum.SubmitWithPayment)) {
			retVal.push({ action: ActionTypesEnum.SubmitWithPayment, text: "Make Payment and Submit For Processing", variant: "contained", color: "primary", disabled: submitDisabled, tooltip: submitDisabled ? "Cannot submit while the cart has unaccepted changes or blocking issues" : "",  icon: < Ten99PrepIrsIcon />, onClick: this.onMakePayment } as ITen99DynamicButtonProps);
		}

		return retVal;
    }

	private onViewDoc = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })
		MakeApiCall<string>("api/ReportManage/TaxForm/Type/" + entity.type + "/Id/" + entity.id, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
						this.setState({ status: enumComponentStatus.PendingAction })
					}
				}
				else {
					this.setState({ status: enumComponentStatus.PendingAction, validationMessages: data.details }); //error
				}
			})
	}

	private onActionClickByEntity = (entity: ITen99EntitySummary, action: ActionTypesEnum) => {
		switch (action) {
			case ActionTypesEnum.Delete:
				this.onDeleteCartItem(entity);
				break;
			case ActionTypesEnum.ViewDoc:
				this.onViewDoc(entity);
				break;
			default:
				//nothing
				break;
		}
	}
	
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {
		//
		return (
			<React.Fragment>
				{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
					<DialogContent>
						<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
					</DialogContent>
				)}
				{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Processing )&& (
					<CartDisplay						
						cart={this.state.cart}
						cartSummary={this.state.cartSummary}
						cartCharges={this.state.cartCharges}
						onClose={this.onCancel}
						pagerInfo={this.state.pagerInfo}
						onActionClick={this.onActionClickByEntity}
						buttons={this.GenerateButtonOptions()}
						mailTypeLookupItems={this.state.mailTypeLookupItems}
						onCartUpdate={!isNullOrUndefined(this.state.cartSummary.actions) && this.state.cartSummary.actions.includes(ActionTypesEnum.Edit) ? this.UpdateCart : undefined}
						validationMessages={this.state.validationMessages}
						cartBlockingIssues={this.state.cartBlockingIssues}
						warnings={this.state.cartWarnings}
					
					/>
				)}
				{this.state.paymentDialogOpen && (
					<Dialog open={this.state.paymentDialogOpen} fullWidth={true} maxWidth="lg">

						<Payment cartId={this.state.cart.cartId} cartTicks={this.state.cart.ticks} amount={this.state.cartCharges.totalAmount} onClose={this.onMakePaymentClose} />
						
					</Dialog>
				)}
				{(this.state.status === enumComponentStatus.Error && (
					<React.Fragment>
						<DialogContent>
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							)}
						</DialogContent>
						<DialogActions>
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => this.props.onClose(false, undefined)}>Close</Button>
						</DialogActions>
					</React.Fragment>
				))}
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.user, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, ...UserStore.actionCreators, ...HomeNavigation.actionCreators }, // Selects which action creators are merged into the component's props
)(ActiveCart);