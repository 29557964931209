import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import * as React from 'react';

interface IKeyValue {
    keyName: string,
    value: string,
    variant?: Variant,
    disableKeyNameWordWrap?: boolean
}

function KeyValueDisplay(props: IKeyValue) {

    return (
         <React.Fragment>
            <Typography variant={props.variant} display="inline" sx={{ fontWeight: "bold", paddingRight: "5px", wordWrap: (props.disableKeyNameWordWrap ?? false) ? "normal" : "break-word", wordBreak: (props.disableKeyNameWordWrap ?? false) ? "normal" : "break-word" }}> {props.keyName + ":"}</Typography><Typography variant={props.variant} display="inline" sx={{ paddingRight: "5px", wordWrap: "break-word", wordBreak: "break-word" }}>{props.value}</Typography>
        </React.Fragment>
    );
}

export default KeyValueDisplay;