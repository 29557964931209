import { Button, DialogActions, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import MessagesDisplay from "components/information/MessagesDisplay";
import ImportHistory from "components/ten99Prep/Import/Components/ImportHistory/ImportHistory";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { ITen99ImportHistory } from "sharedInterfaces/ITen99ImportHistory";
import { ITen99Message } from "sharedInterfaces/ITen99Message";
import { ITen99MessagePriorityEnum } from "sharedInterfaces/ITen99MessagePriorityEnum";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
};

interface ILocalState {
	validationMessages: ITen99ApiResponseMessage[],
	importHistoryItemsCurrentPage: ITen99ImportHistory[],
	importHistoryPagerCurrentPage: number,
	importHistoryPagerTotalCount: number,
	importHistoryStatus: enumComponentStatus,
	messagesOpen: boolean,
	messages: ITen99Message[],
}

interface ICustomerImportProps {
	customer: ITen99Customer,
}

type CustomerImportProps =
	& ICustomerImportProps
	;

const initialLocalState: ILocalState = { importHistoryStatus: enumComponentStatus.IsLoading, importHistoryPagerCurrentPage: 1, importHistoryPagerTotalCount: -1, importHistoryItemsCurrentPage: {} as ITen99ImportHistory[], validationMessages: [] as ITen99ApiResponseMessage[], messagesOpen: false, messages: [] as ITen99Message[] };
class ImportHistoryContoller extends React.PureComponent<CustomerImportProps, ILocalState> {
	importHistoryPageSize: number = 10;
	importHistoryInterval: any;

	constructor(props: CustomerImportProps) {
		super(props);
		this.state = initialLocalState;

		this.importHistorySearch(1); //get first page of import history

	}
	// -----------------
	//Handle User events

	private importHistorySearch = (pageNumber: number) => {

		MakeApiCall<ITen99ImportHistory[]>("api/Imports/Customer/" + this.props.customer.customerId + "/?PageNumber=" + pageNumber.toString() + "&PageSize=" + this.importHistoryPageSize, "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload)) {
					this.setState({ importHistoryStatus: enumComponentStatus.PendingAction, importHistoryItemsCurrentPage: data.payload, importHistoryPagerCurrentPage: data.pageData.pageNumber, importHistoryPagerTotalCount: data.pageData.totalCount });
				}
				else if (data.httpStatusCode === 404) {
					//customer not found
					this.setState({ importHistoryStatus: enumComponentStatus.PendingAction, importHistoryItemsCurrentPage: {} as ITen99ImportHistory[], importHistoryPagerCurrentPage: 0, importHistoryPagerTotalCount: 0 });
				}
				else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
					//empty cart
					this.setState({ importHistoryStatus: enumComponentStatus.PendingAction, importHistoryItemsCurrentPage: {} as ITen99ImportHistory[], importHistoryPagerCurrentPage: 0, importHistoryPagerTotalCount: 0 });
				}
				else {
					this.setState({ importHistoryStatus: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load import history" }] as ITen99ApiResponseMessage[] });
				}
			})
	}

	private importHistoryDownloadClick = (id: number) => {
		this.setState({ validationMessages: [] });
		MakeApiCall<string>("api/Imports/" + id.toString(), "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {

						let item: ITen99ImportHistory | undefined = this.state.importHistoryItemsCurrentPage.find(x => x.importId == id)
						let fileName = "ImportResults.xlsx";
						if (item !== undefined) {
							fileName = item.fileName;
							let extIndex: number = fileName.lastIndexOf(".");
							fileName = fileName.substring(0, extIndex) + "_ImportResults" + fileName.substring(extIndex);
						}

						PromptDownloadFile(data.payload, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", fileName)
					}
				}
				this.setState({ validationMessages: !isNullOrUndefined(data.details) ? data.details : [] });
			})
	}

	private importHistoryDownloadWorksheetErrorsClick = (id: number) => {
		this.setState({ validationMessages: [], messages:[] });
		MakeApiCall<string[]>("api/Imports/" + id.toString() + "/Errors", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let messages: ITen99Message[] = [] as ITen99Message[]; 
						data.payload.forEach(x => messages.push({ messageText: x, priority: ITen99MessagePriorityEnum.Error }));
						if (messages.length === 0) {
							messages.push({ messageText: "No error messages found. Please contact support.", priority: ITen99MessagePriorityEnum.Error });
						}
						this.setState({ messagesOpen: true, messages: messages });
					}
				}
				this.setState({ validationMessages: !isNullOrUndefined(data.details) ? data.details : [] });
			})
	}

	private setMessagesOpen = (open:boolean) => {
		this.setState({ messagesOpen: open });
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	componentDidMount() {
		this.importHistoryInterval = setInterval(() => { if (this.state.importHistoryStatus !== enumComponentStatus.IsLoading) { this.importHistorySearch(this.state.importHistoryPagerCurrentPage) } }, 30000); //check after waiting a bit

	}

	componentWillUnmount() {
		//ensure timeouts are gone
		clearInterval(this.importHistoryInterval);
	}
	// Render
	render() {
		return (
			<React.Fragment>
				<ImportHistory status={this.state.importHistoryStatus} history={this.state.importHistoryItemsCurrentPage} pagerPageSize={this.importHistoryPageSize} pagerCurrentPage={this.state.importHistoryPagerCurrentPage} pagerTotalCount={this.state.importHistoryPagerTotalCount} onPagerClick={this.importHistorySearch} onDownloadClick={this.importHistoryDownloadClick} onDownloadWorkSheetErrorsClick={this.importHistoryDownloadWorksheetErrorsClick} />

				< Dialog open={this.state.messagesOpen} fullWidth={true} >
					<DialogTitle>Issues with Import</DialogTitle>
					<DialogContent>
						<Grid container spacing={1} sx={{ textAlign: 'center', overflow:'visible' }}>
							<Grid item xs={12}>
								<MessagesDisplay messages={this.state.messages} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button type="button" variant="contained" color="primary" onClick={ () => this.setMessagesOpen(false)}>Close</Button>
					</DialogActions>
					</Dialog >
			</React.Fragment>
			)
	}
}

export default ImportHistoryContoller;