import { isNullOrUndefined } from "util";
import { GetCompany, GetUser, GetYear } from 'utilities/UserId';
import { ITen99ApiResponse, ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";

const defaultTimeoutMiliseconds: number = 150000;
const apiRoot: string = sessionStorage.getItem("ten99RootApi") ?? "";
//wrapper for calling fetch -- allows us to change logic if needed, standardize calls, and reduce duplicate code
export function MakeApiCall<expectedResponseType>(path: string, restVerb: string, body?: string | FormData) {
    //set headers
    let requestHeaders: Headers = new Headers();

    const abortController: AbortController = new AbortController();
    const signal:AbortSignal = abortController.signal;
    //set timeout
    const timeoutId = setTimeout(() => abortController.abort(), defaultTimeoutMiliseconds)

    if (typeof (body) === "object") {
        
    }
    else {
        requestHeaders.append("Content-Type", "application/json");
    }
    let token: string | null = GetUser();
    if (!isNullOrUndefined(token)) {
        requestHeaders.append("Authorization", "Bearer " + token);
    }

    let year: string | null = GetYear();
    let delimiter: string = '&';
    if (!isNullOrUndefined(year)) {
        //is there a query string on the current path?
        if (path.indexOf("?") > 0) {
            // use default to add to end of path  
            delimiter = "&";
        }        
        else {
            //no query string add "? to indicate begining of query string
            delimiter = "?";
        }
        path = path + delimiter +"FilingYear=" + year;
    }

    let companyId: string | null = GetCompany();
    
    if (!isNullOrUndefined(companyId)) {
        //is there a query string on the current path?
        if (path.indexOf("?") > 0) {
            // use default to add to end of path   
            delimiter = "&";
        }
        else {
            //no query string add "? to indicate begining of query string
            delimiter = "?";
        }
        path = path + delimiter + "CompanyId=" + companyId;
    }
    
    if (path.indexOf("api/") === 0) { //if we have not overriden the core path 
        path = apiRoot + path;
    }

    const request: Request = new Request(path, {
        method: restVerb.toUpperCase(),
        headers: requestHeaders,
        cache: "no-cache",
        body: body
    });
    
    
    //make api call, return promise
    return fetch(request, { signal }).then(response => {
        //clear timeout
        clearTimeout(timeoutId);
                

        if ((response.status === 401
            && !isNullOrUndefined(token)//check to see if 401 as that means they likely had a token expire - ignore if there is no token (authorized user)
            && !path.startsWith(apiRoot + 'api/User/TwoFactor/Phone/Confirm?') //ingore phone confirmations
            ) 
            || (response.status === 503) // this should only occur when the app offline is put on the front end
        ) {
            sessionStorage.clear(); //clear all the session data (as if user closed window)
            window.location.reload();
        }       
               
        return UnpackITen99ApiResponse<expectedResponseType>(response);  //unpack the response, return promise
    }).catch(
        function (e: Error)
        {
            const message: string = e.name === "AbortError" ? "Timeout occurred. Please try again and contact support if issue persists.": "Unkown error occurred. Please contact support." ;

            return new Promise((resolve, reject) => {
                resolve({
                    isSuccess: false,
                    payload: undefined,
                    details: [{ type: "Fail", propertyNames: [""] as string[], message: message, isWarning: false }],
                    pageData: null,
                    httpStatusCode: 408,
                });
            }) as Promise<ITen99ApiResponse<expectedResponseType>>
        }
    );
   


};

//handle the processing of the response. This is to mainly deal with potential serializiation issues as objects might not come back as planned
function UnpackITen99ApiResponse<expectedResponseType>(response: Response) {
     if (response.ok) {
        //process response                    
         return (response.json() as Promise<ITen99ApiResponse<expectedResponseType>>).then(data =>
             new Promise((resolve, reject) => {
                 data.httpStatusCode = response.status;// update the response code
                 resolve(data);
             }) as Promise<ITen99ApiResponse<expectedResponseType>>)
             .catch(error => {
            return new Promise((resolve, reject) => {
                resolve({
                    isSuccess: false,
                    payload: undefined,
                    details: [{ type: "Fail", propertyNames: [""] as string[], message: "Call successful, but received unexpected response. Please try again. Contact support if issue persists.", isWarning: false }],
                    pageData: null,
                    httpStatusCode: response.status,
                });
            }) as Promise<ITen99ApiResponse<expectedResponseType>>
        });
    }
    else {
        //handle failed
        //try to deserialize just the messages
        return (response.json() as Promise<{ details: ITen99ApiResponseMessage[] }>).then(data =>
            new Promise((resolve, reject) => {
                resolve({
                    isSuccess: false,
                    payload: undefined,
                    details: data.details,
                    pageData: null,
                    httpStatusCode: response.status,
                });
            }) as Promise<ITen99ApiResponse<expectedResponseType>>
        ).catch(error => {
            return new Promise((resolve, reject) => {
                resolve({
                    isSuccess: false,
                    payload: undefined,
                    details: [{ type: "Fail", propertyNames: [""] as string[], message: "Unexpected response. Please try again. Contact support if issue persists.", isWarning: false }],
                    pageData: null,
                    httpStatusCode: response.status,
                });
            }) as Promise<ITen99ApiResponse<expectedResponseType>>
        });
    }
}

export function PromptDownloadFile(data: string, mimeType: string, fileName: string) {
    let view: Uint8Array = Uint8Array.from(atob(data), x => x.charCodeAt(0));
    let blob = new Blob([view.buffer], { type: mimeType })
    var a = document.createElement("a");
    let url: string = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();

    setTimeout(() => { URL.revokeObjectURL(url); url = "" }, 500) //set a timeout to release the objectURL... need to give it just a bit so download starts first

}
