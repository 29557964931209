import MessagesDisplay from 'components/information/MessagesDisplay';
import * as React from 'react';
import { ITen99Message } from 'sharedInterfaces/ITen99Message';
import { ITen99MessagePriorityEnum } from 'sharedInterfaces/ITen99MessagePriorityEnum';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { GetCompany } from 'utilities/UserId';


interface IAnnouncementProps {

}

interface IAnnouncement {
	announcementText: string,
	priorityId: ITen99MessagePriorityEnum,
}

interface ILocalState {	
	messages: IAnnouncement[],
}

const initialLocalState: ILocalState = {messages: [] as IAnnouncement[] };
class Announcements extends React.PureComponent<IAnnouncementProps, ILocalState> {

	constructor(props: IAnnouncement) {
		super(props);
		this.state = initialLocalState;
		this.getAnnoucements();
	}
	// -----------------
	//Handle User events

	private getAnnoucements = () => {
		let companyId: string | null = GetCompany();
		if (!isNullOrUndefined(companyId) && companyId !== "0") {
			//call the api and set the results in the state to reflect on the render
			MakeApiCall<IAnnouncement[]>("api/Announcements/?PageNumber=1&PageSize=25", "GET")
				.then(data => {
					if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.length > 0) {
						//refresh list
						this.setState({ messages: data.payload })
					}
				});
		}
	}
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		return (
			<React.Fragment>
				<MessagesDisplay messages={this.state.messages.map((item, index) => ({ messageText: item.announcementText, priority: item.priorityId } as ITen99Message))} />
			</React.Fragment>			
		);
	}
}

// -----------------
export default Announcements;