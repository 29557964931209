import { Action, Reducer } from 'redux';
import { ITen99ActionResponseTypeEnum } from 'sharedInterfaces/ITen99ActionResponse';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type ToastType = "success" | "warning" | "error" | "info";
export interface IToastMessage {
    id: string,
    message: string,
    type?: ToastType,
}

export interface ToastNotificationsState { //tagged properties with "toastNotifications_" so when looking in the props can easily tell these came from this store
    toastNotifications_messages: IToastMessage[],
    toastNotifications_currentMessage: IToastMessage | undefined
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface Display { type: 'ToastNotifcations/Display', message: IToastMessage }
export interface Remove { type: 'ToastNotifcations/Remove', messageId:string | undefined }
export interface Clear { type: 'ToastNotifcations/Clear' }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = Display | Remove | Clear ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function ConvertEnumToToastType(type: ITen99ActionResponseTypeEnum) {
    switch (type) {
        case ITen99ActionResponseTypeEnum.Error:
            return "error" as ToastType;
        case ITen99ActionResponseTypeEnum.Success:
            return "success" as ToastType;
        case ITen99ActionResponseTypeEnum.Warning:
            return "warning" as ToastType;
    }
} 

export const actionCreators = {
    toastNotifcations_Display: (message: IToastMessage) => ({ type: 'ToastNotifcations/Display', message: message } as Display),
    toastNotifcations_Remove: (messageId: string | undefined) => ({ type: 'ToastNotifcations/Remove', messageId: messageId } as Remove),
    toastNotifcations_Clear: () => ({ type: 'ToastNotifcations/Clear' } as Clear),
};


const unloadedState: ToastNotificationsState = {
    toastNotifications_messages: [] as IToastMessage[], toastNotifications_currentMessage: undefined};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ToastNotificationsState> = (state: ToastNotificationsState | undefined, incomingAction: Action): ToastNotificationsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'ToastNotifcations/Display':
            //do not add if the message is already there
            if (state.toastNotifications_messages.findIndex((item) => item.id === action.message.id) === -1) {                
                let temp: IToastMessage[] = [...state.toastNotifications_messages, action.message];                

                return Object.assign({}, state, {
                    toastNotifications_messages: temp, toastNotifications_currentMessage: temp.length > 0 ? temp[0]: undefined
                });
            }
            return state; // do nothing
        case 'ToastNotifcations/Remove':
            //skip if undefined
            if (action.messageId !== undefined) {
                let temp: IToastMessage[] = [...state.toastNotifications_messages.filter((item) => item.id !== action.messageId)];

                return Object.assign({}, state, {
                    toastNotifications_messages: temp, toastNotifications_currentMessage: temp.length > 0 ? temp[0] : undefined,
                });
            }
        case 'ToastNotifcations/Clear':
            return unloadedState;
        default:
            return state;
    }
};
