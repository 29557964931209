import { Button, Grid } from "@mui/material";
import DialogTemplate from "components/display/DialogTemplate";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { Ten99PrepBackIcon } from "Ten99PrepOverloads/IconOverloads";
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	Error
};

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
}

interface IImportDataProps {
	customer: ITen99Customer,
	onProceed: () => void,
	onBack: () => void,

}

type ImportDataProps =
	& IImportDataProps
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.IsLoading, validationMessages: [] as ITen99ApiResponseMessage[] };
class RollOverAll extends React.PureComponent<ImportDataProps, ILocalState> {

	constructor(props: ImportDataProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<string>("api/Imports/Backfill/" + this.props.customer.customerId, "POST")
			.then(data => {
				if (data.isSuccess) {
					this.props.onProceed();
				}
				else {
					if (data.httpStatusCode === 404) {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "No data from previous year to import." }] as ITen99ApiResponseMessage[] });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //error
					}
				}
			})
	}
		
	// -----------------
	// Componet lifecycle events
	// -----------------
	// Render
	render() {
		return (
			<DialogTemplate onClose={this.props.onBack} title={"Rollover All Prior Year Data: " + this.props.customer.name}
				buttonsLeft={this.state.status === enumComponentStatus.Error ?
					[
						<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.props.onBack}>Back</Button>
					] as unknown as typeof Button[] : [] }>
					<React.Fragment>
						{(this.state.status === enumComponentStatus.IsLoading) && (
							<ProcessingModal modal={true} />
						)}
						<Grid container spacing={1}>
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<Grid item xs={12}>
									<ApiErrorResponseMessages messages={this.state.validationMessages} />
								</Grid>
							)}
						</Grid>
					</React.Fragment>
			</DialogTemplate>
		)
	}
}

export default RollOverAll;