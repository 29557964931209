import SimpleLogout from "components/SimpleLogout";
import { Route, Switch } from "react-router";
import PrivateRouteCustom from "utilities/PrivateRouteCustom";
import DocumentRetrievalGateway from "./dashboard/DocumentRetrievalGateway";
import DocumentRetrievalUserGateway from "./user/DocumentRetrievalUserGateway";

export default function DocumentRetrievalRoutes() {
	return (
		<>
            <Switch>
				<Route exact path="/documentRetrievalGate/login" component={DocumentRetrievalUserGateway} />
				<PrivateRouteCustom exact path="/documentRetrievalGate/dashboard" component={DocumentRetrievalGateway} redirectUrl="/documentRetrievalGate/login" />
				<Route exact path="/documentRetrievalGate/logout"
					render={x => <SimpleLogout LogoutUrl="api/RecipientGateway/Login" RedirectUrl="/documentRetrievalGate/login" />}
				/>
            </Switch>
		</>
	);
}