import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import EntityAddEditView from 'components/ten99Prep/EntityAddEditView/EntityAddEditView';
import * as React from 'react';
import { connect } from 'react-redux';
import { IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage, ITen99Dashboard } from 'sharedInterfaces/ITen99ApiResponse';
import { ApplicationState } from 'store';
import * as ConfirmDialogStore from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from 'store/HomeNavigation';
import * as ToastNotificationsStore from 'store/ToastNotifications';
import { Ten99PrepAddIcon, Ten99PrepCancelIcon, Ten99PrepFilterIcon, Ten99PrepSearchIcon, Ten99PrepSelectedIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { onUserAdminRedirect, UserManagementEndpoint } from 'utilities/RedirectUserAdmin';
import { ITen99ActionResponseMessage } from '../../sharedInterfaces/ITen99ActionResponse';
import CustomerAddMaconomy from './CustomerAddMaconomy';

enum enumConponentStatus {
	PendingAction,
	Processing,
	Invalid,
	Error
};

interface ICustomer {
	id: string;
	name: string;
	maconomyKey: string;
	ticks: string;
}

type CustomersProps =	
	HomeNavigationStore.CustomerSummaryState // ... state we've requested from the Redux store
	& ICustomerSummaryActions // ... plus action creators we've requested
	& typeof ConfirmDialogStore.actionCreators// ... plus action creators we've requested
	& typeof ToastNotificationsStore.actionCreators// ... plus action creators we've requested
	;

interface ILocalState {
	status: enumConponentStatus,
	filter: string,
	pagerCurrentPage: number,
	pagerTotalCount: number,
	customers?: ICustomer[],
	validationMessages: ITen99ApiResponseMessage[],
	editFilterOpen: boolean,
	addEditCustomerOpen: boolean,
	addEditCustomerSetting?: IAevEntitySetting,
	tempFilterValue: string,
	bulkActions: number[],
}
const initialLocalState = { status: enumConponentStatus.PendingAction, filter: "", editFilterOpen: false, addEditCustomerOpen: false, addEditCustomerSetting: undefined, addEditCustomer: undefined, tempFilterValue: "", pagerCurrentPage: 1, pagerTotalCount: 0, bulkActions: [] as number[], customers: {} as ICustomer[], validationMessages: {} as ITen99ApiResponseMessage[] } as ILocalState;
class Customers extends React.PureComponent<CustomersProps, ILocalState> {
	//local state
	state = initialLocalState;

	private pageSize: number = 25;
	// -----------------
	//Handle User events
	
	private customerSearch(searchVal: string, pageNumber: number) {
		//do not process, if already in progress
		//do not search if the user does not have access to company
		if (this.state.status !== enumConponentStatus.Processing && !isNullOrUndefined(this.props.activeCompany) && this.props.activeCompany.hasAccess) {
			this.setState({ status: enumConponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
			//call the api and set the results in the state to reflect on the render
			MakeApiCall<ITen99Dashboard<ICustomer>>("api/Customers/Summary/?SearchVal=" + encodeURIComponent(searchVal) + "&PageNumber=" + pageNumber + "&PageSize=" + this.pageSize.toString(), "GET")
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.pageData)) {
							if (data.pageData.totalCount > 0 && !isNullOrUndefined(data.payload)) {
								this.setState({ status: enumConponentStatus.PendingAction, bulkActions: data.payload.bulkActions, customers: data.payload.rows, pagerCurrentPage: data.pageData.pageNumber, pagerTotalCount: data.pageData.totalCount });
							}
							else if (data.pageData.totalCount === 0 && !isNullOrUndefined(data.payload) && searchVal === "") {
								this.setState({ status: enumConponentStatus.PendingAction, bulkActions: data.payload.bulkActions, customers: [] as ICustomer[], validationMessages: [{ type: "Invalid", propertyNames: [""] as string[], message: "No customers found. Please add one to continue. ", isWarning: false }] });
							}
							else {
								let tempBulkActions: number[] = [];
								//try to grab bulk actions if possible
								if (!isNullOrUndefined(data.payload)) {
									tempBulkActions = data.payload.bulkActions
								}
								this.setState({ status: enumConponentStatus.Error, bulkActions: tempBulkActions, customers: [] as ICustomer[], validationMessages: [{ type: "Invalid", propertyNames: [""] as string[], message: "No customers found. Please alter filter and try again.", isWarning: false }] });
							}
						}
						else {
							this.setState({ status: enumConponentStatus.Error, validationMessages: [{ type: "Fail-pageInfo", propertyNames: [""] as string[], message: "Unexpected response. Please try again. Contact support if issue persists.", isWarning: false }] });
                        }
					}
					else {
						if (data.httpStatusCode === 422) {
							this.setState({ status: enumConponentStatus.Invalid, customers: [] as ICustomer[], validationMessages: data.details });
						}
						else {
							this.setState({ status: enumConponentStatus.Error, validationMessages: data.details });
						}
					}						
				});
		}
	}

	private onAddEditViewEntityProccessed = (proceesed: boolean, message?: ITen99ActionResponseMessage) => {
		this.setState({ addEditCustomerOpen: false, addEditCustomerSetting: undefined });
		//if (response.processed) {
		//	if (!isNullOrUndefined(response.id) && response.id !== "") {
		//		this.onCustomerSelect(response.id)
		//	}
		//	//refresh list as things might have changed, count definately did
		//	this.customerSearch(this.state.tempFilterValue, this.state.pagerCurrentPage);
		//	//send toast notification
		//	this.props.toastNotifcations_Display({ id: "CustomerAddEdit", message: response.message, type: "success" });
		//}
	}

	private onFilterSubmit = () => {
		this.setState((prevState) => { return { filter: prevState.tempFilterValue } })
		this.customerSearch(this.state.tempFilterValue, 1);
		this.onFilterClose();
	}

	private onFilterClear = () => {
		this.setState({ filter: "", tempFilterValue: "" })
		this.customerSearch("", 1);
	}

	private onFilterOpen = () => {
		this.setState({ editFilterOpen: true });
	}
	private onFilterClose = () => {
		this.setState({ editFilterOpen: false });
	}

	private onCustomerSelect = (customerId: string) => {
		//update the active user 
		this.props.CustomerSummaryActions_AddActiveCustomer(customerId);
		window.history.replaceState(null, "", "/gate/home/customer/" + customerId + "/summary");
    }


	private OnAddCustomer = () => {
		//if internal user go to maconomy customer add
		if (this.props.activeCompany?.id === 1) {
			this.setState({ addEditCustomerOpen: true});
		}
		else {
			onUserAdminRedirect(UserManagementEndpoint.Customers);
		}
	}

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	componentDidMount() {
		//send event to home to make sure tab is properly set incase user click "back", "forward" or directly navigated
		this.customerSearch("", 1); // do intial search
	}
	componentDidUpdate(prev: CustomersProps) {
		let process: boolean = false;
		if (!isNullOrUndefined(prev.activeCustomer) && !isNullOrUndefined(this.props.activeCustomer)) {
			//console.info("Customers: Update: " + this.props.activeCustomer.customerId + " " + prev.activeCustomer.ticks + " " + this.props.activeCustomer.ticks);
			//if item updated
			if (prev.activeCustomer.customerId === this.props.activeCustomer.customerId && prev.activeCustomer.ticks !== this.props.activeCustomer.ticks) {				
				process = true;
			}
		}
		else if (!isNullOrUndefined(prev.activeCustomer) && isNullOrUndefined(this.props.activeCustomer)) { //if we had an item and it is now gone refresh as this was likely a delete
			process = true;
		}
		else if (!isNullOrUndefined(prev.activeCompany) && !isNullOrUndefined(this.props.activeCompany) && prev.activeCompany.id !== this.props.activeCompany.id) {
			process = true;
		}
		else if (isNullOrUndefined(prev.activeCompany) && !isNullOrUndefined(this.props.activeCompany)) {
			process = true;
		}

		if (process) {
			this.customerSearch(this.state.tempFilterValue, 1); //refresh search
        }
    }
	
	// Render
	render() {
		
		
		const filterButtonText: string = this.state.filter === "" ? "No Filter" : "Filtered on '" + this.state.filter + "'";
		//stupid typescript is forcie me to allow this to be undfined... but it cannot be
		const AddCustomerDisabled: boolean | undefined = !isNullOrUndefined(this.props.activeCompany) ? !(this.props.activeCompany.hasAccess && (this.props.activeCompany.isUserAdmin || this.props.activeCompany.isAddCustomer)) : true;
		const AddCustomerTooltip: string = AddCustomerDisabled ? "You do not have rights to add customers. Please contact a user admin for your company." : "Add Customer"
			return (			
				<React.Fragment>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h5">Customers
								<InfoToolTip text={AddCustomerTooltip}>
									<Fab size="small" sx={{ marginLeft: "10px" }} color="primary" onClick={this.OnAddCustomer} disabled={AddCustomerDisabled}>
									<Ten99PrepAddIcon />
								</Fab>
								</InfoToolTip>
							</Typography>
						</Grid>
						<Grid item xs={12} sx={{textAlign: 'right'}}>
							<ButtonGroup variant="contained" color="secondary" sx={{ width: "100%"}} >
								<Button type="button" startIcon={<Ten99PrepFilterIcon />} sx={{ width: "100%" }} onClick={this.onFilterOpen}>{filterButtonText}</Button>
								{this.state.filter !== "" && (
									<Button type="button" size="small" fullWidth={false} onClick={this.onFilterClear}><Ten99PrepCancelIcon /></Button>
								)}
							</ButtonGroup>
						</Grid>

						<Grid item xs={12}>
							{this.state.status === enumConponentStatus.Processing && (
								<ProcessingModal modal={true} />
							)}
							{!isNullOrUndefined(this.state.customers) && this.state.customers.length > 0 && (
								<Grid container spacing={1} sx={{textAlign: 'center'}}>
									<Grid item xs={12}>
										<Typography variant="subtitle2" color="textSecondary" align="left" >Showing {this.state.customers.length} of {this.state.pagerTotalCount} customers</Typography>
										{this.state.customers.length === this.pageSize && (
											<Typography variant="subtitle2" color="primary">Use the filter to reduce the number of records if your desired Customer is not shown</Typography>
										)}
										<br />
										<List disablePadding>
											{this.state.customers.map((item, index) => {
												let combinedName: string = isNullOrUndefined(item.name) ? "" : item.name;
												if (!isNullOrUndefined(item.maconomyKey) && item.maconomyKey !== "") {
													combinedName = "(" + item.maconomyKey + ")" + combinedName;
												}
												const selected: boolean = (!isNullOrUndefined(this.props.activeCustomer) && item.id === this.props.activeCustomer.customerId);

												return (
													<React.Fragment key={index}>
														<ListItem sx={{
															paddingTop: 0,
															paddingBottom: 0}} >
															<Button name={item.id.toString()} startIcon={selected ? <Ten99PrepSelectedIcon /> : undefined} color={selected ? "primary" : "inherit"} sx={{justifyContent: "left",	textAlign: 'left'}} fullWidth size="small" onClick={(e) => this.onCustomerSelect(item.id)}>{combinedName}</Button>
														</ListItem>
														<Divider />
													</React.Fragment>
												)
											}
											)}
										</List>
									</Grid>
								</Grid>
							)}
						</Grid>
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						)}
					</Grid>
				
					<Dialog open={this.state.editFilterOpen} fullWidth={true}>
						<DialogTitle>Filter Customers</DialogTitle>
						<DialogContent>
							<Grid container spacing={1} sx={{textAlign: 'center'}}>							
								<Grid item xs={12}>
									<Typography variant="h6">Filter for records containing the following:</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextField
										onChange={this.handleChange}
										value={this.state.tempFilterValue}
										variant="outlined"
										id="tempFilterValue"
										label="Filter by"
										name="tempFilterValue"
										fullWidth
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button type="button" startIcon={<Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onFilterClose}>Cancel</Button>
							<Button type="button" startIcon={<Ten99PrepSearchIcon />} variant="contained" color="primary" onClick={this.onFilterSubmit}>Filter</Button>
						</DialogActions>
					</Dialog>

					{this.state.addEditCustomerOpen && (
						<CustomerAddMaconomy onClose={this.onAddEditViewEntityProccessed} />
					)}
				</React.Fragment>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...HomeNavigationStore.actionCreators, ...ConfirmDialogStore.actionCreators, ...ToastNotificationsStore.actionCreators } // Selects which action creators are merged into the component's props
)(Customers);