import { PropTypes } from '@mui/material';
import FabButton from 'components/buttons/FabButton';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { Ten99PrepAddIcon } from 'Ten99PrepOverloads/IconOverloads';
import { EnumEntityType, GetEnumEntityTypeLabel } from 'sharedInterfaces/ITen99EntitySummary';
import { isNullOrUndefined } from 'util';

interface IAddFabButtonProps {
	onClick?: (id: string, action: ActionTypesEnum) => void,
	actions: ActionTypesEnum[],
	entityType: EnumEntityType,
	colorOverride?: PropTypes.Color
}



class FabButtons extends React.PureComponent<IAddFabButtonProps> {
	//local state
	constructor(props: IAddFabButtonProps) {
		super(props);	
	}

	// -----------------
	//Handle User events
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	private getActionUiDetails = (actionType: ActionTypesEnum) => {
		switch (actionType) {
			case ActionTypesEnum.Add:
				return { action: actionType, icon: <Ten99PrepAddIcon />, text: "", color: "primary", tooltip: "Add " + GetEnumEntityTypeLabel(this.props.entityType) } as ITen99DynamicButtonProps;
			default:
				return {} as ITen99DynamicButtonProps;
		}
	}

	// Render
	render() {
		let buttons: ITen99DynamicButtonProps[] = [] as ITen99DynamicButtonProps[];

		if (!isNullOrUndefined(this.props.actions) && this.props.actions.length > 0)
		this.props.actions.map((item) => {
			let tempUiProps: ITen99DynamicButtonProps = this.getActionUiDetails(item);
			tempUiProps.onClick = this.props.onClick;
			buttons.push(tempUiProps);
		})

		return (
			buttons.map((item, index) => {
				return (
					<FabButton key={index} itemId={""} uiProps={item} />
				)
			})
			);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (FabButtons);
