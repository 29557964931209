import React from "react";
import NumberFormat from 'react-number-format';

interface ICustomerNumberFormatProps {
    
    onChange: (event: { target: { name: string; value: string } }) => void,
    name: string,
    useFormattedValue?: boolean
}
export const CustomerNumberFormat = React.forwardRef(function NumberFormatCustom(props: ICustomerNumberFormatProps, ref) {
    const { onChange, useFormattedValue, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({ target: { name: props.name, value: (useFormattedValue === true) ? values.formattedValue : values.value } });
            }}
        />
    );
});