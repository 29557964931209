import { Badge, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99ImportHistory } from "sharedInterfaces/ITen99ImportHistory";
import { Ten99PrepDownloadIcon, Ten99PrepWarningIcon } from "Ten99PrepOverloads/IconOverloads";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {		
	validationMessages: ITen99ApiResponseMessage[],	
}

interface IImportHistoryProps {
	history: ITen99ImportHistory[],
	pagerCurrentPage: number,
	pagerTotalCount: number,
	pagerPageSize: number,
	onPagerClick: (pageNumber: number) => void,
	onDownloadClick: (id: number) => void,
	onDownloadWorkSheetErrorsClick: (id: number) => void,
	status: enumComponentStatus,
}

type ImportHistoryProps =
	& IImportHistoryProps
	;

const initialLocalState: ILocalState = { validationMessages: [] as ITen99ApiResponseMessage[] };
class ImportHistory extends React.PureComponent<ImportHistoryProps, ILocalState> {
	
	constructor(props: ImportHistoryProps) {
		super(props);
		this.state = initialLocalState;

	}
	// -----------------
	//Handle User events
	
	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.props.onPagerClick(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private getStatusText = (item: ITen99ImportHistory) =>
	{
		if (!item.processed) {
			switch (item.importStatusId) {
				case 1:
					return "Pending";
				case 2:
					return "Processing";
				case 3:
					return "Completed";
			}
		}
		else {
			switch (item.resultStatusId) {
				case 1:
					return "Success";
				case 2:
					return "Failure";
				case 3:
					return "Partial";
				default:
					return "";
			}
		}


	}

	private getStatusClassName = (item: ITen99ImportHistory) => {
				
		if (!item.processed) {
			return undefined;
		}
		else {
			switch (item.resultStatusId) {
				case 1:
					return 'success.main';
				case 2:
					return 'error.main';
				case 3:
					return 'warning.main';
				default:
					return undefined;
			}
		}
	}
		
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {
		return (
			<React.Fragment>
				<Grid container spacing={1} sx={{textAlign: 'center'}}>
					{(this.props.status === enumComponentStatus.Processing || this.props.status === enumComponentStatus.IsLoading) && (
						<ProcessingModal modal={this.props.status === enumComponentStatus.Processing} />
					)}
					{this.props.history.length > 0 && (
						<React.Fragment>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Date/Time (EST)</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>User Name</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Import Type</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Import Source</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold', width:'100px' }}>Form Type</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold', width:'100px' }}>Status</Typography>
											</TableCell>
											<TableCell sx={{
												display: "flex",
												alignItems: "center"
											}}>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Details</Typography> <InfoToolTip text="This is a copy of the import file used. It will have markup on it to indicated any issues that occurred with the import." />
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.props.history.map((item, index) => {
											return (<TableRow key={index}>
												<TableCell>
													<Typography variant="body2">{item.importDate}</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.userName}</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.importType}</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.fileName}</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.formType}</Typography>
												</TableCell>
												<TableCell>
													<React.Fragment>
															<div>
																<Typography color={this.getStatusClassName(item)} variant="body2" display="inline">{this.getStatusText(item)}</Typography>
																
															</div>
															<div>
																<Typography color={this.getStatusClassName(item)} variant="caption">{item.notes} </Typography>
																{item.warningCount > 0 && (
																	<span>
																		<InfoToolTip text="Warnings">
																			<Badge badgeContent={item.warningCount} sx={{ color: 'warning.main' }} color="primary">< Ten99PrepWarningIcon /></Badge>
																		</InfoToolTip>
																	</span>
																)}
															</div>
													</React.Fragment>
												</TableCell>
												<TableCell>
													{!item.processed && (
														<React.Fragment>
															<Typography variant="body2">NA</Typography>
														</React.Fragment>
													)}
													{item.processed && !item.resultIsFile && (
														<IconButton onClick={e => this.props.onDownloadWorkSheetErrorsClick(item.importId)}>
															<Ten99PrepWarningIcon />
														</IconButton>
													)}
													{item.processed && item.resultIsFile && (
														<IconButton onClick={e => this.props.onDownloadClick(item.importId)}>
															<Ten99PrepDownloadIcon />
														</IconButton>
													)}
												</TableCell>												
											</TableRow>)
										}
										)}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												page={this.props.pagerCurrentPage - 1}
												rowsPerPage={this.props.pagerPageSize}
												rowsPerPageOptions={[]}
												count={this.props.pagerTotalCount}
												onPageChange={this.onPagerClick}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</React.Fragment>
					)}
					{this.props.status === enumComponentStatus.PendingAction && this.props.history.length === 0 && (
						<React.Fragment>
							<Grid item xs={12}>
								<Typography variant="h6">No items in History</Typography>
							</Grid>
						</React.Fragment>
					)}
					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
						<ApiErrorResponseMessages messages={this.state.validationMessages} />
					)}
				</Grid>
			</React.Fragment>
		)
	}
}

export default ImportHistory;