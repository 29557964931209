import { Grid, GridSize } from '@mui/material';
import ActionButton from 'components/buttons/ActionButton';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import { DynamicInputElement } from 'components/ten99Prep/DynamicInputGrid/DynamicInputElement';
import * as React from 'react';
import { CSSProperties } from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { EnumEntityType } from 'sharedInterfaces/ITen99EntitySummary';
import { IFormElement } from 'sharedInterfaces/ITen99FormElement';
import { IFormElementTemplate } from 'sharedInterfaces/ITen99FormElementTemplate';
import { Ten99PrepEditIcon, Ten99PrepPayerIcon, Ten99PrepRecipientIcon, Ten99StateAccounts } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';

interface IDynamicInputElementProps {
	templates: IFormElementTemplate[],
	formElements: IFormElement[],
	invalidState: boolean,
	disabled: boolean,
	onchange: (name: string, value: string) => void,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
	onActionClick?: (id: string, action: ActionTypesEnum) => void,
}

interface ILocalState {

}

const initialLocalState = {} as ILocalState;
export class DynamicInputGrid extends React.PureComponent<IDynamicInputElementProps, ILocalState>
{
	//local state
	
	constructor(props: IDynamicInputElementProps) {
		super(props);
		
		this.state = initialLocalState;	
    }

	private getFormElementValue(formElementId: number | string) {
		const element: IFormElement | undefined = this.props.formElements.find((x) => x.formElementId === formElementId);

		if (isNullOrUndefined(element)) {
			return "";
		}
		else {
			return element.elementValue;
		}
	}
	// -----------------
	//Handle User events

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object


	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		//alert(JSON.stringify(this.props.templates));
		return (			
			<React.Fragment>
				{(this.props.templates.map((item, index) =>
					
					<Grid key={index} item xs={12} sm={item.gridWidth as GridSize} style={{ "display":"flex",} as CSSProperties}>
						<DynamicInputElement
							invalid={this.props.invalidState && (this.props.invalidProperties.find(x => x.toLowerCase() === item.formElementId.toString().toLowerCase()) !== undefined)}
							disabled={this.props.disabled}
							onchange={this.props.onchange}
							template={item}
							value={this.getFormElementValue(item.formElementId)}
							onclick={item.linkedEntityTypeId === EnumEntityType.QboMapping ? this.props.onActionClick : undefined}
						/>
						<InfoToolTip text={item.tooltip} />
						{item.linkedEntityTypeId === EnumEntityType.StateAccount && !isNullOrUndefined(this.props.onActionClick) && (
							<ActionButton itemId={EnumEntityType.StateAccount.toString()} uiProps=
								{{ action: ActionTypesEnum.StateAccounts, icon: <Ten99StateAccounts />, text: "", color: "primary", tooltip: "Manage State Accounts", onClick: this.props.onActionClick } as ITen99DynamicButtonProps}
							/>
						)}
						{(item.linkedEntityTypeId === EnumEntityType.Payer || item.linkedEntityTypeId === EnumEntityType.PayerWithholdingAgent) && !isNullOrUndefined(this.props.onActionClick) && (
							<ActionButton itemId={EnumEntityType.Payer.toString()} uiProps=
								{{ action: ActionTypesEnum.Edit, icon: <Ten99PrepPayerIcon />, text: "", color: "primary", tooltip: item.linkedEntityTypeId === EnumEntityType.Payer ? "Manage Payer" : "Manage Withholding Agent", onClick: this.props.onActionClick } as ITen99DynamicButtonProps}
							/>
						)}

						{item.linkedEntityTypeId === EnumEntityType.Recipient && !isNullOrUndefined(this.props.onActionClick) && (
							<ActionButton itemId={EnumEntityType.Recipient.toString()} uiProps=
								{{ action: ActionTypesEnum.Edit, icon: <Ten99PrepRecipientIcon />, text: "", color: "primary", tooltip: "Manage Recipient", onClick: this.props.onActionClick } as ITen99DynamicButtonProps}
							/>
						)}

						{item.linkedEntityTypeId === EnumEntityType.QboMapping && !isNullOrUndefined(this.props.onActionClick) && (
							<ActionButton itemId={item.formElementId.toString()} uiProps=
								{{ action: ActionTypesEnum.Edit, icon: <Ten99PrepEditIcon />, text: "", color: "primary", tooltip: "Map Accounts", onClick: this.props.onActionClick } as ITen99DynamicButtonProps}
							/>
						)}

					</Grid>
					
				))}		
			</React.Fragment>
		);
	}
}