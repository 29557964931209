import { Dialog, Divider, Grid } from "@mui/material";
import AlertsDashboard from "components/information/AlertsDashboard";
import AlertsSummary from "components/information/AlertsSummary";
import KeyValueDisplay from "components/information/KeyValue";
import SummaryButtons from "components/ten99Prep/SummaryButtons";
import * as React from 'react';
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { Ten99PrepFormIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from "util";
import SummaryTitle from "components/ten99Prep/SummaryTitle";

interface ILocalProps {
	item: ITen99EntitySummary,
	onActionClick: (entity: ITen99EntitySummary, action: ActionTypesEnum) => void	
}


interface ILocalState {
	alertsOpen: boolean
}

type CartFormEntityProps =
	ILocalProps
	;

const initialLocalState: ILocalState = { alertsOpen : false};
class CartFormEntitySummary extends React.PureComponent<CartFormEntityProps, ILocalState> {

	constructor(props: CartFormEntityProps) {
		super(props);
		this.state = initialLocalState;
	}
	// -----------------
	//Handle User events
	private onActionClick = (id: string, action: ActionTypesEnum) => {
		switch (action) {			
			case ActionTypesEnum.ShowAlerts:
				this.setState({ alertsOpen: true });
				break;
			default:
				this.props.onActionClick(this.props.item, action)
				break;
		}
	}
	private onCloseAlerts = () => {
		this.setState({ alertsOpen: false });
	}

// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {
		const formUiProps: ITen99DynamicButtonProps = {
			action: ActionTypesEnum.ShowAlerts, icon: <Ten99PrepFormIcon />, text: "", tooltip: "", onClick: undefined
		} as ITen99DynamicButtonProps;

		
		return (
			<React.Fragment>
				<Grid container spacing={1} sx={{ textAlign: 'center', borderBottom: '1px'}}>
					<Grid item xs={5} sx={{ textAlign: "left", display: "flex" }}>
						<Ten99PrepFormIcon /><AlertsSummary entityId={this.props.item.id} alertSummary={this.props.item.alertsInfo} onActionClick={this.onActionClick} /><SummaryTitle entity={this.props.item} inline={false} />
					</Grid>
					<Grid item xs={2} sx={{ textAlign: "left", display: "flex" }}>
						{(!isNullOrUndefined(this.props.item.status) && this.props.item.status !== "") && (
							<KeyValueDisplay keyName={"Status"} value={this.props.item.status} disableKeyNameWordWrap={true} />
						)}
					</Grid>
					<Grid item xs={5} sx={{textAlign: 'right'}}>
						<SummaryButtons itemId={this.props.item.id} onClick={this.onActionClick} actions={this.props.item.actions} displayAllAsButtons={true} />
					</Grid>
					{this.state.alertsOpen && (
						<Dialog open={this.state.alertsOpen} fullWidth={true} maxWidth="lg">
							<AlertsDashboard alertSource={this.props.item} alertSourceParents={[] as ITen99EntitySummary[]} onClose={this.onCloseAlerts} />
						</Dialog>
					)}
				</Grid>
				<Divider />
			</React.Fragment>
		)
	}
}

export default CartFormEntitySummary;