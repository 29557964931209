import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { ApplicationState } from "store";
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99PrepCancelIcon, Ten99PrepDownloadIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	selectedFormType: string,
}

interface ICustomerExportProps {
	customer: ITen99Customer,
	onClose: () => void,
}

type CustomerExportProps =
	& ICustomerExportProps
	& HomeNavigationStore.CustomerSummaryState
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.IsLoading, selectedFormType: "", validationMessages: [] as ITen99ApiResponseMessage[] };
class CustomerImport extends React.PureComponent<CustomerExportProps, ILocalState> {

	private formTypes: ITen99LookupItem[] = {} as ITen99LookupItem[];

	constructor(props: CustomerExportProps) {
		super(props);
		this.state = initialLocalState;


		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/8/?parentId=1", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 8);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.formTypes = lookup.lookupItems;
							this.setState({ status: enumComponentStatus.PendingAction });
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load form types" }] as ITen99ApiResponseMessage[] });
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})

	}
	// -----------------
	//Handle User events
	
	private onCancel = () => {
		this.props.onClose();
	}

	private onDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
		}
	}

	private getFileName = () => {
		let item: ITen99LookupItem | undefined = this.formTypes.find(x => x.id === parseInt(this.state.selectedFormType))

		if (!isNullOrUndefined(item)) {
			return item.name + "_template.xlsx";
		}
		else {
			return "template.xlsx";
		}
	}

	private onDownloadClick = () => {
		const formTypeLookup: ITen99LookupItem | undefined = this.formTypes.find((x) => x.id === Number.parseInt(this.state.selectedFormType))
		if (!isNullOrUndefined(formTypeLookup)) {
			MakeApiCall<string>("api/Exports/" + this.state.selectedFormType + "/Customer/" + this.props.customer.customerId, "GET")
				.then(data => {
					if (data.isSuccess) {
						this.setState({ status: enumComponentStatus.PendingAction, validationMessages: [] });
						if (!isNullOrUndefined(data.payload)) {
							PromptDownloadFile(data.payload, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", this.getFileName())
						}
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //error
					}
				})
		}
		else {
			this.setState({ status: enumComponentStatus.Invalid, validationMessages: [{ type: "Error", message: "You must select a valid Form Type", propertyNames: ["selectedFormType"] as string[] }] as ITen99ApiResponseMessage[] }); //unable to load lookup, we need to stop
		}
	}

	
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	//original map of tabs with mutlitple cusomter , might need in future
	//{ !isNullOrUndefined(this.props.activeCustomers) && this.props.activeCustomers.map((item, index) => <Tab key={index}> id={"Customer_" + item.CustomerId} >{item.Name}</Tab>) }
	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h5">Export Data: {this.props.customer.name} for filing year {this.props.currentFilingYear}
								<InfoToolTip text="The year displayed is controlled by the Filing Year in the menu in the upper right corner" />
							</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
							<Grid item xs={12}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							</Grid>
						)}
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<Grid item xs={12}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}
						{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Invalid) && (
							<React.Fragment>
								<Grid item xs={12} sx={{textAlign: 'left'}}>
									<Typography variant="body2">You may only export one form type at a time.</Typography>
								</Grid>

								<Grid item xs={12} sx={{textAlign: 'left'}}>
									<FormControl
										error={this.state.status === enumComponentStatus.Invalid}
										fullWidth
										required>
										<InputLabel id="formTypeLabel">Form Type</InputLabel>
										<Select
											labelId="formTypeLabel"
											label="Form Type"
											value={this.state.selectedFormType}
											onChange={this.onDropDownChange}
											inputProps={{}}
											id="selectedFormType"
											name="selectedFormType"
										>
											{(this.formTypes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
										</Select>
									</FormControl>
								</Grid>

							</React.Fragment>
						)}
						
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepDownloadIcon />} onClick={this.onDownloadClick}>Export</Button> <Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Close</Button>
				</DialogActions>

			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, }, // Selects which action creators are merged into the component's props
)(CustomerImport);