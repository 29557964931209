import { ArrowRight } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import * as React from 'react';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { isNullOrUndefined } from 'util';

interface IApiResponseProps {
	messages: ITen99ApiResponseMessage[],
	onIgnoreWarnings?: () => void,
}

interface ILocalState {
	warningsOpen: boolean
	allWarnings: boolean
}

const initialLocalState = { warningsOpen: false } as ILocalState;

class ApiErrorResponseMessages extends React.PureComponent<IApiResponseProps, ILocalState> {
	// -----------------
	//Handle User events
	
	constructor(props: IApiResponseProps) {
		super(props);
		let initialState: ILocalState = { ...initialLocalState };

		initialState.warningsOpen = this.props.messages.every(x => x.isWarning);
		initialState.allWarnings = initialState.warningsOpen;
		
		this.state = initialState;
	}
	// -----------------
	// Componet lifecycle events
	// -----------------

	private onCancelClick = () => {
		this.setState({ warningsOpen: false});
	}

	private onProceedlClick = () => {
		this.setState({ warningsOpen: false });
		if (!isNullOrUndefined(this.props.onIgnoreWarnings)) {
			this.props.onIgnoreWarnings();
		}
	}

	// Render
	render() {	
		return (
			<React.Fragment>
				{!this.state.allWarnings && (
					< Grid container spacing={1} sx={{ textAlign: "center" }}>
				<Grid item xs={12}>
					<List dense={true}>
						{this.props.messages.filter(x => !x.isWarning).map((item, index) => <ListItem key={index}><ListItemIcon><ArrowRight color={"error"} /></ListItemIcon><ListItemText primary={item.message} primaryTypographyProps={{ color: "error" }} /></ListItem>)}
					</List>
				</Grid>
				</Grid>
				)}

				{this.state.allWarnings && (
					<Dialog open={this.state.warningsOpen} fullWidth={true}>

						<DialogTitle>Warnings</DialogTitle>
						<DialogContent>
							{this.props.messages.map((item, index) => <ListItem key={index}><ListItemIcon><ArrowRight color={"error"} /></ListItemIcon><ListItemText primary={item.message} primaryTypographyProps={{ color: "error" }} /></ListItem>)}
							<Typography variant="body2">Do you want to ignore the warnings and proceed?</Typography>
						</DialogContent>
						<DialogActions>
							<Button type="button" variant="contained" color="secondary" onClick={this.onCancelClick}>Cancel Action</Button>
							<Button type="button" variant="contained" color="primary" onClick={this.onProceedlClick}>Ignore and Proceed</Button>
						</DialogActions>

					</Dialog>	
				)}

			</React.Fragment>
		);
	}
}

// -----------------
export default ApiErrorResponseMessages;