import { Button, Grid, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99Accept, Ten99PrepCancelIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { ITen99Company } from 'sharedInterfaces/ITen99Company';
import * as HomeNavigationStore from 'store/HomeNavigation';

type CompanyInviteProps =
	RouteComponentProps<{ companyId: string, companyName: string }> // ... plus incoming routing parameters
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	& HomeNavigationStore.ICustomerSummaryActions // ... plus action creators we've requested
	; // ... plus styles

const initialLocalState = { processing: false, success: false, validationMessages: {} as ITen99ApiResponseMessage[] };
class CompanyInvite extends React.PureComponent<CompanyInviteProps> {
	//local state
	state = initialLocalState;

	constructor(props: CompanyInviteProps) {
		super(props);
		
	}

	private onSubmit = () => {
		this.setState({ processing: true });
		//call the api and set the results in the state to reflect on the render
		MakeApiCall<string>("api/Companies/" + this.props.match.params.companyId + "/acceptInvite/", "POST", undefined)
			.then(data => {
				if (data.isSuccess) {
					this.setState({ processing: false, success: true });
					this.props.toastNotifcations_Display({ id: "CompanyInvite" + this.props.match.params.companyId, message: "Company Invite Accepted. This company will be selected. Current company can be changed in the company option in the toolbar.", type: "success" });
					MakeApiCall<ITen99Company[]>("api/Companies/user", "GET") //get lookup company
						.then(data => {
							let failed: boolean = false;

							if (data.isSuccess) {
								if (!isNullOrUndefined(data.payload) && data.payload.length > 0) {
									let selectedCompany: ITen99Company | undefined = undefined;

									selectedCompany = data.payload.find(x => x.id === Number.parseInt(this.props.match.params.companyId));
									if (!isNullOrUndefined(selectedCompany)) {
										this.props.CustomerSummaryActions_SetCompany(selectedCompany);
									}
									else {
										failed = true;
									}
								}
								else {
									failed = true;
								}
							}
							else {
								failed = true;
							}

							if (failed) {
								this.props.toastNotifcations_Display({ id: "CompanyInvite" + this.props.match.params.companyId, message: "Issue occurred selecting company. Please use the company option in the toolbar to manually select.", type: "error" });
                            }
						})
				}
				else {
					this.setState({ processing: false, success: false, validationMessages: data.details });
				}
			});
	}
	// -----------------
	// Render
	render() {
		if (this.state.success) {
			return (
				//redirect to home
				<div>
					<Redirect to={"/gate/home/customers"} />
				</div>
			)
		}
		
		return (
			<div>
				{(this.state.processing === true) && (					
					<ProcessingModal modal={true} />
				)}
				<Grid container spacing={1} sx={{textAlign: 'center'}}>
					<Grid item xs={12}>
						<Typography variant="h6">You have been invited to {this.props.match.params.companyName} Company. Do you wish to accept the invitation?</Typography>
					</Grid>
					<Grid item xs={12} />
					<Grid item xs={12}>
						<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" component={Link} to={"/gate/home/customers"}> Cancel</Button>
						<Button type="button" startIcon={< Ten99Accept />} variant="contained" color="primary" onClick={this.onSubmit}>Accept</Button>
					</Grid>
					{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					)}
				</Grid>				
			</div>
		);
	}
}

export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...HomeNavigationStore.actionCreators, ...ToastNotifications.actionCreators } // Selects which action creators are merged into the component's props
)((withRouter((CompanyInvite as any))));