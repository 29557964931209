import { IMenu, IMenuButton, IMenuSection } from "components/display/PopupMenu/MenuInterfaces";
import PopupMenu from "components/display/PopupMenu/PopupMenu";
import * as React from 'react';

interface IImportMenuProps {
	onSelect: (name: "RollOverAll" | "ExportToFile" | "ImportFromFile") => void,
}

function RollOverMenu(props: IImportMenuProps) {

	let menu: IMenu =
		{
			sections: [
				{
					title: "", buttons:
						[
							{ title: "Rollover All Prior Year Data", onClick: () => props.onSelect("RollOverAll") },
							{ title: "Download Prior Year to Excel File", onClick: () => props.onSelect("ExportToFile") },
							{ title: "Import Prior Year from File", onClick: () => props.onSelect("ImportFromFile") },
						] as IMenuButton[]
				},
			] as IMenuSection[]
		};

	return (
		<PopupMenu menu={menu} />
	)
}

export default RollOverMenu;