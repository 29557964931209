import { Grid } from '@mui/material';
import Announcemets from 'components/information/Announcemets';
import * as React from 'react';
import { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as HomeNavigation from 'store/HomeNavigation';
import { isNullOrUndefined } from 'util';
import Customers from './Customers';
import CustomerSummary from './CustomerSummary';

type HomeProps =
	& typeof HomeNavigation.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{customerId?: string }> // ... plus incoming routing parameters
	;

const initialLocalState = { };
class Home extends React.PureComponent<HomeProps> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle User events
	
	// -----------------
	// Componet lifecycle events

	componentDidMount() {
		//if there are items in the path, load that customer as the active customer (this is for bookmarks)
		if (!isNullOrUndefined(this.props.match.params.customerId)) //means we navigated to this page with a diffrent param in url{
		{
			this.props.CustomerSummaryActions_AddActiveCustomer(this.props.match.params.customerId);
		}
    }
	
	// -----------------
	// Render

	render() {
		
		return (
			<React.Fragment>
				<Grid container spacing={3} style={{ "height": "100%", } as CSSProperties}>
					<Grid item xs={2} sx={{
						textAlign: 'left',
						borderRight: "1px",
						borderRightStyle: "solid",
						borderRightColor: 'primary.dark'
					}} style={{ "height": "100%", } as CSSProperties}>
						<Customers />
					</Grid>
					<Grid item xs={8} sx={{
						textAlign: 'left',
						borderRight: "1px",
						borderRightStyle: "solid",
						borderRightColor: 'primary.dark'
					}} style={{ "height": "100%", } as CSSProperties}>
						<CustomerSummary />
					</Grid>
					<Grid item xs={2}  style={{ "height": "100%", } as CSSProperties}>
						<Announcemets />
					</Grid>
				</Grid>
			</React.Fragment>	
			)					
		}
	}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	null,
	{ ...HomeNavigation.actionCreators, } // Selects which action creators are merged into the component's props
)(withRouter(Home as any));