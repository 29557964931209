export interface ITen99ActionResponse {
	actionSucceeded: boolean,
	messages: ITen99ActionResponseMessage[],
}

export interface ITen99ActionResponseMessage {
	messageType: ITen99ActionResponseTypeEnum,
	message: string,
}

export enum ITen99ActionResponseTypeEnum {
	Success = 1,
	Warning = 2,
	Error = 3
}

export function ConvertEnumToToastType(type: ITen99ActionResponseTypeEnum) {
	switch (type) {
		case ITen99ActionResponseTypeEnum.Error:
			return "error"; 
		case ITen99ActionResponseTypeEnum.Success:
			return "success";
		case ITen99ActionResponseTypeEnum.Warning:
			return  "warning"
    }
}