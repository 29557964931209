import { Button, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { INamedFilter, INamedFilterTypeEnum } from 'sharedInterfaces/ITen99PagerInfo';
import { ITen99PersonTypeEnum } from 'sharedInterfaces/ITen99PersonTypeEnum';
import { Ten99PrepCancelIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';

interface ICustomerStatusFilters_SearchProps {
	onClose: () => void,
	onSave: (filters: INamedFilter[]) => void,
	existingFilters?: INamedFilter[],
}

interface ILocalState {
	payerPersonType: ITen99PersonTypeEnum,
	payerFirstName: string,
	payerLastName: string,
	payerCompanyName: string,
	recipientPersonType: ITen99PersonTypeEnum,
	recipientFirstName: string,
	recipientLastName: string,
	recipientCompanyName: string,

}

const initialLocalState = {
	payerPersonType: ITen99PersonTypeEnum.Individual, payerFirstName: "", payerLastName: "", payerCompanyName: "",
	recipientPersonType: ITen99PersonTypeEnum.Individual, recipientFirstName: "", recipientLastName: "", recipientCompanyName: ""
} as ILocalState;
class CustomerStatusFilters_Search extends React.PureComponent<ICustomerStatusFilters_SearchProps, ILocalState> {
	//local state
	// -----------------
	//Handle User events

	constructor(props: ICustomerStatusFilters_SearchProps) {
		super(props);
		let tempState: ILocalState = { ...initialLocalState };

		//for the filters passed in, set state
		if (!isNullOrUndefined(this.props.existingFilters)) {
			let filterIndex: number = this.props.existingFilters.findIndex(x => x.type === INamedFilterTypeEnum.PayerFirstName);
			if (filterIndex >= 0) {
				tempState.payerFirstName = this.props.existingFilters[filterIndex].value;
			}

			filterIndex = this.props.existingFilters.findIndex(x => x.type === INamedFilterTypeEnum.PayerLastName);
			if (filterIndex >= 0) {
				tempState.payerLastName = this.props.existingFilters[filterIndex].value;
			}

			filterIndex = this.props.existingFilters.findIndex(x => x.type === INamedFilterTypeEnum.PayerCompanyName);
			if (filterIndex >= 0) {
				tempState.payerCompanyName = this.props.existingFilters[filterIndex].value;
				tempState.payerPersonType = ITen99PersonTypeEnum.Company;
			}
        }

		if (!isNullOrUndefined(this.props.existingFilters)) {
			let filterIndex: number = this.props.existingFilters.findIndex(x => x.type === INamedFilterTypeEnum.RecipientFirstName);
			if (filterIndex >= 0) {
				tempState.recipientFirstName = this.props.existingFilters[filterIndex].value;
			}

			filterIndex = this.props.existingFilters.findIndex(x => x.type === INamedFilterTypeEnum.RecipientLastName);
			if (filterIndex >= 0) {
				tempState.recipientLastName = this.props.existingFilters[filterIndex].value;
			}

			filterIndex = this.props.existingFilters.findIndex(x => x.type === INamedFilterTypeEnum.RecipientCompanyName);
			if (filterIndex >= 0) {
				tempState.recipientCompanyName = this.props.existingFilters[filterIndex].value;
				tempState.recipientPersonType = ITen99PersonTypeEnum.Company;
			}
		}
		this.state = tempState;
	}
	
	private onPayerTypeChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ payerPersonType : Number.parseInt(event.target.value) });
	}

	private onRecipientTypeChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ recipientPersonType: Number.parseInt(event.target.value) });
	}

	private onChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
	}

	private onCancel = () => {
		this.props.onClose();
	}

	private onSave = () => {
		let filters: INamedFilter[] = [] as INamedFilter[];

		if (this.state.payerPersonType === ITen99PersonTypeEnum.Individual) {
			if (this.state.payerFirstName !== "") {
				filters.push({ type: INamedFilterTypeEnum.PayerFirstName, value: this.state.payerFirstName } as INamedFilter)
			}

			if (this.state.payerLastName !== "") {
				filters.push({ type: INamedFilterTypeEnum.PayerLastName, value: this.state.payerLastName } as INamedFilter)
			}
		}
		else {
			if (this.state.payerCompanyName !== "") {
				filters.push({ type: INamedFilterTypeEnum.PayerCompanyName, value: this.state.payerCompanyName } as INamedFilter)
			}
        }

		if (this.state.recipientPersonType === ITen99PersonTypeEnum.Individual) {
			if (this.state.recipientFirstName !== "") {
				filters.push({ type: INamedFilterTypeEnum.RecipientFirstName, value: this.state.recipientFirstName } as INamedFilter)
			}

			if (this.state.recipientLastName !== "") {
				filters.push({ type: INamedFilterTypeEnum.RecipientLastName, value: this.state.recipientLastName } as INamedFilter)
			}
		}
		else {
			if (this.state.recipientCompanyName !== "") {
				filters.push({ type: INamedFilterTypeEnum.RecipientCompanyName, value: this.state.recipientCompanyName } as INamedFilter)
			}
		}

		this.props.onSave(filters);
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		return (
			<React.Fragment>
				<DialogContent>
					<Grid container spacing={1} sx={{
						textAlign: 'center',
						BorderRight: "1px solid"
					}}>
						<Grid item xs={12} sx={{
							textAlign: 'left',
							display: "flex",
							alignItems: "center"
						}}>
							<Typography variant="h6">Payer
							</Typography>
						</Grid>
						<Grid item xs={12} sx={{
							border: 'solid 1px ',
							borderColor: 'primary.dark',
							marginBottom: "10px",
							paddingRight: "10px",
							paddingBottom: "10px"
						}}>
							<Grid container spacing={1}>
								<Grid item xs={12} sx={{
									textAlign: 'left'
								}}>
									<FormControl fullWidth required>
										<FormLabel id="payerTypeLabel">Payer Type</FormLabel>
										<RadioGroup name="payerTypeId" value={this.state.payerPersonType.toString()} onChange={this.onPayerTypeChange} row>
											<FormControlLabel value={ITen99PersonTypeEnum.Individual.toString()} label="Individual" control={<Radio color="primary" />} />
											<FormControlLabel value={ITen99PersonTypeEnum.Company.toString()} label="Company" control={<Radio color="primary" />} />
										</RadioGroup>
									</FormControl>
								</Grid>

								{this.state.payerPersonType === ITen99PersonTypeEnum.Individual && (
									<React.Fragment>
										<Grid item xs={6}>
											<TextField
												onChange={this.onChange}
												value={this.state.payerFirstName}
												variant="outlined"
												id="payerFirstName"
												label="Payer First Name"
												name="payerFirstName"
												fullWidth
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												onChange={this.onChange}
												value={this.state.payerLastName}
												variant="outlined"
												id="payerLastName"
												label="Payer Last Name"
												name="payerLastName"
												fullWidth
											/>
										</Grid>
									</React.Fragment>
								)}
								{this.state.payerPersonType === ITen99PersonTypeEnum.Company && (
									<React.Fragment>
										<Grid item xs={12}>
											<TextField
												onChange={this.onChange}
												value={this.state.payerCompanyName}
												variant="outlined"
												id="payerCompanyName"
												label="Payer Company Name"
												name="payerCompanyName"
												fullWidth
											/>
										</Grid>
									</React.Fragment>
								)}
							</Grid>
						</Grid>			


						<Grid item xs={12} sx={{
							textAlign: 'left',
							display: "flex",
							alignItems: "center"
						}}>
							<Typography variant="h6">Recipient
							</Typography>
						</Grid>

						<Grid item xs={12} sx={{
							border: 'solid 1px ',
							borderColor: 'primary.dark',
							marginBottom: "10px",
							paddingRight: "10px",
							paddingBottom: "10px"
						}}>
							<Grid container spacing={1}>
								<Grid item xs={12} sx={{
									textAlign: 'left'
								}}>
									<FormControl fullWidth required>
										<FormLabel id="recipientTypeLabel">Recipient Type</FormLabel>
										<RadioGroup name="recipientTypeId" value={this.state.recipientPersonType.toString()} onChange={this.onRecipientTypeChange} row>
											<FormControlLabel value={ITen99PersonTypeEnum.Individual.toString()} label="Individual" control={<Radio color="primary" />} />
											<FormControlLabel value={ITen99PersonTypeEnum.Company.toString()} label="Company" control={<Radio color="primary" />} />
										</RadioGroup>
									</FormControl>
								</Grid>

								{this.state.recipientPersonType === ITen99PersonTypeEnum.Individual && (
									<React.Fragment>
										<Grid item xs={6}>
											<TextField
												onChange={this.onChange}
												value={this.state.recipientFirstName}
												variant="outlined"
												id="recipientFirstName"
												label="Recipient First Name"
												name="recipientFirstName"
												fullWidth
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												onChange={this.onChange}
												value={this.state.recipientLastName}
												variant="outlined"
												id="recipientLastName"
												label="Recipient Last Name"
												name="recipientLastName"
												fullWidth
											/>
										</Grid>
									</React.Fragment>
								)}
								{this.state.recipientPersonType === ITen99PersonTypeEnum.Company && (
									<React.Fragment>
										<Grid item xs={12}>
											<TextField
												onChange={this.onChange}
												value={this.state.recipientCompanyName}
												variant="outlined"
												id="recipientCompanyName"
												label="Recipient Company Name"
												name="recipientCompanyName"
												fullWidth
											/>
										</Grid>
									</React.Fragment>
								)}
							</Grid>
						</Grid>
						
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
					<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="primary" onClick={this.onSave}>Apply Filters</Button>
				</DialogActions>
			</React.Fragment>
			);
		}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default CustomerStatusFilters_Search;
