import { Autocomplete, Grid, TextField } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import { IAddressProps } from 'components/ten99Prep/Address/IAddressProps';
import * as React from 'react';


class UsAddress extends React.PureComponent<IAddressProps> {
	//local state
	
	// -----------------
	//Handle User events

	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.props.onchange(event.target.name, event.target.value);
	}

	private handleDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.props.onchange(event.target.name, event.target.value);
		}
	}
	
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		//
		return (			
			<React.Fragment>
				<Grid item xs={12}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.addressLine1}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "AddressLine1") !== undefined)}
						id="addressLine1"
						label="Address Line 1"
						name="addressLine1"
						required
						fullWidth
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.addressLine2}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "AddressLine2") !== undefined)}
						id="addressLine2"
						label="Address Line 2"
						name="addressLine2"
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.city}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "City") !== undefined)}
						id="city"
						label="City"
						name="city"
						required
						fullWidth
					/>
				</Grid>

				<Grid item xs={4}>
					<Autocomplete
						id="stateId"
						options={this.props.states}
						disabled={this.props.disabled}
						value={this.props.states.find(x => x.id === this.props.address.stateId) }
						getOptionLabel={(option) => option.description}
						onChange={(e, value) => {
							this.props.onchange("stateId", value?.id);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={this.props.invalid && (this.props.invalidProperties.find(x => x === "StateId") !== undefined)}
								label="State"
								placeholder=""
							/>
						)}
					/>
				</Grid>

				<Grid item xs={4}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.zip}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "Zip") !== undefined)}
						id="zip"
						label="Zip"
						name="zip"
						required
						fullWidth
						InputProps={{
							inputComponent: CustomerNumberFormat as any,
							inputProps: { format: "#####-####", mask: "#" }
						}}
					/>
				</Grid>								
			</React.Fragment>
		);
	}
}

export default UsAddress;