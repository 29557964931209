import ComplexIconButton from "components/buttons/ComplexIconButton";
import * as React from 'react';
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99AlertSummary } from "sharedInterfaces/ITen99Alert";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { Ten99PrepFailIcon, Ten99PrepWarningIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from "util";

interface ILocalState {	
}

interface IAlertsProps {
	alertSummary: ITen99AlertSummary,
	entityId: string,
	onActionClick: (id: string, action: ActionTypesEnum) => void,
}

type AlertsProps =
	& IAlertsProps
	;

const initialLocalState: ILocalState = {
};
class AlertsSummary extends React.PureComponent<AlertsProps, ILocalState> {

	constructor(props: AlertsProps) {
		super(props);
		this.state = initialLocalState;
	}

	// Render
	render() {
		let errorAlertUiProps: ITen99DynamicButtonProps | undefined;
		let warningAlertUiProps: ITen99DynamicButtonProps | undefined;

		if (!isNullOrUndefined(this.props.alertSummary)) {
			if (this.props.alertSummary.errorCount > 0) {
				errorAlertUiProps = {
					action: ActionTypesEnum.ShowAlerts, icon: <Ten99PrepFailIcon sx={{ color: 'error.main' }} />, text: "", tooltip: this.props.alertSummary.errorCount.toString() + " errors. Click to see alerts tied to this", onClick: this.props.onActionClick
				} as ITen99DynamicButtonProps;
			}

			if (this.props.alertSummary.warningCount > 0) {
				warningAlertUiProps = {
					action: ActionTypesEnum.ShowAlerts, icon: < Ten99PrepWarningIcon sx={{ color: 'warning.main' }} />, text: "", tooltip: this.props.alertSummary.warningCount.toString() + " warnings. Click to see alerts tied to this", onClick: this.props.onActionClick
				} as ITen99DynamicButtonProps;
			}
		}
		//alert(JSON.stringify(warningAlertUiProps));

		return (
			<React.Fragment>
				{!isNullOrUndefined(errorAlertUiProps) && (<ComplexIconButton itemId={this.props.entityId}
					uiProps={errorAlertUiProps} stopPropagation={true} dense={true} />)}
				{!isNullOrUndefined(warningAlertUiProps) && (<ComplexIconButton itemId={this.props.entityId}
					uiProps={warningAlertUiProps} stopPropagation={true} dense={true} />)}
			</React.Fragment>
		)
	}
}

export default AlertsSummary;