import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import * as React from 'react';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';

interface ParentItems {
	onReset: () => void;
	onSubmit: (twoFactorProviderCode: string) => void;
	twoFactorProviders: ITen99LookupItem[];
	isInvalid: boolean;
	invalidProperties: string[];
}

type SelectTwoFactorOptionsProps =
	ParentItems
	;

const initialLocalState = { twoFactorProviderCode: "",  validationMessages: [] as ITen99ApiResponseMessage[], invalidProperties: [] as string[] };
class SelectTwoFactorOptions extends React.PureComponent<SelectTwoFactorOptionsProps> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle User events
	private onTwoFactorSelect = () => {
		this.props.onSubmit(this.state.twoFactorProviderCode);
	}

	private handleDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ [event.target.name]: event.target.value });
		}
	}

	// -----------------
	// Componet lifecycle events
	componentDidMount() {
	//if there is only one selection, set default
		if (this.props.twoFactorProviders.length === 1) {
			this.setState({ twoFactorProviderCode: this.props.twoFactorProviders[0].name });
		}
		else {
			this.setState({ twoFactorProviderCode: this.props.twoFactorProviders.find(x => x.isSelected)?.name ?? "" });
		}
	}	
	// -----------------
	// Render
	render() {
		return (
			<React.Fragment>
				{this.props.twoFactorProviders !== undefined && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h4">
								Select Two Factor Authentication Method
							</Typography>
							<br/>
						</Grid>
						<Grid item xs={12}>
							<FormControl style={{ width: '100%' }} required
								error={this.props.isInvalid && (this.props.invalidProperties.find(x => x === "DeviceId") !== undefined)}>								
								<InputLabel>Two Factor Provider</InputLabel>
								<Select
									value={this.state.twoFactorProviderCode}
									label="Two Factor Provider"
									onChange={this.handleDropDownChange}
									inputProps={{}}
									name="twoFactorProviderCode"
								>
									{(this.props.twoFactorProviders !== undefined) && (this.props.twoFactorProviders.map((item, index) => <MenuItem key={index} value={item.name}>{item.description}</MenuItem>))}
												
							</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth onClick={() => this.onTwoFactorSelect()}>Proceed</Button>
						</Grid>
						
						<Grid item xs={12}>
							<Button type="button" variant="outlined" fullWidth onClick={this.props.onReset}>Cancel</Button>
						</Grid>
					</Grid>
				)}
		
			</React.Fragment>	
			);					
			}
		}

export default SelectTwoFactorOptions;