//negative numbers are UI only actions
export enum ActionTypesEnum {
	ShowAlerts = -1,
	AcceptChanges = -3,
	Reporting = -4,
	Email = -5,
	Add = 9,
	Edit = 3,
	Delete = 2,
	View = 4,
	Print = 6,
	Submit = 7,
	ViewDoc = 10,
	ViewPayerForms = 11,
	SubmitToCart = 12,	
	AddMaconomyCustomer = 14,
	EditMaconomyCustomer = 15,
	SubmitWithPayment = 16,
	Reopen = 17,
	Correct = 18,
	Erase = 19,
	FormHistory = 21,
	DeleteAllCustomers = 22,
	DeleteAllPayers = 23,
	DeleteAllRecipients = 24,
	AddCartAllCustomers = 25,
	AddCartAllPayers = 26,
	AddCartAllRecipients = 27,
	ReopenRejectedAllCustomers = 31,
	ReopenRejectedAllPayers = 32,
	ReopenRejectedAllRecipients = 33,
	BulkPreview = 34,
	Import = 35,
	Export = 36,
	StaticDoc = 37,
	StateAccounts = 38,
	Reset = 43,
	Revert = 44,
	ViewCart = 45,
	ViewCartHistory = 46,
	FormDelivery = 47
}


//ActionId	Name	Descr	IsSelected	IsActive	CreatedUserId	CreatedDate	ModifiedUserId	ModifiedDate	CoalesceDate	IsDownload
//1	Cart	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.680	NULL	NULL	2020 - 09 - 29 10: 03: 24.680	0
//2	Delete	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.700	NULL	NULL	2020 - 09 - 29 10: 03: 24.700	0
//3	Edit	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.703	NULL	NULL	2020 - 09 - 29 10: 03: 24.703	0
//4	View	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.703	NULL	NULL	2020 - 09 - 29 10: 03: 24.703	0
//5	Email	NULL	0	0	44	2020 - 09 - 29 10: 03: 24.703	NULL	NULL	2020 - 09 - 29 10: 03: 24.703	0
//6	Print	NULL	0	0	44	2020 - 09 - 29 10: 03: 24.707	NULL	NULL	2020 - 09 - 29 10: 03: 24.707	0
//7	Submit	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.707	NULL	NULL	2020 - 09 - 29 10: 03: 24.707	0
//8	DeleteAll	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.707	NULL	NULL	2020 - 09 - 29 10: 03: 24.707	0
//9	Add	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.707	NULL	NULL	2020 - 09 - 29 10: 03: 24.707	0
//10	View Doc	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.710	NULL	NULL	2020 - 09 - 29 10: 03: 24.710	1
//11	Add Payer Form	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.710	NULL	NULL	2020 - 09 - 29 10: 03: 24.710	0
//12	Add to cart	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.710	NULL	NULL	2020 - 09 - 29 10: 03: 24.710	0
//14	Add Maconomy	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.710	NULL	NULL	2020 - 09 - 29 10: 03: 24.710	0
//15	Edit Maconomy	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.713	NULL	NULL	2020 - 09 - 29 10: 03: 24.713	0
//16	Submit with Payment	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.713	NULL	NULL	2020 - 09 - 29 10: 03: 24.713	0
//17	Reopen	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.713	NULL	NULL	2020 - 09 - 29 10: 03: 24.713	0
//18	Correct	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.717	NULL	NULL	2020 - 09 - 29 10: 03: 24.717	0
//19	Erase	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.717	NULL	NULL	2020 - 09 - 29 10: 03: 24.717	0
//21	Form History	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.717	NULL	NULL	2020 - 09 - 29 10: 03: 24.717	0
//22	DeleteAllCustomers	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.720	NULL	NULL	2020 - 09 - 29 10: 03: 24.720	0
//23	DeleteAllPayers	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.720	NULL	NULL	2020 - 09 - 29 10: 03: 24.720	0
//24	DeleteAllRecipients	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.720	NULL	NULL	2020 - 09 - 29 10: 03: 24.720	0
//25	AddCartAllCustomers	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.720	NULL	NULL	2020 - 09 - 29 10: 03: 24.720	0
//26	AddCartAllPayers	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.720	NULL	NULL	2020 - 09 - 29 10: 03: 24.720	0
//27	AddCartAllRecipients	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.723	NULL	NULL	2020 - 09 - 29 10: 03: 24.723	0
//31	ReopenRejectedAllCustomers	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.723	NULL	NULL	2020 - 09 - 29 10: 03: 24.723	0
//32	ReopenRejectedAllPayers	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.723	NULL	NULL	2020 - 09 - 29 10: 03: 24.723	0
//33	ReopenRejectedAllRecipients	NULL	0	1	44	2020 - 09 - 29 10: 03: 24.727	NULL	NULL	2020 - 09 - 29 10: 03: 24.727	0

export interface IAction {
	actionTypeId: number,
	additonalActionData: string ,
	ticks: string,
}

