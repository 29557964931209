import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from 'store';
import { Auth_ActionCreators } from 'store/SharedActions';
import * as UserStore from 'store/User';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { SessionClear } from 'utilities/UserId';

interface ILogoutProps {
}

type logoutProps =
	& ILogoutProps
	& UserStore.UserState
	& typeof Auth_ActionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ redirect: string }> // ... plus incoming routing parameters
;
class Logout extends React.PureComponent<logoutProps> {
	componentDidMount() {
		const redirect = (new URLSearchParams(this.props.location.search)).get("redirect");
		console.log("begin Logout");
		MakeApiCall<string>("api/User/" + this.props.user_Id + "/Login", "DELETE"); // we do not care about result
		SessionClear();
		this.props.Auth_Logout();
		this.props.history.push("/usergate/login/" + (isNullOrUndefined(redirect) ? "" : "?redirect=" + redirect)); // go to login
		console.log("end Logout");
    }

	// Render
	render() {
		return (			
			<React.Fragment>
			</React.Fragment>
		);
	}
}

export default connect(
	(state: ApplicationState) => state.user, // Selects which state properties are merged into the component's props
	{ ...Auth_ActionCreators  } // Selects which action creators are merged into the component's props
)(withRouter(Logout));

