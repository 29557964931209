import { GetEnumEntityTypeLabel, ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { isNullOrUndefined } from "util";

export function getEntityPrintableName(entity: ITen99EntitySummary) {

	if (!isNullOrUndefined(entity.name)) {
		return GetEnumEntityTypeLabel(entity.type) + " : " + entity.name;
	}
	else if (!isNullOrUndefined(entity.names)) {
		return entity.names.map((item, index) => {
			return item.title + " : " + item.descr;
		}).join(" - ");
	}
	else {
		return "";
	}
}
