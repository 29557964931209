import { Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableFooter, TablePagination, TableRow, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import { EntityNav } from "components/ten99Prep/EntityAddEditView/EntityNav";
import * as React from 'react';
import { ITen99ActionResponseTypeEnum } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99Alert } from "sharedInterfaces/ITen99Alert";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { GetEnumEntityTypeLabel, ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { Ten99PrepCancelIcon, Ten99PrepFailIcon, Ten99PrepWarningIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import Pager from "components/buttons/Pager";
import { INamedFilter, INamedFilterTypeEnum, ITen99PagerInfo } from "sharedInterfaces/ITen99PagerInfo";
import { getEntityPrintableName } from "utilities/EntityNameSummary";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {		
	status: enumComponentStatus,
	alerts: ITen99Alert[],
	validationMessages: ITen99ApiResponseMessage[],
	pagerInfo: ITen99PagerInfo,
}

interface IAlertsProps {
	onClose: () => void,
	alertSource: ITen99EntitySummary,
	alertSourceParents: ITen99EntitySummary[],
}

type AlertsProps =
	& IAlertsProps
	;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,	
	alerts: [] as ITen99Alert[],
	pagerInfo: { pageNumber: 1, totalCount: 0, pageSize: 10 } as ITen99PagerInfo,
	validationMessages: [] as ITen99ApiResponseMessage[],
};
class AlertsDashboard extends React.PureComponent<AlertsProps, ILocalState> {

	constructor(props: AlertsProps) {
		super(props);
		let initState: ILocalState = initialLocalState;
		initState.pagerInfo.onPagerClick = this.onPagerClick;
		this.state = initState;
		this.search("", 1);		
	}
	// -----------------
	//Handle User events
	private getSerchVal = () => {
		if (!isNullOrUndefined(this.state.pagerInfo.namedFilters)) {
			let filter: INamedFilter | undefined = this.state.pagerInfo.namedFilters.find(x => x.type === INamedFilterTypeEnum.SearchVal)
			if (filter !== undefined) {
				return filter.value;
			}
			else {
				return "";
			}
		}
		else {
			return "";
		}
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(this.getSerchVal(), page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private search(searchVal: string, pageNumber: number) {
		if (this.state.status !== enumComponentStatus.IsLoading) { //do not set state on intial load
			this.setState({ status: enumComponentStatus.Processing, validationMessages: [] as ITen99ApiResponseMessage[] });
		}
		
		MakeApiCall<ITen99Alert[]>("api/Alerts/?entityType=" + this.props.alertSource.type + "&identifier=" + this.props.alertSource.id + "&PageNumber=" + pageNumber + "&PageSize=" + this.state.pagerInfo.pageSize, "GET")
			.then(data => {
				if (data.isSuccess) {
					//refresh list
					if (!isNullOrUndefined(data.payload) && !isNullOrUndefined(data.pageData)) {
						this.setState({ status: enumComponentStatus.PendingAction, alerts: data.payload, pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount } });
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load alerts" }] as ITen99ApiResponseMessage[] });
				}
			});		
	}		

	private getClassname(type: ITen99ActionResponseTypeEnum) {		
		switch (type) {
			case (ITen99ActionResponseTypeEnum.Error):
				return "#d32f2f";
			case (ITen99ActionResponseTypeEnum.Success):
				return "#43a047";
			case (ITen99ActionResponseTypeEnum.Warning):
				return "#ff9800";
			default:
				return "";
		}		
	}

	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Alerts for {getEntityPrintableName(this.props.alertSource)}</Typography>
							<EntityNav items={this.props.alertSourceParents} />
						</Grid>
						
						<Grid item xs={12} sx={{ textAlign: 'right' }}>
							<Typography variant="subtitle2" color="textSecondary" align="left" >Showing {this.state.alerts.length} of {this.state.pagerInfo.totalCount} alerts</Typography>
						</Grid>	
					</Grid>

				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
							<Grid item xs={12}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							</Grid>
						)}
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<Grid item xs={12}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<List>
								{this.state.alerts.map((item: ITen99Alert, index) => {
									return (
										<React.Fragment key={index} >
											<ListItem sx={{ backgroundColor: this.getClassname(item.messageDisplayType) }} >
												<ListItemIcon>
													{item.messageDisplayType === ITen99ActionResponseTypeEnum.Warning && (<Ten99PrepWarningIcon />)}
													{item.messageDisplayType === ITen99ActionResponseTypeEnum.Error && (<Ten99PrepFailIcon />)}
												</ListItemIcon>
												<ListItemText color="secondary" primary={item.alertPostedDate + " - " + item.text} />
											</ListItem>
											<Divider sx={{
												height: "5px",
												backgroundColor: "#FFFFFF"}}/>
										</React.Fragment>
									);
								}
								)}
							</List>		
						</Grid>
						<Grid item xs={12} sx={{textAlign: 'right'}}>
							<Pager pagerInfo={this.state.pagerInfo} />
						</Grid>						
					</Grid>

				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.props.onClose}>Cancel</Button>					
				</DialogActions>				
			</React.Fragment>
		)
	}
}

export default AlertsDashboard;