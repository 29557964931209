import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import { EntityNav } from 'components/ten99Prep/EntityAddEditView/EntityNav';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { Ten99PrepCancelIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import FormSummary from './FormSummary';

interface IFormHistoryProps {
	onClose: () => void,
	onActionClickByEntity: (entity: ITen99EntitySummary, action: ActionTypesEnum) => void,
	parents: ITen99EntitySummary[],
	history: ITen99EntitySummary[],
}
type FormHistoryProps =
	IFormHistoryProps
	;

class FormHistory extends React.PureComponent<FormHistoryProps> {
	//local state
	// -----------------
	//Handle User events
	private onActionClick = (id: string, actionId: number) => {

		const entity: ITen99EntitySummary | undefined = this.props.history.find((item) => item.id === id)

		if (!isNullOrUndefined(entity)) {
			this.props.onActionClickByEntity(entity, actionId);
		}
		else {
			console.error("Unable to locally find form: " + id);
		}
	}
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		//
		return (			
			<React.Fragment>

				<DialogTitle>						
					<Typography variant="h4">Correction History for Form
						<InfoToolTip text="Display the correction history for the indicated form " />
					</Typography>
					<EntityNav items={this.props.parents} />						

				</DialogTitle>
				<DialogContent>
					{this.props.history.map((item, index) => {
						return (
							<FormSummary key={index} onActionClick={this.onActionClick} summaryItem={item} />
						)
					})
					}
				</DialogContent>
				<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.props.onClose}>Close</Button>
				</DialogActions>
				
			</React.Fragment>
		);
	}
}
export default FormHistory;
