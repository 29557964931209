import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from "react-redux";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Company } from 'sharedInterfaces/ITen99Company';
import { ApplicationState } from "store";
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from "store/HomeNavigation";
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	PendingAction,
	Processing,
	Loading,
	Invalid,
	Error
};

interface IChangeCompanyProps {
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,	
	validationMessages?: ITen99ApiResponseMessage[],	
}

type ChangeCompanyProps =
	& IChangeCompanyProps
	& HomeNavigationStore.CustomerSummaryState
	& ICustomerSummaryActions // ... plus action creators we've requested
	;

interface ILocalState {	
	status: enumComponentStatus,	
	validationMessages: ITen99ApiResponseMessage[],
	companies: ITen99Company[],
	selectedCompany?: ITen99Company,
}

const initialLocalState = {status: enumComponentStatus.Loading, companies: {} as ITen99Company[], validationMessages: {} as ITen99ApiResponseMessage[] } as ILocalState;
class ChangeCompany extends React.PureComponent<ChangeCompanyProps, ILocalState> {

	//local state

	constructor(props: ChangeCompanyProps) {
		super(props);
		this.state = initialLocalState;
		MakeApiCall<ITen99Company[]>("api/Companies/user", "GET") //get lookup company
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload) && data.payload.length > 0) {
						let selectedCompany: ITen99Company | undefined = undefined ;

						if (!isNullOrUndefined(this.props.activeCompany)) {
							let tempCompany: ITen99Company = this.props.activeCompany; // compiler complaining about this if i skip this line
							selectedCompany = data.payload.find(x => x.id === tempCompany.id);
						}

						if (isNullOrUndefined(this.props.activeCompany) || !isNullOrUndefined(selectedCompany)) {
							this.setState({ status: enumComponentStatus.PendingAction, selectedCompany: selectedCompany, companies: data.payload.sort((c1, c2) => c1.name.localeCompare(c2.name)), validationMessages: {} as ITen99ApiResponseMessage[] });
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Current company not in results." }] as ITen99ApiResponseMessage[] });
                        }
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "No companies associated with this user." }] as ITen99ApiResponseMessage[] });
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
				}
			})

	}
	// -----------------
	//Handle events
	private onChange = (value: string) => {
		let company: ITen99Company | undefined = this.state.companies.find(x => x.id === Number.parseInt(value));
		if (isNullOrUndefined(company)) {
			this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to set company" }] as ITen99ApiResponseMessage[] });
		}
		else {
			this.setState({ selectedCompany: company });
        }
	}

	private onSubmit = () => {
		if (isNullOrUndefined(this.state.selectedCompany)) {
			this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Company not set" }] as ITen99ApiResponseMessage[] });
		}
		else {
			this.props.CustomerSummaryActions_SetCompany(this.state.selectedCompany);
			this.props.onClose(true, undefined)
		}
    }
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Select Company
							<InfoToolTip text="Select the company you wish to view information for" />
							</Typography>
						</Grid>						
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop: "8px" }}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
							<Grid item xs={12}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							</Grid>	
						)}
						{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
							<Grid item xs={12}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}
						{(this.state.status !== enumComponentStatus.Processing && this.state.status !== enumComponentStatus.Loading && this.state.status !== enumComponentStatus.Error) && (
							<Grid item xs={12}>
								<FormControl
									error={this.state.status === enumComponentStatus.Invalid}
									fullWidth
									required>
									<InputLabel id="formTypeLabel">Company</InputLabel>
									<Select
										labelId="formTypeLabel"
										label="Company"
										value={isNullOrUndefined(this.state.selectedCompany) ? "" : this.state.selectedCompany.id}
										onChange={event => this.onChange(event.target.value as string)}
										inputProps={{}}
										id="company"
										name="company"
									>
										{(this.state.companies.map((item, index) => <MenuItem key={index} value={item.id} >{item.name}</MenuItem>))}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => this.props.onClose(false, undefined)}>Close</Button>
					<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={this.onSubmit}>Submit</Button>
					
				</DialogActions>
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...HomeNavigationStore.actionCreators } // Selects which action creators are merged into the component's props
)(ChangeCompany);