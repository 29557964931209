//These are interfaces to actions that can be processed by mutliple states


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface Auth_LogoutAction { type: 'Auth/Logout' }

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const Auth_ActionCreators = {
    Auth_Logout: () => ({ type: 'Auth/Logout' } as Auth_LogoutAction)
};