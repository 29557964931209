import { Dialog } from '@mui/material';
import CartHistorySearch from 'components/ten99Prep/Cart/CartHistorySearch';
import ChangeCompany from 'components/ten99Prep/ChangeCompany';
import ChangeFilingYear from 'components/ten99Prep/ChangeFilingYear';
import TechnicalAssistance from 'components/ten99Prep/TechnicalAssistance';
import UserNotifications from 'components/ten99Prep/UserNotifications';
import * as React from 'react';
import { ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import UserProfileGateway from './UserProfile/UserProfileGateway';

interface IGenericDialogProps {
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,
	type: DialogTypesEnum,
	value?: string,
	userId: string,
}

interface ILocalState {	
	dialogOpen: boolean,
}
export enum DialogTypesEnum{
	Cart,
	CartHistory,
	ChangeCompany,
	ChangeFilingYear,
	Notifications,
	TechnicalAssitance,
	UserProfile
}

const initialLocalState = { dialogOpen: true} as ILocalState;
class Ten99ToolbarDialog extends React.PureComponent<IGenericDialogProps, ILocalState> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle User events

	private onClose = (proceesed: boolean, message?: ITen99ActionResponseMessage) => {
		//close form
		this.setState({ dialogOpen: false })
		//call parent
		this.props.onClose(proceesed, message);		
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
			return (			
				<React.Fragment>								
					{this.state.dialogOpen && (
						<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth="lg">							
							{this.props.type === DialogTypesEnum.Notifications && (
								<UserNotifications onClose={this.onClose} userId={this.props.userId} />
							)}
							{this.props.type === DialogTypesEnum.TechnicalAssitance && (
								<TechnicalAssistance onClose={this.onClose} />
							)}
							{this.props.type === DialogTypesEnum.ChangeFilingYear && (
								<ChangeFilingYear onClose={this.onClose} />
							)}
							{this.props.type === DialogTypesEnum.Cart && (
								<CartHistorySearch cartId={this.props.value ?? ""} onClose={this.onClose} />
							)}
							{this.props.type === DialogTypesEnum.ChangeCompany && (
								<ChangeCompany onClose={this.onClose} />
							)}
							{this.props.type === DialogTypesEnum.UserProfile && (
								<UserProfileGateway onClose={this.onClose} />
							)}
						</Dialog>	
					)}
				</React.Fragment>
		);
	}
}

// -----------------
export default Ten99ToolbarDialog;