import { AccountBalance, Add, AddShoppingCartOutlined, ArrowBack, ArrowForward, Assessment, BallotOutlined, BookOutlined, Business, Check, CheckCircle, Close, ContactMail, Delete, Edit, Error, Event, FilterList, FormatListNumbered, Gavel, GetApp, Group, Label, ListAlt, ListAltTwoTone, Mail, Person, PersonOutlined, Phone, PictureAsPdfOutlined, Publish, RemoveCircle, Replay, ReplayCircleFilled, Restore, Save, Search, Settings, ShoppingCart, ShoppingCartOutlined, ThumbUp, Visibility, Warning } from "@mui/icons-material";
import { CommonProps } from "@mui/material/OverridableComponent";
interface ITen99PrepIcon extends CommonProps {

}

export function Ten99PrepCustomerIcon(props: any ) {
    return (
        <BookOutlined {...props} />
    );
}

export function Ten99PrepPayerIcon(props: any) {
     return (
         <Person {...props}/>
    );
}

export function Ten99PrepRecipientIcon(props: any) {
    return (
        <PersonOutlined {...props}/>
    );
}

export function Ten99PrepFormIcon(props: any) {
    return (
        <ListAlt {...props}/>
    );
}

export function Ten99PrepStateFormIcon(props: any) {
    return (
        <ListAltTwoTone {...props}/>
    );
}

export function Ten99PrepSaveIcon(props: any) {
    return (
        <Save {...props}/>
    );
}

export function Ten99PrepCancelIcon(props: any) {
    return (
        <Close {...props}/>
    );
}

export function Ten99PrepAddCartIcon(props: any) {
    return (
        <AddShoppingCartOutlined {...props}/>
    );
}

export function Ten99PrepViewIcon(props: any) {
    return (
        <Visibility {...props}/>
    );
}

export function Ten99PrepEditIcon(props: any) {
    return (
        <Edit {...props}/>
    );
}

export function Ten99PrepCorrectionIcon(props: any) {
    return (
        <Check {...props}/>
    );
}

export function Ten99PrepAddIcon(props: any) {
    return (
        <Add {...props}/>
    );
}

export function Ten99PrepDeleteIcon(props: any) {
    return (
        <Delete {...props}/>
    );
}

export function Ten99PrepSearchIcon(props: any) {
    return (
        <Search {...props}/>
    );
}

export function Ten99PrepIrsIcon(props: any) {
    return (
        <AccountBalance {...props}/>
    );
}

export function Ten99PrepFilterIcon(props: any) {
    return (
        <FilterList {...props}/>
    );
}

export function Ten99PrepSelectedIcon(props: any) {
    return (
        <Label {...props}/>
    );
}

export function Ten99PrepUploadIcon(props: any) {
    return (
        <Publish {...props}/>
    );
}

export function Ten99PrepDownloadIcon(props: any) {
    return (
        <GetApp {...props}/>
    );
}

export function Ten99PrepViewDoc(props: any) {
    return (
        <PictureAsPdfOutlined {...props}/>
    );
}

export function Ten99PrepReopenIcon(props: any) {
    return (
        <Replay {...props}/>
    );
}

export function Ten99PrepResetIcon(props: any) {
    return (
        <ReplayCircleFilled {...props} />
    );
}

export function Ten99PrepRevertIcon(props: any) {
    return (
        <Restore {...props} />
    );
}

export function Ten99PrepIrsErase(props: any) {
    return (
        <RemoveCircle {...props}/>
    );
}

export function Ten99BulkActions(props: any) {
    return (
        <Settings {...props}/>
    );
}

export function Ten99StateAccounts(props: any) {
    return (
        <BallotOutlined {...props}/>
    );
}

export function Ten99Accept(props: any) {
    return (
        <ThumbUp {...props}/>
    );
}

export function Ten99LegalIcon(props: any) {
    return (
        <Gavel {...props}/>
    );
}

export function Ten99ReportIcon(props: any) {
    return (
        <Assessment {...props} />
    );
}

export function Ten99PrepSuccessIcon(props: any) {
    return (
        <CheckCircle {...props}/>
    );
}

export function Ten99PrepFailIcon(props: any) {
    return (
        <Error {...props}/>
    );
}

export function Ten99PrepWarningIcon(props: any) {
    return (
        <Warning {...props}/>
    );
}

export function Ten99PrepCalender(props: any) {
    return (
        <Event {...props} />
    );
}

export function Ten99Company(props: any) {
    return (
        <Business {...props} />
    );
}

export function Ten99Users(props: any) {
    return (
        <Group {...props} />
    );
}

export function Ten99PrepCartIcon(props: any) {
    return (
        <ShoppingCartOutlined {...props} />
    );
}

export function Ten99PrepCartHistoryIcon(props: any) {
    return (
        <ShoppingCart {...props} />
    );
}

export function Ten99PrepBackIcon(props: any) {
    return (
        <ArrowBack {...props} />
    );
}

export function Ten99PrepForwardIcon(props: any) {
    return (
        <ArrowForward {...props} />
    );
}

export function Ten99PrepEmailDeliveryIcon(props: any) {
    return (
        <ContactMail {...props} />
    );
}

export function Ten99PrepEmailIcon(props: any) {
    return (
        <Mail {...props} />
    );
}

export function Ten99PrepNumberedListIcon(props: any) {
    return (
        <FormatListNumbered  {...props} />
    );
}

export function Ten99PrepPhoneIcon(props: any) {
    return (
        <Phone {...props} />
    );
}