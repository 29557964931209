import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { IFormElementTemplate } from 'sharedInterfaces/ITen99FormElementTemplate';
import { ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { isNullOrUndefined } from 'util';

interface IDynamicInputElementProps {
	template: IFormElementTemplate,
	value: string,
	invalid: boolean,
	disabled: boolean,
	onchange: (name: string, value: string) => void,
	onclick?: (id: string, action: ActionTypesEnum) => void,
}

interface ILocalState {

}

const initialLocalState = {} as ILocalState;
export class DynamicInputElement extends React.PureComponent<IDynamicInputElementProps, ILocalState>
{
	//local state
	
	constructor(props: IDynamicInputElementProps) {
		super(props);
		
		this.state = initialLocalState;	
    }

	// -----------------
	//Handle User events

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.props.onchange(event.target.name, event.target.value);
	}

	private handleDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.props.onchange(event.target.name, String(event.target.value));
		}
	}

	private handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onchange(event.target.name, event.target.checked.toString());
	}

	private getInputProps = () => {
		if (!isNullOrUndefined(this.props.template.formElementValidation)) {
			if (this.props.template.formElementValidation === "dollar") {
				return ({
					inputComponent: CustomerNumberFormat as any,
					inputProps: {
						thousandSeparator: true,
						prefix: "$",
						decimalScale: 2,
						fixedDecimalScale: true,
						maxLength: this.props.template.maxLength,
					}
				});
			}
			else if (this.props.template.formElementValidation === "numeric") {
				return ({
					inputComponent: CustomerNumberFormat as any,
					inputProps: {						
						decimalScale: 0,						
						maxLength: this.props.template.maxLength,
					}
				});
			}
			else if (this.props.template.formElementValidation === "decimal") {
				return ({
					inputComponent: CustomerNumberFormat as any,
					inputProps: {
						maxLength: this.props.template.maxLength,
					}
				});
			}
			else if (this.props.template.formElementValidation === "date") {
				return ({
					inputComponent: CustomerNumberFormat as any,
					inputProps: {
						format: "##/##/####",
						mask: "_",
						useFormattedValue: true,
						//maxLength: this.props.template.maxLength,
					}
				});
			}
			else {
				return ({
					inputProps: {
						maxLength: this.props.template.maxLength,
					}});
            }
		}
		else {
			return ({});
		}
	}
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		//console.log(this.props.template.elementName + this.props.template.elementName + " Render");
		return (			
			<React.Fragment>
				{this.props.template.formElementTypeId === 1 && (//textbox
					<TextField
					disabled={this.props.disabled || this.props.template.isDisabled}
					onChange={this.handleChange}
					value={this.props.value === "" ? this.props.template.defaultValue : this.props.value}
					variant="outlined"
					error={this.props.invalid}
					id={this.props.template.elementName + this.props.template.elementName}
					label={this.props.template.elementName}
					name={this.props.template.formElementId.toString()}
					required={this.props.template.isRequired}
					helperText={this.props.template.placeHolder}
					InputProps={this.getInputProps()}
					fullWidth
					/>
				)}
				{this.props.template.formElementTypeId === 2 && (//dropdown
					<FormControl
						error={this.props.invalid}
						fullWidth
						required={this.props.template.isRequired}>
						<InputLabel id={this.props.template.elementName + this.props.template.elementName + "Label"}>{this.props.template.elementName}</InputLabel>
						<Select
							labelId={this.props.template.elementName + this.props.template.elementName + "Label"}
							label={this.props.template.elementName}
							disabled={this.props.disabled || this.props.template.isDisabled}
							value={this.props.value === "" ? this.props.template.defaultValue : this.props.value}
							onChange={this.handleDropDownChange}
							inputProps={{}}
							id={this.props.template.elementName + this.props.template.elementName}
							name={this.props.template.formElementId.toString()}							
						>
							{!isNullOrUndefined(this.props.template.source) && this.props.template.source.length > 0 &&
								(this.props.template.source.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
						</Select>
						<FormHelperText>{ this.props.template.placeHolder }</FormHelperText>
					</FormControl>
				)}
				{this.props.template.formElementTypeId === 3 && (//checkbox
					<FormControlLabel
						label={<Typography variant="body1">{this.props.template.elementName}</Typography>}
						disabled={this.props.disabled || this.props.template.isDisabled}
						control={
							<Checkbox
								color="primary"
								disabled={this.props.disabled || this.props.template.isDisabled}
								onChange={this.handleCheckboxChange}
								checked={(this.props.value.toLowerCase() === "true")}
								id={this.props.template.elementName + this.props.template.elementName}
								name={this.props.template.formElementId.toString()}								
							/>}
					/>
				)}
				{this.props.template.formElementTypeId === 4 && (//label
					<TextField
						disabled={true}
						variant="outlined"
						label={this.props.template.elementName}
						value={this.props.value === "" ? this.props.template.defaultValue : this.props.value}
						fullWidth
					/>
				)}
				{this.props.template.formElementTypeId === 6 && (//autocomplete
					<Autocomplete
						multiple
						fullWidth
						freeSolo
						readOnly
						id={this.props.template.elementName}
						options={this.props.template.source}
						getOptionLabel={(option) => (option as ITen99LookupItem).name}
						value={this.props.template.source}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								label={this.props.template.elementName}
								placeholder=""
								InputLabelProps={{ shrink: true }}
								onClick={() => { if (!isNullOrUndefined(this.props.onclick)) { this.props.onclick(this.props.template.formElementId.toString(), ActionTypesEnum.Edit); }}}
							/>
						)}						
						/>					
				)}
			</React.Fragment>
		);
	}
}