import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ConfirmTwoFactor from 'components/user/ConfirmTwoFactor';
import LoginDisplay from 'components/user/LoginDisplay';
import SelectTwoFactorOptions from 'components/user/SelectTwoFactorOptions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ApplicationState } from 'store';
import * as LoginStore from 'store/Login';
import { isNullOrUndefined } from 'util';


type LoginProps =
	LoginStore.LoginState // ... state we've requested from the Redux store
	& typeof LoginStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps // ... plus incoming routing parameters
	;

interface ILocalState {
	userName: string,
	twoFactorStartTime: number
	validationMessages: ITen99ApiResponseMessage[],
}

class Login extends React.PureComponent<LoginProps, ILocalState> {
	constructor(props: LoginProps) {
		super(props);

		let tempState: ILocalState = {
			userName: "",
			twoFactorStartTime: 0,
			validationMessages: [] as ITen99ApiResponseMessage[],
		} as ILocalState;
		this.state = tempState;

		this.props.LoginActions_Reset();
	}


	// -----------------
	//Handle User events
	private handleSignInClick = (creds: LoginStore.Credentials) => {
		this.props.LoginActions_Login(creds);
		//clear
		this.setState({ userName: creds.userName, validationMessages: [] as ITen99ApiResponseMessage[] });	
	}

	private handleResetClick = () => {		
		this.props.LoginActions_Reset();
		this.setState({ userName: "", twoFactorStartTime: 0, validationMessages: [] as ITen99ApiResponseMessage[] }); //clear local state as well
	}
	
	private getTwoFactorOptions = () => {
		this.props.LoginActions_GetTwoFactorOptions(this.props.twoFactorId);
	}

	private handleTwoFactorSelect = (twoFactorProviderCode: string) => {
		this.setState({ twoFactorStartTime: Date.now() });
		this.props.LoginActions_SubmitTwoFactorOption({ userId: this.props.twoFactorId.userId, twoFactorToken: this.props.twoFactorId.authToken, twoFactorProviderCode: twoFactorProviderCode });
	}

	private handleTwoFactorSubmit = (twoFactorCode: string) => {
		this.props.LoginActions_SubmitTwoFactor({ userId: this.props.twoFactorId.userId, twoFactorToken: this.props.twoFactorId.authToken, twoFactorCode: twoFactorCode });
	}	
		
	private handleTwoFactorResend = () => {
		this.setState({ twoFactorStartTime: Date.now() });
		this.props.LoginActions_ResendTwoFactor({ userId: this.props.twoFactorId.userId, twoFactorToken: this.props.twoFactorId.authToken});
	}

	private handleTwoFactorCancel = () => {
		this.props.LoginActions_CancelTwoFactor();
	}
	
	// Render
	render() {
		if (this.props.status === LoginStore.enumLoginStatus.Authenticated) {
			const redirect = (new URLSearchParams(this.props.location.search)).get("redirect");
			return (
				//redirect to login refresh
				<div>
					<Redirect to={"/usergate/loginRefresh" + ((redirect === "" || redirect === null) ? "" : "?redirect=" + redirect)} />
				</div>
			)
		}
		return (
			<React.Fragment>
				{this.props.isProcessing && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<div>
								<CircularProgress size={90} />
								<div>{this.props.processingMessage}</div>
							</div>
						</Grid>
					</Grid>
				)}

				{!this.props.isProcessing && this.props.status === LoginStore.enumLoginStatus.NotStarted && (
					<LoginDisplay userName={this.state.userName} onSubmit={this.handleSignInClick} isInvalid={this.props.isInvalid} invalidProperties={this.props.invalidProperties}/>
				)}
				{!this.props.isProcessing && this.props.status === LoginStore.enumLoginStatus.PendingTwoFactorOptions && (
					this.getTwoFactorOptions()
				)}
				{!this.props.isProcessing && this.props.status === LoginStore.enumLoginStatus.PendingTwoFactorSelection && this.props.twoFactorProviders !== undefined && (
					<SelectTwoFactorOptions onReset={this.handleResetClick} onSubmit={this.handleTwoFactorSelect} twoFactorProviders={this.props.twoFactorProviders} isInvalid={this.props.isInvalid} invalidProperties={this.props.invalidProperties}/>
				)}
				{!this.props.isProcessing && this.props.status === LoginStore.enumLoginStatus.PendingTwoFactorResolution && (
					<ConfirmTwoFactor
						cancelFunction={ this.handleTwoFactorCancel }
						resendFunction={ this.handleTwoFactorResend }
						submitFunction={ this.handleTwoFactorSubmit }
					/>
				)}
				{this.props.status === LoginStore.enumLoginStatus.Error && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h6" color="error">
								Issue occurred during login. Please try again. If issue persists, contact support.
						</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth onClick={this.handleResetClick}>
								Back to Login
						</Button>
						</Grid>
					</Grid>					
				)}	
				{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
					<ApiErrorResponseMessages messages={this.state.validationMessages} />
				)}	
				{!isNullOrUndefined(this.props.validationMessages) && this.props.validationMessages.length > 0 && (
					<ApiErrorResponseMessages messages={this.props.validationMessages} />
				)}	

				{!this.props.isProcessing && this.props.status === LoginStore.enumLoginStatus.NotStarted && (
					<Grid container spacing={1} sx={{ textAlign: 'center' }}>
						<Grid item xs={12}><br/></Grid>
						<Grid item xs={12}>
							<Typography variant="h6">
								1099-Prep provides users with a simple, secure, and a cost effective process for preparing and filing IRS forms.
							</Typography>
							<br />
							<Typography variant="subtitle1">
								Easily organize, send, and track all required forms – in minutes! This streamlined filing process is simple, easy and secure so that you can get back to doing what you do best: managing your business.
							</Typography>
						</Grid>
					</Grid>
				)}	


			</React.Fragment>	
			);					
			}
		}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.login, // Selects which state properties are merged into the component's props
	LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(Login as any);