export function GetUser() {
    return sessionStorage.getItem("sessionId");
};

export function SetUser(user: string) {
    //on set of user we need to clear everything set - just in case not cleared on logout
    SessionClear();
    return sessionStorage.setItem("sessionId", user);
};

export function GetYear() {
    return sessionStorage.getItem("filingYear");
};

export function SetYear(year: string) {
    return sessionStorage.setItem("filingYear", year);
};

export function GetCompany() {
    return sessionStorage.getItem("company");
};

export function SetCompany(company: string) {
    return sessionStorage.setItem("company", company);
};

export function ClearCompany() {
    sessionStorage.removeItem("company");
};

export function SessionClear()
{
    sessionStorage.removeItem("company");
    sessionStorage.removeItem("filingYear");
    sessionStorage.removeItem("sessionId");

}