import { CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { isNullOrUndefined } from 'util';

type ProcessingModalProps =
	{
		message?: string,
		modal?: boolean,
		size?: number
	}
	;

interface ILocalState {	
	
}

class ProcessingModal extends React.PureComponent<ProcessingModalProps, ILocalState> {
	// -----------------
	//Handle User events
	

	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {	
		
		return (
			<React.Fragment>
				{(isNullOrUndefined(this.props.modal) || this.props.modal === false) && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<CircularProgress size={isNullOrUndefined(this.props.size) ? 90 : this.props.size} />
							<Typography variant="h6">{isNullOrUndefined(this.props.message) ? "Processing" : this.props.message}</Typography>
						</Grid>
					</Grid>
				)}
				{(this.props.modal === true) && (
					<Dialog open={true} fullWidth={true}>
						<DialogContent>
							<Grid container spacing={1} sx={{textAlign: 'center'}}>
								<Grid item xs={12}>
									<CircularProgress size={isNullOrUndefined(this.props.size) ? 90 : this.props.size} />
									<Typography variant="h6">{isNullOrUndefined(this.props.message) ? "Processing" : this.props.message}</Typography>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
			</React.Fragment>
		);
	}
}

// -----------------
export default ProcessingModal;