import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { EnumEntityType } from "sharedInterfaces/ITen99EntitySummary";
import { Ten99PrepCustomerIcon, Ten99PrepFormIcon, Ten99PrepPayerIcon, Ten99PrepRecipientIcon } from 'Ten99PrepOverloads/IconOverloads';

function getIcon(type: EnumEntityType) {
	switch (type) {
		case (EnumEntityType.Customer):
			return <Ten99PrepCustomerIcon />;
		case (EnumEntityType.Payer):
			return <Ten99PrepPayerIcon />;
		case (EnumEntityType.Recipient):
			return <Ten99PrepRecipientIcon />;
		case (EnumEntityType.RecipientForm):
			return <Ten99PrepFormIcon />;
	}
}

export interface IEntityNavItem {
	type: EnumEntityType,
	name: string
}

interface IEntityNavProps {
	items: IEntityNavItem[],
}

export const EntityNav: React.FunctionComponent<IEntityNavProps> =(props) => {

    return (
        <Breadcrumbs separator=">">
			{props.items.map((item, index) => {
				return (
					<div key={index}>
						<Typography display="inline" variant="subtitle2" color="textSecondary" > {getIcon(item.type)} {item.name}</Typography>
					</div>
				)
			}
			)}			
        </Breadcrumbs>
    );
}