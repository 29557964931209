import { Action, Reducer } from 'redux';
import { Auth_LogoutAction } from 'store/SharedActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface ITen99User {
    id: string,
    isInternal: boolean,
    firstName: string,
    lastName: string,
    companyId: string,
    isCurrentTermsAccepted: boolean,
}

export interface UserState { //tagged properties with "user_" so when looking in the props can easily tell these came from userstate
    user_Id: string,
    user_Name: string,
    user_isInternalUser: boolean,
    user_CartCount: number,
    user_isCurrentTermsAccepted: boolean,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoadUser { type: 'User/Load', id: string, name: string, isInternal: boolean, isCurrentTermsAccepted: boolean }
export interface UserAcceptedTerms { type: 'User/AcceptedTerms' }
export interface ResetUser { type: 'User/Reset' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoadUser | ResetUser | Auth_LogoutAction | UserAcceptedTerms;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    UserActions_AcceptedTerms: () => ({ type: 'User/AcceptedTerms' } as UserAcceptedTerms),
    UserActions_LoadUser: (id: string, name: string, isInternal: boolean, isCurrentTermsAccepted: boolean) => ({ type: 'User/Load', id: id, name: name, isInternal: isInternal, isCurrentTermsAccepted: isCurrentTermsAccepted } as LoadUser),
    UserActions_Reset: () => ({ type: 'User/Reset' } as ResetUser),
};


const unloadedState: UserState = { user_Id: "", user_Name: "", user_isInternalUser: false, user_CartCount: 0, user_isCurrentTermsAccepted: false };
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'User/AcceptedTerms':
            return Object.assign({}, state, { user_isCurrentTermsAccepted: true });
        case 'User/Load':
            return Object.assign({}, state, { user_Id: action.id, user_Name: action.name, user_isInternalUser: action.isInternal, user_isCurrentTermsAccepted: action.isCurrentTermsAccepted });
        case 'User/Reset':
        case 'Auth/Logout':
            return unloadedState;
        default:
            return state;
    }
};
