import { Box, Grid, Paper } from '@mui/material';
import Faqs from 'components/information/Faqs';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import CompanyInvite from 'components/ten99Prep/CompanyInvite';
import DynamicNotifications from 'components/ten99Prep/DynamicNotifications';
import HomeGateway from 'components/ten99Prep/Ten99HomeGateway';
import Ten99Toolbar from 'components/ten99Prep/Ten99Toolbar';
import Terms from 'components/ten99Prep/Terms';
import Logout from 'components/user/Logout';
import React from "react";
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99Company } from 'sharedInterfaces/ITen99Company';
import { ITen99GatewayStartup } from 'sharedInterfaces/ITen99GatewayStartup';
import { ApplicationState } from 'store';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { Auth_ActionCreators } from 'store/SharedActions';
import * as UserStore from 'store/User';
import { ITen99User } from 'store/User';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import PrivateRoute from 'utilities/PrivateRoute';
import { ClearCompany, GetCompany, GetYear } from 'utilities/UserId';

interface ILocalState {
    processing: boolean,
    validationMessages: ITen99ApiResponseMessage[],
}

interface IGateWayProps {
   
}

type GateWayProps =
    & IGateWayProps
    & UserStore.UserState // ... state we've requested from the Redux store
    & typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
    & typeof Auth_ActionCreators // ... plus action creators we've requested
    & typeof UserStore.actionCreators// ... plus action creators we've requested
    & typeof HomeNavigationStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps // ... plus incoming routing parameters
    ;

const initialLocalState = {
    processing: true, validationMessages: {} as ITen99ApiResponseMessage[]} as ILocalState;
class Ten99PrepGateway extends React.PureComponent<React.PropsWithChildren<GateWayProps>, ILocalState>
{
    private idleTimer: React.RefObject<IdleTimer>;
    private idleTimerWarningTimeout: NodeJS.Timeout | undefined;
    constructor(props: GateWayProps) {
        super(props);
        this.state = initialLocalState;
        this.idleTimer = React.createRef<IdleTimer>();

        let proceed: boolean = true;
        let localValidationMessages: ITen99ApiResponseMessage[] = [];
        let currentYearString: string | null = GetYear();
        let currentCompany: string | null = GetCompany();
        Promise.all([MakeApiCall<ITen99User>("api/User/Details", "GET"), MakeApiCall<ITen99GatewayStartup>("api/Common/StartupInfo", "GET"), MakeApiCall<ITen99Company[]>("api/Companies/user", "GET")]).then(values => {
            values.forEach((response, index) => {
                if (response.isSuccess && response.payload !== undefined) {
                    switch (index) {
                        case 0:
                            let tempUserPayload: ITen99User = response.payload as ITen99User;
                            this.props.UserActions_LoadUser(tempUserPayload.id, tempUserPayload.firstName + " " + tempUserPayload.lastName, tempUserPayload.isInternal, tempUserPayload.isCurrentTermsAccepted);
                            break;
                        case 1:
                            let config: ITen99GatewayStartup = response.payload as ITen99GatewayStartup;
                            sessionStorage.setItem("qEnabled", config.quickBooksEnabled.toString());
                            if (isNullOrUndefined(currentYearString)) {
                                const year: number = isNullOrUndefined(config.defaultFilingYear) ? 0 : config.defaultFilingYear;
                                this.props.CustomerSummaryActions_SetCurrentFilingYear(year);                            }
                            else {
                                //use existing year
                                this.props.CustomerSummaryActions_SetCurrentFilingYear(Number(currentYearString));
                            }

                            break;
                        case 2:
                            let tempCompanyPayload: ITen99Company[] = response.payload as ITen99Company[];
                            let company: ITen99Company | undefined = undefined;
                            if (!isNullOrUndefined(tempCompanyPayload)) {
                                //get company
                                if (isNullOrUndefined(currentCompany)) {
                                    company = tempCompanyPayload.find(x => x.isDefault);
                                    if (isNullOrUndefined(company)) { // if we cannot find default, pick first valid company returned
                                        company = tempCompanyPayload[0];
                                    }
                                }
                                else {                                    
                                    company = tempCompanyPayload.find(x => x.id.toString() === currentCompany);
                                }
                            }
                            if (!isNullOrUndefined(company)) {
                                this.props.CustomerSummaryActions_SetCompany(company);
                            }
                            else {                                
                                ClearCompany();
                                if (!this.props.location.pathname.toLowerCase().startsWith("/gate/companyinvite/")) {
                                    this.props.confirmDialog_Display("No Active Compaines", "1", "You have not accepted an invitation to join 1099-Prep. Please click the activation link in the email you received when your account was registered with 1099-Prep. Please contact support@1099-prep.com for assistance, if needed.", this.onAcknowledge, true)
                                }
                            }
                            break;
                    }
                }
                else if (response.httpStatusCode === 401)
                {
                    //do nothing, let higher level funcitons log user out
                     proceed = false;
                }
                else {
                    localValidationMessages.push({ type: "Fail-pageInfo", propertyNames: [""] as string[], message: "Issue initializing account. Please contact support@1099-prep.com for assistance.", isWarning: false });
                }
            });
            if (proceed) {
                this.setState({ processing: false, validationMessages: localValidationMessages });
            }
        });
    }
   
    private onUserAcknowledge = (id: string) => {
        //clear timeout
        if (!isNullOrUndefined(this.idleTimerWarningTimeout)) {
            clearTimeout(this.idleTimerWarningTimeout);
        }
    }

    private onAcknowledge = (value: string = "") => {
        this.props.confirmDialog_Reset();        
    }
    private onLogout = (redirect: string = "") => {
        this.props.confirmDialog_Reset();
        this.props.history.push("/gate/logout/?redirect=" + window.location.pathname); // go to login
    }

    // If the user is inactive for X amount of time (X = idleTimeoutThreshold from constants.js) a warning dialog opens.
    private onUserIdle = () => {
        this.props.confirmDialog_Display("Inactivity Timeout", "1", "You are about to be logged out due to inactivity. Click 'Proceed' to continue working.", this.onUserAcknowledge, true)

        if (!isNullOrUndefined(process.env.REACT_APP_MAX_INACIVITY_WARNING_TIME)) {
            this.idleTimerWarningTimeout = setTimeout(() => { this.onLogout(); }, Number.parseInt(process.env.REACT_APP_MAX_INACIVITY_WARNING_TIME));
        }

        if (!isNullOrUndefined(this.idleTimer) && !isNullOrUndefined(this.idleTimer.current)) {
            this.idleTimer.current.reset();
        }
    };

    public render() {
        return (
            <React.Fragment>
                <IdleTimer
                    ref={this.idleTimer as any}
                    //element={document}
                    onIdle={this.onUserIdle}
                    debounce={250}
                    timeout={!isNullOrUndefined(process.env.REACT_APP_MAX_INACIVITY_TIME) ? Number.parseInt(process.env.REACT_APP_MAX_INACIVITY_TIME) : 0}
                />
                {(this.state.processing) && (
                    <Box sx={{
                        backgroundImage: "linear-gradient(to bottom right, white, rgb(0, 112, 122))",
                        backgroundColor: "white",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        //height: "100vh",
                        minHeight: "700px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        overflowY: "auto"
                    }}>
				        <Grid container sx={{textAlign: 'center'}}>
                            <Grid item xs={12}>
                                <Box
                                    component="img"
                                    alt="Logo"
                                    src="./1099Logo.png"
                                    sx={{
                                        width: "300px",
                                        marginTop: "1vh",
                                    }}
                                />
					        </Grid>
					        <Grid item xs={3}>
					        </Grid>
					        <Grid item xs={6} sx={{textAlign: 'center'}}>				
                                <Paper elevation={3} variant="outlined" sx={{
                                    width: "55vw",
                                    height: "50vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    minHeight: "600px",
                                    minWidth: "600px",
                                    boxShadow: "0px 5px 20px #00000029",
                                    marginBottom: "8vh",
                                    borderRadius: "0px",
                                    overflowY: "auto"
                                }}>
							        <Grid container sx={{textAlign: 'center'}}>
								        <Grid item xs={3}>
								        </Grid>
								        <Grid item xs={6}>
                                            <ProcessingModal modal={false} />
								        </Grid>
							        </Grid>							
						        </Paper>
					        </Grid>
				        </Grid>
			        </Box>                    
                )}
                {(!this.state.processing) && (
                    <React.Fragment>
                        <Ten99Toolbar />
                        <br />
                        <Grid container spacing={1} sx={{ minHeight: "85vh", }}>
                            <Grid item xs={12}>
                                <Paper square sx={{height: "100%" }}>
                                    <Grid container spacing={1} sx={{ height: "100%", }}>
                                        <Grid item xs={1}>
                                            <PrivateRoute path='/gate/logout/' component={Logout} />
                                        </Grid>                                        
                                        {(this.state.validationMessages !== undefined && this.state.validationMessages.length > 0) && (
                                                <React.Fragment>
                                                    <Grid item xs={10}>
                                                        <ApiErrorResponseMessages messages={this.state.validationMessages} />
                                                    </Grid>
                                                    <Grid item xs={1}>                                                    
                                                    </Grid>
                                                </React.Fragment>
                                        )}
                                        {((this.state.validationMessages === undefined || this.state.validationMessages.length === 0) && !this.props.user_isCurrentTermsAccepted) && (
                                            <Grid item xs={10}>
                                                <Terms logout={this.onLogout} />
                                            </Grid>
                                        )}
                                        {((this.state.validationMessages === undefined || this.state.validationMessages.length === 0) && this.props.user_isCurrentTermsAccepted) && (
                                                <Grid item xs={10}>
                                                    <PrivateRoute path='/gate/home' component={HomeGateway} />
                                                    <PrivateRoute exact path='/gate/faqs' component={Faqs} />
                                                    <PrivateRoute exact path='/gate/companyInvite/:companyId/:companyName' component={CompanyInvite} />
                                                    
                                                </Grid>
                                            )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <DynamicNotifications />
                    </React.Fragment>
                )}
                
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.user, // Selects which state properties are merged into the component's props
    { ...Auth_ActionCreators, ...ConfirmDialog.actionCreators, ...HomeNavigationStore.actionCreators, ...UserStore.actionCreators } // Selects which action creators are merged into the component's props
)((Ten99PrepGateway as any));
