import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from "react-redux";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { ApplicationState } from "store";
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from "store/HomeNavigation";
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";


enum enumComponentStatus {
	PendingAction,
	Processing,
	Loading,
	Invalid,
	Error
};

interface IChangeFilingYearProps {
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,	
	validationMessages?: ITen99ApiResponseMessage[],	
}

type ChangeFilingYearProps =
	& IChangeFilingYearProps
	& HomeNavigationStore.CustomerSummaryState
	& ICustomerSummaryActions // ... plus action creators we've requested
	;

interface ILocalState {	
	status: enumComponentStatus,	
	validationMessages: ITen99ApiResponseMessage[],
	years: ITen99LookupItem[],
	selectedYear: number,
}

const initialLocalState = {
	status: enumComponentStatus.Loading, years: {} as ITen99LookupItem[], validationMessages: {} as ITen99ApiResponseMessage[] } as ILocalState;
class ChangeFilingYear extends React.PureComponent<ChangeFilingYearProps, ILocalState> {

	//local state

	constructor(props: ChangeFilingYearProps) {
		super(props);
		this.state = initialLocalState;
		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=10", "GET") //get lookup years
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 10);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.setState({ status: enumComponentStatus.PendingAction, selectedYear: this.props.currentFilingYear, years: lookup.lookupItems, validationMessages: {} as ITen99ApiResponseMessage[] });
						}
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
				}
			})

	}
	// -----------------
	//Handle User events
	private onFilingYearChange = (event: { target: { name?: string; value: unknown; }; }) => {
		this.setState({ selectedYear: Number(event.target.value) });
	}

	private onSubmit = () => {
		this.props.CustomerSummaryActions_SetCurrentFilingYear(this.state.selectedYear);
		this.props.CustomerSummaryActions_RefreshActiveCustomerSummary();
		this.props.onClose(true, undefined)
    }
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Select Filing Year
							<InfoToolTip text="Select the filing year you wish to view information for" />
							</Typography>
						</Grid>						
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop: "8px" }}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
							<Grid item xs={12}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							</Grid>
						)}
						{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
							<Grid item xs={12}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}
						{(this.state.status === enumComponentStatus.Invalid || this.state.status === enumComponentStatus.PendingAction) && (
							<Grid item xs={12}>
								<FormControl
									error={this.state.status === enumComponentStatus.Invalid}
									fullWidth
									required>
									<InputLabel id="formTypeLabel">Filing Year</InputLabel>
									<Select
										labelId="formTypeLabel"
										label="Filing Year"
										value={this.state.selectedYear}
										onChange={this.onFilingYearChange}
										inputProps={{}}
										id="filingYear"
										name="filingYear"
									>
										{(this.state.years.map((item, index) => <MenuItem key={index} value={item.id} >{item.name}</MenuItem>))}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => this.props.onClose(false, undefined)}>Close</Button>
					<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={this.onSubmit}>Submit</Button>
					
				</DialogActions>
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...HomeNavigationStore.actionCreators } // Selects which action creators are merged into the component's props
)(ChangeFilingYear);