import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import Address from 'components/ten99Prep/Address/Address';
import Contact from 'components/ten99Prep/Contact';
import { DynamicInputGrid } from 'components/ten99Prep/DynamicInputGrid/DynamicInputGrid';
import { EntityNav } from 'components/ten99Prep/EntityAddEditView/EntityNav';
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99Address } from 'sharedInterfaces/ITen99Address';
import { enumAddEditViewAction, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99Contact } from 'sharedInterfaces/ITen99Contact';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { IFormElementTemplate } from 'sharedInterfaces/ITen99FormElementTemplate';
import { ITen99LookupData, ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { ITen99PersonTypeEnum } from 'sharedInterfaces/ITen99PersonTypeEnum';
import { ApplicationState } from 'store';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

enum enumComponentStatus {
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface IRecipient {
	recipientTypeId: number,
	firstName: string,
	lastName: string,
	companyName: string,
	name2: string,
	tin: string,
	isNaTin: boolean,

	address: ITen99Address,
	contact: ITen99Contact,

	//1042-S
	ten42sBox13RecipientCountryCodeId?: number,
	ten42sBox13fRecipientCh3StatusCodeId?: number,
	ten42sBox13gRecipientCh4StatusCodeId?: number,
    ten42sBox13hRecipientGiin: string,
	ten42sBox13iRecipientForeignTin: string,
    ten42sBox13jRecipientLobCodeId ?: number,
	ten42sBox13kRecipientAccountNumber: string,
	ten42sBox13lRecipientBirthDate: string,

	filingYearId: number,
	ticks: string,
}

interface IAddEditViewRecipientExternalProps {
	onClose: (response: IAevEntityResponse) => void;
	entitySettings: IAevEntitySetting;
}
type AddEditProps =
	IAddEditViewRecipientExternalProps
	& HomeNavigationStore.CustomerSummaryState
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	;

interface ILocalState {
	is1042sInformationExpanded: boolean,
	status: enumComponentStatus,
	recipient: IRecipient,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
	hasTin: boolean,
}

const emptyAddress = { addressLine1: "", addressLine2: "", city: "", stateId: -1, zip: "", countryId: -1, foreignPostalCode: "", foreignStateOrProvince: "" } as ITen99Address
const emptyContact = { contactName: "", email: "", isPhoneNumberInternational: false, phoneNumber: "", phoneNumberExt: "" } as ITen99Contact
const emptyRecipient = { firstName: "", lastName: "", companyName: "", tin: "", ticks: "", address: emptyAddress, contact: emptyContact, filingYearId: 0, recipientTypeId: 1 } as IRecipient

const initialLocalState = {
	hasTin: true, is1042sInformationExpanded: false, status: enumComponentStatus.Processing, recipient: emptyRecipient, validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: {} as string[] } as ILocalState;
class AddEditViewRecipient extends React.PureComponent<AddEditProps, ILocalState> {
	private states: ITen99LookupItem[] = {} as ITen99LookupItem[];
	private countries: ITen99LookupItem[] = {} as ITen99LookupItem[];
	private usaCountryId: number = -1;
	private recipientAdditional1042sInformationTemplate: IFormElementTemplate[];
	//local state
	
	constructor(props: AddEditProps) {
		super(props);
		let tempState = initialLocalState;
		tempState.recipient.filingYearId = props.currentFilingYear;
		

		this.state = tempState;
		this.recipientAdditional1042sInformationTemplate = JSON.parse(sessionStorage.getItem("recipientAditional1042sInformationTemplate") ?? "");

		//load states, countries first
		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=1&lookupTableIds=14&lookupTableIds=16&lookupTableIds=17&lookupTableIds=20&lookupTableIds=44", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 1);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.states = lookup.lookupItems;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load states" }] as ITen99ApiResponseMessage[] });
						}

						lookup = data.payload.find(x => x.options.requestedTable === 14);
						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.countries = lookup.lookupItems;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load countries" }] as ITen99ApiResponseMessage[] });
						}

						//get USA ID
						let item: ITen99LookupItem | undefined = this.countries.find(x => x.name === "USA");						
						if (!isNullOrUndefined(item)) {
							this.usaCountryId = item.id;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to find country: USA" }] as ITen99ApiResponseMessage[] });
						}

						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 16), this.recipientAdditional1042sInformationTemplate.find(x => x.formElementId === "ten42sBox13fRecipientCh3StatusCodeId"), "1042-S Chapter 3");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 17), this.recipientAdditional1042sInformationTemplate.find(x => x.formElementId === "ten42sBox13gRecipientCh4StatusCodeId"), "1042-S Chapter 4");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 44), this.recipientAdditional1042sInformationTemplate.find(x => x.formElementId === "ten42sBox13RecipientCountryCodeId"), "1042-S Country");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 20), this.recipientAdditional1042sInformationTemplate.find(x => x.formElementId === "ten42sBox13jRecipientLobCodeId"), "1042-S LOBs");

						if (this.state.status !== enumComponentStatus.Error) {
							//load entity if needed
							if (props.entitySettings.action === enumAddEditViewAction.Edit || props.entitySettings.action === enumAddEditViewAction.View) {

								MakeApiCall<IRecipient>("api/Recipients/" + this.props.entitySettings.id, "GET")
									.then(data => {
										if (data.isSuccess && !isNullOrUndefined(data.payload)) {
											this.setState({ recipient: data.payload, hasTin: !data.payload.isNaTin, status: enumComponentStatus.PendingAction })
										}
										else {
											this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the recipient" }] as ITen99ApiResponseMessage[] });
										}
									})
							}
							else {
								this.setState({ status: enumComponentStatus.PendingAction, recipient: { ...this.state.recipient, address: { ...this.state.recipient.address, countryId: this.usaCountryId } } });
							}
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})
    }

	private setTemplateSource = (lookup: ITen99LookupData | undefined, templateItem: IFormElementTemplate | undefined, name: string) => {

		if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0 && !isNullOrUndefined(templateItem)) {
			templateItem.source = lookup.lookupItems;
		}
		else {
			this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to map " + name + " lookup items" }] as ITen99ApiResponseMessage[] });
		}
	}

	private getTitleName = () => {
		if (this.state.recipient.recipientTypeId === ITen99PersonTypeEnum.Company) {
			return this.state.recipient.companyName;
		}
		else {
			return this.state.recipient.firstName + " " + this.state.recipient.lastName;
		}
	}

	private getTitle(action: enumAddEditViewAction) {
		switch (action) {
			case enumAddEditViewAction.Add:
				return "Add New Recipient:";
			case enumAddEditViewAction.Edit:
				return "Edit Recipient: " + this.getTitleName();
			case enumAddEditViewAction.View:
				return "Recipient: " + this.getTitleName();
        }
	}

	// -----------------
	//Handle User events

	private onSubmit = () => {
		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing) {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
			//call the api and set the results in the state to reflect on the render
			let url: string = "";
			let httpAction: string = "";
			let responseMessage: string = "";
			switch (this.props.entitySettings.action) {
				case enumAddEditViewAction.Add:
					const parent: ITen99EntitySummary | undefined = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Payer)
					url = "api/Recipients/?payerId=" + (isNullOrUndefined(parent) ? "" : parent.id);
					httpAction = "POST";
					responseMessage = "Recipient " + this.state.recipient.firstName + " " + this.state.recipient.lastName + " added";
					break;
				case enumAddEditViewAction.Edit:
					url = "api/Recipients/" + this.props.entitySettings.id;
					httpAction = "PUT";
					responseMessage = "Recipient " + this.state.recipient.firstName + " " + this.state.recipient.lastName + " updated";
					break;
			}

			MakeApiCall<string>(url, httpAction, JSON.stringify(this.state.recipient))
				.then(data => {
					if (data.isSuccess) {
						this.props.onClose({ processed: true, message: responseMessage } as IAevEntityResponse);
					}
					else {
						if (data.httpStatusCode === 422) {
							this.setState({ status: enumComponentStatus.Invalid, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
						}
						else if (data.httpStatusCode === 409)
						{
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Recipient has been updated by another process. Please close and reload." }] as ITen99ApiResponseMessage[] });
                        }
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
						}
					}						
				});
		}
    }

	private onCancel = () =>
	{
		this.props.onClose({ processed: false, message: "" } as IAevEntityResponse);
	}

	private onRecipientTypeChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ recipient: { ...this.state.recipient, recipientTypeId: Number.parseInt(event.target.value) } });
	}
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleRecipientChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ recipient: { ...this.state.recipient, [event.target.name]: event.target.value } } as Pick<ILocalState, keyof ILocalState>);
	}

	private handleHasTinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ hasTin: event.target.checked, recipient: { ...this.state.recipient, isNaTin: !event.target.checked } } as Pick<ILocalState, keyof ILocalState>);
		if (!event.target.checked) {
			this.props.confirmDialog_Display("Has no TIN", "blank", "You have indicated that this recipient does not have a TIN. You will need to enter a 7 digit number in the TIN box in order to save this recipient.",undefined, true);
		}
	}

	private handleRecipientAddressChange = (name: string, value: any) => {
		this.setState({ recipient: { ...this.state.recipient, address: { ...this.state.recipient.address, [name]: value } } } as Pick<ILocalState, keyof ILocalState>);
	}
	private handleRecipientContactChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ recipient: { ...this.state.recipient, contact: { ...this.state.recipient.contact, [event.target.name]: event.target.value } } } as Pick<ILocalState, keyof ILocalState>);
	}

	private handle1042sInformationChange = (name: string, value: string) => {
		this.setState({ recipient: { ...this.state.recipient, [name]: value } } as Pick<ILocalState, keyof ILocalState>);
	}

	private on1042sInformationExpanded = () => {
		this.setState({ is1042sInformationExpanded: !this.state.is1042sInformationExpanded });
	}

	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		const tinLabel: string = this.state.recipient.recipientTypeId === ITen99PersonTypeEnum.Individual ? "Taxpayer Identifier Number" : "Employer Identifier Number";
		const tinMaskFormat: string = this.state.hasTin ? (this.state.recipient.recipientTypeId === ITen99PersonTypeEnum.Individual ? "###-##-####" : "##-#######") : "NA#######";
		
		return (			
			<React.Fragment>
				<DialogTitle>
					<Typography variant="h5" >{this.getTitle(this.props.entitySettings.action)}</Typography>
					<EntityNav items={this.props.entitySettings.parents} />
				</DialogTitle>
				<DialogContent>	
				<Grid container spacing={1} sx={{textAlign: 'center'}}>
					<Grid item xs={12}>
						{this.state.status === enumComponentStatus.Processing && (
							<Grid container spacing={1} sx={{textAlign: 'center'}}>
								<Grid item xs={12}>
									<div>
										<CircularProgress size={90} />
										<div>Processing...</div>
									</div>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid item xs={12}>
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						)}
						<Grid container spacing={1} sx={{textAlign: 'center'}}>
							{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Invalid) && (
								<React.Fragment>
									<Grid item xs={12} sx={{textAlign: 'left'}}>
										<FormControl
											fullWidth
											required>
											<FormLabel id="payerTypeLabel">Recipient Type</FormLabel>
											<RadioGroup name="recipientTypeId" value={this.state.recipient.recipientTypeId.toString()} onChange={this.onRecipientTypeChange} row>
													<FormControlLabel disabled={this.props.entitySettings.action === enumAddEditViewAction.View} value={ITen99PersonTypeEnum.Individual.toString()} label="Individual" control={<Radio color="primary" />} />
													<FormControlLabel disabled={this.props.entitySettings.action === enumAddEditViewAction.View} value={ITen99PersonTypeEnum.Company.toString()} label="Company" control={<Radio color="primary" />} />
											</RadioGroup>
										</FormControl>
									</Grid>
										{this.state.recipient.recipientTypeId === ITen99PersonTypeEnum.Individual && (
										<React.Fragment>
											<Grid item xs={6}>
												<TextField
													disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
													onChange={this.handleRecipientChange}
													value={this.state.recipient.firstName}
													variant="outlined"
													error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "FirstName") !== undefined)}
													id="firstName"
													label="First Name"
													name="firstName"
													required
													fullWidth
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
													onChange={this.handleRecipientChange}
													value={this.state.recipient.lastName}
													variant="outlined"
													error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "LastName") !== undefined)}
													id="lastName"
													label="Last Name"
													name="lastName"
													required
													fullWidth
												/>
											</Grid>
										</React.Fragment>
									)}
									{this.state.recipient.recipientTypeId === ITen99PersonTypeEnum.Company && (
										<React.Fragment>
											<Grid item xs={12}>
												<TextField
													disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
													onChange={this.handleRecipientChange}
													value={this.state.recipient.companyName}
													variant="outlined"
													error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "CompanyName") !== undefined)}
													id="companyName"
													label="Company Name"
													name="companyName"
													required
													fullWidth
												/>
											</Grid>
										</React.Fragment>
									)}

									<Grid item xs={12}>
										<TextField
											disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
											onChange={this.handleRecipientChange}
											value={this.state.recipient.name2}
											variant="outlined"
											error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "Name2") !== undefined)}
											id="name2"
											label="Name Line 2"
											name="name2"
											fullWidth
										/>
									</Grid>
									<Grid item xs={3}>
										<FormControlLabel
												label={<Typography variant="body1" color={(enumComponentStatus.Invalid && this.state.validationMessages.length > 0 && (this.state.invalidProperties.find(x => x === "HasTin") !== undefined)) ? "error" : "textPrimary"}>Has TIN</Typography>}
											disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
											control={
												<Checkbox
													color="primary"
													disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
													onChange={this.handleHasTinChange}
													checked={this.state.hasTin}
													id="hasTin"
													name="hasTin"
												/>}
										/>
											<InfoToolTip text="Uncheck this checkbox if you do not have a valid TIN. You will still need to provide a 7 digit number that is unique for this customer as it is used for various processes including imports." />
									</Grid>
									<Grid item xs={9}>
										<TextField
											disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
											onChange={this.handleRecipientChange}
											value={this.state.recipient.tin}
											variant="outlined"
											error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "TaxNumber") !== undefined)}
											id="tin"
											label={tinLabel}
											name="tin"
											required={true}
											fullWidth
											InputProps={{
												inputComponent: CustomerNumberFormat as any,
												inputProps: { format: tinMaskFormat, mask: "#", useFormattedValue: !this.state.hasTin }
											}}
										/>
									</Grid>							
										
									<Grid item xs={12} sx={{textAlign: 'left'}} >
										<Typography variant="h6">Address:</Typography>
									</Grid>

										<Address disabled={this.props.entitySettings.action === enumAddEditViewAction.View} invalid={this.state.status === enumComponentStatus.Invalid} address={this.state.recipient.address} states={this.states} countries={this.countries} onchange={this.handleRecipientAddressChange} validationMessages={this.state.validationMessages} invalidProperties={ this.state.invalidProperties}/>

									<Grid item xs={12} sx={{textAlign: 'left'}} >
										<Typography variant="h6">Contact Info:</Typography>
									</Grid>

										<Contact disabled={this.props.entitySettings.action === enumAddEditViewAction.View} invalid={this.state.status === enumComponentStatus.Invalid} contact={this.state.recipient.contact} onchange={this.handleRecipientContactChange} validationMessages={this.state.validationMessages} invalidProperties={this.state.invalidProperties} />

										<Grid item xs={12}>
											<Accordion disableGutters={true} expanded={this.state.is1042sInformationExpanded} onChange={this.on1042sInformationExpanded}>
												<AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: "payer.main", marginBottom: 0 }}>
													<Typography >Additional Information (only required for 1042-S forms)</Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ paddingLeft: 5 }}>
													{(this.state.is1042sInformationExpanded) && (
														<Grid container spacing={1}>
															<DynamicInputGrid invalidState={this.state.status === enumComponentStatus.Invalid}
																disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
																onchange={this.handle1042sInformationChange}
																templates={this.recipientAdditional1042sInformationTemplate}
																validationMessages={this.state.validationMessages}
																invalidProperties={ this.state.invalidProperties}
																formElements={[
																	{ formElementValueId: 1, formElementId: "ten42sBox13RecipientCountryCodeId", elementValue: this.state.recipient.ten42sBox13RecipientCountryCodeId?.toString() ?? "" },
																	{ formElementValueId: 2, formElementId: "ten42sBox13fRecipientCh3StatusCodeId", elementValue: this.state.recipient.ten42sBox13fRecipientCh3StatusCodeId?.toString() ?? "" },
																	{ formElementValueId: 3, formElementId: "ten42sBox13gRecipientCh4StatusCodeId", elementValue: this.state.recipient.ten42sBox13gRecipientCh4StatusCodeId?.toString() ?? "" },
																	{ formElementValueId: 4, formElementId: "ten42sBox13hRecipientGiin", elementValue: this.state.recipient.ten42sBox13hRecipientGiin },
																	{ formElementValueId: 5, formElementId: "ten42sBox13iRecipientForeignTin", elementValue: this.state.recipient.ten42sBox13iRecipientForeignTin },
																	{ formElementValueId: 6, formElementId: "ten42sBox13jRecipientLobCodeId", elementValue: this.state.recipient.ten42sBox13jRecipientLobCodeId?.toString() ?? "" },
																	{ formElementValueId: 7, formElementId: "ten42sBox13kRecipientAccountNumber", elementValue: this.state.recipient.ten42sBox13kRecipientAccountNumber },
																	{ formElementValueId: 8, formElementId: "ten42sBox13lRecipientBirthDate", elementValue: this.state.recipient.ten42sBox13lRecipientBirthDate },
																]}
															/>
														</Grid>

													)}
												</AccordionDetails>
											</Accordion>
										</Grid>
									
								</React.Fragment>
								)}					
							</Grid>						
					</Grid>					
				</Grid>
				</DialogContent>
				<DialogActions>
					{(this.state.status === enumComponentStatus.Invalid || this.state.status === enumComponentStatus.PendingAction) &&
						(this.props.entitySettings.action === enumAddEditViewAction.Add || this.props.entitySettings.action === enumAddEditViewAction.Edit)
						&& (
							<React.Fragment>
								<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
								<Button type="button" startIcon={< Ten99PrepSaveIcon />} variant="contained" color="primary" onClick={this.onSubmit}>Save</Button>
							</React.Fragment>
						)}
					{(this.state.status === enumComponentStatus.Error || this.props.entitySettings.action === enumAddEditViewAction.View) && (
						<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="primary" onClick={this.onCancel}>Close</Button>
					)}
				</DialogActions>
			</React.Fragment>
		);
	}
}
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators}
)(AddEditViewRecipient);
