import { Autocomplete, Button, TextField } from "@mui/material";
import DialogTemplate from "components/display/DialogTemplate";
import * as React from 'react';
import { ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";

interface IQboMappingAutoCompleteProps {
	items: ITen99LookupItem[],
	disabledItems: ITen99LookupItem[],
	selectedItems: ITen99LookupItem[],
	fieldName: string
	onClose: () => void,
	onSave: (items: ITen99LookupItem[]) => void,
}

class QboMappingAutoComplete extends React.PureComponent<IQboMappingAutoCompleteProps> {

	private chosenItems: ITen99LookupItem[] = [] as ITen99LookupItem[];

	// Render
	render() {
		return (
			<React.Fragment>
				<DialogTemplate onClose={this.props.onClose} title={"Accounts"}
					buttonsLeft={
						[
							<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.props.onClose}>Cancel</Button>,
						] as unknown as typeof Button[]}
					buttonsRight={
						[
							<Button key={0} type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={() => { this.props.onSave(this.chosenItems); }}>Save</Button>,
						] as unknown as typeof Button[]}
				>
					<Autocomplete
						multiple
						id="auto_complete_accounts"
						options={this.props.items}
						getOptionDisabled={(option) => this.props.disabledItems.findIndex(x => x.id === option.id) >= 0}
						defaultValue={this.props.items.filter(item => this.props.selectedItems.findIndex(selectedItem => selectedItem.id === item.id) >=0) }
						getOptionLabel={(option) => option.name}
						onChange={(e, value) => { this.chosenItems = value;}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={ this.props.fieldName }
								placeholder=""
							/>
						)}
					/>
				</DialogTemplate>
			</React.Fragment>
		);
	}
}

export default QboMappingAutoComplete;