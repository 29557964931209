import { Button, Dialog } from "@mui/material";
import DialogTemplate from 'components/display/DialogTemplate';
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponse, ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import * as ConfirmDialog from 'store/ConfirmDialog';
import { Ten99PrepBackIcon, Ten99PrepForwardIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import ImportFileDisplay from "./ImportFileDisplay";


interface ILocalState {
	importFiles: File[],
	processing: boolean,
	validationMessages: ITen99ApiResponseMessage[],
}

export enum UploadType {
	Import = 1,
	Historical = 2,
	QuickBooks = 3
}

interface IImportDataProps {
	customer: ITen99Customer,
	title: string,
	proceedMsg: string,
	uploadType: UploadType,
	onBack: () => void,
	onClose: () => void,
	onNext: () => void,
}

type ImportDataProps =
	& IImportDataProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = {importFiles: [] as File[], validationMessages: [] as ITen99ApiResponseMessage[], processing: false };

class ImportFileDialog extends React.PureComponent<ImportDataProps, ILocalState> {


	constructor(props: ImportDataProps) {
		super(props);
		this.state = initialLocalState;
	}

	private onFileSelect = (file: File) => {
		this.setState({importFiles: [...this.state.importFiles, file ] });
	}

	private onFileRemove = (index: number) => {
		let tempFiles: File[] = [...this.state.importFiles];
		tempFiles.splice(index, 1);
		this.setState({ importFiles: tempFiles });
	}

	private onFileUploadNext = () => {
		if (this.state.importFiles.length !== 0) {
			this.props.confirmDialog_Display(this.props.title + ": " + this.props.customer.name, "-1", this.props.proceedMsg, this.onFileUploadProceed)
		}
		else {
			this.props.confirmDialog_Display(this.props.title + ": " + this.props.customer.name, "-1", "No files selected to import.", undefined, true);
        }
	}

	private onFileUploadProceed = (id: string ) => {		
		if (this.state.importFiles.length !== 0) {			

			let promiseArray: Promise<ITen99ApiResponse<string>>[]= [];

			this.setState({ processing: true, validationMessages: []});

			this.state.importFiles.forEach((file) => {
				let body: FormData = new FormData();
				body.append('file', file);
				promiseArray.push(MakeApiCall<string>("api/Imports/Import/" + this.props.customer.customerId + "/Type/" + this.props.uploadType, "POST", body));
			});

			let messages: ITen99ApiResponseMessage[] = [];

			Promise.all(promiseArray).then(values => {
				
				let failedFiles: string[] = [];
				values.forEach((response, index) => {
					if (!response.isSuccess) {
						failedFiles.push(this.state.importFiles[index].name);
						if ((response.httpStatusCode === 422 || response.httpStatusCode === 415) && !isNullOrUndefined(response.details)) {
							response.details.forEach((detail, detailIndex) => {
								messages.push({ type: "Error", isWarning: false, message: this.state.importFiles[index].name + ": " + detail.message, propertyNames: [] })
							});
                        }
					}
				});

				if (failedFiles.length === 0) { //success
					this.props.onNext();
				}
				if (failedFiles.length === this.state.importFiles.length) { //fail
					this.setState({ processing: false, validationMessages: [{ type: "Error", isWarning: false, message: "Files failed to upload. Please try again. If issue persits, contact support.", propertyNames: [] }, ...messages] }); //error
				}
				else { //partial
					this.setState({
						processing: false,
						validationMessages: [{
							type: "Error", isWarning: false, message: "The following files failed to upload : " + failedFiles.join(",") + ". All others uploaded. Please try again. If issue persits, contact support.", propertyNames: [] }, ...messages] }); //error
				}

				this.clearFileInput(); //cleanup

			});
		}
	}

	private clearFileInput = () => {
		const element: HTMLInputElement | null = document.getElementById("hiddenFileImport") as HTMLInputElement;
		if (!isNullOrUndefined(element)) {
			element.value = "";
		}
	}

	// Render
	render() {
		return (
			<React.Fragment>
				{(this.state.processing) && (
					<ProcessingModal modal={true} />
				)}
				<Dialog open={true} fullWidth={true} maxWidth={"sm"}>
					<DialogTemplate onClose={this.props.onClose} title={this.props.title + ": " + this.props.customer.name}
						buttonsLeft={
							[
								<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.props.onBack}>Back</Button>,
							] as unknown as typeof Button[]}
						buttonsRight={
							[
								<Button key={0} type="button" variant="contained" color="primary" startIcon={<Ten99PrepForwardIcon />} onClick={this.onFileUploadNext}>Next</Button>,
							] as unknown as typeof Button[]}>
						<ImportFileDisplay acceptedFileExtension={".xlsx"} acceptedMimeType={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"} maxFileSizeMb={2} onFileSelect={this.onFileSelect} onFileRemove={this.onFileRemove} customerId={this.props.customer.customerId} importFiles={this.state.importFiles} externalValidationMessages={this.state.validationMessages} />
					</DialogTemplate>
				</Dialog>
			</React.Fragment>
		)
	}
}
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators }, // Selects which action creators are merged into the component's props
)((ImportFileDialog));
