export interface ITen99PagerInfo {
	pageNumber: number,
	pageSize: number,
	totalCount: number,
	namedFilters?: INamedFilter[],
	onPagerClick: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
}

export interface INamedFilter {
	type: INamedFilterTypeEnum,
	value: string
}

export enum INamedFilterTypeEnum {
	FormStatus = 1,
	PayerFirstName = 2,
	PayerLastName = 3,
	PayerCompanyName = 4,
	RecipientFirstName = 5,
	RecipientLastName = 6,
	RecipientCompanyName = 7,
	SearchVal = 8
}