import { Action, Reducer } from 'redux';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99Company } from 'sharedInterfaces/ITen99Company';
import { ITen99Customer } from 'sharedInterfaces/ITen99Customer';
import { INamedFilter, INamedFilterTypeEnum } from 'sharedInterfaces/ITen99PagerInfo';
import { ISummaryCategoryEnum, ITen99SummaryCount, ITen99SummaryCountCategory } from 'sharedInterfaces/ITen99SummaryCounts';
import { Auth_LogoutAction } from 'store/SharedActions';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { SetCompany, SetYear } from 'utilities/UserId';
import { AppThunkAction } from './';

export enum enumHomeNavigationStatus {
    PendingAction,
    Processing,    
    Invalid,
    Error
};

export interface ICustomerSummaryActions {
    CustomerSummaryActions_RefreshSummaryCounts: () => void,
    CustomerSummaryActions_RefreshActiveCustomerSummary: () => void,
    CustomerSummaryActions_AddActiveCustomer: (customerId: string) => void,
    CustomerSummaryActions_SetCompany: (company: ITen99Company) => SetCompany,
    CustomerSummaryActions_SetCurrentFilingYear: (year: number) => void,
    CustomerSummaryActions_AddNamedFilters: (filters: INamedFilter[], clearExisting: boolean) => AddNamedFilters,
    CustomerSummaryActions_RemoveNamedFilters: (filters: INamedFilter[]) => RemoveNamedFilters,
    CustomerSummaryActions_ClearNamedFilters: () => ClearNamedFilters,

}
// -----------------
// Locally Needed Interfaces

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface CustomerSummaryState {
    //statuses
    status: enumHomeNavigationStatus,

    //properties
    activeCustomer?: ITen99Customer,
    activeCustomerSummaryCounts?: ITen99SummaryCountCategory[],
    activeCustomerCartCount: number;
    activeCompany?: ITen99Company,
    currentFilingYear: number,
    validationMessages: ITen99ApiResponseMessage[],
    namedFilters?: INamedFilter[],
    filtersSetOn?: Date,
    countsRefreshedOn?: Date,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAddActiveCustomer {
    type: 'CustomerSummary/RequestAddActiveCustomer',  
    customerId: string;
}

interface RefreshActiveCustomer {
    type: 'CustomerSummary/RefreshActiveCustomer',
}


interface SetError {
    type: 'CustomerSummary/SetError',
    message: string,
}

interface ProcessRefreshActiveCustomerResponse {
    type: 'CustomerSummary/ProcessRefreshActiveCustomerResponse',
    customer: ITen99Customer | undefined,
    customerCartCount: number,
    customerSummaryCounts: ITen99SummaryCountCategory[] | undefined
}

interface SelectCustomerTabIndex {
    type: 'CustomerSummary/SelectCustomerTabIndex',
    index: number,
}

interface Reset {
    type: 'CustomerSummary/Reset',
}

interface RefreshSummaryCounts { type: 'CustomerSummary/RefreshSummaryCounts' }
interface RefreshSummaryCountsRequest { type: 'CustomerSummary/RefreshSummaryCountsRequest' }
interface RefreshSummaryCountsResponse { type: 'CustomerSummary/RefreshSummaryCountsResponse', cartCount: number, summaryCounts: ITen99SummaryCountCategory[] | undefined}

export interface SetCompany { type: 'CustomerSummary/SetCompany', company: ITen99Company }
export interface SetCurrentFilingYear { type: 'CustomerSummary/SetCurrentFilingYear', year: number }

export interface AddNamedFilters { type: 'CustomerSummary/AddNamedFilters', filters: INamedFilter[], clearExisting?: boolean }
export interface RemoveNamedFilters { type: 'CustomerSummary/RemoveNamedFilters', filters: INamedFilter[] }
export interface ClearNamedFilters { type: 'CustomerSummary/ClearNamedFilters'}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAddActiveCustomer | RefreshActiveCustomer | ProcessRefreshActiveCustomerResponse | SetError | SelectCustomerTabIndex | Reset | SetCompany | SetCurrentFilingYear | Auth_LogoutAction | AddNamedFilters | RemoveNamedFilters | ClearNamedFilters | RefreshSummaryCounts | RefreshSummaryCountsRequest | RefreshSummaryCountsResponse;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// NOTE: These should be names in the style of "{state}Actions_{actionName}", since thes are mereged in with all other properties in componets, 
//       this calls them out as specifically what they are.
function getCustomerAndSummary(customerId: string, dispatch: (action: KnownAction) => void, filters?: INamedFilter[])
{   
    Promise.all(
        [
            MakeApiCall<ITen99Customer>("api/Customers/" + customerId, "GET") //get customer
            , MakeApiCall<number>("api/Carts/Customer/" + customerId + "/ActiveCart/Count", "GET") // get cart count
            , MakeApiCall<ITen99SummaryCountCategory[]>("api/Customers/" + customerId + "/Summary", "POST", JSON.stringify({ namedFilters: filters })) // get status counts
        ]
    ).then(values => {
        let activeCustomer: ITen99Customer | undefined;
        let activeCustomerCartCount: number = 0;
        let activeCustomerSummaryCounts: ITen99SummaryCountCategory[] | undefined;
        values.forEach((response, index) => {
            if (response.isSuccess && response.payload !== undefined) {
                switch (index) {
                    case 0: //customer
                        activeCustomer = response.payload as ITen99Customer;
                        break;
                    case 1: //count
                        if (isNullOrUndefined(response.payload)) {
                            activeCustomerCartCount = 0;
                        }
                        else {
                            activeCustomerCartCount = response.payload as number;
                        }
                        break;
                    case 2: //customer summary
                        activeCustomerSummaryCounts = response.payload as ITen99SummaryCountCategory[];
                }
            }
        });
        dispatch({ type: 'CustomerSummary/ProcessRefreshActiveCustomerResponse', customer: activeCustomer, customerCartCount: activeCustomerCartCount, customerSummaryCounts: activeCustomerSummaryCounts });
    });
    dispatch({ type: 'CustomerSummary/RequestAddActiveCustomer', customerId: customerId });   
}
export const actionCreators = {
    CustomerSummaryActions_AddActiveCustomer: (customerId: string): AppThunkAction<KnownAction> => (dispatch) => {
        getCustomerAndSummary(customerId, dispatch, []);       
    },
    CustomerSummaryActions_RefreshActiveCustomerSummary: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //load customer summary
        const appState = getState();
        let activeCustomer: ITen99Customer | undefined;
        let filters: INamedFilter[] | undefined;
        if (!isNullOrUndefined(appState) && !isNullOrUndefined(appState.homeNavigation)) {
            activeCustomer = appState.homeNavigation.activeCustomer;
            filters = appState.homeNavigation.namedFilters;
        }

        ///const activeCustomer: ITen99Customer | undefined = getState()?.homeNavigation?.activeCustomer;
        if (!isNullOrUndefined(activeCustomer)) {
            getCustomerAndSummary(activeCustomer.customerId, dispatch, filters);
        }
        else {
            dispatch({ type: 'CustomerSummary/SetError', message:"No Active Customer" });
        }
    },
    CustomerSummaryActions_Reset: () => ({ type: 'CustomerSummary/Reset' } as Reset),
    CustomerSummaryActions_SetCompany: (company: ITen99Company) => ({ type: 'CustomerSummary/SetCompany', company: company } as SetCompany),
    CustomerSummaryActions_SetCurrentFilingYear: (year: number) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        //set year
        dispatch({ type: 'CustomerSummary/SetCurrentFilingYear', year: year });
        //kick off reload of active customer
        //load customer summary
        const appState = getState();
        let activeCustomer: ITen99Customer | undefined;
        if (!isNullOrUndefined(appState) && !isNullOrUndefined(appState.homeNavigation)) {
            activeCustomer = appState.homeNavigation.activeCustomer;
        }

        ///const activeCustomer: ITen99Customer | undefined = getState()?.homeNavigation?.activeCustomer;
        if (!isNullOrUndefined(activeCustomer)) {
            getCustomerAndSummary(activeCustomer.customerId, dispatch);
        }
        else {
            //no active customer, do nothing
        }
    },
    CustomerSummaryActions_AddNamedFilters: (filters: INamedFilter[], clearExisting: boolean = false) => ({ type: 'CustomerSummary/AddNamedFilters', filters: filters, clearExisting: clearExisting} as AddNamedFilters),
    CustomerSummaryActions_RemoveNamedFilters: (filters: INamedFilter[]) => ({ type: 'CustomerSummary/RemoveNamedFilters', filters: filters } as RemoveNamedFilters),
    CustomerSummaryActions_ClearNamedFilters: () => ({ type: 'CustomerSummary/ClearNamedFilters' } as ClearNamedFilters),
    CustomerSummaryActions_RefreshSummaryCounts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (!isNullOrUndefined(appState) && !isNullOrUndefined(appState.homeNavigation) && !isNullOrUndefined(appState.homeNavigation.activeCustomer)) {
            Promise.all(
                [
                    MakeApiCall<number>("api/Carts/Customer/" + appState.homeNavigation.activeCustomer.customerId + "/ActiveCart/Count", "GET") // get cart count
                    , MakeApiCall<ITen99SummaryCountCategory[]>("api/Customers/" + appState.homeNavigation.activeCustomer.customerId + "/Summary", "POST", JSON.stringify({ namedFilters: appState.homeNavigation.namedFilters })) // get status counts
                ]
            ).then(values => {
                let activeCustomerCartCount: number = 0;
                let activeCustomerSummaryCounts: ITen99SummaryCountCategory[] | undefined;
                values.forEach((response, index) => {
                    if (response.isSuccess && response.payload !== undefined) {
                        switch (index) {
                            case 0: //count
                                if (isNullOrUndefined(response.payload)) {
                                    activeCustomerCartCount = 0;
                                }
                                else {
                                    activeCustomerCartCount = response.payload as number;
                                }
                                break;
                            case 1: //customer summary
                                activeCustomerSummaryCounts = response.payload as ITen99SummaryCountCategory[];
                        }
                    }
                });
                dispatch({ type: 'CustomerSummary/RefreshSummaryCountsResponse', cartCount: activeCustomerCartCount, summaryCounts: activeCustomerSummaryCounts });
            });

            dispatch({ type: 'CustomerSummary/RefreshSummaryCountsRequest' });
        }        
    },
};

//({ type: 'CustomerSummary/SetCurrentFilingYear', year: year } as SetCurrentFilingYear),

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CustomerSummaryState = {
    status: enumHomeNavigationStatus.PendingAction, activeCustomer: undefined, activeCustomerSummaryCounts: undefined, activeCustomerCartCount: 0, activeCompany: undefined, namedFilters: undefined, currentFilingYear: 0, validationMessages: {} as ITen99ApiResponseMessage[]
};

export const reducer: Reducer<CustomerSummaryState> = (state: CustomerSummaryState | undefined, incomingAction: Action): CustomerSummaryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CustomerSummary/RequestAddActiveCustomer':
            return Object.assign({}, state, {
                status: enumHomeNavigationStatus.Processing,
                processingMessage: "Loading Customer...",
                validationMessages: {},                
            });
        case 'CustomerSummary/RefreshActiveCustomer':
            return Object.assign({}, state, {
                status: enumHomeNavigationStatus.Processing,
                processingMessage: "Loading Customer...",
                validationMessages: {},                
            });
        case 'CustomerSummary/ProcessRefreshActiveCustomerResponse':
            if (!isNullOrUndefined(action.customer)) {
                return Object.assign({}, state, {
                    status: enumHomeNavigationStatus.PendingAction,
                    activeCustomer: action.customer,
                    activeCustomerCartCount: action.customerCartCount,
                    activeCustomerSummaryCounts: action.customerSummaryCounts,
                    namedFilters: undefined,
                    filtersSetOn: undefined,
                });
            } //something went wrong
            else {
                return Object.assign({}, state, {
                    status: enumHomeNavigationStatus.Error,
                    validationMessages: [{ type: "Error", message: "Unable to load Customer" }],
                });
            }
        case 'CustomerSummary/SelectCustomerTabIndex':
            return Object.assign({}, state, {
                customerTabIndex: action.index,
            });;
        case 'CustomerSummary/SetCompany':
            SetCompany(action.company.id.toString());
            //company has changed clear out selected customer
            return Object.assign({}, state, { activeCompany: action.company, activeCustomer: undefined, activeCustomerSummary: undefined });
        case 'CustomerSummary/SetCurrentFilingYear':
            SetYear(action.year.toString());
            return Object.assign({}, state, { currentFilingYear: action.year }); //do not set anything else, let the refresh do that
        case 'CustomerSummary/AddNamedFilters':
            let tempAdd: INamedFilter[] = [];
            if (!isNullOrUndefined(state.namedFilters)) {
                tempAdd = state.namedFilters;
            }
            if (action.clearExisting) {
                tempAdd = action.filters;
            }
            else {
                tempAdd.push(...action.filters);
            }
            
            return Object.assign({}, state, { namedFilters: tempAdd, filtersSetOn: Date.now() });
        case 'CustomerSummary/RemoveNamedFilters':
            if (!isNullOrUndefined(state.namedFilters)) {
                return Object.assign({}, state, { namedFilters: state.namedFilters.filter(x => action.filters.findIndex( y => y.type === x.type && y.value === x.value) < 0), filtersSetOn: Date.now() });
            }
            else { return state; }
        case 'CustomerSummary/ClearNamedFilters':
            return Object.assign({}, state, { namedFilters: undefined, filtersSetOn: Date.now() });
        case 'CustomerSummary/RefreshSummaryCountsRequest':
            return state;
        case 'CustomerSummary/RefreshSummaryCountsResponse':
            //if a filter is no longer applicable, make sure it still shows but with no count, so user can turn it off. 

            let tempNamedFilters: INamedFilter[] | undefined = state.namedFilters;
            let tempSummaryCounts: ITen99SummaryCountCategory[] | undefined = state.activeCustomerSummaryCounts;

            if (!isNullOrUndefined(tempNamedFilters) && !isNullOrUndefined(action.summaryCounts)) {
                let newFormStatuses: ITen99SummaryCountCategory | undefined = action.summaryCounts.find(y => y.category === ISummaryCategoryEnum.FormStatus);
                let currentFormStatuses: ITen99SummaryCountCategory | undefined = !isNullOrUndefined(tempSummaryCounts) ? tempSummaryCounts.find(y => y.category === ISummaryCategoryEnum.FormStatus) : undefined;

                if (!isNullOrUndefined(newFormStatuses)) {
                    let newFormStatusCounts = newFormStatuses.counts;

                    tempNamedFilters.forEach(x => {
                        if (newFormStatusCounts.findIndex(y => y.id.toString() == x.value) < 0) {

                            let oldItem: ITen99SummaryCount | undefined = !isNullOrUndefined(currentFormStatuses) ? currentFormStatuses.counts.find(y => y.id.toString() == x.value) : undefined;
                            if (!isNullOrUndefined(oldItem)) {
                                newFormStatusCounts.push({ id: oldItem.id, name: oldItem.name, count: 0 } as ITen99SummaryCount);
                            }
                        }
                    })
                }                
            }

            
            return Object.assign({}, state, { status: enumHomeNavigationStatus.PendingAction, countsRefreshedOn: Date.now(), activeCustomerCartCount: action.cartCount, activeCustomerSummaryCounts: action.summaryCounts, namedFilters: tempNamedFilters });
        case 'Auth/Logout':
        case 'CustomerSummary/Reset':
            return unloadedState;
        default:
            return state;
    }
};
