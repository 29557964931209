import { Dialog } from '@mui/material';
import * as React from 'react';
import { enumEntityType, IAevEntitySetting, IAevEntityResponse } from 'sharedInterfaces/ITen99AevEntity';
import AddEditViewMaconomyCustomer from 'components/ten99Prep/EntityAddEditView/AddEditViewMaconomyCustomer';
import AddEditViewPayer from 'components/ten99Prep/EntityAddEditView/AddEditViewPayer';
import AddEditViewRecipient from 'components/ten99Prep/EntityAddEditView/AddEditViewRecipient';
import AddEditViewForm from 'components/ten99Prep/EntityAddEditView/AddEditViewForm';

interface IEntityAddEditViewProps {
	
	onClose: (response: IAevEntityResponse) => void;
	entitySettings: IAevEntitySetting;
}

interface ILocalState {	
	dialogOpen: boolean,
}
const initialLocalState = { dialogOpen: true} as ILocalState;
class EntityAddEditView extends React.PureComponent<IEntityAddEditViewProps, ILocalState> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle User events

	private onClose = (response: IAevEntityResponse) => {
		//close form
		this.setState({ dialogOpen: false })
		//call parent
		this.props.onClose(response);		
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
			return (			
				<React.Fragment>								
					{this.state.dialogOpen && (
						<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth="lg">
							{this.props.entitySettings.type === enumEntityType.MaconomyCustomer && (
								<AddEditViewMaconomyCustomer onClose={this.onClose} entitySettings={this.props.entitySettings} />
							)}	
							{this.props.entitySettings.type === enumEntityType.Payer && (
								<AddEditViewPayer onClose={this.onClose} entitySettings={this.props.entitySettings} />
							)}
							{this.props.entitySettings.type === enumEntityType.Recipient && (
								<AddEditViewRecipient onClose={this.onClose} entitySettings={this.props.entitySettings} />
							)}
							{(this.props.entitySettings.type === enumEntityType.Form || this.props.entitySettings.type === enumEntityType.PayerForm) && (
								<AddEditViewForm onClose={this.onClose} entitySettings={this.props.entitySettings} />
							)}						
						</Dialog>	
					)}
				</React.Fragment>
		);
	}
}

// -----------------
export default EntityAddEditView;