import Layout from 'components/Layout';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from 'utilities/PrivateRoute';

import DocumentRetrievalRoutes from 'components/documentRetrieval/DocumentRetrievalRoutes';
import Ten99Prep from 'components/ten99Prep/Ten99PrepGateway';
import UserGateway from 'components/user/UserGateway';


export default () => (
    <React.Fragment>
        <Layout>
            <Switch>
                <Route exact path="/" >
                    <Redirect to="/usergate/login/" />
                </Route>
                <Route exact path="/usergate/*" component={UserGateway} />
                <PrivateRoute path="/gate/*" component={Ten99Prep} />
                <Route path="/documentRetrievalGate/*" component={DocumentRetrievalRoutes} />
                <Route>
                    <Redirect to="/usergate/login/" />
                </Route>
            </Switch>
        </Layout>
    </React.Fragment>
);
