import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Ten99PrepCancelIcon } from 'Ten99PrepOverloads/IconOverloads';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ConfirmTwoFactor from 'components/user/ConfirmTwoFactor';
import { useEffect, useState } from 'react';
import { ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

export default function PhoneConfirmation({ onClose }: { onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void }) {

	enum enumComponentStatus {
		PendingAction,
		Processing,
		Invalid,
		Error
	};

	interface IPhoneConfirmState {
		status: enumComponentStatus,
		twoFactorToken: string|null,

		validationMessages: ITen99ApiResponseMessage[],
		invalidProperties: string[]
	}

	const [componentState, setComponentState] = useState<IPhoneConfirmState>({
		status: enumComponentStatus.PendingAction,
		twoFactorToken: "",

		validationMessages: [] as ITen99ApiResponseMessage[],
		invalidProperties: [] as string[],		
	} as IPhoneConfirmState);

	function StartPhoneConfirm() {
		MakeApiCall<string>("api/User/TwoFactor/Phone/Confirm", "POST")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload !== "") {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.Processing,
							validationMessages: [] as ITen99ApiResponseMessage[],
							invalidProperties: [] as string[],
							twoFactorToken: data.payload ?? null
						};
					});
					SendTwoFactorCode(data.payload); //send the code to the user

				}
				else {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.Error,
							validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Issue starting Phone Confirmation" }] as ITen99ApiResponseMessage[],
							invalidProperties: flattenProperties(data.details)
						}
					});
				}
			});
	}

	function ReSendTwoFactorCode() {
		if (componentState.twoFactorToken !== null) {
			SendTwoFactorCode(componentState.twoFactorToken);
		}
	}

	function SendTwoFactorCode(twoFactorToken: string) {
		setComponentState(prevState => {
			return {
				...prevState,
				status: enumComponentStatus.Processing,
			};
		});

		MakeApiCall<string>("api/User/TwoFactor/Phone/Confirm/Notification", "POST", JSON.stringify({ TwoFactorToken: twoFactorToken }))
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.PendingAction,
						};
					});
				}
				else {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.Error,
							validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Issue Sending Two Factor Code" }] as ITen99ApiResponseMessage[],
						}
					});
				}
			});
	}

	function ValidateTwoFactorCode(twoFactorCode: string) {
		setComponentState(prevState => {
			return {
				...prevState,
				status: enumComponentStatus.Processing,
				validationMessages: [] as ITen99ApiResponseMessage[],
				invalidProperties: [] as string[]
			}
		});

		MakeApiCall<string>("api/User/TwoFactor/Phone/Confirm", "PUT", JSON.stringify({ TwoFactorToken: componentState.twoFactorToken, TwoFactorCode: twoFactorCode }))
			.then(data => {
				if (data.isSuccess) {
					onClose(true);
				}
				else { //something wrong
					switch (data.httpStatusCode) {
						case 401: //invalid credentials
							setComponentState(prevState => {
								return {
									...prevState,
									status: enumComponentStatus.PendingAction,
									validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Invalid Code" }] as ITen99ApiResponseMessage[],
									invalidProperties: flattenProperties(data.details)
								}
							});
							break;
						case 403: //token no longer avaliable
							setComponentState(prevState => {
								return {
									...prevState,
									status: enumComponentStatus.PendingAction,
									validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Two Factor Code Expired" }] as ITen99ApiResponseMessage[],
								}
							});
							break;
						default:

							setComponentState(prevState => {
								return {
									...prevState,
									status: enumComponentStatus.PendingAction,
									validationMessages: data.details,
									invalidProperties: flattenProperties(data.details)
								}
							});
							break;
					}
				}
			});
	}

	useEffect(() => {
		StartPhoneConfirm();
	}, []);

	return (
		<>
			<DialogContent>
				<ConfirmTwoFactor submitFunction={ValidateTwoFactorCode} resendFunction={ReSendTwoFactorCode} cancelFunction={() => onClose(false)} />
				{!isNullOrUndefined(componentState.validationMessages) && componentState.validationMessages.length > 0 && (
					<Grid item xs={12}>
						<ApiErrorResponseMessages messages={componentState.validationMessages} />
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={() => onClose(false, undefined)}>Close</Button>
			</DialogActions>
		</>
	);
}