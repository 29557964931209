import { IconButton, InputAdornment, TextField } from '@mui/material';
import * as React from 'react';
import { Ten99PrepSearchIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';

interface ILocalState {
	searchValue: string,
}

interface ISimpleSearchBoxProps {
	label: string,
	onSearch: (searchVal: string) => void,
	startAdornment: React.ReactNode,
	maxLength?: number
}

class SimpleSearchBox extends React.PureComponent<ISimpleSearchBoxProps, ILocalState> {
	constructor(props: ISimpleSearchBoxProps) {
		super(props);
		this.state = { searchValue : ""};
	}

	
	private handleValueChange = (event: { target: { name: string; value: string; }; }) => {
		this.setState({ searchValue: event.target.value });
	}

	private onSearchClick = () => {
		if (this.state.searchValue !== "") { //do not search blank
			this.props.onSearch(this.state.searchValue);
			this.setState({ searchValue: "" });
		}
    }

	// Render
	render() {
		let tempInputProps = {};

		if (!isNullOrUndefined(this.props.maxLength)) {
			tempInputProps = { maxLength: this.props.maxLength};
        }

		return (
			

			<TextField
				variant="standard"
				sx={{ width: '25ch', margin: 1, "input": { color: "white", fontSize: "14px", fontWeight: "500", "::placeholder": { opacity: 100} } }} //should be passed in form calling component
				placeholder={this.props.label}
				id="searchValInput"
				value={this.state.searchValue}
				onChange={this.handleValueChange}
				onKeyPress={(event) => {
					if (event.key === "Enter") {
						this.onSearchClick();
					}
				}}
				inputProps={tempInputProps}
				InputProps={{
					startAdornment:
						<InputAdornment position="start">
							<IconButton
								color="secondary"
								onClick={this.onSearchClick}
								edge="start"
							>
								{this.props.startAdornment}
							</IconButton>
						</InputAdornment>,
					endAdornment:
						<InputAdornment position="end">
							<IconButton
								color="secondary"
								onClick={this.onSearchClick}
								edge="end"
							>
								{<Ten99PrepSearchIcon />}
							</IconButton>
						</InputAdornment>
				}}
			/>
		);
	}
}
export default SimpleSearchBox;
