import { ITen99MessagePriorityEnum } from "sharedInterfaces/ITen99MessagePriorityEnum";

export enum ITen99NotificationTypeEnum {
    General = 1,
    Customer = 2,
    Import = 3,
    Submission = 4,
}

export interface ITen99Notification {
    customerId? : number,
    isRead: boolean,
    notificationId: number,
    notificationText: string,
    notificationTypeId: ITen99NotificationTypeEnum,
    prioirtyId: ITen99MessagePriorityEnum,
    ticks: string,
    whoUserId: number,
}