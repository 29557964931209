import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { isNullOrUndefined } from "util";

interface IToolTip {
    text: string,
}
export default function InfoToolTip(props: React.PropsWithChildren<IToolTip>) {
 
    // return a span instead of null; input adornment requires something as a child
    let content: React.ReactNode;
    if (!isNullOrUndefined(props.children)) {
        content = props.children;
    }
    else {
        //negative tab index, we do not want users tabing the tool tips
        content = (< IconButton tabIndex={-1} ><InfoOutlined /></IconButton >);
    }
    //if no tooltip, return children or empty span
        return (
            <React.Fragment>
                {!isNullOrUndefined(props.text) && (
                    <Tooltip componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: "#f5f5f9",
                                color: "rgba(0, 0, 0, 0.87)",
                                maxWidth: 220,
                                border: "1px solid #dadde9"
                            }
                        }
                    }}
                    color="primary"
                    placement="right"
                    title={props.text}
                >
                    <span>
                        {content}
                    </span>
                    </Tooltip>
            )
                }
                {isNullOrUndefined(props.text) && (
                        <span>
                            {content}
                        </span>
                )
                }

            </React.Fragment>
    );
}