import { Button, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { useState } from "react";
import { ITen99ActionResponseMessage, ITen99ActionResponseTypeEnum } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { flattenProperties } from 'utilities/PropertyList';

export default function TechnicalAssistance({ onClose }: { onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void }) {

	enum enumComponentStatus {
		PendingAction,
		Processing,
		Invalid,
		Error
	};

	const [status, setStatus] = useState<enumComponentStatus>(enumComponentStatus.PendingAction);
	const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([] as ITen99ApiResponseMessage[]);
	const [summary, setSummary] = useState<string>("");
	const [details, setDetails] = useState<string>("");
	const [invalidProperties, setInvalidProperties] = useState<string[]>([] as string[]);
	

	function onSubmit()
	{
		setStatus(enumComponentStatus.Processing);
		setValidationMessages({} as ITen99ApiResponseMessage[]);
		MakeApiCall<boolean>("api/RecipientGateway/TechnicalAssistanceRequest", "POST", JSON.stringify({ "summary": summary, "details": details }))
			.then(data => {
				if (data.isSuccess) {
					onClose(true, { message: "Issue accepted. You will be contacted about your issue soon.", messageType: ITen99ActionResponseTypeEnum.Success })
				}
				else {
					if (data.httpStatusCode === 422) {
						setStatus(enumComponentStatus.Invalid);
						setValidationMessages(data.details);
						setInvalidProperties(flattenProperties(data.details));
					}
					else {
						setStatus(enumComponentStatus.Error);
						setValidationMessages([{ type: "Error", message: "An issue occurred submitting your request. Please try again. If the issue persists, please email support@1099prep.com directly." }] as ITen99ApiResponseMessage[]);
					}
				}
			});
	}

	return (
		<>
			<DialogTitle>
				<Grid container spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="h4">Request Assistance
							<InfoToolTip text="Submit an issue to 1099 Prep to be answered" />
						</Typography>
						<Typography variant="h6">
							Support can also be reached via <Link href="mailto:support@1099-prep.com">support@1099-prep.com</Link>
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop: "8px" }}>
					{(status === enumComponentStatus.Processing) && (
						<Grid item xs={12}>
							<ProcessingModal modal={true} />
						</Grid>
					)}

					{!isNullOrUndefined(validationMessages) && validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={validationMessages} />
						</Grid>
					)}

					<Grid item xs={12}>
						<TextField
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSummary(event.target.value) }}
							value={summary}
							variant="outlined"
							error={status === enumComponentStatus.Invalid && (invalidProperties.find(x => x === "Summary") !== undefined)}
							id="summary"
							label="Summary of the issue"
							name="summary"
							required
							fullWidth
							inputProps={{ maxLength: 100 }}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setDetails(event.target.value) }}
							value={details}
							variant="outlined"
							error={status === enumComponentStatus.Invalid && (invalidProperties.find(x => x === "Details") !== undefined)}
							id="details"
							label="Details"
							name="details"
							multiline
							rows={20}
							required
							fullWidth
							inputProps={{ maxLength: 2000 }}
						/>
					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => onClose(false, undefined)}>Close</Button>
				<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={onSubmit}>Submit</Button>

			</DialogActions>
		</>
	)
}