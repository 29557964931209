import * as React from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { actionCreators } from 'store/ConfirmDialog';
import { isNullOrUndefined } from 'util';

export default function IdleLogoutTimer({ onLogoutFunction }: { onLogoutFunction: () => void }) {

    const idleTimer: React.RefObject<IdleTimer> = React.createRef<IdleTimer>();
    let idleTimerWarningTimeout: NodeJS.Timeout | undefined;
    const dispatch = useDispatch();

	function onLogout()
    {
        dispatch(actionCreators.confirmDialog_Reset()); //clear confirm dialog
        onLogoutFunction();
    }

     // If the user is inactive for X amount of time (X = idleTimeoutThreshold from constants.js) a warning dialog opens.
    function onUserIdle() {
        dispatch(actionCreators.confirmDialog_Display("Inactivity Timeout", "1", "You are about to be logged out due to inactivity. Click 'Proceed' to continue working.", onUserStopLogout, true));

        if (!isNullOrUndefined(process.env.REACT_APP_MAX_INACIVITY_WARNING_TIME)) {
            idleTimerWarningTimeout = setTimeout(() => { onLogout(); }, Number.parseInt(process.env.REACT_APP_MAX_INACIVITY_WARNING_TIME));
        }

        if (!isNullOrUndefined(idleTimer) && !isNullOrUndefined(idleTimer.current)) {
            idleTimer.current.reset();
        }
    };

    function onUserStopLogout(id: string) {
        //clear timeout
        if (!isNullOrUndefined(idleTimerWarningTimeout)) {
            clearTimeout(idleTimerWarningTimeout);
        }
    }

	return	(
        <>
            <IdleTimer
                ref={idleTimer as any}
                onIdle={onUserIdle}
                debounce={250}
                timeout={!isNullOrUndefined(process.env.REACT_APP_MAX_INACIVITY_TIME) ? Number.parseInt(process.env.REACT_APP_MAX_INACIVITY_TIME) : 0}
            />
		</>
	);
}