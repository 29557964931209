import { Button, Grid, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import { useState } from 'react';

export default function DocumentRetrievalUser2FA({ submitFunction, resendFunction, cancelFunction, disabled }: { submitFunction: (code: string) => void, resendFunction: () => void, cancelFunction: () => void,  disabled?: boolean }) {

	function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.key === "Enter") {
			submit();
		}
	}

	function submit() {
		submitFunction(code);
	}

	const [code, setCode] = useState<string>("");

	return (
		<>			
			<Grid container spacing={1} sx={{ textAlign: 'center' }}>
				<Grid item xs={12}>
					<Typography variant="h6">
						For security, an email has been sent to the user associated with this login with a security code. Please enter this security code to proceed. 
					</Typography>
					<br />
				</Grid>

				<Grid item xs={12} style={{ "display": "flex", } as React.CSSProperties}>
					<TextField
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setCode(event.target.value) }}
						disabled={disabled}
						value={code}
						variant="outlined"
						id="2FaCode"
						label="Security Code"
						name="2FaCode"
						onKeyDown={(e) => { onKeyDown(e); }}
						fullWidth
						required
						InputProps={{
							inputComponent: CustomerNumberFormat as any,
							inputProps: { format: "######", mask: "#" }
						}}
					/>
				</Grid>

				<Grid item xs={6} sx={{ textAlign: 'left' }}>
					<Button type="button" disabled={disabled} variant="contained" color="secondary" onClick={resendFunction}>Resend</Button>
					<Button type="button" disabled={disabled} variant="contained" color="secondary" onClick={cancelFunction}>Cancel</Button>
				</Grid>
				<Grid item xs={3}>
				</Grid>
				<Grid item xs={3} sx={{ textAlign: 'right' }}>
					<Button type="button" disabled={disabled} variant="contained" color="primary" onClick={submit}>Submit</Button>
				</Grid>

			</Grid>
		</>
	);
}