import { Action, Reducer } from 'redux';
import { ITen99ApiResponse, ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { Auth_LogoutAction } from 'store/SharedActions';
import { LoadUser } from 'store/User';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';
import { SetUser } from 'utilities/UserId';
import { AppThunkAction } from './';


export enum enumLoginStatus {
    NotStarted,
    PendingTwoFactorOptions,
    PendingTwoFactorSelection,
    PendingTwoFactorResolution,
    Authenticated,
    Error
};
// -----------------
// Locally Needed Interfaces

interface User {
    userId: string,
    isInternal: boolean,
    firstName: string,
    lastName: string
}
interface LoginResponse {
    authToken: string;
    details: User;
    skip2FA: boolean;
}

export interface Credentials {
    userName: string,
    password: string,
}

export interface TwoFactorId {
    userId: string;
    authToken: string;
}

export interface TwoFactorRequest {
    userId: string;
    twoFactorToken: string;
    twoFactorProviderCode?: string;
}

export interface TwoFactorSubmit {
    userId: string;
    twoFactorToken: string;
    twoFactorCode: string;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface LoginState {
    //statuses
    isProcessing: boolean;
    isProcessingApiCall: boolean;
    isInvalid: boolean;
    status: enumLoginStatus;

    //properties
    processingMessage: string;
    twoFactorId: TwoFactorId;
    validationMessages: ITen99ApiResponseMessage[];
    twoFactorProviders: ITen99LookupItem[];
    invalidProperties: string[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface Login {
    type: 'Auth/Login';
}

interface ProcessLoginResponse {
    type: 'Auth/ProcessLoginResponse';
    response: ITen99ApiResponse<LoginResponse>;
}

interface GetTwoFactorOptions {
    type: 'Auth/GetTwoFactorOptions';
}

interface ProcessGetTwoFactorOptionsResponse {
    type: 'Auth/ProcessGetTwoFactorOptionsResponse';
    response: ITen99ApiResponse<ITen99LookupItem[]>;
}

interface SubmitTwoFactorOption {
    type: 'Auth/SubmitTwoFactorOption';
}

interface ProcessSubmitTwoFactorOptionResponse {
    type: 'Auth/ProcessSubmitTwoFactorOptionResponse';
    response: ITen99ApiResponse<boolean>;    
}


interface CancelTwoFactor {
    type: 'Auth/CancelTwoFactor';
}

interface ResendTwoFactor {
    type: 'Auth/ResendTwoFactor';
}

interface ProcessResendTwoFactorResponse {
    type: 'Auth/ProcessResendTwoFactorResponse';
    response: ITen99ApiResponse<boolean>;
}

interface SubmitTwoFactor {
    type: 'Auth/SubmitTwoFactor';
}

interface ProcessSubmitTwoFactorResponse {
    type: 'Auth/ProcessSubmitTwoFactorResponse';
    response: ITen99ApiResponse<string>;
}

interface Reset {
    type: 'Auth/Reset';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    Login | ProcessLoginResponse |
    GetTwoFactorOptions | ProcessGetTwoFactorOptionsResponse |
    SubmitTwoFactorOption | ProcessSubmitTwoFactorOptionResponse |
    ResendTwoFactor | ProcessResendTwoFactorResponse |
    SubmitTwoFactor | ProcessSubmitTwoFactorResponse |
    CancelTwoFactor |
    Reset |
    Auth_LogoutAction |
    LoadUser;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// NOTE: These should be names in the style of "{state}Actions_{actionName}", since thes are mereged in with all other properties in componets, 
//       this calls them out as specifically what they are.

export const actionCreators = {
    LoginActions_Login: (data: Credentials): AppThunkAction<KnownAction> => (dispatch) => {
        MakeApiCall<LoginResponse>("api/User/Login", "POST", JSON.stringify(data))
            .then(data => {
                if (data.payload !== undefined && data.payload.skip2FA) {
                    SetUser(data.payload.authToken);
                }
                dispatch({ type: 'Auth/ProcessLoginResponse', response: data });
            });
        dispatch({ type: 'Auth/Login'});        
    },
    LoginActions_GetTwoFactorOptions: (twoFactorId: TwoFactorId ): AppThunkAction<KnownAction> => (dispatch) => {
        MakeApiCall<ITen99LookupItem[]>("api/User/" + twoFactorId.userId + "/TwoFactor", "GET")
            .then(data => {
                dispatch({ type: 'Auth/ProcessGetTwoFactorOptionsResponse', response: data });
            });

        dispatch({ type: 'Auth/GetTwoFactorOptions'});
    },
    LoginActions_SubmitTwoFactorOption: (twoFactorOption: TwoFactorRequest): AppThunkAction<KnownAction> => (dispatch) => {
        MakeApiCall<boolean>("api/User/" + twoFactorOption.userId + "/TwoFactor", "POST", JSON.stringify(twoFactorOption))
            .then(data => {
                dispatch({ type: 'Auth/ProcessSubmitTwoFactorOptionResponse', response: data });
            });                
        dispatch({ type: 'Auth/SubmitTwoFactorOption'});
    },
    LoginActions_CancelTwoFactor: () => ({ type: 'Auth/CancelTwoFactor' } as CancelTwoFactor),
    LoginActions_ResendTwoFactor: (twoFactorRequest: TwoFactorRequest): AppThunkAction<KnownAction> => (dispatch) => {
        MakeApiCall<boolean>("api/User/" + twoFactorRequest.userId + "/TwoFactor", "POST", JSON.stringify(twoFactorRequest))
            .then(data => {
                dispatch({ type: 'Auth/ProcessResendTwoFactorResponse', response: data });
            });

        dispatch({ type: 'Auth/ResendTwoFactor' });
    },
    LoginActions_SubmitTwoFactor: (twoFactorSubmit: TwoFactorSubmit): AppThunkAction<KnownAction> => (dispatch) => {
        MakeApiCall<string>("api/User/" + twoFactorSubmit.userId + "/TwoFactor", "PUT", JSON.stringify(twoFactorSubmit))
            .then(data => {
                if (data.payload !== undefined) {
                    SetUser(data.payload);                   
                }
                dispatch({ type: 'Auth/ProcessSubmitTwoFactorResponse', response: data });
            });
        dispatch({ type: 'Auth/SubmitTwoFactor'});
    },
    LoginActions_Reset: () => ({ type: 'Auth/Logout' } as Auth_LogoutAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LoginState = {
    isProcessing: false, isProcessingApiCall: false, isInvalid: false, status: enumLoginStatus.NotStarted,
    twoFactorId: { userId: "", authToken: "" }, processingMessage: "",
    validationMessages: [], invalidProperties: [], twoFactorProviders: []
};

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'Auth/Login':
            return Object.assign({}, state, {
                isProcessing: true,
                processingMessage: "Authenticating...",
                isInvalid: false,
                validationMessages: {},
            });
        case 'Auth/ProcessLoginResponse':
            if (action.response.isSuccess && action.response.payload !== undefined) { //credentials valid                
                return Object.assign({}, state, {
                    isProcessing: false,
                    isInvalid: false,
                    status: action.response.payload.skip2FA ? enumLoginStatus.Authenticated : enumLoginStatus.PendingTwoFactorOptions,
                    twoFactorId: action.response.payload.skip2FA ? undefined : { authToken: action.response.payload.authToken, userId: action.response.payload.details.userId } as TwoFactorId,
                    validationMessages: action.response.details,
                });
            } //something went wrong
            else { 
                switch (action.response.httpStatusCode) {
                    case 401: //invalid credentials
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isInvalid: true,
                            validationMessages: [{ type:"Error", propertyNames: [""] as string[], message: "Invalid Credentials"}] as ITen99ApiResponseMessage[],
                        });
                    case 503: //Unavailable
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isInvalid: true,
                            validationMessages: action.response.details,
                            invalidProperties: flattenProperties(action.response.details)
                        });
                    default:
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isInvalid: true,
                            validationMessages: action.response.details,
                            invalidProperties: flattenProperties(action.response.details)
                        });
                }                
            }
        case 'Auth/GetTwoFactorOptions':
            return Object.assign({}, state, {               
                isProcessing: true,
                processingMessage: "Getting users options...",
                status: enumLoginStatus.PendingTwoFactorSelection,
                isInvalid: false,
                validationMessages: {},
            });
        case 'Auth/ProcessGetTwoFactorOptionsResponse':
            if (action.response.isSuccess) { //gotOptions
                return Object.assign({}, state, {
                    isProcessing: false,
                    isInvalid: false,
                    status: enumLoginStatus.PendingTwoFactorSelection,
                    twoFactorProviders: action.response.payload,
                });
            }
            else { //something wrong
                return Object.assign({}, state, {
                    isProcessing: false,
                    isInvalid: true,
                    status: enumLoginStatus.Error,
                    validationMessages: action.response.details,
                    invalidProperties: flattenProperties(action.response.details)
                });
            }
        case 'Auth/SubmitTwoFactorOption':
            return Object.assign({}, state, {
                isProcessing: true,
                processingMessage: "Submitting Two Factor Authentication Choice...",
                isInvalid: false,
                validationMessages: {},
            });
        case 'Auth/ProcessSubmitTwoFactorOptionResponse':
            if (action.response.isSuccess) { //gotOptions
                return Object.assign({}, state, {
                    isProcessing: false,
                    processingMessage: "Waiting for User to complete Two Factor Authentication...",
                    isInvalid: false,
                    status: enumLoginStatus.PendingTwoFactorResolution,
                });
            }
            else { //something wrong
                switch (action.response.httpStatusCode) {
                    case 422: //invalid 
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isInvalid: true,
                            validationMessages: action.response.details,
                            invalidProperties: flattenProperties(action.response.details)
                        });
                    default:
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isInvalid: true,
                            validationMessages: action.response.details,
                        });
                }     
            }
        case 'Auth/ResendTwoFactor':
            return Object.assign({}, state, {
                isProcessing: true,
                processingMessage: "Resending Two Factor Code...",
                isInvalid: false,
                validationMessages: {},
            });
        case 'Auth/ProcessResendTwoFactorResponse':
            if (action.response.httpStatusCode === 200) { 
               return Object.assign({}, state, {
                    isProcessing: false
                }); 
            }
            else { //something wrong
                return Object.assign({}, state, {
                    isProcessing: false,
                    isInvalid: true,
                    validationMessages: action.response.details,
                    invalidProperties: flattenProperties(action.response.details)
                });
            }
        case 'Auth/SubmitTwoFactor':
            return Object.assign({}, state, {
                isProcessing: true,
                processingMessage: "Processing...",
                isInvalid: false,
                validationMessages: {},
            });
        case 'Auth/ProcessSubmitTwoFactorResponse':
            if (action.response.httpStatusCode === 200) {
                return Object.assign({}, state, {
                    isProcessing: false,
                    status: enumLoginStatus.Authenticated,
                });
            }
            else
            { //something wrong
                switch (action.response.httpStatusCode) {
                    case 401: //invalid credentials
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isInvalid: true,
                            validationMessages: [{
                                type: "Error", propertyNames: [""] as string[], message: "You have entered an invalid code, please try again" }] as ITen99ApiResponseMessage[],
                        });
                    case 403: //token no longer usuable
                        return Object.assign({}, unloadedState, {
                            validationMessages: action.response.details,
                            invalidProperties: flattenProperties(action.response.details),
                            isProcessingApiCall: false,
                        });
                    default:
                        return Object.assign({}, state, {
                            isProcessing: false,
                            isProcessingApiCall: false,
                            isInvalid: true,
                            validationMessages: action.response.details,
                            invalidProperties: flattenProperties(action.response.details)
                        });
                }
            }
        case 'Auth/CancelTwoFactor':
        case 'Auth/Logout':
        case 'Auth/Reset':
            return unloadedState;
        default:
            return state;
    }
};
