import * as Login from './Login';
import * as User from './User';
import * as HomeNavigation from './HomeNavigation';
import * as ConfirmDialog from './ConfirmDialog';
import * as ToastNotifications from './ToastNotifications';

// The top-level state object
export interface ApplicationState {
    login: Login.LoginState | undefined;
    user: User.UserState | undefined;
    homeNavigation: HomeNavigation.CustomerSummaryState | undefined;
    confirmDialog: ConfirmDialog.ConfirmDialogState | undefined;
    toastNotifications: ToastNotifications.ToastNotificationsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: Login.reducer,
    user: User.reducer,
    homeNavigation: HomeNavigation.reducer,
    confirmDialog: ConfirmDialog.reducer,
    toastNotifications: ToastNotifications.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
