import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99ReportHistory } from "sharedInterfaces/ITen99ReportHistory";
import { Ten99PrepDownloadIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	reportHistoryItemsCurrentPage: ITen99ReportHistory[],
	reportHistoryPagerCurrentPage: number,
	reportHistoryPagerTotalCount: number,
}

interface IQboPreviewProps {
	customerId: number,
}

type QboPreviewtProps =
	& IQboPreviewProps
;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,
	reportHistoryPagerCurrentPage: 1,
	reportHistoryPagerTotalCount: -1,
	reportHistoryItemsCurrentPage: {} as ITen99ReportHistory[],
	validationMessages: [] as ITen99ApiResponseMessage[]
};

class QboPreview extends React.PureComponent<QboPreviewtProps, ILocalState> {

	pageSize: number = 10;
	historyInterval: any;

	constructor(props: QboPreviewtProps) {
		super(props);
		this.state = JSON.parse(JSON.stringify(initialLocalState));;

		this.reportHistorySearch(1); //get first page of import history
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.reportHistorySearch(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private reportHistorySearch = (pageNumber: number) => {
		MakeApiCall<ITen99ReportHistory[]>("api/Reports/Previews/" + this.props.customerId + "/?PageNumber=" + pageNumber.toString() + "&PageSize=" + this.pageSize, "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload)) {
					this.setState({ status: enumComponentStatus.PendingAction, reportHistoryItemsCurrentPage: data.payload, reportHistoryPagerCurrentPage: data.pageData.pageNumber, reportHistoryPagerTotalCount: data.pageData.totalCount });
				}
				else if (data.httpStatusCode === 404) {
					//customer not found
					this.setState({ status: enumComponentStatus.PendingAction, reportHistoryItemsCurrentPage: {} as ITen99ReportHistory[], reportHistoryPagerCurrentPage: 0, reportHistoryPagerTotalCount: 0 });
				}
				else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
					//empty
					this.setState({ status: enumComponentStatus.PendingAction, reportHistoryItemsCurrentPage: {} as ITen99ReportHistory[], reportHistoryPagerCurrentPage: 0, reportHistoryPagerTotalCount: 0 });
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load history" }] as ITen99ApiResponseMessage[] });
				}
			})
	}

	private onReportHistoryDownloadClick = (id: number) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: [] });
		MakeApiCall<string>("api/Reports/" + id.toString(), "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "QBO_Export.xlsx")
					}
				}
				this.setState({ status: enumComponentStatus.PendingAction, validationMessages: !isNullOrUndefined(data.details) ? data.details : [] });
			})
	}

	private getStatusText = (item: ITen99ReportHistory) => {
		if (!item.processed) {
			return "Processing";
		}
		else {
			switch (item.reportStatusId) {
				case 1:
					return "Pending";
				case 2:
					return "Processing";
				case 3:
					return "Completed";
				case 4:
					return "Failed";
				case 5:
					return "No Data Found";
				case 6:
					return "Aggregating";
				default:
					return "";
			}
		}


	}

	private getStatusClassName = (item: ITen99ReportHistory) => {

		if (!item.processed) {
			return undefined;
		}
		else {
			switch (item.reportStatusId) {
				case 3:
					return 'success.main';
				case 4:
					return 'error.main';
				default:
					return undefined;;
			}
		}
	}

	componentDidMount() {
		this.historyInterval = setInterval(() => { if (this.state.status !== enumComponentStatus.IsLoading) { this.reportHistorySearch(this.state.reportHistoryPagerCurrentPage) } }, 30000); //check after waiting a bit

	}

	componentWillUnmount() {
		//ensure timeouts are gone
		clearInterval(this.historyInterval);
	}

	// Render

	render() {	
		
		return (
			<React.Fragment>
				<Grid container spacing={1}>
					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (this.state.status !== enumComponentStatus.Processing && this.state.status !== enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					)}
					<Grid item xs={6}>
						<Grid container spacing={1} sx={{ textAlign: 'center' }}>
							<Grid item xs={12} sx={{ textAlign: 'center'}}>
								<Typography variant="h6">If you are attempting to import a payer with a large volume of recipients,
									you may experience a longer wait time  for your preview to generate. For your convenience, we will
									work on this and notify you once available.
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid container spacing={1} sx={{ textAlign: 'center' }}>
							<Grid item xs={12} sx={{
								border: 'solid 1px ',
								borderColor: 'primary.dark',
								marginBottom: "10px",
								paddingRight: "10px",
								paddingBottom: "10px"
							}}>
								{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
									<ProcessingModal modal={false} />
								)}
								{this.state.reportHistoryItemsCurrentPage.length > 0 && (
									<TableContainer>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography variant="body2" sx={{fontWeight: 'bold'}} >Report Date</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Payer</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Status</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Download</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.reportHistoryItemsCurrentPage.map((item, index) => {
													return (<TableRow key={index}>
														<TableCell>
															<Typography variant="body2">{item.reportDate}</Typography>
														</TableCell>
														<TableCell>
															<Typography variant="body2">{item.payer}</Typography>
														</TableCell>
														<TableCell>
															<Typography color={this.getStatusClassName(item)} variant="body2">{this.getStatusText(item)}</Typography>
														</TableCell>
														<TableCell>
															{(!item.processed || item.reportStatusId !== 3) && (
																<React.Fragment>
																	<Typography variant="body2">NA</Typography>
																</React.Fragment>
															)}
															{item.processed && item.reportStatusId === 3 && (
																<IconButton onClick={e => this.onReportHistoryDownloadClick(item.reportId)}>
																	<Ten99PrepDownloadIcon />
																</IconButton>
															)}
														</TableCell>
													</TableRow>)
												}
												)}
											</TableBody>
											<TableFooter>
												<TableRow>
													<TablePagination
														page={this.state.reportHistoryPagerCurrentPage - 1}
														rowsPerPage={this.pageSize}
														rowsPerPageOptions={[]}
														count={this.state.reportHistoryPagerTotalCount}
														onPageChange={this.onPagerClick}
													/>
												</TableRow>
											</TableFooter>
										</Table>
									</TableContainer>
								)}
								{this.state.reportHistoryItemsCurrentPage.length === 0 && this.state.status === enumComponentStatus.PendingAction && (
									<Typography>No prior Quickbooks® Online exports found to preview. To initiate a new import, select the option to Import from Quickbooks® on the previous menu.</Typography>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

export default QboPreview;