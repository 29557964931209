import { Button, Grid, Link, List, ListItem, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from "react-redux";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import * as UserStore from 'store/User';
import { Ten99Accept, Ten99LegalIcon, Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	unacceptedTerms: ITen99Term[],
	validationMessages: ITen99ApiResponseMessage[],
}

interface ITen99Term {
	termId: number,
	title: string,
	descr: string,
	url: string,
}

interface ITermProps {
	logout: () => void,
}


type TermProps =
	ITermProps
    & typeof UserStore.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.IsLoading, unacceptedTerms: [] as ITen99Term[], validationMessages: [] as ITen99ApiResponseMessage[] };
class Terms extends React.PureComponent<TermProps, ILocalState> {

	constructor(props: TermProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<ITen99Term[]>("api/Terms", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						this.setState({ status: enumComponentStatus.PendingAction, unacceptedTerms: data.payload });
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load terms, we need to stop
				}
			})
	}
	// -----------------
	//Handle User events
	
	private onCancel = () => {
		this.props.logout();
	}

	private onAccept = () => {
		let termIds: number[] = [] as number[];
		this.state.unacceptedTerms.map(item => { termIds.push(item.termId) });

		this.setState({ status: enumComponentStatus.Processing, validationMessages: [] as ITen99ApiResponseMessage[] });
		MakeApiCall<ITen99Term[]>("api/Terms", "POST", JSON.stringify({ "AcceptedTerms": termIds }))
			.then(data => {
				if (data.isSuccess) {
					this.setState({ status: enumComponentStatus.PendingAction, unacceptedTerms: [] as ITen99Term[] });
					this.props.UserActions_AcceptedTerms();
				}
				else {
					this.setState({ status: enumComponentStatus.PendingAction, validationMessages: data.details }); //unable to load terms, we need to stop
				}
			})

	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {		

		return (
			<React.Fragment>
				{
					this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
						<ApiErrorResponseMessages messages={this.state.validationMessages} />
					)
				}
				{((this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Processing) && this.state.unacceptedTerms.length > 0) && (
						<Grid container spacing={1} sx={{ textAlign: 'center' }}>
							<Grid item xs={12} sx={{textAlign: 'left'}}>
								<Typography variant="h6">EULA: Terms that need to be accepted </Typography>
							</Grid>
							<Grid item xs={1} sx={{textAlign: 'left'}}></Grid>
							<Grid item xs={11} sx={{textAlign: 'left'}}>
								<Typography variant="body2">In order to use the application there are terms you must accept. Either you have not yet accepted the terms or there have been updates. Please review the terms below and click "Accept" to agree to all the terms.</Typography>
							</Grid>

							<Grid item xs={12} sx={{ textAlign: 'left' }}></Grid>
							<Grid container spacing={1} sx={{textAlign: 'center'}}>
								<List>
									{
										this.state.unacceptedTerms.map((item, index) => {
											return (
												<ListItem key={index} >
													<Ten99LegalIcon />
													{!isNullOrUndefined(item.url) && item.url !== "" && (
														<Link href={item.url} target="_blank">{item.title}</Link>
													)}
													{(isNullOrUndefined(item.url) || item.url === "") && (
														<Typography variant="body2" display="inline">{item.title}</Typography>
													)}
													<Typography variant="body2" display="inline">{(item.title === "" ? "" : ": ") + item.descr}</Typography>
												</ListItem>
											)
										})
									}
								</List>
							
						</Grid>
						<Grid container spacing={1} sx={{ textAlign: 'right' }}>
							<div style={{ flex: '1 0 0' }} />
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Decline and Logout</Button>
							<Button type="button" variant="contained" color="primary" startIcon={<Ten99Accept />} onClick={this.onAccept} sx={{ paddingLeft: '16px', marginLeft: "16px" }}>Accept</Button>
						</Grid>
					</Grid>
				)}
				{this.state.status === enumComponentStatus.Processing && (
					<ProcessingModal modal={true} />
				)}
			</React.Fragment>
		)
	}
}
export default connect(
	null,
	{ ...UserStore.actionCreators } // Selects which action creators are merged into the component's props
)((Terms));
