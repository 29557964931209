import { Button, DialogActions, DialogContent } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import CartDisplay from "components/ten99Prep/Cart/CartDisplay";
import * as React from 'react';
import { connect } from "react-redux";
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage, ITen99Dashboard } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Cart } from "sharedInterfaces/ITen99Cart";
import { ITen99CartCharges } from "sharedInterfaces/ITen99CartCharges";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { ITen99PagerInfo } from "sharedInterfaces/ITen99PagerInfo";
import { ApplicationState } from "store";
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigation from 'store/HomeNavigation';
import { ICustomerSummaryActions } from "store/HomeNavigation";
import * as ToastNotifications from 'store/ToastNotifications';
import * as UserStore from 'store/User';
import { Ten99PrepCancelIcon, Ten99PrepDeleteIcon, Ten99PrepEmailIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	cart: ITen99Cart,
	cartSummary: ITen99EntitySummary,
	cartCharges: ITen99CartCharges,
	validationMessages: ITen99ApiResponseMessage[],
	pagerInfo: ITen99PagerInfo
	paymentDialogOpen: boolean,
	printOption: boolean,
	paperFilingOption: boolean,	
	mailTypeLookupItems: ITen99LookupItem[]
}

interface ICartProps {
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,
	cartId?: number,
}

type CartProps =
	& ICartProps	
	& ICustomerSummaryActions // ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	& UserStore.UserState // ... state we've requested from the Redux store
	;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,
	pagerInfo: { pageNumber: 1, totalCount: -1, pageSize: 25 } as ITen99PagerInfo,
	printOption: false,
	paperFilingOption: false,
	cart: {} as ITen99Cart,
	cartSummary: {} as ITen99EntitySummary,
	cartCharges: {} as ITen99CartCharges,
	paymentDialogOpen: false,
	validationMessages: [] as ITen99ApiResponseMessage[],
	mailTypeLookupItems: [] as ITen99LookupItem[]
};

class HistoricalCart extends React.PureComponent<CartProps, ILocalState> {
	constructor(props: CartProps) {
		super(props);
		this.state = initialLocalState;
		this.state.pagerInfo.onPagerClick = this.onPagerClick;

		const UnableToLoadCartMsg: string = "We are currently unable to display your cart, please try again. If the issue persists please contact support.";

		MakeApiCall<ITen99Cart>("api/Carts/" + this.props.cartId, "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
						
					let tempCart: ITen99Cart = data.payload;
					let array = [];
					array.push(MakeApiCall<ITen99CartCharges>("api/Carts/" + data.payload.cartId + "/Charges", "GET"));
					array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/74/", "GET"));


					Promise.all(array).then(values => {
						let mailTypeLookup: ITen99LookupData = {
							lookupItems: [], options: { requestedTable: 0 }, isSelected: false
						};
						let cartCharges: ITen99CartCharges = { totalAmount: 0, charges: [] };

						//charges
						let chargesLoaded: boolean = false;
						if (values[0].isSuccess) {
							if (!isNullOrUndefined(values[0].payload)) {
								if (values[0].isSuccess && !isNullOrUndefined(values[0].payload)) {
									cartCharges = values[0].payload as ITen99CartCharges;
									chargesLoaded = true;
								}
							}
						}

						//mail types lookup
						let lookupsLoaded: boolean = false;
						if (values[1].isSuccess) {
							if (!isNullOrUndefined(values[1].payload)) {
								if (values[1].isSuccess && !isNullOrUndefined(values[1].payload)) {
									let lookupPayload: ITen99LookupData[] = values[1].payload as ITen99LookupData[];

									const lookup: ITen99LookupData | undefined = lookupPayload.find(x => x.options.requestedTable === 74);
									if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
										mailTypeLookup = lookup;
										lookupsLoaded = true;
									}
								}
							}
						}

						let subObjectsLoaded: boolean = (chargesLoaded && lookupsLoaded);

						//set state and search
						this.setState({
							cart: tempCart,
							cartCharges: cartCharges,
							mailTypeLookupItems: mailTypeLookup.lookupItems,
							validationMessages: subObjectsLoaded ? [] as ITen99ApiResponseMessage[] : [{ type: "Error", message: UnableToLoadCartMsg }] as ITen99ApiResponseMessage[],
							status: subObjectsLoaded ? enumComponentStatus.IsLoading : enumComponentStatus.Error,
						});

						if (subObjectsLoaded) {
							this.search(this.state.pagerInfo.pageNumber); //get elements
						}
					})
				}
				else if (data.isSuccess && isNullOrUndefined(data.payload)) {
					this.setState({ status: enumComponentStatus.PendingAction });
				}
				else if ((data.httpStatusCode === 404) || (data.isSuccess && isNullOrUndefined(data.payload))) {
					//cart not found, user has not added anything
					this.setState({ status: enumComponentStatus.PendingAction, cart: {} as ITen99Cart, cartSummary: {} as ITen99EntitySummary, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
					//update store for badge
					//this.props.UserActions_SetCartCount(0);
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: UnableToLoadCartMsg }] as ITen99ApiResponseMessage[] });
				}
			})
	}
	// -----------------
	//Handle User events
	private search(pageNumber: number) {

		let apiString = "api/Carts/" + this.props.cartId + "/Summary/?PageNumber=" + pageNumber.toString() + "&PageSize=" + this.state.pagerInfo.pageSize.toString();

		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>(apiString, "GET")
			.then(data => {				
				if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload) && data.payload.rows.length === 1 && !isNullOrUndefined(data.payload.rows[0].nestedEntities)) {
					this.setState({ cartSummary: data.payload.rows[0], pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount }});					
					this.setState({ status: enumComponentStatus.PendingAction});
				}
				else if (data.httpStatusCode === 404)
				{
					//cart not found, user has not added anything
					this.setState({ status: enumComponentStatus.PendingAction, cartSummary: {} as ITen99EntitySummary, pagerInfo: { ...this.state.pagerInfo, pageNumber:0, totalCount: 0 } });
				}
				else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
					//empty cart
					this.setState({ status: enumComponentStatus.PendingAction, cartSummary: {} as ITen99EntitySummary, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the cart" }] as ITen99ApiResponseMessage[] });
				}
			})		
	}

	private onCancel = () => {
		this.props.onClose(false, undefined);
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private onViewDoc = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })
		MakeApiCall<string>("api/ReportManage/TaxForm/Type/" + entity.type + "/Id/" + entity.id, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
						this.setState({ status: enumComponentStatus.PendingAction })
					}
				}
				else {
					this.setState({ status: enumComponentStatus.PendingAction, validationMessages: data.details }); //error
				}
			})
	}

	private onActionClickByEntity = (entity: ITen99EntitySummary, action: ActionTypesEnum) => {
		switch (action) {			
			case ActionTypesEnum.ViewDoc:
				this.onViewDoc(entity);
				break;
			default:
				//nothing
				break;
		}
	}

	private onClearCart = () => {
		if (!isNullOrUndefined(this.state.cart.cartId)) {
			this.props.confirmDialog_Display("Delete Cart", this.state.cart.cartId.toString(), "You are about to clear ALL the items in the cart", this.onClearCartProceed);
		}
	}

	private onEmail = () => {
		if (!isNullOrUndefined(this.state.cart.cartId)) {
			this.setState({ status: enumComponentStatus.Processing });
			MakeApiCall<boolean>("api/Carts/" + this.state.cart.cartId + "/Receipt/Email/", "POST")
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload) && data.payload) {
							this.props.toastNotifcations_Display({ id: this.state.cart.cartId + "EmailSend", type: "success", message: "Email sent" } as ToastNotifications.IToastMessage)
							this.setState({ status: enumComponentStatus.PendingAction })
						}
					}
					else if (data.httpStatusCode === 422) {
						data.details.forEach(x => {
							this.props.toastNotifcations_Display({ id: this.state.cart.cartId + "EmailSend", type: "error", message: x.message } as ToastNotifications.IToastMessage)
						}
						)						
						this.setState({ status: enumComponentStatus.PendingAction}); 
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //error
					}
				})
		}
	}

	private onClearCartProceed = () => {
		this.setState({ status: enumComponentStatus.Processing });
		MakeApiCall<string>("api/Carts/" + this.state.cart.cartId + "/?ticks=" + this.state.cart.ticks, "DELETE")
			.then(data => {
				if (!data.isSuccess) {
					//send toast notification
					this.props.toastNotifcations_Display({ id: "CartDelete" + this.state.cart.cartId, message: "Issue occurred clearing cart", type: "error" });

					//refresh list
					this.search(this.state.pagerInfo.pageNumber); // let search set processing state
				}
				else {
					//update store for badge
					this.props.CustomerSummaryActions_RefreshActiveCustomerSummary();
					this.props.onClose(true, undefined);
					this.props.toastNotifcations_Display({ id: "CartDelete" + this.state.cart.cartId, message: "Cart Cleared", type: "success" });
				}
			});
	}

	private GenerateButtonOptions() {

		let retVal: ITen99DynamicButtonProps[] = [] as ITen99DynamicButtonProps[];

		retVal.push({ action: ActionTypesEnum.Email, text: "Resend Receipt Email", variant: "contained", color: "secondary", icon: < Ten99PrepEmailIcon />, onClick: this.onEmail } as ITen99DynamicButtonProps);

		if (!isNullOrUndefined(this.state.cartSummary.actions) && this.state.cartSummary.actions.includes(ActionTypesEnum.Delete)) {
			retVal.push({ action: ActionTypesEnum.Delete, text: "Clear Cart", variant: "contained", color: "secondary", icon: < Ten99PrepDeleteIcon />, onClick: this.onClearCart } as ITen99DynamicButtonProps);
		}		
		return retVal;
	}
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {
		//
		return (
			<React.Fragment>
				{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
					<DialogContent>
						<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
					</DialogContent>
				)}
				{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Processing) && (
					<CartDisplay
						cart={this.state.cart}
						cartSummary={this.state.cartSummary}
						cartCharges={this.state.cartCharges}
						buttons={this.GenerateButtonOptions()}
						onClose={this.onCancel}
						onActionClick={this.onActionClickByEntity}
						showReport={false}
						pagerInfo={this.state.pagerInfo}
						mailTypeLookupItems={this.state.mailTypeLookupItems}						
					/>
				)}				
				{(this.state.status === enumComponentStatus.Error && (
					<React.Fragment>
						<DialogContent>
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							)}
						</DialogContent>
						<DialogActions>							
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => this.props.onClose(false, undefined)}>Close</Button>
						</DialogActions>
					</React.Fragment>
				))}
				
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.user, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, ...HomeNavigation.actionCreators}, // Selects which action creators are merged into the component's props
)(HistoricalCart);