import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import InfoTooltip from 'components/notifcations/InfoTooltip';
import * as React from 'react';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { isNullOrUndefined } from 'util';

interface IMenuButtonProps {
	id: string,
	button: ITen99DynamicButtonProps,
	menuItems: ITen99DynamicButtonProps[]
}
interface ILocalState {
	anchorElement?: HTMLElement,
}

const initialLocalState = { anchorElement: undefined} as ILocalState;
class MenuButton extends React.PureComponent<IMenuButtonProps, ILocalState> {
	//local state
	state = initialLocalState;
	// -----------------
	//Handle User events
	private setMenuAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({ anchorElement: event.currentTarget });
	};

	//clear menu state when menu is closed
	private handleMenuClose = () => {
		this.setState({ anchorElement: undefined });
	};
	// -----------------
	// Componet lifecycle events
	// -----------------
	private CreateMenuItems(items?: ITen99DynamicButtonProps[]) {
		if (items) {
			return items.map((item, index) => {
				if (!isNullOrUndefined(item.icon) && !isNullOrUndefined(item.text) && item.text !== "")
				return <MenuItem key={index} color={item.color} onClick={() => { this.handleMenuClose(); if (!isNullOrUndefined(item.onClick)) { item.onClick(this.props.id, item.action); } }}><ListItemIcon>{item.icon}</ListItemIcon><ListItemText primary={item.text} /></MenuItem>;
			});
		}
	}

	// Render
	render() {		
		return (
			<React.Fragment>
				{(!isNullOrUndefined(this.props.menuItems) && this.props.menuItems.length > 0 && this.props.menuItems.findIndex(x => !isNullOrUndefined(x.text) && x.text !== "") !== -1 ) && (
				<React.Fragment>	
						<InfoTooltip text={isNullOrUndefined(this.props.button.tooltip) ? "" : this.props.button.tooltip}>
							<IconButton id={this.props.id + "ActionButton" + this.props.button.action} color={this.props.button.color} disabled={isNullOrUndefined(this.props.button.disabled) ? false : this.props.button.disabled} onClick={this.setMenuAnchor}>{this.props.button.icon}</IconButton>
						</InfoTooltip>

					<Menu id={"Menu_" + this.props.id}
						anchorEl={this.state.anchorElement}
						keepMounted
						open={!isNullOrUndefined(this.state.anchorElement)}
						onClose={this.handleMenuClose}>
						{this.CreateMenuItems(this.props.menuItems)}
					</Menu>
				</React.Fragment>
			)}
			</React.Fragment>
			);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (MenuButton);