import { Button, Dialog } from "@mui/material";
import DialogTemplate from 'components/display/DialogTemplate';
import ProcessingModal from "components/notifcations/ProcessingModal";
import ImportFileDialog, { UploadType } from 'components/ten99Prep/Import/Components/FileUpload/ImportFileDialog';
import ImportHistoryContoller from 'components/ten99Prep/Import/Components/ImportHistory/ImportHistoryContoller'
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import * as ConfirmDialog from 'store/ConfirmDialog';
import { Ten99PrepBackIcon } from "Ten99PrepOverloads/IconOverloads";
import ImportFileTypeMenu from "./ImportFileTypeMenu";


interface ILocalState {
	dialogOpen: boolean,
	dialogSize: "sm" | "md" | "lg",
	processing: boolean,
	validationMessages: ITen99ApiResponseMessage[],
	wizardItem: "FileType" | "FileUpload" | "ImportHistory",
	uploadType: UploadType
}

interface IImportDataProps {
	customer: ITen99Customer,
	goHome: () => void,
	onClose: () => void,
}

type ImportDataProps =
	& IImportDataProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = {
	dialogOpen: true, dialogSize: "sm", wizardItem: "FileType", validationMessages: [] as ITen99ApiResponseMessage[], processing: false, uploadType: UploadType.Import
};

class ImportFileWizard extends React.PureComponent<ImportDataProps, ILocalState> {


	constructor(props: ImportDataProps) {
		super(props);
		this.state = initialLocalState;
	}


	private onSelect = (type: UploadType) => {
		this.setState({ wizardItem: "FileUpload", dialogSize: "sm", uploadType: type })
    }

	private goBackToImportFileTypeSelectPrompt = () => {
		this.props.confirmDialog_Display("Import from File: " + this.props.customer.name, "-1", "Are you sure you would like to close out of the Import from File screen without importing any data?", this.goBackToImportFileTypeSelect)
	}

	private onClosePrompt = () => {
		this.props.confirmDialog_Display("Import from File: " + this.props.customer.name, "-1", "Are you sure you would like to close out of the Import from File screen without importing any data?", this.props.onClose)
	}

	private goBackToImportFileTypeSelect = () => {
		this.setState({ wizardItem: "FileType", dialogSize: "sm" })
	}

	private goBackToImportFile = () => {
		this.setState({ wizardItem: "FileUpload", dialogSize: "sm" })
	}

	private goToImportHistory = () => {
		this.setState({ wizardItem: "ImportHistory", dialogSize: "lg" })
	}

	// Render
	render() {
		return (
			<React.Fragment>
				{(this.state.processing) && (
					<ProcessingModal modal={true} />
				)}
				<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth={this.state.dialogSize}>
					{this.state.wizardItem === "FileType" && (
						<DialogTemplate onClose={this.props.onClose} title={"Import from File: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.props.goHome}>Back</Button>
								] as unknown as typeof Button[]}>
							<ImportFileTypeMenu onSelect={this.onSelect} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "FileUpload" && (
						<ImportFileDialog title="Import from File" customer={this.props.customer} proceedMsg="The imported files containing current year data will be imported." uploadType={this.state.uploadType} onBack={this.goBackToImportFileTypeSelectPrompt} onNext={this.goToImportHistory} onClose={this.onClosePrompt} />
					)}
					{this.state.wizardItem === "ImportHistory" && (
						<DialogTemplate onClose={this.props.onClose} title={"Import History Template: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.goBackToImportFile}>Back</Button>
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" onClick={this.props.onClose}>Finish</Button>,
								] as unknown as typeof Button[]}>
							<ImportHistoryContoller customer={this.props.customer} />
						</DialogTemplate>
					)}
				</Dialog>
			</React.Fragment>
		)
	}
}
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators }, // Selects which action creators are merged into the component's props
)((ImportFileWizard));
