import { Badge, createStyles, Fab, Theme } from '@mui/material';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import * as React from 'react';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { isNullOrUndefined } from 'util';

interface IAddFabButtonProps {
	itemId: string,
	uiProps: ITen99DynamicButtonProps
}

class FabButton extends React.PureComponent<IAddFabButtonProps> {
	//local state

	// -----------------
	//Handle User events
	
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		return (
			<InfoToolTip text={isNullOrUndefined(this.props.uiProps.tooltip) ? "" : this.props.uiProps.tooltip} >
				<Badge badgeContent={this.props.uiProps.badge} color="primary" max={9999}>
					<Fab size="small" variant={this.props.uiProps.variant as 'circular' | 'extended' | undefined} sx={{ marginLeft: "10px" }} color={this.props.uiProps.color} onClick={x => { if (!isNullOrUndefined(this.props.uiProps.onClick)) { this.props.uiProps.onClick(this.props.itemId, this.props.uiProps.action) } }}>
						{this.props.uiProps.icon}
						{this.props.uiProps.text}
					</Fab>
				</Badge>
			</InfoToolTip>
			);
	}
}

export default FabButton;