import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ActionButton from 'components/buttons/ActionButton';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import AddEditAccount from 'components/ten99Prep/PayerStateAccounts/AddEditAccount';
import SummaryTitle from 'components/ten99Prep/SummaryTitle';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { ITen99LookupData, ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { ITen99PayerStateAccount } from 'sharedInterfaces/ITen99PayerStateAccount';
import * as ConfirmDialog from 'store/ConfirmDialog';
import { Ten99PrepAddIcon, Ten99PrepCancelIcon, Ten99PrepDeleteIcon, Ten99PrepEditIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

enum enumComponentStatus {
	PendingAction,
	Processing,
	IsLoading,
	Invalid,
	Error
}; 

interface IPayerStateAccountsDashboardProps {
	payer: ITen99EntitySummary,
	addOnly?: boolean,
	onClose: () => void,
}

interface ILocalState {
	status: enumComponentStatus,
	stateAccounts: ITen99PayerStateAccount[],
	addEditDialogOpen: boolean,
	addEditStateAccount?: ITen99PayerStateAccount, 
	addEditValidationMessages: ITen99ApiResponseMessage[],
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
}

type PayerStateAccountsDashboardProps =
	IPayerStateAccountsDashboardProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	;

const initialLocalState = { status: enumComponentStatus.IsLoading, stateAccounts: [] as ITen99PayerStateAccount[], addEditDialogOpen: false, addEditStateAccount: undefined, validationMessages: [] as ITen99ApiResponseMessage[], addEditValidationMessages: [] as ITen99ApiResponseMessage[], invalidProperties: [] as string[]} as ILocalState;
class PayerStateAccountsDashboard extends React.PureComponent<PayerStateAccountsDashboardProps, ILocalState> {
	//local state
	private states: ITen99LookupItem[] = {} as ITen99LookupItem[];

	private disabledText: string;

	constructor(props: PayerStateAccountsDashboardProps) {
		super(props);
		this.state = initialLocalState;

		if (this.props.addOnly) {
			this.disabledText = "A form is currently being edited, which means you cannot Edit Or Delete existing state accounts. Please finish editing the form and alter the accounts via the payer.";
		}
		else {
			this.disabledText = "";
        }
		//get the lookup
		//call the api and set the results in the state to reflect on the render
		//load states first
		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=1", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 1);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.states = lookup.lookupItems;
							this.loadStateAccounts();
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load states" }] as ITen99ApiResponseMessage[] });
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details, invalidProperties: flattenProperties(data.details) }); //unable to load lookup, we need to stop
				}
			});
	}
	// -----------------
	//Handle User events

	private getAddEditTitle() {
		if (isNullOrUndefined(this.state.addEditStateAccount)) {
			return "Add State Account";
		}
		else {
			return "Edit Existing State Account";
		}
	}

	private onStateAccountAdd = () => {
		//add new state account		
		this.setState({ addEditDialogOpen: true, addEditValidationMessages: [] as ITen99ApiResponseMessage[], addEditStateAccount: undefined })
	}

	private onStateAccountEdit = (id: number) => {
		//add new state account		
		this.setState({ addEditDialogOpen: true, addEditValidationMessages: [] as ITen99ApiResponseMessage[], addEditStateAccount: this.state.stateAccounts.find(x => x.id === id)})
	}
	private onStateAccountDelete = (id: number) =>
	{
		const tempStateAccount: ITen99PayerStateAccount | undefined = this.state.stateAccounts.find(x => x.id === id)


		if (isNullOrUndefined(tempStateAccount)) {
			console.error("Unable to find State Account: " + id.toString())
		}
		else {
			this.props.confirmDialog_Display("Delete state", tempStateAccount.id.toString(), "You are about to delete a state account", this.onStateAccountDeleteProceed);
		}
	}

	private onStateAccountDeleteProceed = (id: string) => {
		const tempStateAccount: ITen99PayerStateAccount | undefined = this.state.stateAccounts.find(x => x.id.toString() === id)


		if (isNullOrUndefined(tempStateAccount)) {
			console.error("Unable to find State Account: " + id.toString())
		}
		else {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: [] as ITen99ApiResponseMessage[] });
			MakeApiCall<string>("api/PayerStateAccount/" + id.toString() + "/?ticks=" + tempStateAccount.ticks, "DELETE")
				.then(data => {
					if (data.isSuccess) {
						this.loadStateAccounts();
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to delete" }] as ITen99ApiResponseMessage[] });
					}
				});
		}
	}

	private onCloseAddEditDialog = (proceed: boolean) => {
		this.setState({ addEditDialogOpen: false, addEditStateAccount: undefined })

		if (proceed) {
			this.loadStateAccounts();
        }
	}

	private loadStateAccounts = () => {
		const componentState: enumComponentStatus = (this.state.stateAccounts.length > 0 ? enumComponentStatus.Processing : enumComponentStatus.IsLoading)

		this.setState({ status: componentState, validationMessages: [] as ITen99ApiResponseMessage[] });
		MakeApiCall<ITen99PayerStateAccount[]>("api/PayerStateAccount/?payerId=" + this.props.payer.id + "&PageNumber=1&PageSize=50", "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					this.setState({ stateAccounts: data.payload, status: enumComponentStatus.PendingAction })
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the state accounts" }] as ITen99ApiResponseMessage[] });
				}
			});
	}
	

	private onActionClickById = (id: string, action: ActionTypesEnum) => {
		if (!isNullOrUndefined(id)) {
			let numberId: number = Number.parseInt(id);
			if (!isNaN(numberId)) {
				switch (action) {
					case ActionTypesEnum.Delete:
						this.onStateAccountDelete(Number.parseInt(id));
						break;
					case ActionTypesEnum.Edit:
						this.onStateAccountEdit(Number.parseInt(id));
						break;
				}
			}
		}
	}
	// -----------------

	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		
		return (			
			<React.Fragment>
				<DialogTitle>				
					<SummaryTitle variant="h5" entity={this.props.payer} /><Typography variant="h5" display="inline">{"- State Accounts"}
											<InfoToolTip text="Specify the account numbers for the states this Payer reports earnings in" />
						<InfoToolTip text="Add State account">
							<Fab size="small" sx={{marginLeft: "10px"}} color="primary" onClick={this.onStateAccountAdd}>
								<Ten99PrepAddIcon />
							</Fab>
						</InfoToolTip>
					</Typography>
				</DialogTitle>
				<DialogContent>
					{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
						<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
					)}
					<Grid container spacing={1} sx={{ textAlign: 'center' }}>
						{!isNullOrUndefined(this.state.stateAccounts) && this.state.stateAccounts.length > 0 && (
							<React.Fragment>
							<Grid item xs={12}>
								{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
									<ApiErrorResponseMessages messages={this.state.validationMessages} />
								)}
							</Grid>
								<Grid item xs={2}>
									<Typography variant="body1" color="textPrimary">State</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography variant="body1" color="textPrimary">Form Type</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant="body1" color="textPrimary">State ID Number</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography variant="body1" color="textPrimary">Enabled For State Submission<InfoToolTip text="This shows whether or not the state in question is enabled to submit applicable forms to the State agencies as well as Federal" /></Typography>
																		
								</Grid>
								<Grid item xs={3}>
								</Grid>
								{this.state.stateAccounts.filter(x => x.isActive).map((item, index) => {
									return (
										<React.Fragment key={index}>
											<Grid item xs={2}>
												<FormControl
													fullWidth
													>
													<InputLabel id="stateLabel">State</InputLabel>
													<Select
														labelId="stateLabel"
														label="State"
														disabled={true}
														value={item.stateId}
														inputProps={{}}
														id={item.id.toString() + "stateId"}
														name="stateId"
													>
														{(this.states.map((item, index) => <MenuItem key={index} value={item.id}>{item.description}</MenuItem>))}

													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={2}>
												<TextField
													disabled={true}
													value={isNullOrUndefined(item.ruleData) ? "Other" : item.ruleData.descr}
													variant="outlined"
													error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "accountType") !== undefined)}
													id={item.id.toString() + "accountType"}
													label="Form Type"
													name="accountType"
													fullWidth
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													disabled={true}
													value={item.stateAccount}
													variant="outlined"
													error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "account") !== undefined)}
													id={item.id.toString() + "account"}
													label="State ID Number"
													name="account"													
													fullWidth
												/>
											</Grid>
											<Grid item xs={2}>												
												<Checkbox
													color="primary"
													disabled={true}
													checked={!item.isBlock}
													id={item.id.toString() + "isblock"}
													name="isBlock"
												/>											
											</Grid>
											<Grid item xs={3} sx={{textAlign: 'right'}}>
												<ActionButton itemId={item.id.toString()} uiProps=
													{{ action: ActionTypesEnum.Edit, icon: <Ten99PrepEditIcon />, text: "Edit", color: "secondary", tooltip: this.disabledText, onClick: this.onActionClickById, disabled: this.props.addOnly} as ITen99DynamicButtonProps}
												/>
												<ActionButton itemId={item.id.toString()} uiProps=
													{{ action: ActionTypesEnum.Delete, icon: <Ten99PrepDeleteIcon />, text: "Delete", color: "secondary", tooltip: this.disabledText, onClick: this.onActionClickById, disabled: this.props.addOnly } as ITen99DynamicButtonProps}
												/>
											</Grid>
										</React.Fragment>
									);
								})
								}
							</React.Fragment>
						)}
						{this.state.status === enumComponentStatus.PendingAction && (isNullOrUndefined(this.state.stateAccounts) || this.state.stateAccounts.length === 0) && (
							<Grid item xs={12}>
								<Typography variant="body2" color="textPrimary">No state accounts found for this payer. You may add them below.</Typography>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={(e) => this.props.onClose()}>Close</Button>
				</DialogActions>
				{this.state.addEditDialogOpen && (
					<Dialog open={this.state.addEditDialogOpen} fullWidth={true} maxWidth="lg">
						<DialogTitle>
							<Typography variant="h5">{this.getAddEditTitle()}	
							</Typography>
						</DialogTitle>
						<DialogContent>
							<AddEditAccount payerId={this.props.payer.id} payerStateAccount={this.state.addEditStateAccount} onClose={this.onCloseAddEditDialog} states={this.states} />
						</DialogContent>
						<DialogActions>
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={(e) => this.onCloseAddEditDialog(false)}>Close</Button>
						</DialogActions>

					</Dialog>
				)}
			</React.Fragment>
		)
	}
}
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators } // Selects which action creators are merged into the component's props
)(PayerStateAccountsDashboard);
