import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { isNullOrUndefined } from 'util';


type LoginRefreshProps = RouteComponentProps;
interface ILocalState {
}

class LoginRefresh extends React.PureComponent<LoginRefreshProps, ILocalState> {
	// -----------------
	// Render
	render() {
		let refreshed: boolean = sessionStorage.getItem("LoginRefreshed") === null ? false : true;
		if (refreshed) {
			sessionStorage.removeItem("LoginRefreshed")
			const redirect = (new URLSearchParams(this.props.location.search)).get("redirect");
			return (
				//redirect to home
				<div>
					<Redirect to={isNullOrUndefined(redirect) || redirect === "" ? "/gate/home/customers" : redirect} />
				</div>
			)
		}
		else { //reload the screen to ensure we have latest version of UI
			sessionStorage.setItem("LoginRefreshed","true");
			window.location.reload();
		}
			
		return (
			<React.Fragment>
			</React.Fragment>	
			);					
			}
		}

export default LoginRefresh