import { Button, Grid, TextField, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import * as LoginStore from 'store/Login';
import { isNullOrUndefined } from 'util';

interface IParentItems {
	userName: string,
	onSubmit: (creds: LoginStore.Credentials) => void,
	isInvalid: boolean,
	invalidProperties: string[],
}

interface ILocalState {
	userName: string,
	password: string,		
}

type LoginDisplayProps =
	IParentItems
	;

const initialLocalState = {
	userName: "", password: ""
} as ILocalState;

class LoginDisplay extends React.PureComponent<LoginDisplayProps, ILocalState> {
	//local state

	constructor(props: LoginDisplayProps) {
		super(props);
		let temp: ILocalState = { ...initialLocalState };
		temp.userName = this.props.userName;
		this.state = temp;
	}
	// -----------------
	//Handle User events
	private onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			this.submit();
		}
	}

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
	}

	private submit = () => {
		this.props.onSubmit({ userName: this.state.userName, password: this.state.password } as LoginStore.Credentials);
	}
	// -----------------
	// Componet lifecycle events
	
	// -----------------
	// Render
	render() {
		
		return (
			
				<Grid container spacing={1} sx={{textAlign: 'center'}}>
					<Grid item xs={12}>
						<Typography variant="h4">
							Sign In
							</Typography>
						<br />
					</Grid>
					<Grid item xs={12}>
						<TextField
							onChange={this.handleChange}
							value={this.state.userName}
							variant="outlined"
						error={this.props.isInvalid && (this.props.invalidProperties.find(x => x === "UserName") !== undefined)}
							id="userName"
							label={"Email"}
							name="userName"
							onKeyDown={(e) => { this.onKeyDown(e); }}
							autoFocus={true}
							fullWidth
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							onChange={this.handleChange}
							value={this.state.password}
							variant="outlined"
						error={this.props.isInvalid && (this.props.invalidProperties.find(x => x === "Password") !== undefined)}
							id="password"
							label="Password"
							name="password"
							type="password"
							onKeyDown={(e) => { this.onKeyDown(e); }}
							fullWidth
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Button type="button" variant="contained" color="primary" fullWidth onClick={this.submit}>SIGN IN</Button>
					</Grid>
					<Grid item xs={3} sx={{textAlign: 'left'}}>
						<Link to="/usergate/forgotPassword">Forgot Password?</Link>
					</Grid>
					<Grid item xs={6}>
					</Grid>
					<Grid item xs={3} sx={{textAlign: 'right'}}>
						<Link to="/usergate/RegisterUser">Create Account</Link>
					</Grid>
				</Grid>
				)				
			}
		}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default LoginDisplay;