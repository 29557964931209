import { Dialog, Grid, Typography } from '@mui/material';
import Pager from 'components/buttons/Pager';
import AlertsDashboard from 'components/information/AlertsDashboard';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from "components/notifcations/ProcessingModal";
import EntityAddEditView from 'components/ten99Prep/EntityAddEditView/EntityAddEditView';
import FabButtons from 'components/ten99Prep/FabButtons';
import FormHistory from 'components/ten99Prep/FormHistory';
import FormSummary from 'components/ten99Prep/FormSummary';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum, IAction } from 'sharedInterfaces/ITen99Action';
import { enumAddEditViewAction, enumEntityType, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage, ITen99Dashboard } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99CartElement } from 'sharedInterfaces/ITen99Cart';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { ITen99PagerInfo } from 'sharedInterfaces/ITen99PagerInfo';
import { ApplicationState } from 'store';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from 'utilities/ApiFunctions';
import { getEntityPrintableName } from 'utilities/EntityNameSummary';
import FormDeliveryDialog from './FormDeliveryDialog';

enum enumComponentStatus {
	PendingAction,
	Processing,
	SilentProcessing,
	Loading,
	Invalid,
	Error
};

interface IForms {
	parents: ITen99EntitySummary[],
	recipientId: string,
}

type FormsProps =	
	& IForms
	& HomeNavigationStore.CustomerSummaryState // ... state we've requested from the Redux store
	& ICustomerSummaryActions // ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	;

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	addEditViewEntityOpen: boolean,
	addEditViewEntitySetting?: IAevEntitySetting,
	historyDialogOpen: boolean,
	historyItems: ITen99EntitySummary[],
	alertsOpen: boolean,
	alertsEntity?: ITen99EntitySummary
	pagerInfo: ITen99PagerInfo,
	forms?: ITen99Dashboard<ITen99EntitySummary>,
	filtersSetOn?: Date,
	countsRefreshedOn?: Date,
	formDeliveryDialogOpen: boolean,
	formDeliveryEntity?: ITen99EntitySummary,
}

const initialLocalState = { status: enumComponentStatus.Loading, pagerInfo: { pageNumber: 0, totalCount: 0, pageSize: 25 }, historyDialogOpen: false, addEditViewEntityOpen: false, addEditViewEntitySetting: undefined, alertsOpen: false, alertsEntity: undefined, historyItems: [] as ITen99EntitySummary[], validationMessages: {} as ITen99ApiResponseMessage[], formDeliveryDialogOpen: false } as ILocalState;
class Forms extends React.PureComponent<FormsProps, ILocalState> {
	//local state
	constructor(props: FormsProps) {
		super(props);
		let temp: ILocalState = { ...initialLocalState };
		temp.filtersSetOn = this.props.filtersSetOn;
		temp.countsRefreshedOn = this.props.countsRefreshedOn;
		temp.pagerInfo.onPagerClick = this.onPagerClick;
		this.state = temp;
		this.search(1);
	}

	// -----------------
	//Handle User events
	private onAddEditViewEntityProccessed = (response: IAevEntityResponse) => {
		let entityId: string | undefined = undefined;
		if (!isNullOrUndefined(this.state.addEditViewEntitySetting)) {
			entityId = this.state.addEditViewEntitySetting.id;
		}
		this.setState({ addEditViewEntityOpen: false, addEditViewEntitySetting: undefined, validationMessages: {} as ITen99ApiResponseMessage[] });
		if (response.processed) {
			this.props.CustomerSummaryActions_RefreshSummaryCounts();
			this.search(this.state.pagerInfo.pageNumber, true);
			//send toast notification
			this.props.toastNotifcations_Display({ id: "FormAddEdit" + entityId, message: response.message, type: "success" });
		}
	}
	private onAdd = () => { this.onAddEditViewOpen({ parents: this.props.parents, action: enumAddEditViewAction.Add, type: enumEntityType.Form } as IAevEntitySetting) }
	private onEdit = (entity: ITen99EntitySummary) => { this.onAddEditViewOpen({ id: entity.id, parents: this.props.parents, action: enumAddEditViewAction.Edit, type: enumEntityType.Form } as IAevEntitySetting) }
	private onView = (entity: ITen99EntitySummary) => { this.onAddEditViewOpen({ id: entity.id, parents: this.props.parents, action: enumAddEditViewAction.View, type: enumEntityType.Form } as IAevEntitySetting) }
	private onDelete = (entity: ITen99EntitySummary) => {
		this.props.confirmDialog_Display("Delete Form", entity.id, "You are about to delete the following " + getEntityPrintableName(entity), this.onDeleteProceed);
	}
	private onViewDoc = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })
		MakeApiCall<string>("api/ReportManage/TaxForm/Type/" + entity.type + "/Id/" + entity.id, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
						
					}
				}
				else {
					this.props.toastNotifcations_Display({ id: "FormDoc" + entity.id, message: "Unable to view the document", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction })
			})
	}
	private onSubmitToCart = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing }); //display modal
		//call the api and set the results in the state to reflect on the render
		MakeApiCall<number>("api/CartElements/", "POST", JSON.stringify({ recipientFormId: Number.parseInt(entity.id), ticks: entity.ticks } as ITen99CartElement))
			.then(data => {
				if (data.isSuccess) {
					if (data.payload === 0) { // zero returned when not done becuase already exists
						this.props.toastNotifcations_Display({ id: "CartSubmit" + entity.id, message: getEntityPrintableName(entity) + " is already in cart", type: "warning" });
					}
					else {
						//send toast notification
						this.props.toastNotifcations_Display({ id: "CartSubmit" + entity.id, message: getEntityPrintableName(entity) + " added to cart", type: "success" });
						this.props.CustomerSummaryActions_RefreshSummaryCounts();
						//refresh list
						this.search(this.state.pagerInfo.pageNumber, true);
					}
				}
				else {
					//send toast notification
					this.props.toastNotifcations_Display({ id: "CartSubmit" + entity.id, message: "Issue occurred adding " + getEntityPrintableName(entity) + " to cart", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction }); //remove modal
			});
	}

	private onFormHistoryLoad = (entity: ITen99EntitySummary) => {		
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })

		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/RecipientForms/" + entity.id + "/FormHistory", "Get")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.rows.length > 0) {
					this.setState({ historyDialogOpen: true, historyItems: data.payload.rows })
				}
				else {
					this.props.toastNotifcations_Display({ id: "FormHistory" + entity.id, message: "Unable to load Form History", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}

	private onFormHistoryClose= () => {
		this.setState({ historyDialogOpen: false, historyItems: [] as ITen99EntitySummary[] })
	}

	private onGenericAction = (entity: ITen99EntitySummary, actionId: number) => {

		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })

		MakeApiCall<string>("api/RecipientForms/" + entity.id + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, ticks: entity.ticks } as IAction))
			.then(data => {
				if (data.isSuccess) {
					this.search(this.state.pagerInfo.pageNumber);
					this.props.toastNotifcations_Display({ id: "Form" + entity.id + "action" + actionId, message: "Action Processed", type: "success" });
					this.props.CustomerSummaryActions_RefreshSummaryCounts();
				}
				else {
					data.details.forEach((item, index) => {
						this.props.toastNotifcations_Display({ id: "Form" + entity.id + "action" + actionId + "_" + index.toString(), message: item.message, type: "error" });
					})					
					this.setState({ status: enumComponentStatus.PendingAction });
				}
			});
	}

	private onShowAlerts = (entity: ITen99EntitySummary) => {
		this.setState({ alertsOpen: true, alertsEntity: entity });
	}

	private onCloseAlerts = () => {
		this.setState({ alertsOpen: false, alertsEntity: undefined });
	}

	private onShowFormDelivery = (entity: ITen99EntitySummary) => {
		this.setState({ formDeliveryDialogOpen: true, formDeliveryEntity: entity });
	}

	private onCloseFormDelivery = () => {
		this.setState({ formDeliveryDialogOpen: false, alertsEntity: undefined });
	}

	private onActionClickByEntity = (entity: ITen99EntitySummary, action: ActionTypesEnum) => {
		switch (action) {
			case ActionTypesEnum.Add:
				this.onAdd();
				break;
			case ActionTypesEnum.Delete:
				this.onDelete(entity);
				break;
			case ActionTypesEnum.Edit:
				this.onEdit(entity);
				break;
			case ActionTypesEnum.SubmitToCart:
				this.onSubmitToCart(entity);
				break;
			case ActionTypesEnum.View:
				this.onView(entity);
				break;
			case ActionTypesEnum.ViewDoc:
				this.onViewDoc(entity);
				break;
			case ActionTypesEnum.FormHistory:
				this.onFormHistoryLoad(entity)
				break;
			case ActionTypesEnum.ShowAlerts:
				this.onShowAlerts(entity);
				break;
			case ActionTypesEnum.FormDelivery:
				this.onShowFormDelivery(entity);
				break;
			default:
				this.onGenericAction(entity, action.valueOf());
				break;

		}
	}
	private onActionClickById = (id: string, action: ActionTypesEnum) => {
		if (!isNullOrUndefined(this.state.forms)) {
			const entity: ITen99EntitySummary | undefined = id === "" ? { id: "" } as ITen99EntitySummary : this.state.forms.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				this.onActionClickByEntity(entity, action);
			}
			else {
				console.error("Unable to locally find form: " + id);
				}
		}
		else {
			console.error("Froms dashboard not loaded: " + id);
		}
	}

	private onAddEditViewOpen = (entitySetting: IAevEntitySetting) => {
		this.setState({ addEditViewEntityOpen: true, addEditViewEntitySetting: entitySetting });
	}

	private onDeleteProceed = (id:string) => {
		
		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing && !isNullOrUndefined(this.state.forms)) {			
			//get the customer to make sure it is still there and get name
			const entity: ITen99EntitySummary | undefined = this.state.forms.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				//call the api and set the results in the state to reflect on the render
				MakeApiCall<string>("api/RecipientForms/" + id + "/?ticks=" + entity.ticks, "DELETE")
					.then(data => {
						if (data.isSuccess) {
							//refresh list							
							this.search(this.state.pagerInfo.pageNumber);
							this.props.toastNotifcations_Display({ id: "FormDelete" + id, message: getEntityPrintableName(entity) + " deleted", type: "success" });
							this.props.CustomerSummaryActions_RefreshSummaryCounts();
						}
						else {
							//send toast notification
							this.props.toastNotifcations_Display({ id: "FormDelete" + id, message: "Issue occurred deleting " + getEntityPrintableName(entity), type: "error" });
						}
					});
			}
			else {
				console.error("Unable to locally find Form: " + id);
            }
		}
	}

	private search = (pageNumber: number, silentLoad: boolean = false) => {
		if (!isNullOrUndefined(this.props.recipientId)) {
			let loadStatus: enumComponentStatus = silentLoad ? enumComponentStatus.SilentProcessing : enumComponentStatus.Processing;
			this.setState({ status: loadStatus, pagerInfo: { ...this.state.pagerInfo, pageNumber: pageNumber } });
			let pager2: ITen99PagerInfo = { ...this.state.pagerInfo, pageNumber: pageNumber, namedFilters: this.props.namedFilters };
			MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/RecipientForms/Summary/?RecipientId=" + this.props.recipientId, "POST", JSON.stringify(pager2))
				.then(data => {
					if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload) && !isNullOrUndefined(data.pageData)) {
						this.setState({ status: enumComponentStatus.PendingAction, forms: data.payload, pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount } });
					}
					else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
						//empty
						this.setState({ status: enumComponentStatus.PendingAction, forms: undefined, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load forms" }] as ITen99ApiResponseMessage[] });
					}
				})
		}
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private filtersChanged = () => {
		this.setState({ filtersSetOn: this.props.filtersSetOn });
		this.search(this.state.pagerInfo.pageNumber);
	}

	private countsChanged = () => {
		this.setState({ countsRefreshedOn: this.props.countsRefreshedOn });
		this.search(this.state.pagerInfo.pageNumber);
	}
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		if (this.state.status === enumComponentStatus.PendingAction && this.state.filtersSetOn !== this.props.filtersSetOn) {
			this.filtersChanged();
		}
		else if(this.state.status === enumComponentStatus.PendingAction && this.state.countsRefreshedOn !== this.props.countsRefreshedOn) {
			
			this.countsChanged();
		}
		
		
		const showPager: boolean = !isNullOrUndefined(this.state.forms) && this.state.forms.rows.length < this.state.pagerInfo.totalCount;
			return (			
				<React.Fragment>
					{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
						<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
					)}
					{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.SilentProcessing) && (
						<Grid container spacing={1} sx={{textAlign: 'center'}}>
							<Grid item xs={8} sx={{textAlign: 'left'}}>
								<Typography variant="h5">Forms
								{(!isNullOrUndefined(this.state.forms) &&
										<FabButtons entityType={EnumEntityType.RecipientForm} actions={this.state.forms.bulkActions} onClick={this.onActionClickById} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={4} sx={{textAlign: 'right'}}>
								<Pager pagerInfo={this.state.pagerInfo} show={showPager}/>
							</Grid>
							<Grid item xs={12}>
								{!isNullOrUndefined(this.state.forms) && this.state.forms.rows.length > 0 && (
									<React.Fragment>
										{
											this.state.forms.rows.map((item, index) => {
												return (
													<FormSummary key={index} onActionClick={this.onActionClickById} summaryItem={item} />
												)
											})
										}
									</React.Fragment>
								)}
							</Grid>
							<Grid item xs={8} sx={{textAlign: 'right'}}>
							</Grid>
							<Grid item xs={4} sx={{textAlign: 'right'}}>
								<Pager pagerInfo={this.state.pagerInfo} show={showPager} />
							</Grid>
							<Grid item xs={12}>
								{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
									<ApiErrorResponseMessages messages={this.state.validationMessages} />
								)}
							</Grid>
						</Grid>
					)}

					{this.state.addEditViewEntityOpen && !isNullOrUndefined(this.state.addEditViewEntitySetting) && (
						<EntityAddEditView onClose={this.onAddEditViewEntityProccessed} entitySettings={this.state.addEditViewEntitySetting} />
					)}
					{this.state.historyDialogOpen && (
						<Dialog open={this.state.historyDialogOpen} fullWidth={true} maxWidth="lg">
							<FormHistory parents={this.props.parents} history={this.state.historyItems} onActionClickByEntity={this.onActionClickByEntity} onClose={this.onFormHistoryClose} />
						</Dialog>	
					)}
					{this.state.alertsOpen && !isNullOrUndefined(this.state.alertsEntity) && (
						<Dialog open={this.state.alertsOpen} fullWidth={true} maxWidth="lg">
							<AlertsDashboard alertSource={this.state.alertsEntity} alertSourceParents={this.props.parents} onClose={this.onCloseAlerts} />
						</Dialog>
					)}
					{this.state.formDeliveryDialogOpen && !isNullOrUndefined(this.state.formDeliveryEntity) && (
						<Dialog open={this.state.formDeliveryDialogOpen} fullWidth={true} maxWidth="lg">
							<FormDeliveryDialog recipientFormId={this.state.formDeliveryEntity.id} navItems={[...this.props.parents, this.state.forms?.rows.find(x => x.id === this.state.formDeliveryEntity?.id) ?? {} as ITen99EntitySummary]} onClose={this.onCloseFormDelivery} />
						</Dialog>
					)}

				</React.Fragment>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, ...HomeNavigationStore.actionCreators } // Selects which action creators are merged into the component's props
)(Forms);