import { Button, Dialog } from "@mui/material";
import DialogTemplate from "components/display/DialogTemplate";
import ImportFileDialog, { UploadType } from "components/ten99Prep/Import/Components/FileUpload/ImportFileDialog";
import ImportHistoryContoller from "components/ten99Prep/Import/Components/ImportHistory/ImportHistoryContoller";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import * as ConfirmDialog from 'store/ConfirmDialog';
import { Ten99PrepBackIcon } from "Ten99PrepOverloads/IconOverloads";
import Export from "./Export";
import RollOverAll from "./RollOverAll";
import RollOverMenu from "./RollOverMenu";

interface ILocalState {
	dialogOpen: boolean,
	dialogSize: "sm" | "md" | "lg",
	wizardItem: "RollOverHome" | "RollOverAll" | "RollOverAllProceed" | "ExportToFile" | "ImportFromFile" | "ImportHistory",
}

interface IRollOverWizardProps {
	customer: ITen99Customer,
	goHome: () => void,
	onClose: () => void,
}

type RollOverWizardProps =
	& IRollOverWizardProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested;
	;

const initialLocalState: ILocalState = { dialogOpen: true, wizardItem: "RollOverHome", dialogSize: "sm"};
class RollOverWizard extends React.PureComponent<RollOverWizardProps, ILocalState> {
	
	constructor(props: RollOverWizardProps) {
		super(props);
		this.state = initialLocalState;
	}
	
	private closeImportWizard = () => {
		this.setState({ dialogOpen: false });
		this.props.onClose();
	}

	private closeImportWizardPrompt = () => {
		this.props.confirmDialog_Display("Import from File: " + this.props.customer.name, "-1", "Are you sure you would like to close out of the Import from File screen without importing any data?", this.closeImportWizard)
	}

	private goHome = () => {
		this.setState({ wizardItem: "RollOverHome", dialogSize: "sm"});
	}

	private selectMenuItem = (name: "RollOverAll" | "ExportToFile" | "ImportFromFile") => {
		if (name === "RollOverAll") {
			this.props.confirmDialog_Display("Rollover All Prior Year Data: " + this.props.customer.name, "-1", "All prior year data will be rolled over to the current year.", this.proceedAllDataRollOver)
		}
		else {
			this.setState({ wizardItem: name, dialogSize: "sm" });
		}
	}

	private proceedAllDataRollOver = () => {
		this.setState({ wizardItem: "RollOverAllProceed"});
	}

	private goToImportHistory = () => {
		this.setState({ wizardItem: "ImportHistory", dialogSize: "lg" })
	}

	// Render
	render() {
		return (
			<React.Fragment>
				<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth={this.state.dialogSize}>
					{this.state.wizardItem === "RollOverHome" && (
						<DialogTemplate onClose={this.closeImportWizard} title={"Rollover Prior Year Data: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.props.goHome}>Back</Button>
								] as unknown as typeof Button[]}>
							<RollOverMenu onSelect={this.selectMenuItem} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "ExportToFile" && (
						<DialogTemplate onClose={this.closeImportWizard} title={"Download Prior Year to Excel File: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.goHome}>Back</Button>
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="secondary" onClick={this.closeImportWizard}>Finish</Button>,
								] as unknown as typeof Button[]}>
							<Export customerId={this.props.customer.customerId} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "ImportHistory" && (
						<DialogTemplate onClose={this.closeImportWizard} title={"Import History Template: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.goHome}>Back</Button>
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" onClick={this.closeImportWizard}>Finish</Button>,
								] as unknown as typeof Button[]}>
							<ImportHistoryContoller customer={this.props.customer} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "RollOverAllProceed" && (
						<RollOverAll customer={this.props.customer} onProceed={this.goToImportHistory} onBack={this.goHome} />
					)}
				</Dialog>
				{this.state.wizardItem === "ImportFromFile" && (
					<ImportFileDialog title="Import Prior Year from File" customer={this.props.customer} proceedMsg="The imported files containg the prior year data will be rolled over to the current year." uploadType={UploadType.Historical} onBack={this.goHome} onNext={this.goToImportHistory} onClose={this.closeImportWizardPrompt} />
				)}
			</React.Fragment>
		)
	}
}

export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators }, // Selects which action creators are merged into the component's props
)((RollOverWizard));