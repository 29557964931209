import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as ConfirmDialogStore from 'store/ConfirmDialog';
import { isNullOrUndefined } from 'util';

type ConfirmDialogProps =	
	ConfirmDialogStore.ConfirmDialogState // ... state we've requested from the Redux store
	& typeof ConfirmDialogStore.actionCreators // ... plus action creators we've requested
	;

interface ILocalState {	
	dialogOpen: boolean,
}
const initialLocalState = { } as ILocalState;
class ConfirmDialog extends React.PureComponent<ConfirmDialogProps, ILocalState> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle User events
	private onCancelClick = () => {
		const id: string = this.props.confirmDialog_objectId;
		this.props.confirmDialog_Reset();// clear modal
		if (!isNullOrUndefined(this.props.confirmDialog_onCancel)) {
			this.props.confirmDialog_onCancel(id);
		}	
	}

	private onProceedClick = () => {
		const id: string = this.props.confirmDialog_objectId;
		this.props.confirmDialog_Reset();// clear modal
		if (!isNullOrUndefined(this.props.confirmDialog_onProceed)) {
			this.props.confirmDialog_onProceed(id);
		}		
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		
			return (			
				<React.Fragment>								
					{this.props.confirmDialog_dialogOpen && (
						<Dialog open={this.props.confirmDialog_dialogOpen} fullWidth={true}>
						
							<DialogTitle>{this.props.confirmDialog_title}</DialogTitle>
							<DialogContent>
								<Grid container spacing={1} sx={{textAlign: 'center'}}>
									<Grid item xs={12}>
										<Typography variant="body2">{this.props.confirmDialog_message}</Typography>
									</Grid>
									{!this.props.confirmDialog_hideCancel && (
										<Grid item xs={12}>
											<Typography variant="body2">Do you wish to proceed?</Typography>
										</Grid>
									)}
								</Grid>
							</DialogContent>
							<DialogActions>
								{!this.props.confirmDialog_hideCancel && (
									<Button type="button" variant="contained" color="secondary" onClick={this.onCancelClick}>Cancel</Button>
								)}
								<Button type="button" variant="contained" color="primary" onClick={this.onProceedClick}>Proceed</Button>
							</DialogActions>
						
						</Dialog>	
					)}
				</React.Fragment>
		);
	}
}

// -----------------
export default connect(
	(state: ApplicationState) => state.confirmDialog, // Selects which state properties are merged into the component's props
	ConfirmDialogStore.actionCreators // Selects which action creators are merged into the component's props
)(ConfirmDialog);