import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";

export function flattenProperties(validationMessages: ITen99ApiResponseMessage[]) {
    let invalidPropList: string[] = Array<string>();
    for (var i in validationMessages) {
        for (var j in validationMessages[i].propertyNames) {
            invalidPropList.push(validationMessages[i].propertyNames[j]);
        }
    }
    return invalidPropList;
};
