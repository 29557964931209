import { AccountBalanceOutlined, AccountBoxOutlined, Announcement, ContactSupportOutlined, ExitToApp, HelpOutlineOutlined, HomeOutlined, PictureAsPdfOutlined, QuestionAnswerOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from '@mui/material';
import SimpleSearchBox from 'components/ten99Prep/SimpleSearchBox';
import Ten99ToolbarDialog, { DialogTypesEnum } from 'components/ten99Prep/Ten99ToolbarDialog';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { ConvertEnumToToastType, ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import { ITen99MessagePriorityEnum } from 'sharedInterfaces/ITen99MessagePriorityEnum';
import { ITen99Notification, ITen99NotificationTypeEnum } from 'sharedInterfaces/ITen99Notification';
import { ApplicationState } from 'store';
import * as ConfirmDialogStore from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { Auth_ActionCreators } from 'store/SharedActions';
import * as ToastNotificationsStore from 'store/ToastNotifications';
import { ToastType } from 'store/ToastNotifications';
import * as UserStore from 'store/User';
import { Ten99Company, Ten99PrepCalender, Ten99PrepCartHistoryIcon, Ten99Users } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { onUserAdminRedirect, UserManagementEndpoint } from 'utilities/RedirectUserAdmin';
import { GetCompany } from 'utilities/UserId';

enum enumActionType {
    link,
    menu,
    onClick,
    search,
};    

interface ToolbarItem {
    title: string;
    icon: any;
    itemType: enumActionType;
    navUrl?: string;
    onClick?: () => void;
    subItems?: ToolbarItem[];
};

interface localState {
    anchorElement?: HTMLElement,
    activeMenu?: string,
    openDialog: boolean,
    openDialogType?: DialogTypesEnum,
    openDialogValue?: string,
}
interface reduxItems {
    userStore: UserStore.UserState,
    homeNavigationStore: HomeNavigationStore.CustomerSummaryState
}
type ten99ToolbarProps =
    reduxItems
    & typeof Auth_ActionCreators // ... plus action creators we've requested
    & typeof HomeNavigationStore.actionCreators// ... plus action creators we've requested
    & typeof ConfirmDialogStore.actionCreators// ... plus action creators we've requested
    & typeof ToastNotificationsStore.actionCreators// ... plus action creators we've requested
    & typeof UserStore.actionCreators// ... plus action creators we've requested 
    & RouteComponentProps // ... plus incoming routing parameters
    ;

class Ten99Toolbar extends React.PureComponent<ten99ToolbarProps> {
    //local state
    state = { anchorElement: undefined, activeMenu: "", openDialog: false, openDialogType: undefined} as localState;

    constructor(props: ten99ToolbarProps) {
        super(props); 
    }
  
    private tokenWarningTimeout?: NodeJS.Timeout; 
    private getNotificationsInterval?: NodeJS.Timeout; 

    //Actions

    //Logout
    private onLogout = (redirect: string = "") => {
        this.props.history.push("/gate/logout/?redirect=" + window.location.pathname); // go to login
    }

    private redirectToLogin = () => {
        this.onLogout(this.props.match.url.toLowerCase())
    }

    //Attach the menu to an element and open it    
    private setMenuAnchor = (event: React.MouseEvent<HTMLButtonElement>, menuName:string) => {
        this.setState({ anchorElement: event.currentTarget });
        this.setState({ activeMenu: menuName });
    };

    //clear menu state when menu is closed
    private handleMenuClose = () => {
        this.setState({ anchorElement: undefined });
        this.setState({ activeMenu: undefined });
    };    
    
    private onTechnicalAssistanceClick = () => {        
        this.setState({ openDialog: true, openDialogType: DialogTypesEnum.TechnicalAssitance });        
    }

    private onNewWindowOpen = (url:string ) => {
        window.open(url, '_blank');
        this.handleMenuClose();
    };   



    private openDialog = (type: DialogTypesEnum, value: string) => {
        this.setState({ openDialog: true, openDialogType: type, openDialogValue: value });
    }


    private onCartSearch = (cartId: string) => {
        this.setState({ openDialog: true, openDialogType: DialogTypesEnum.Cart, openDialogValue: cartId });
    }

    private onDialogClose = (proceesed: boolean, message?: ITen99ActionResponseMessage) => {
        this.setState({ openDialog: false, openDialogType: "" });

        if (proceesed && !isNullOrUndefined(message)) {
            //send Toast message
            const toastMessage: ToastNotificationsStore.IToastMessage = { id: "dialog", message: message.message, type: ConvertEnumToToastType(message.messageType) };
            this.props.toastNotifcations_Display(toastMessage);
        }
    }; 

    private CreateToolbar(items: ToolbarItem[]) {
        return items.map((item, index) => {
            switch (item.itemType) {
                case enumActionType.link:
                    return <div key={index}><Button color="inherit" startIcon={item.icon} component={Link} to={item.navUrl || ""}>{item.title}</Button></div>;
                case enumActionType.onClick:
                    return <div key={index}><Button color="inherit" startIcon={item.icon} onClick={item.onClick}>{item.title}</Button></div>;
                case enumActionType.search:
                    return <div key={index}><SimpleSearchBox label={item.title} onSearch={this.onCartSearch} startAdornment={item.icon} maxLength={20} /></div>;
                case enumActionType.menu:
                    return <div key={index}>
                        <Button color="inherit" startIcon={item.icon} onClick={e => this.setMenuAnchor(e, item.title)}>{item.title}</Button>
                        <Menu id={"Menu_" + index.toString()}
                            anchorEl={this.state.anchorElement}
                            keepMounted
                            open={Boolean(this.state.activeMenu === item.title)}
                            onClose={this.handleMenuClose}>
                            {this.CreateMenuItems(item.subItems)}
                        </Menu>
                    </div>;
            }
            return "";
        });
    }

    private CreateMenuItems(items?: ToolbarItem[]) {
        if (items) {
            return items.map((item, index) => {
                switch (item.itemType) {
                    case enumActionType.link:
                        return <div key={index}><MenuItem color="inherit" onClick={this.handleMenuClose} component={Link} to={item.navUrl || ""}><ListItemIcon>{item.icon}</ListItemIcon><ListItemText primary={item.title} /></MenuItem></div>;
                    case enumActionType.onClick:
                        return <div key={index}><MenuItem color="inherit" onClick={() => { this.handleMenuClose(); if (!isNullOrUndefined(item.onClick)){ item.onClick(); } }}><ListItemIcon>{item.icon}</ListItemIcon><ListItemText primary={item.title} /></MenuItem></div>;               
                }
                return "";
            });
        }
        else { return "";}
    }

    private notifyUserAboutTokenTimeout() {
        this.props.confirmDialog_Display("Warning: Login Expiration", "-1", "Your login is about to expire! You may 'Proceed' and be prompted to login again or you may 'Cancel' and try to finish your current work before being logged out.", this.redirectToLogin);
    }

    private checkForUnreadNotifcations = () => {
        if (!isNullOrUndefined(GetCompany()) && this.props.userStore.user_isCurrentTermsAccepted) {
            MakeApiCall<ITen99Notification[]>("api/Notifications/?SearchVal=false&PageNumber=1&PageSize=5", "GET")
                .then(data => {
                    if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.length > 0) {
                        let refreshedCustomer: boolean = false;
                        //add messages to toast notificaiton store
                        data.payload.forEach((notification: ITen99Notification) => {
                            //map objects

                            //do we need to refresh current customer
                            if (!refreshedCustomer && !isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) && !isNullOrUndefined(notification.customerId)) {
                                if (notification.notificationTypeId === ITen99NotificationTypeEnum.Import && this.props.homeNavigationStore.activeCustomer.customerId.toString() === notification.customerId.toString()) {
                                    this.props.CustomerSummaryActions_RefreshActiveCustomerSummary();
                                    refreshedCustomer = true;
                                }
                            }
                            if (!refreshedCustomer && notification.notificationTypeId === ITen99NotificationTypeEnum.Submission) {
                                this.props.CustomerSummaryActions_RefreshSummaryCounts();
                                refreshedCustomer = true;
                            }

                            let toastMessageType: ToastType = "info";
                            switch (notification.prioirtyId) {
                                case (ITen99MessagePriorityEnum.Normal):
                                    toastMessageType = "success";
                                    break;
                                case (ITen99MessagePriorityEnum.Urgent):
                                    toastMessageType = "warning";
                                    break;
                                case (ITen99MessagePriorityEnum.Error):
                                    toastMessageType = "error";
                                    break;
                            }

                            //send Toast message
                            const message: ToastNotificationsStore.IToastMessage = { id: "notification" + notification.notificationId.toString(), message: notification.notificationText, type: toastMessageType };
                            this.props.toastNotifcations_Display(message);

                            //mark item as read
                            notification.isRead = true;

                            //update items to note as read
                            MakeApiCall<string>("api/Notifications/" + notification.notificationId, "PUT", JSON.stringify(notification));
                        });


                    }
                })
        }
    }

    // -----------------
	// Componet lifecycle events
	// -----------------
    componentDidMount() {         

        if (!isNullOrUndefined(process.env.REACT_APP_TOKEN_LIFETIME_WARNING)) {
            this.tokenWarningTimeout = setTimeout(() => { this.notifyUserAboutTokenTimeout(); }, Number.parseInt(process.env.REACT_APP_TOKEN_LIFETIME_WARNING)); // notify user before token expires
        }

        if (!isNullOrUndefined(process.env.REACT_APP_NOTIFICATION_CHECK_INTERVAL_MILLISECONDS)) {
            this.getNotificationsInterval = setInterval(() => { this.checkForUnreadNotifcations() }, Number.parseInt(process.env.REACT_APP_NOTIFICATION_CHECK_INTERVAL_MILLISECONDS));
        }
    }

    componentWillUnmount() {
        if (!isNullOrUndefined(this.tokenWarningTimeout)) {
            clearTimeout(this.tokenWarningTimeout);
        }
        if (!isNullOrUndefined(this.getNotificationsInterval)) {
            clearInterval(this.getNotificationsInterval);
        }
    }

    public render() {
        let userName: string = this.props.userStore.user_Name;

        if (isNullOrUndefined(userName) || userName === "") {
            userName = " "; // empty string breaks anchor tags --- line 170
        }
        //Items that will be added to the toolbar - alter this to affect the menu
        let items: ToolbarItem[] = [
            { title: "Home", icon: < HomeOutlined />, itemType: enumActionType.link, navUrl: "/gate/home/customers" },
            {
                title: "Help", icon: < HelpOutlineOutlined />, itemType: enumActionType.menu, subItems:
                    [
                        { title: "FAQ", icon: <QuestionAnswerOutlined />, itemType: enumActionType.link, navUrl: "/gate/faqs" },
                        { title: "User Guide", icon: <PictureAsPdfOutlined />, itemType: enumActionType.onClick, onClick: () => this.onNewWindowOpen("/UserGuide.pdf") },
                        { title: "1099 IRS instructions", icon: <AccountBalanceOutlined />, itemType: enumActionType.onClick, onClick: () => this.onNewWindowOpen("https://www.irs.gov/instructions/i1099mec") },
                        { title: "Technical Assistance", icon: <ContactSupportOutlined />, itemType: enumActionType.onClick, onClick: this.onTechnicalAssistanceClick },
                        { title: "PRICING", icon: <PictureAsPdfOutlined />, itemType: enumActionType.onClick, onClick: () => this.onNewWindowOpen("/PRICING.pdf") },
                    ]
            },
            { title: "Filing Year: " + (this.props.homeNavigationStore.currentFilingYear === 0 ? "" : this.props.homeNavigationStore.currentFilingYear.toString()), icon: < Ten99PrepCalender />, itemType: enumActionType.onClick, onClick: () => this.openDialog(DialogTypesEnum.ChangeFilingYear, "") },
            { title: "Company: " + (isNullOrUndefined(this.props.homeNavigationStore.activeCompany) ? "" : this.props.homeNavigationStore.activeCompany.name), icon: < Ten99Company />, itemType: enumActionType.onClick, onClick: () => this.openDialog(DialogTypesEnum.ChangeCompany, "") },
            {
                title: userName, icon: < AccountBoxOutlined />, itemType: enumActionType.menu, subItems:
                    [
                        { title: "User Profile", icon: < ExitToApp />, itemType: enumActionType.onClick, onClick: () => this.openDialog(DialogTypesEnum.UserProfile, "") },
                        { title: "Notifications (Last 25)", icon: < Announcement />, itemType: enumActionType.onClick, onClick: () => this.openDialog(DialogTypesEnum.Notifications, "") },
                        { title: "Log Out", icon: < ExitToApp />, itemType: enumActionType.onClick, onClick: () => this.onLogout() }
                    ]
            },
            { title: "CART ID SEARCH ", icon: < Ten99PrepCartHistoryIcon />, itemType: enumActionType.search },
        ];

        if (!isNullOrUndefined(this.props.homeNavigationStore.activeCompany) && (this.props.homeNavigationStore.activeCompany.hasAccess) && (this.props.homeNavigationStore.activeCompany.isCompanyOwner || this.props.homeNavigationStore.activeCompany.isUserAdmin)) {
            items.push({ title: "User Management", icon: < Ten99Users />, itemType: enumActionType.onClick, onClick: () => onUserAdminRedirect(UserManagementEndpoint.Main) });
        }

        items.push({ title: "Log Out", icon: < ExitToApp />, itemType: enumActionType.onClick, onClick: () => this.onLogout() });
        const toolbarItems = this.CreateToolbar(items);
        return (
            <React.Fragment>
                <AppBar position="static">
                    <Toolbar sx={{
                        background: `linear-gradient(-80deg, #00A0AF, white)`,
                        display: "flex",
                        color: "primary"
                    }}>
                        <Box sx={{flexGrow: 1}}
                        >
                            <Box
                                component="img"
                                alt="Logo"
                                src="./1099Logo.png"
                                sx={{
                                    flexGrow: 1,
                                    width: "200px",
                                    height: "50px",
                                    marginTop: "1vh",
                                }}
                                />
                        </Box>                        
                        {toolbarItems}
                    </Toolbar>
                </AppBar>

                {this.state.openDialog && !isNullOrUndefined(this.state.openDialogType) && (
                    <Ten99ToolbarDialog onClose={this.onDialogClose} type={this.state.openDialogType} userId={this.props.userStore.user_Id} value={ this.state.openDialogValue } />
                )}
            </React.Fragment>
            )
        }  
}
function mapStateToProps(state: ApplicationState) {

    return { userStore: state.user, homeNavigationStore: state.homeNavigation  }
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(mapStateToProps, // Selects which state properties are merged into the component's props
    { ...Auth_ActionCreators, ...ConfirmDialogStore.actionCreators, ...ToastNotificationsStore.actionCreators, ...HomeNavigationStore.actionCreators, ...UserStore.actionCreators } // Selects which action creators are merged into the component's props
)(withRouter((Ten99Toolbar as any)));