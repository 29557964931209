import { Announcement, ShoppingCartOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Popover, Select, Typography } from "@mui/material";
import ActionButton from "components/buttons/ActionButton";
import ComplexIconButton from "components/buttons/ComplexIconButton";
import Pager from "components/buttons/Pager";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import CartEntitySummary from "components/ten99Prep/Cart/CartEntitySummary";
import * as React from 'react';
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Cart } from "sharedInterfaces/ITen99Cart";
import { ITen99CartCharges } from "sharedInterfaces/ITen99CartCharges";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { ITen99PagerInfo } from "sharedInterfaces/ITen99PagerInfo";
import { Ten99PrepCancelIcon, Ten99PrepNumberedListIcon, Ten99PrepViewDoc } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import CartReports from "./CartReports";

interface ICartProps {
	cart: ITen99Cart
	cartSummary: ITen99EntitySummary,
	cartCharges: ITen99CartCharges,
	pagerInfo: ITen99PagerInfo,
	buttons?: ITen99DynamicButtonProps[],
	onClose: (proceesed: boolean, message: string) => void,
	onActionClick: (entity: ITen99EntitySummary, action: ActionTypesEnum) => void,
	onCartUpdate?: (mailType: number, consent: boolean) => void,
	showReport?: boolean,
	validationMessages?: ITen99ApiResponseMessage[],
	cartBlockingIssues?: string[],
	warnings?: string[],
	mailTypeLookupItems: ITen99LookupItem[],
}
interface ILocalState {
	popoverElementAnchor?: boolean,	
	reportDialogOpen: boolean,
}


type CartProps =
	& ICartProps
	;

const initialLocalState: ILocalState = { popoverElementAnchor: undefined, reportDialogOpen: false};
class CartDisplay extends React.PureComponent<ICartProps, ILocalState> {

	constructor(props: ICartProps) {
		super(props);
		this.state = initialLocalState;
	}
	// -----------------
	//Handle User events
	private onChargesPopoverOpen = (id: string, action: ActionTypesEnum) =>
	{
		this.setState({ popoverElementAnchor: true });
	}

	private onChargesPopoverClose = (event: React.MouseEvent) => {
		this.setState({ popoverElementAnchor: undefined });
	}
	private onReportsOpen = (id: string, action: ActionTypesEnum) => {
		this.setState({ reportDialogOpen: true });
	}
	private onReportsClose = () =>
	{
		this.setState({ reportDialogOpen: false });
	}

	private onPrintOptionChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
		}

		if (this.props.onCartUpdate !== undefined && this.props.onCartUpdate !== null) 
		{
			this.props.onCartUpdate(event.target.value as number, this.props.cart.electronicDeliveryRecipientConsent);
		}
	}

	private onConsentChange = (value: boolean ) => {
		if (this.props.onCartUpdate !== undefined && this.props.onCartUpdate !== null) {
			this.props.onCartUpdate(this.props.cart.mailTypeId, value as boolean);
		}
	}

	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={9} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Cart {this.props.cartSummary.id} {isNullOrUndefined(this.props.cartSummary.status) ? "" : (" - " + this.props.cartSummary.status)} {(!isNullOrUndefined(this.props.cart.submittedDate) && this.props.cart.submittedDate !== "") ? (" - Submitted " + this.props.cart.submittedDate) : ""}
							<InfoToolTip text="File the displayed forms with the IRS" />
							</Typography>
						</Grid>
						<Grid item xs={3} sx={{textAlign: 'right'}}>
							{this.props.pagerInfo.totalCount >= 0 && (
								<React.Fragment>
									<Typography variant="h6">< ShoppingCartOutlined />{this.props.pagerInfo.totalCount} items in cart</Typography>
								</React.Fragment>
							)}
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						{!isNullOrUndefined(this.props.validationMessages) && this.props.validationMessages.length > 0 && (
							<Grid item xs={12}>
								<ApiErrorResponseMessages messages={this.props.validationMessages} />
							</Grid>
						)}
						{!isNullOrUndefined(this.props.cartSummary.nestedEntities) && this.props.cartSummary.nestedEntities.rows.length > 0 && (
							<React.Fragment>
								<Grid item xs={12} sx={{textAlign: 'center'}}>
									<Typography variant="h6" display="inline"><Announcement color="primary" />1099-PREP SUPPORTS STATE SUBMISSION FOR INITIAL FEDERAL FORMS </Typography><Typography variant="h6" sx={{ textDecoration: 'underline' }} display="inline">WHEN THE STATE IS ACTIVATED WITH A STATE ID</Typography><Typography variant="h6" display="inline">, AS INDICATED IN THE STATE SUBMISSION LIST ACCESSED VIA THE HELP MENU.</Typography>

									<Typography variant="body2">Other Federal forms are not submitted to the states for filing, including corrections. Subsequent filings will include state submission of the Federal forms. However, some states do not accept subsequent filings.  Please confirm processing requirements with the applicable state.</Typography>
 
								</Grid>
		
								{!isNullOrUndefined(this.props.cartBlockingIssues) && this.props.cartBlockingIssues.length > 0 && (
									<Grid item xs={12} sx={{
										border: 'solid 1px ',
										borderColor: 'primary.dark',
										marginBottom: "10px",
										textAlign: 'left'
									}}>
										<Typography variant="h6">Validation Issues preventing filing<InfoToolTip text="These issues must be resolved before you can proceed" /></Typography>
									
										<Grid container spacing={1} >
											<Grid item xs={12}>
												<List dense={true}>
													{this.props.cartBlockingIssues.map((item, index) => <ListItem key={index}><ListItemIcon><Announcement color="error" /></ListItemIcon><ListItemText primary={item} primaryTypographyProps={{ color: "error" }} /></ListItem>)}
												</List>
											</Grid>
										</Grid>
									</Grid>
								)}

								{!isNullOrUndefined(this.props.warnings) && this.props.warnings.length > 0 && (
									<Grid item xs={12} sx={{
										border: 'solid 1px ',
										borderColor: 'primary.dark',
										marginBottom: "10px",
										textAlign: 'left'
									}}>
										<Typography variant="h6">Warnings - Review before you proceed <InfoToolTip text="These issues can be ignored but please review to make sure you are not impacted" /></Typography>

										<Grid container spacing={1} >
											<Grid item xs={12}>
												<List dense={true}>
													{this.props.warnings.map((item, index) => <ListItem key={index}><ListItemIcon><Announcement /></ListItemIcon><ListItemText primary={item} /></ListItem>)}
												</List>
											</Grid>
										</Grid>
									</Grid>
								)}

								<Grid item xs={12}>
									{this.props.cartSummary.nestedEntities.rows.map((item, index) => {
										return (<CartEntitySummary key={index} item={item} onActionClick={this.props.onActionClick} />);
									})}

								</Grid>

								<Grid item xs={12} sx={{textAlign: 'right'}}>
									<Pager pagerInfo={this.props.pagerInfo} />
								</Grid>								

								{this.state.reportDialogOpen && (
									<Dialog open={this.state.reportDialogOpen} fullWidth={true} maxWidth="lg">
										<CartReports cartId={this.props.cart.cartId} onClose={this.onReportsClose} />
									</Dialog>
								)}
							</React.Fragment>
						)}
						{(isNullOrUndefined(this.props.cartSummary.nestedEntities) || this.props.cartSummary.nestedEntities.rows.length === 0) && (
							<Grid item xs={12}>
								<Typography variant="body1">Empty Cart</Typography>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						{!isNullOrUndefined(this.props.cartSummary.nestedEntities) && this.props.cartSummary.nestedEntities.rows.length > 0 && (
							<React.Fragment>
								<Grid item xs={9} sx={{ textAlign: 'right' }} />
								<Grid item xs={3} sx={{ textAlign: 'right' }} style={{ "display": "flex", } as React.CSSProperties}>
									<FormControl										
										disabled={isNullOrUndefined(this.props.onCartUpdate)}
										fullWidth={true}
									>
										<InputLabel id="mailTypeLabel">Statements to Recipients</InputLabel>
										<Select
											labelId="mailTypeLabel"
											label="Statements to Recipients"
											value={this.props.cart.mailTypeId}
											onChange={this.onPrintOptionChange}
											inputProps={{}}
											id="mailTypeId"
											name="mailTypeId"
										>
											{!isNullOrUndefined(this.props.mailTypeLookupItems) && this.props.mailTypeLookupItems.length > 0 && (this.props.mailTypeLookupItems.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
										</Select>
									</FormControl>
									<InfoToolTip text="The selected option will apply to all forms in cart. If additional mailing options are needed, submit additional cart(s). If filing with state, we recommend all forms be submitted in a single cart." />
								</Grid>								

								<Grid item xs={6} sx={{ textAlign: 'right' }} />
								<Grid item xs={6} sx={{ textAlign: 'right' }}>
									{this.props.cart.electronicDeliveryRecipientConsentRequired && (
										<>
										<FormControlLabel
											label={<Typography variant="body1">Recipient Consent to Electronic Delivery of Recipient Statements</Typography>}
											control={
												<Checkbox
													color="primary"
													disabled={isNullOrUndefined(this.props.onCartUpdate)}
													onChange={(e) => this.onConsentChange(e.target.checked)}
													checked={this.props.cart.electronicDeliveryRecipientConsent}
													id={"RcpEmailConsent"}
													name="RcpEmailConsent"
												/>}
										/>
										<InfoToolTip text="The recipient must consent in the affirmative to receiving the statement electronically and not have withdrawn the consent before the statement is furnished. Please review current IRS instructions regarding Electronic Delivery of Recipient Statements." />
										</>
									)}
								</Grid>

								<Grid item xs={11} sx={{ textAlign: 'right' }} >
									<Typography variant="h6">Total: ${this.props.cartCharges?.totalAmount?.toFixed(2) ?? "NA"}</Typography>
								</Grid>

								<Grid item xs={1} sx={{ textAlign: 'center' }}>
									<ComplexIconButton itemId="1" dense uiProps={{ action: ActionTypesEnum.View, icon: <Ten99PrepNumberedListIcon />, onClick: this.onChargesPopoverOpen, tooltip: "Detailed Cost List", color: "primary" } as ITen99DynamicButtonProps} />
									<div id="cartPopoverAnchor"></div>

									{(this.props.cartCharges?.charges?.length ?? 0) > 0 && (
										<Popover
											open={!isNullOrUndefined(this.state.popoverElementAnchor)}
											onClose={this.onChargesPopoverClose}
											anchorEl={document.getElementById("cartPopoverAnchor")}
											anchorOrigin={{ vertical: "center", horizontal: "right" }}
										>
											<List>
												{this.props.cartCharges.charges.map((item, index) => {
													return (<ListItem key={index}>
														<Typography variant="body1">{item.chargeQuantity} {item.chargeDescription} : ${item.chargeAmount.toFixed(2)} {item.chargeQuantity > 1 ? "each" : ""}</Typography>
													</ListItem>)
												})}
											</List>
										</Popover>
									)}
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={12} sx={{textAlign: 'right'}}>
							{!isNullOrUndefined(this.props.showReport) && this.props.showReport && (
								<React.Fragment>							
									<ActionButton itemId={"cartAction" + ActionTypesEnum.Reporting} uiProps={{ action: ActionTypesEnum.Reporting, text: "Print", variant: "contained", color: "secondary", icon: <Ten99PrepViewDoc />, tooltip: "Print Forms for Cart", onClick: this.onReportsOpen } as ITen99DynamicButtonProps} />
								</React.Fragment>
							)}
							<Box component="span" sx={{marginLeft: "8px"}}>
								<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => this.props.onClose(false, "")}>Close</Button>
							</Box>
							{!isNullOrUndefined(this.props.buttons) && this.props.buttons.length > 0 && (
								<React.Fragment>
									{this.props.buttons.map((item, index) => {
										return (<Box component="span" sx={{ marginLeft: "8px" }}><ActionButton key={index} itemId={"cartAction" + item.action} uiProps={item} /></Box>);
									})}
								</React.Fragment>
									)}
						</Grid>
					</Grid>					
				</DialogActions>
				
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (CartDisplay);