import { ArrowRight } from "@mui/icons-material";
import { Button, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import * as ConfirmDialog from 'store/ConfirmDialog';
import { Ten99PrepDownloadIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	selectedFormType: string,
	formTypes: ITen99LookupItem[],
}

interface IExportTemplateProps {
	customerId: string,
}

type ExportTemplateProps =
	& IExportTemplateProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
;

const initialLocalState: ILocalState = { status: enumComponentStatus.IsLoading, validationMessages: [] as ITen99ApiResponseMessage[], selectedFormType: "", formTypes: [] as ITen99LookupItem[] };
class Export extends React.PureComponent<ExportTemplateProps, ILocalState> {


	constructor(props: ExportTemplateProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/48?parentId=" + this.props.customerId, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 48);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.setState({ status: enumComponentStatus.PendingAction, formTypes: lookup.lookupItems});
						}
						else if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length === 0){
							this.setState({ status: enumComponentStatus.Invalid, validationMessages: [{ type: "Error", message: "No prior year data to roll over for this customer." }] as ITen99ApiResponseMessage[] });
                        }
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load form types" }] as ITen99ApiResponseMessage[] });
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})
	}
	// -----------------
	//Handle User events
	private onDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
		}
	}

	private getFileName = () => {
		let item: ITen99LookupItem | undefined = this.state.formTypes.find(x => x.id === parseInt(this.state.selectedFormType))

		if (!isNullOrUndefined(item)) {
			return item.name + "_template.xlsx";
		}
		else {
			return "template.xlsx";
        }

    }

	private onDownloadClick = () => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: [] });
		if (this.state.selectedFormType !== "") {
			MakeApiCall<string>("api/Exports/" + this.state.selectedFormType + "/CustomerBackfill/" + this.props.customerId, "GET")
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload)) {
							PromptDownloadFile(data.payload, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", this.getFileName())
							this.setState({ status: enumComponentStatus.PendingAction })
						}
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //error
					}
				})
		}
		else {
			this.setState({ status: enumComponentStatus.Invalid, validationMessages: [{ type: "Error", message: "Form Type is a required field", propertyNames: ["selectedFormType"] }] as ITen99ApiResponseMessage[] }); //error
        }
    }
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {	

		return (
			<React.Fragment>
				{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
					<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
				)}
				{(this.state.status !== enumComponentStatus.IsLoading) && (
					<Grid container spacing={1}>
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<Typography variant="body2">
								Complete the following steps:
							</Typography>
							<List>
								<ListItem><ListItemIcon><ArrowRight color={"primary"} /></ListItemIcon><ListItemText primaryTypographyProps={{ variant: "body2" }}>Select Form Type</ListItemText></ListItem>
								<ListItem><ListItemIcon><ArrowRight color={"primary"} /></ListItemIcon><ListItemText primaryTypographyProps={{ variant: "body2" }}>Click "Download Template" to export prior year data to an Excel file</ListItemText></ListItem>
								<ListItem><ListItemIcon><ArrowRight color={"primary"} /></ListItemIcon><ListItemText primaryTypographyProps={{ variant: "body2" }}>Delete rows in Excel file for the data you choose not to be rolled over</ListItemText></ListItem>
								<ListItem><ListItemIcon><ArrowRight color={"primary"} /></ListItemIcon><ListItemText primaryTypographyProps={{ variant: "body2" }}>Save the file and complete the import process</ListItemText></ListItem>
							</List>
						</Grid>

						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<Grid item xs={12} sx={{ textAlign: 'left' }}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}

						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<FormControl
								fullWidth
								required>
								<InputLabel id="formTypeLabel">Form Type</InputLabel>
								<Select
									labelId="formTypeLabel"
									label="Form Type"
									error={this.state.status === enumComponentStatus.Invalid}
									value={this.state.selectedFormType}
									onChange={this.onDropDownChange}
									inputProps={{}}
									id="selectedFormType"
									name="selectedFormType"
								>
									{(this.state.formTypes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} sx={{ textAlign: 'right' }}>
							<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepDownloadIcon />} onClick={this.onDownloadClick}>Download Template</Button>
						</Grid>

					</Grid>
				)}
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators,}, // Selects which action creators are merged into the component's props
)(Export);