import { Grid } from '@mui/material';
import ActionButton from 'components/buttons/ActionButton';
import SummaryTitle from 'components/ten99Prep/SummaryTitle';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { Ten99PrepFormIcon, Ten99PrepViewDoc } from 'Ten99PrepOverloads/IconOverloads';
import { IRecipientEmailLanding } from './models/IRecipientEmailLanding';

export default function DocumentSummary({ documentDetails, onDocumentSelect }: { documentDetails: IRecipientEmailLanding, onDocumentSelect: (id: string, action: ActionTypesEnum) => void }) {

	return	(
        <>
            <Grid container spacing={1} sx={{
                textAlign: 'center',
                backgroundColor: "form.main",
                marginTop: "10px",
                marginBottom: "10px",
                alignItems: "center",
                paddingLeft: "16px",
                paddingRight: "16px",
                minHeight: "64px"
            }}>
                <Grid item xs={5} sx={{ textAlign: "left", display: "flex" }}>
                    <Ten99PrepFormIcon /><SummaryTitle entity={{
                        id: "document",
                        names: [{ title: "Customer", descr: documentDetails.customerName, displayOrder: 1 }, { title: "Payer", descr: documentDetails.payerName, displayOrder: 2 }, { title: "Form Type", descr: documentDetails.formType, displayOrder: 3 }]
                    } as ITen99EntitySummary} inline={false} />
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "left", display: "flex" }}>
                </Grid>
                <Grid item xs={5} sx={{ textAlign: 'right' }}>
                    <ActionButton itemId={documentDetails.id} uiProps={{ action: ActionTypesEnum.ViewDoc, icon: <Ten99PrepViewDoc />, text: "", color: "secondary", tooltip: "View Doc", onClick:onDocumentSelect } as ITen99DynamicButtonProps} />
                </Grid>
            </Grid>
		</>
	);
}