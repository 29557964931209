import { IMenu, IMenuButton, IMenuSection } from "components/display/PopupMenu/MenuInterfaces";
import PopupMenu from "components/display/PopupMenu/PopupMenu";
import * as React from 'react';
import { UploadType } from "../Components/FileUpload/ImportFileDialog";
import { QBO_STRING } from "../Qbo/QboConstants";

interface IImportFileTypeMenu {
	onSelect: (type: UploadType) => void,
}

function ImportFileTypeMenu(props: IImportFileTypeMenu) {

	let menu: IMenu =
		{
			sections: [
				{
					title: "Select the type of file to import", buttons:
						[
							{ title: "Standard Import File", onClick: () => props.onSelect(UploadType.Import) },
							{ title: "Prior Year File", onClick: () => props.onSelect(UploadType.Historical) },
						] as IMenuButton[]
				},
			] as IMenuSection[]
		};

	if (sessionStorage.getItem("qEnabled") === 'true') {
		menu.sections[0].buttons.push(
			{ title: QBO_STRING + " Import File", onClick: () => props.onSelect(UploadType.QuickBooks) }
		)
	}

	return (
		<PopupMenu menu={menu} />
	)
}

export default ImportFileTypeMenu;