import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponse } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupItem, ITen99PagedLookupData } from 'sharedInterfaces/ITen99LookupData';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon, Ten99PrepSearchIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';

interface ISearchableDropdown {
	label: string,
	required: boolean,
	disabled?: boolean,
	onSearch: (searchValue: string) => Promise<ITen99ApiResponse<ITen99PagedLookupData>> | undefined,
	onSearchValueChange?: (searchVal: string) => void,
	onVerified?: (selectedItem: ITen99LookupItem) => void,
	defaultValue?: ITen99LookupItem
}

type SearchableDropdownProps = ISearchableDropdown
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	;

interface ILocalState {
	processing: boolean,
	searchOpen: boolean,	
	searchResults?: ITen99PagedLookupData,	
	searchTotalCount: number,
	searchValue: string,
	selectedSearchResult?: ITen99LookupItem,
}

const initialLocalState = { processing: false, searchOpen: false, searchResults: undefined, searchTotalCount: 0, searchValue: ""} as ILocalState;
class SearchableDropdown extends React.PureComponent<SearchableDropdownProps, ILocalState> {
	

	constructor(props: SearchableDropdownProps) {
		super(props);
		
		
		if (!isNullOrUndefined(props.defaultValue)) {
			this.state = { processing: false, searchOpen: false, searchResults: undefined, searchTotalCount: 0, searchValue: props.defaultValue.name, selectedSearchResult: props.defaultValue } as ILocalState
		}
		else {
			this.state = initialLocalState;
		}
	}

	private runSearch = () => {
		//turn on processing
		if (this.props.onSearch !== undefined) {
			//run search
			let results: Promise<ITen99ApiResponse<ITen99PagedLookupData>> = this.props.onSearch(this.state.searchValue) as Promise<ITen99ApiResponse<ITen99PagedLookupData>>;
			this.setState({ processing: true, searchResults: { totalCount: 0, numberReturnedCount: 0, lookupItems: [] as ITen99LookupItem[] } as ITen99PagedLookupData });
			if (!isNullOrUndefined(results)) {
				results.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload)) {
							//if more than one value returned - set dropdown and open modal
							if (data.payload.numberReturnedCount > 1) {
								this.setState({ processing: false, searchResults: data.payload, searchOpen: true });
							}
							//if one value returned
							else if (data.payload.numberReturnedCount === 1) {
								this.props.toastNotifcations_Display({ id: "searchResult" + this.state.searchValue, message: "One item found matching search value " + this.state.searchValue + ": " + data.payload.lookupItems[0].description + " found.", type: "success" });

								this.setState({ processing: false, selectedSearchResult: data.payload.lookupItems[0], searchValue: data.payload.lookupItems[0].name, searchOpen: false });
								if (!isNullOrUndefined(this.props.onVerified)) {
									this.props.onVerified(data.payload.lookupItems[0]);
								}
							}
							else {
								this.setState({
									processing: false
								});
								this.props.toastNotifcations_Display({
									id: "searchResult" + this.state.searchValue, message: "No items found for search value " + this.state.searchValue + ". Please alter filter and try again.", type: "warning"
								});
							}
						}
					}
					else {
						this.props.toastNotifcations_Display({ id: "searchResult" + this.state.searchValue, message: "Error occured getting search results.", type: "error" });
					}
				});
			}
			else {
				this.props.toastNotifcations_Display({ id: "searchResult" + this.state.searchValue, message: "Error occured. Search not set.", type: "error" });
			}
		}
	};

	private onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (!isNullOrUndefined(this.state.selectedSearchResult)) { //they are altering, clear any selected items
			this.setState({ selectedSearchResult: undefined, searchValue: event.target.value });
		}
		else {
			this.setState({ searchValue: event.target.value });
		}			
		
		if (!isNullOrUndefined(this.props.onSearchValueChange)) {
			this.props.onSearchValueChange(event.target.value);
		}
	};

	private onResultSelectedChange = (value: string) => {
		if (!isNullOrUndefined(this.state.searchResults)) {
			let temp: ITen99LookupItem | undefined = this.state.searchResults.lookupItems.find(x => x.id === Number.parseInt(isNullOrUndefined(value) ? "0" : value));
			if (!isNullOrUndefined(temp)) {
				this.setState({ selectedSearchResult: temp});
			}
		}
	};

	private onSubmit = () => {
		if (!isNullOrUndefined(this.state.selectedSearchResult)) {
			if (!isNullOrUndefined(this.props.onVerified)) {
				this.props.onVerified(this.state.selectedSearchResult);
			}
			this.setState({ searchValue: this.state.selectedSearchResult.name, searchOpen: false });
		}
	};

	private onCancel = () => { this.setState({ searchOpen: false }); };
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		
		return (
			<React.Fragment>
				<Grid container spacing={1} sx={{ textAlign: 'center', alignItems: 'center'}}>
					
					<Grid item xs={10}>
						<TextField
							disabled={this.props.disabled}
							onChange={this.onSearchValueChange}
							value={this.state.searchValue}
							variant="outlined"							
							id="searchStringTxt"
							label={this.props.label}
							name="searchStringTxt"
							required={this.props.required}
							helperText={"Selected " + this.props.label + ": " + (isNullOrUndefined(this.state.selectedSearchResult) ? "(none)" : this.state.selectedSearchResult.description)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button type="button" disabled={this.props.disabled} startIcon={< Ten99PrepSearchIcon />} variant="contained" color="secondary" onClick={this.runSearch}>Search / Verify</Button>
					</Grid>				
				</Grid>

				{this.state.searchOpen && !isNullOrUndefined(this.state.searchResults) && (
					<Dialog open={this.state.searchOpen} fullWidth={true} maxWidth="lg">
						<DialogTitle>
							<Typography variant="h5" >{"Multiple items returned for search. Please select one or refine your search. "}</Typography>
						</DialogTitle>
						<DialogContent>
							<Grid container spacing={1} sx={{textAlign: 'center'}}>
								<Grid item xs={10}>
									<TextField
										disabled={this.props.disabled}
										onChange={this.onSearchValueChange}
										value={this.state.searchValue}
										variant="outlined"
										id="searchStringTxt"
										label={this.props.label}
										name="searchStringTxt"
										required={this.props.required}
										fullWidth
									/>
								</Grid>
								<Grid item xs={2}>
									<Button type="button" disabled={this.props.disabled} startIcon={< Ten99PrepSearchIcon />} variant="contained" color="secondary" onClick={this.runSearch}>Search</Button>
								</Grid>			

								<Grid item xs={6}>
									<FormControl
										fullWidth
										required>
										<InputLabel id="resultsLabel">{this.props.label}</InputLabel>
										<Select
											disabled={this.props.disabled}
											labelId="resultsLabel"
											label={this.props.label}
											value={this.state.selectedSearchResult}
											onChange={(e) => this.onResultSelectedChange(e.target.value as string)}
											inputProps={{}}
											id="selectedCustomerId"
											name="selectedCustomerId"
										>
											{this.state.searchResults.lookupItems.length > 0 && (
												 this.state.searchResults.lookupItems.map((item, index) => <MenuItem key={index} value={item.id}>{item.description}</MenuItem>)
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									{(this.state.processing) && (
										<ProcessingModal modal={false} size={40} message={""} />
									)}
									{(!this.state.processing) && (
										<Typography variant="body1">Showing {this.state.searchResults.numberReturnedCount.toString()} of {this.state.searchResults.totalCount.toString()} results. Refine your search above to narrow the result set. </Typography>
									)}
								</Grid>
							</Grid>							
						</DialogContent>	
						<DialogActions>
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Cancel</Button>
							<Button type="button" variant="contained" color="primary" startIcon={< Ten99PrepSaveIcon />} onClick={this.onSubmit}>Save</Button>
						</DialogActions>
					</Dialog>
				)}
				
				
			</React.Fragment>
			);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ToastNotifications.actionCreators } // Selects which action creators are merged into the component's props
)(SearchableDropdown);
