import { IMenu, IMenuButton, IMenuSection } from "components/display/PopupMenu/MenuInterfaces";
import PopupMenu from "components/display/PopupMenu/PopupMenu";
import * as React from 'react';
import { QBO_STRING } from "./Qbo/QboConstants";

interface IImportMenuProps {
	onSelect: (name: "Home" | "ImportFromFile" | "ImportTemplates" | "ImportHistory" | "QboImport" | "QboPreview" | "RollOver") => void,
}

function ImportMenu(props: IImportMenuProps) {

	let menu: IMenu =
		{
			sections: [
				{
					title: "Import to Current Year", buttons:
						[
							{ title: "Download Import Templates", onClick: () => props.onSelect("ImportTemplates") },
							{ title: "Import from file", onClick: () => props.onSelect("ImportFromFile") },
						] as IMenuButton[]
				},
				{
					title: "Rollover Prior Year Data", buttons: [
						{ title: "Rollover Prior Year Data", onClick: () => props.onSelect("RollOver") },
					] as IMenuButton[]
				},
				{
					title: "Import History", buttons: [
						{ title: "View Import History", onClick: () => props.onSelect("ImportHistory") },
					] as IMenuButton[]
				},
			] as IMenuSection[]
		};

	if (sessionStorage.getItem("qEnabled") === 'true') {
		menu.sections[0].buttons.push(
			{ title: "Import from " + QBO_STRING, onClick: () => props.onSelect("QboImport") },
			{ title: "Preview " + QBO_STRING + " Exports", onClick: () => props.onSelect("QboPreview") },
		)
    }

	return (
		<PopupMenu menu={menu} />
	)
}

export default ImportMenu;