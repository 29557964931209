import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import { DynamicInputGrid } from 'components/ten99Prep/DynamicInputGrid/DynamicInputGrid';
import { EntityNav } from 'components/ten99Prep/EntityAddEditView/EntityNav';
import PayerStateAccountsDashboard from 'components/ten99Prep/PayerStateAccounts/PayerStateAccountsDashboard';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { enumAddEditViewAction, enumEntityType, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { IFormElement } from 'sharedInterfaces/ITen99FormElement';
import { IFormElementTemplate } from 'sharedInterfaces/ITen99FormElementTemplate';
import { ITen99LookupData, ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { ApplicationState } from 'store';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { Ten99PrepCancelIcon, Ten99PrepFormIcon, Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';
import EntityAddEditView from './EntityAddEditView';

enum enumComponentStatus {
	PendingFormType,
	PendingFormTypeInvalid,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface IForm {
	formTypeId: number,
	formTypeName: string,

	//fields
	formElementValues: IFormElement[],

	filingYearId: number,
	ignoreValidationWarnings: number,
	ticks: string,
}

interface IAddEditViewFormExternalProps {
	onClose: (response: IAevEntityResponse) => void;
	entitySettings: IAevEntitySetting;
}
type AddEditProps =
	IAddEditViewFormExternalProps
	& HomeNavigationStore.CustomerSummaryState
	;

interface ILocalState {
	status: enumComponentStatus,
	formType: string,
	form: IForm,
	formTemplate: IFormElementTemplate[],
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
	dialogOpen: boolean,
	dialogType?: EnumEntityType,
	dialogEntity?: ITen99EntitySummary,
}

const emptyForm = {
	formTypeId: 0, formTypeName: "", formElementValues: {} as IFormElement[], filingYearId: 0, ticks: "", ignoreValidationWarnings: 0} as IForm;

const initialLocalState = {
	status: enumComponentStatus.Processing, formType: "", form: emptyForm, validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: {} as string[], dialogOpen: false, dialogType: undefined, dialogEntity: undefined } as ILocalState;
class AddEditViewForm extends React.PureComponent<AddEditProps, ILocalState> {

	private payer: ITen99EntitySummary | undefined = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Payer);
	private formTypes: ITen99LookupItem[] = {} as ITen99LookupItem[];
	private formApiUrl: string = (this.props.entitySettings.type === enumEntityType.Form ? "api/RecipientForms/" : "api/PayerForms/");
	private formTypeLookupApiUrl: string = (this.props.entitySettings.type === enumEntityType.Form ? "api/Common/Lookup/8/?parentId=1" : "api/PayerForms/Lookup/" + this.payer?.id + (this.props.entitySettings.action !== enumAddEditViewAction.Add ? "?includeInactive=true" : "" ));
	private recipient: ITen99EntitySummary | undefined = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Recipient)
	private formSubmitQueryString = (this.props.entitySettings.type === enumEntityType.Form ? "recipientId=" + (isNullOrUndefined(this.recipient) ? "" : this.recipient.id) : "payerId=" + this.payer?.id);
	

	//local state

	

	constructor(props: AddEditProps) {
		super(props);

		let tempState = initialLocalState;
		tempState.form.filingYearId = props.currentFilingYear;

		this.state = tempState;
		MakeApiCall<ITen99LookupData[]>(this.formTypeLookupApiUrl, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 8);
						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.formTypes = lookup.lookupItems;

							if (this.props.entitySettings.action === enumAddEditViewAction.Add) {
								this.setState({ status: enumComponentStatus.PendingFormType, validationMessages: {} as ITen99ApiResponseMessage[] });
							}
							else {
								MakeApiCall<IForm>(this.formApiUrl + this.props.entitySettings.id, "GET")
									.then(data => {
										if (data.isSuccess && !isNullOrUndefined(data.payload)) {
											let formTypeId: number = data.payload.formTypeId;
											let tempForm: IForm = data.payload;
											let index: number = this.formTypes.findIndex(x => x.id === formTypeId);
											if (index >= 0) {
												tempForm.formTypeName = this.formTypes[index].name;
											}
											this.setState({ form: tempForm, formType: formTypeId.toString() })
											this.loadTemplate(data.payload.formTypeId);
										}
										else {
											this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the form" }] as ITen99ApiResponseMessage[] });
										}
									})
							}
						}
						else {
							if (this.props.entitySettings.type === enumEntityType.PayerForm) {
								this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "No forms were found for this payer that require a State Reconciliation Form. Verify the forms have valid state witholding if you think this is in error." }] as ITen99ApiResponseMessage[] });
							}
							else {
								this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load form types" }] as ITen99ApiResponseMessage[] });
							}
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})
	}
    

	private getTitle(action: enumAddEditViewAction) {
		switch (action) {
			case enumAddEditViewAction.Add:
				return "Add New Form:" + (isNullOrUndefined(this.state.form.formTypeName) ? "" : this.state.form.formTypeName);;
			case enumAddEditViewAction.Edit:
				return "Edit Form: " + (isNullOrUndefined(this.state.form.formTypeName) ? "" : this.state.form.formTypeName);
			case enumAddEditViewAction.View:
				return "Form: " + (isNullOrUndefined(this.state.form.formTypeName) ? "" : this.state.form.formTypeName);
        }
	}

	// -----------------
	//Handle User events
	private loadTemplate(formType: number) {
		//derive form type name 
		const payer: ITen99EntitySummary | undefined = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Payer);
		if (!isNullOrUndefined(payer)) {
			//const formTypeLookup: ITen99LookupItem | undefined = this.formTypes.find((x) => x.id === Number.parseInt(formType))
			if (!isNullOrUndefined(formType)) {
				
				const recipient: ITen99EntitySummary | undefined = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Recipient);

				var url: string = "api/Forms/" + formType.toString() + "/template?payerId=" + payer.id;
				if (!isNullOrUndefined(recipient)) {
					url = url + "&recipientId=" + recipient.id;
				}
				url = url + "&isAdd=" + (this.props.entitySettings.action === enumAddEditViewAction.Add ? "1" : "0");
				//load form definition
				MakeApiCall<IFormElementTemplate[]>(url, "GET")
					.then(data => {
						if (data.isSuccess) {
							if (!isNullOrUndefined(data.payload)) {
								//create form elements in state for all items that are not loaded as part fo the form (new forms or forms with new fields)
								let elements: IFormElement[];
								if (isNullOrUndefined(this.state.form.formElementValues.length) || this.state.form.formElementValues.length === 0) {
									elements = [] as IFormElement[];
								}
								else {
									elements = [...this.state.form.formElementValues];
								}
								data.payload.forEach((item) => {
									if (elements.findIndex(x => x.formElementId === item.formElementId) === -1) {
										elements.push({ formElementValueId: -1, formElementId: item.formElementId, elementValue: "" } as IFormElement)
										console.log("Added missing element value: " + item.elementName);
									}
								});

								this.setState({ formTemplate: data.payload, form: { ...this.state.form, formTypeId: formType, formElementValues: elements, ignoreValidationWarnings: 0 } });

								//get all dropdowns needed
								//get special dropdowns
								if (data.payload.findIndex(x => x.formElementSourceId === 12) !== -1 && !isNullOrUndefined(this.payer)) {
									MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/12/?parentId=" + this.payer.id, "GET")
										.then(lookupData => {
											if (data.isSuccess) {
												if (!isNullOrUndefined(lookupData.payload)) {//map each lookup to the templates requiring them

													//create copy
													let tempItems: IFormElementTemplate[] = [...this.state.formTemplate];

													//loop through the lookups and populate any dorpdowns that match the type
													lookupData.payload.forEach((value) => {
														const elements: IFormElementTemplate[] = this.state.formTemplate.filter(x => x.formElementSourceId === value.options.requestedTable)
														elements.forEach(item => {
															//get index
															const templateIndex: number = this.state.formTemplate.indexOf(item);
															//populate item
															tempItems[templateIndex] = { ...tempItems[templateIndex], source: value.lookupItems };
														});
													})

													this.setState({ status: enumComponentStatus.PendingAction, formTemplate: tempItems });
												}
											}
											else {
												this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
											}
										})
								}

								if (data.payload.findIndex(x => x.formElementSourceId === 46) !== -1 && !isNullOrUndefined(this.payer)) {
									MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/46/?parentId=" + this.payer.id, "GET")
										.then(lookupData => {
											if (data.isSuccess) {
												if (!isNullOrUndefined(lookupData.payload)) {//map each lookup to the templates requiring them

													//create copy
													let tempItems: IFormElementTemplate[] = [...this.state.formTemplate];

													//loop through the lookups and populate any dorpdowns that match the type
													lookupData.payload.forEach((value) => {
														const elements: IFormElementTemplate[] = this.state.formTemplate.filter(x => x.formElementSourceId === value.options.requestedTable)
														elements.forEach(item => {
															//get index
															const templateIndex: number = this.state.formTemplate.indexOf(item);
															//populate item
															tempItems[templateIndex] = { ...tempItems[templateIndex], source: value.lookupItems };
														});
													})

													this.setState({ status: enumComponentStatus.PendingAction, formTemplate: tempItems });
												}
											}
											else {
												this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
											}
										})
								}
								//get all non special dropdowns	
								const datasourceIds: string[] = data.payload.filter(x => !isNullOrUndefined(x.formElementSourceId) && x.formElementSourceId !== 12 && x.formElementSourceId !== 46).map((value) => isNullOrUndefined(value.formElementSourceId) ? "" : value.formElementSourceId.toString());
								const uniqueDatasourceIdsString: string = Array.from(new Set(datasourceIds)).join("&lookupTableIds=");

								if (!isNullOrUndefined(uniqueDatasourceIdsString) && uniqueDatasourceIdsString !== "") { //if there is datasource we need to pull

									MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=" + uniqueDatasourceIdsString, "GET")
										.then(lookupData => {
											if (data.isSuccess) {
												if (!isNullOrUndefined(lookupData.payload)) {//map each lookup to the templates requiring them

													//create copy
													let tempItems: IFormElementTemplate[] = [...this.state.formTemplate];

													//loop through the lookups and populate any dorpdowns that match the type
													lookupData.payload.forEach((value) => {
														const elements: IFormElementTemplate[] = this.state.formTemplate.filter(x => x.formElementSourceId === value.options.requestedTable)
														elements.forEach(item => {
															//get index
															const templateIndex: number = this.state.formTemplate.indexOf(item);
															//populate item
															tempItems[templateIndex] = { ...tempItems[templateIndex], source: value.lookupItems };
														});
													})

													this.setState({ status: enumComponentStatus.PendingAction, formTemplate: tempItems });
												}
											}
											else {
												this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
											}
										})
								}
								else {
									//set state
									this.setState({ status: enumComponentStatus.PendingAction });
								}
							}
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
						}
					})
			}
			else {
				this.setState({ status: enumComponentStatus.PendingFormTypeInvalid, validationMessages: [{ type: "Error", message: "You must select a valid Form Type", propertyNames: ["formType"] as string[] }] as ITen99ApiResponseMessage[] }); //unable to load lookup, we need to stop
			}
		}
		else {
			this.setState({ status: enumComponentStatus.PendingFormTypeInvalid, validationMessages: [{ type: "Error", message: "A valid payer must be selected.", propertyNames: ["formType"] as string[] }] as ITen99ApiResponseMessage[] }); //unable to load lookup, we need to stop
		}
	}
	private onFormTypeSelect = () => {
		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing) {
			
			let formTypeId: number = Number.parseInt(this.state.formType);
			let tempForm: IForm = { formTypeId: Number.parseInt(this.state.formType), formElementValues :[] as IFormElement[] } as IForm;
			let index: number = this.formTypes.findIndex(x => x.id === formTypeId);
			if (index >= 0) {
				tempForm.formTypeName = this.formTypes[index].name;
			}
			this.setState({ status: enumComponentStatus.Processing, form: tempForm, validationMessages: {} as ITen99ApiResponseMessage[] });
			this.loadTemplate(formTypeId);
		}
	}
	
	private onSubmit = () => {
		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing) {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
			//call the api and set the results in the state to reflect on the render
			let url: string = "";
			let httpAction: string = "";
			let responseMessage: string = "";
			switch (this.props.entitySettings.action) {
				case enumAddEditViewAction.Add:
					url = this.formApiUrl + "?" + this.formSubmitQueryString;
					httpAction = "POST";
					responseMessage = "Form " + this.state.form.formTypeName + " added";
					break;
				case enumAddEditViewAction.Edit:
					url = this.formApiUrl + this.props.entitySettings.id;
					httpAction = "PUT";
					responseMessage = "Form " + this.state.form.formTypeName + " updated";
					break;
			}
			MakeApiCall<string>(url, httpAction, JSON.stringify(this.state.form))
				.then(data => {
					if (data.isSuccess) {
						this.props.onClose({ processed: true, message: responseMessage } as IAevEntityResponse);
					}
					else {
						if (data.httpStatusCode === 422) {
							this.setState({ status: enumComponentStatus.Invalid, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
						}
						else if (data.httpStatusCode === 409)
						{
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Form has been updated by another process. Please close and reload." }] as ITen99ApiResponseMessage[] });
                        }
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
						}
					}						
				});
		}
    }

	private onCancel = () =>
	{
		this.props.onClose({ processed: false, message: "" } as IAevEntityResponse);
	}

	private processLinkedEntityClick = (id: string, action: ActionTypesEnum) => {
		let entity: ITen99EntitySummary | undefined;
		
		if (action === ActionTypesEnum.StateAccounts) {
			entity = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Payer)

			if (!isNullOrUndefined(entity)) {
				this.setState({ dialogOpen: true, dialogType: EnumEntityType.StateAccount, dialogEntity: entity });
			}
		}
		else {
			entity = this.props.entitySettings.parents.find(x => x.type.toString() === id);

			if (!isNullOrUndefined(entity)) {
				this.setState({ dialogOpen: true, dialogType: entity.type, dialogEntity: entity });
			}
		}
	}

	private onCloseDialog = () => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[], dialogOpen: false, dialogType: undefined, dialogEntity: undefined });
		this.loadTemplate(Number.parseInt(this.state.formType));
	}
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleFormChange = (name: string, value: string) => {
		const stateIndex = this.state.form.formElementValues.findIndex((x) => x.formElementId === Number.parseInt(name));
		let tempItems =  [...this.state.form.formElementValues];
		tempItems[stateIndex] = { ...tempItems[stateIndex], elementValue: value };
		this.setState({ form: { ...this.state.form, formElementValues: tempItems } } as Pick<ILocalState, keyof ILocalState>);
	}

	private handleDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({[event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
		}
	}

	private onSubmitIgnoreWarnings = () => {
		this.setState({
			form: { ...this.state.form, ignoreValidationWarnings: 1 }
		}, this.onSubmit);
	}
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		
		return (			
			<React.Fragment>
				<DialogTitle>
					<Typography variant="h5" >{this.getTitle(this.props.entitySettings.action)}</Typography>
					<EntityNav items={this.props.entitySettings.parents} />
				</DialogTitle>
				<DialogContent style={{ padding: 20 }}>					
					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
						<ApiErrorResponseMessages messages={this.state.validationMessages} onIgnoreWarnings={this.onSubmitIgnoreWarnings} />
					)}
					<Grid container spacing={3} sx={{textAlign: 'center'}}>
						{(this.state.status === enumComponentStatus.Processing ) && (
							<Grid item xs={12}>
								<div>
									<CircularProgress size={90} />
									<div>Processing...</div>
								</div>
							</Grid>
						)}
						{(this.state.status === enumComponentStatus.PendingFormType || this.state.status === enumComponentStatus.PendingFormTypeInvalid) && (
							<React.Fragment>
								<Grid item xs={12}>
									<FormControl
										error={this.state.status === enumComponentStatus.PendingFormTypeInvalid}
										fullWidth
										required>
										<InputLabel id="formTypeLabel">Form Type</InputLabel>
										<Select
											labelId="formTypeLabel"
											label="Form Type"
											value={this.state.formType}
											onChange={this.handleDropDownChange}
											inputProps={{}}
											id="formType"
											name="formType"
										>
											{(this.formTypes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
										</Select>
									</FormControl>
								</Grid>								
							</React.Fragment>
						)}
						{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Invalid) && !isNullOrUndefined(this.state.formTemplate) && this.state.formTemplate.length > 0 && (
							<DynamicInputGrid invalidState={this.state.status === enumComponentStatus.Invalid}
								disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
								onchange={this.handleFormChange}
								templates={this.state.formTemplate}
								formElements={this.state.form.formElementValues}
								validationMessages={this.state.validationMessages}
								invalidProperties={this.state.invalidProperties }
								onActionClick={this.processLinkedEntityClick}
							/>							
						)}		
					</Grid>
					</DialogContent>
					<DialogActions>
					{(this.state.status === enumComponentStatus.PendingFormType || this.state.status === enumComponentStatus.PendingFormTypeInvalid) &&
						(this.props.entitySettings.action === enumAddEditViewAction.Add || this.props.entitySettings.action === enumAddEditViewAction.Edit)
						&& (
							<React.Fragment>
								<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
								<Button type="button" startIcon={< Ten99PrepFormIcon />} variant="contained" color="primary" onClick={this.onFormTypeSelect}>Select Form Type</Button>
							</React.Fragment>
						)}
						{(this.state.status === enumComponentStatus.Invalid || this.state.status === enumComponentStatus.PendingAction) &&
							(this.props.entitySettings.action === enumAddEditViewAction.Add || this.props.entitySettings.action === enumAddEditViewAction.Edit)
							&& (
								<React.Fragment>
									<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
									<Button type="button" startIcon={< Ten99PrepSaveIcon />} variant="contained" color="primary" onClick={this.onSubmit}>Save</Button>
								</React.Fragment>
							)}
						{(this.state.status === enumComponentStatus.Error || this.props.entitySettings.action === enumAddEditViewAction.View) && (
							<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="primary" onClick={this.onCancel}>Close</Button>
						)}
				</DialogActions>
				{this.state.dialogOpen && !isNullOrUndefined(this.state.dialogType) && !isNullOrUndefined(this.state.dialogEntity) && (
					<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth="lg">
						{this.state.dialogType === EnumEntityType.StateAccount && (
							<PayerStateAccountsDashboard payer={this.state.dialogEntity} onClose={this.onCloseDialog} addOnly={true} />
						)}
						{this.state.dialogType === EnumEntityType.Payer && (
							<EntityAddEditView onClose={this.onCloseDialog} entitySettings={{ id: this.state.dialogEntity.id, parents: this.props.entitySettings.parents, action: enumAddEditViewAction.Edit, type: enumEntityType.Payer } as IAevEntitySetting} />
						)}
						{this.state.dialogType === EnumEntityType.Recipient && (
							<EntityAddEditView onClose={this.onCloseDialog} entitySettings={{ id: this.state.dialogEntity.id, parents: this.props.entitySettings.parents, action: enumAddEditViewAction.Edit, type: enumEntityType.Recipient } as IAevEntitySetting} />
						)}
					</Dialog>
				)}
			</React.Fragment>
		);
	}
}

export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{}
)(AddEditViewForm);