import { Box, Grid, Paper, Typography } from '@mui/material';
import DocumentRetrievalUser2FA from 'components/documentRetrieval/user/DocumentRetrievalUser2FA';
import DocumentRetrievalUserLogin from 'components/documentRetrieval/user/DocumentRetrievalUserLogin';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import DynamicNotifications from 'components/ten99Prep/DynamicNotifications';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import * as ToastNotificationsStore from 'store/ToastNotifications';
import * as UserStore from 'store/User';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { SetUser } from 'utilities/UserId';
import { IDocumentRetrievalCreds } from './models/IDocumentRetrievalCreds';

export default function DocumentRetrievalUserGateway() {
	enum enumComponentStatus {
		Initializing,
		Login_User,
		Login_Verifying,
		TwoFactor_User,
		TwoFactor_Verifying,
		Error,
		Authenticaed
	};

	const processingStatuses = new Set([enumComponentStatus.Initializing,
		enumComponentStatus.Login_Verifying,
		enumComponentStatus.TwoFactor_Verifying,
	])

	const dispatch = useDispatch();
	const [email, setEmail] = useState<string>("");
	const [formGuid, setFormGuid] = useState<string>("");
	const [loginGuid, setLoginGuid] = useState<string>("");
	const [status, setStatus] = useState<enumComponentStatus>(enumComponentStatus.Initializing);
	const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([] as ITen99ApiResponseMessage[]);


	useEffect(() => { 
		if (status === enumComponentStatus.Initializing) {
			let params = new URLSearchParams(window.location.search);
			let tempFormGuid = params.get("formGuid");
			if (tempFormGuid === null || tempFormGuid === "") {
				setStatus(enumComponentStatus.Error);
				setValidationMessages([{ type: "Error", message: "No document has been specifed. Please open the link sent to you via email." }] as ITen99ApiResponseMessage[]);
			}
			else
			{
				setStatus(enumComponentStatus.Login_User);
				setFormGuid(tempFormGuid);
			}
		}
		//console.log("render: status = " + status + ", isProcessing = " + processingStatuses.has(status));
	})

	function Login(creds: IDocumentRetrievalCreds) {
		setStatus(enumComponentStatus.Login_Verifying);
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		MakeApiCall<string>("api/RecipientGateway/Login/", "POST", JSON.stringify({ EmailGuid: formGuid, EmailAddress: creds.userName, RecipientLastFourSsn: creds.password }))
			.then(data => {
				if (data.isSuccess) {
					setStatus(enumComponentStatus.TwoFactor_User);
					setEmail(creds.userName);
					setLoginGuid(data.payload ?? "");
				}
				else if ((data.httpStatusCode === 401) || (data.httpStatusCode === 403)) {
					setValidationMessages([{ type: "Error", message: "Invalid Credentials" }] as ITen99ApiResponseMessage[]);
					setStatus(enumComponentStatus.Login_User);
				}
				else {
					setStatus(enumComponentStatus.Error);
					setValidationMessages([{ type: "Error", message: "Unexpected response.Please try again.Contact support if issue persists." }] as ITen99ApiResponseMessage[]);
				}
			});
	}

	function Cancel2FA() {
		setLoginGuid("");
		setStatus(enumComponentStatus.Login_User);
	}

	function Submit2FA(code: string) {
		setStatus(enumComponentStatus.TwoFactor_Verifying);
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		MakeApiCall<string>("api/RecipientGateway/Login/", "PUT", JSON.stringify({ EmailGuid: formGuid, LoginGuid: loginGuid, TwoFactorCode: code }))
			.then(data => {
				if (data.isSuccess) {
					SetUser(data.payload ?? "");
					dispatch(UserStore.actionCreators.UserActions_LoadUser(email, email, false, false));
					setStatus(enumComponentStatus.Authenticaed);
				}
				else {
					setValidationMessages(data.details as ITen99ApiResponseMessage[]);
					setStatus(enumComponentStatus.TwoFactor_User);
				}
			});
	}

	function Resend2FA() {
		setStatus(enumComponentStatus.TwoFactor_Verifying);
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		MakeApiCall<boolean>("api/RecipientGateway/Code/", "POST", JSON.stringify({ EmailGuid: formGuid, LoginGuid: loginGuid }))
			.then(data => {
				if (data.isSuccess) {
					//send Toast message
					dispatch(ToastNotificationsStore.actionCreators.toastNotifcations_Display({ id: "dialog", message: "Securtiy code resent.", type: "success" }))
					setStatus(enumComponentStatus.TwoFactor_User);
				}
				else {
					setValidationMessages(data.details as ITen99ApiResponseMessage[]);
					setLoginGuid("");
					setStatus(enumComponentStatus.Login_User);				}
			});
	}


	return (
		<>		
			{status === enumComponentStatus.Authenticaed && (
				<Redirect to={"/documentRetrievalGate/dashboard?formGuid=" + formGuid } />
			)}
			{status !== enumComponentStatus.Authenticaed && (
				<Box sx={{
					backgroundImage: "linear-gradient(to bottom right, white, rgb(0, 112, 122))",
					backgroundColor: "white",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					//height: "100vh",
					minHeight: "700px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					overflowY: "auto"
				}}>
					<Grid container sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Box
								component="img"
								alt="Logo"
								src="./1099Logo.png"
								sx={{
								width: "300px",
								marginTop: "1vh",
								}}
							/>
						</Grid>
						<Grid item xs={3}>
						</Grid>
						<Grid item xs={6} sx={{textAlign: 'center'}}>				
							<Paper elevation={3} variant="outlined" sx={{
								width: "55vw",
								height: "50vh",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								minHeight: "600px",
								minWidth: "600px",
								boxShadow: "0px 5px 20px #00000029",
								marginBottom: "8vh",
								borderRadius: "0px",
								overflowY: "auto"
							}}>
								<Grid container sx={{textAlign: 'center'}}>
									<Grid item xs={3}>
									</Grid>
									<Grid item xs={6}>
										<Grid container sx={{ textAlign: 'center' }}>

											<Grid item xs={12}>
												<Typography variant="h4">
													Sign In
												</Typography>
												<br />
											</Grid>

											<Grid item xs={12}>
												{(status === enumComponentStatus.Login_User || status === enumComponentStatus.Login_Verifying) && (
													<DocumentRetrievalUserLogin submitFunction={Login} />// disabled={status === enumComponentStatus.Error} />
												)}
												{(status === enumComponentStatus.TwoFactor_User || status === enumComponentStatus.TwoFactor_Verifying) && (
													<DocumentRetrievalUser2FA submitFunction={Submit2FA} resendFunction={Resend2FA} cancelFunction={Cancel2FA} />
												)}
											</Grid>	

											<Grid item xs={12}>
												<DynamicNotifications />

												{validationMessages !== undefined && validationMessages.length > 0 && (
													<ApiErrorResponseMessages messages={validationMessages} />
												)}

												{processingStatuses.has(status) && (
													<ProcessingModal modal={true} />
												)}
											</Grid>	
										</Grid>
									</Grid>
								</Grid>							
							</Paper>
						</Grid>
					</Grid>
					</Box>
			)}
		</>
	);
}