import { ITen99Dashboard } from "sharedInterfaces/ITen99ApiResponse";
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99AlertSummary } from "sharedInterfaces/ITen99Alert";
import { isNullOrUndefined } from "util";

export interface ITen99EntitySummary {
    id: string,
    type: EnumEntityType,
    name: string,
    names: ITen99EntitySummaryNameObject[],
    status: string,
    summaryText: string,
    nestedEntities?: ITen99Dashboard<ITen99EntitySummary>,
    actions: ActionTypesEnum[],
    ticks: string,
    alertsInfo: ITen99AlertSummary,
}

export interface ITen99EntitySummaryNameObject {
    title: string,
    descr: string,
    displayOrder: number,
}

export enum EnumEntityType {
    QboMapping = -1,
    RecipientForm = 1,
    Payer = 2,
    PayerWithholdingAgent = -2,
    Recipient = 3,
    CartForm = 5,
    Cart = 6,
    Customer = 7,
    PayerForm = 8,
    CartHistory = 9,
    CartElementHistory = 10,
    FormHistory = 11,
    StateAccount = 12
}


export function GetEnumEntityTypeLabel(type: EnumEntityType) {
    switch (type) {
        case (EnumEntityType.Customer):
            return "Customer";
        case (EnumEntityType.Payer):
            return "Payer";
        case (EnumEntityType.Recipient):
            return "Recipient";
        case (EnumEntityType.RecipientForm):
        case (EnumEntityType.PayerForm):
            return "Form";
        default:
            return "";
    }
}
