import { ShoppingCartOutlined } from "@mui/icons-material";
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, Typography } from "@mui/material";
import Pager from "components/buttons/Pager";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import HistoricalCart from "components/ten99Prep/Cart/HistoricalCart";
import * as React from 'react';
import { connect } from "react-redux";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage, ITen99Dashboard } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { INamedFilter, INamedFilterTypeEnum, ITen99PagerInfo } from "sharedInterfaces/ITen99PagerInfo";
import { ApplicationState } from "store";
import * as HomeNavigationStore from 'store/HomeNavigation';
import { Ten99PrepCancelIcon, Ten99PrepFilterIcon, Ten99PrepSearchIcon, Ten99PrepViewIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {		
	status: enumComponentStatus,
	carts: ITen99EntitySummary[],
	validationMessages: ITen99ApiResponseMessage[],	
	cartSummaryOpen: boolean,
	cartSummaryCartId: number,	
	editFilterOpen: boolean,
	tempFilterValue: string,
	pagerInfo: ITen99PagerInfo,
}

interface ICartHistoryDashboardProps {
	customer: ITen99Customer
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,
}

type CartHistoryDashboardProps =	
	& ICartHistoryDashboardProps
	& HomeNavigationStore.CustomerSummaryState
	;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,
	cartSummaryOpen: false,
	cartSummaryCartId: 0,
	carts: [] as ITen99EntitySummary[],
	validationMessages: [] as ITen99ApiResponseMessage[],
	editFilterOpen: false,
	tempFilterValue: "",
	pagerInfo: { pageNumber: 1, totalCount: 0, pageSize: 25 } as ITen99PagerInfo
};
class CartHistoryDashboard extends React.PureComponent<CartHistoryDashboardProps, ILocalState> {

	constructor(props: CartHistoryDashboardProps) {
		super(props);
		let initState: ILocalState = initialLocalState;
		initState.pagerInfo.onPagerClick = this.onPagerClick;
		this.state = initialLocalState;
		this.search("", 1);
	}
	// -----------------
	//Handle User events
	private onCartSummaryClose = (proceesed: boolean, message?: ITen99ActionResponseMessage) => {
		//close form		
		this.setState({ cartSummaryOpen: false })
		if (proceesed) {
			//the cart summary did something, most likely delete refresh the current page
			this.search(this.getSerchVal(), this.state.pagerInfo.pageNumber);
        }
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(this.getSerchVal(), page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private onViewDetailsClick = ( cartId: number) => {
		this.setState({ cartSummaryOpen: true, cartSummaryCartId: cartId })
	}

	private onCancel = () => {
		this.props.onClose(false, undefined);
	}

	private search(searchVal:string, pageNumber: number) {
		this.setState({ status: enumComponentStatus.Processing });
		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/Carts/Customer/" + this.props.customer.customerId + "/History/?SearchVal=" + searchVal + "&PageNumber=" + pageNumber + "&PageSize=" + this.state.pagerInfo.pageSize, "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload) && !isNullOrUndefined(data.pageData)) {
					this.setState({ status: enumComponentStatus.PendingAction, carts: data.payload.rows, pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount } });
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the cart" }] as ITen99ApiResponseMessage[] });
				}
			});
	}

	private getSerchVal = () => {
		if (!isNullOrUndefined(this.state.pagerInfo.namedFilters)) {
			let filter: INamedFilter | undefined = this.state.pagerInfo.namedFilters.find(x => x.type === INamedFilterTypeEnum.SearchVal)
			if (filter !== undefined) {
				return filter.value;
			}
			else {
				return "";
            }			
		}
		else {
			return "";
		}
	}

	private onFilterSubmit = () => {
		this.setState((prevState) => { return { pagerInfo: { ...prevState.pagerInfo, namedFilters: [{ type: INamedFilterTypeEnum.SearchVal, value: prevState.tempFilterValue } as INamedFilter] }}})
		this.search(this.state.tempFilterValue, 1);
		this.onFilterClose();
	}

	private onFilterClear = () => {
		this.setState({ pagerInfo: { ...this.state.pagerInfo, namedFilters: [{ type: INamedFilterTypeEnum.SearchVal, value: "" } as INamedFilter] }, tempFilterValue: "" })
		this.search("",1);
	}

	private onFilterOpen = () => {
		this.setState({ editFilterOpen: true });
	}
	private onFilterClose = () => {
		this.setState({ editFilterOpen: false });
	}

	private onFilterSet = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
	}
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {
		const searchVal:string = this.getSerchVal();
		const filterButtonText: string = searchVal === "" ? "No Filter" : "Filtered on '" + searchVal + "'";
		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={6} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Cart History for filing year {this.props.currentFilingYear}
								<InfoToolTip text="List of the previously processed carts. The year displayed is controlled by the Filing Year in the menu in the upper right corner" />
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{textAlign: 'right'}}>
							
						</Grid>
						<Grid item xs={10} sx={{textAlign: 'left'}}>
							<Typography variant="subtitle2" color="textSecondary" align="left" >Showing {this.state.carts.length} of {this.state.pagerInfo.totalCount} carts</Typography>
						</Grid>
						<Grid item xs={2} sx={{textAlign: 'right'}}>
							<ButtonGroup variant="contained" color="secondary" sx={{ width: "100%" }} >
								<Button type="button" startIcon={<Ten99PrepFilterIcon />} sx={{ width: "100%" }} onClick={this.onFilterOpen}>{filterButtonText}</Button>
								{searchVal !== "" && (
									<Button type="button" size="small" fullWidth={false} onClick={this.onFilterClear}><Ten99PrepCancelIcon /></Button>
								)}
							</ButtonGroup>
						</Grid>

						{this.state.editFilterOpen && (
							<Dialog open={this.state.editFilterOpen} fullWidth={true}>
								<DialogTitle>Filter Carts</DialogTitle>
								<DialogContent>
									<Grid container spacing={1} sx={{textAlign: 'center'}}>
										<Grid item xs={12}>
											<Typography variant="h6">Filter for records containing the following Cart Id:</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextField
												onChange={this.onFilterSet}
												value={this.state.tempFilterValue}
												variant="outlined"
												id="tempFilterValue"
												label="Filter by"
												name="tempFilterValue"
												fullWidth
											/>
										</Grid>
									</Grid>
								</DialogContent>
								<DialogActions>
									<Button type="button" startIcon={<Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onFilterClose}>Cancel</Button>
									<Button type="button" startIcon={<Ten99PrepSearchIcon />} variant="contained" color="primary" onClick={this.onFilterSubmit}>Filter</Button>
								</DialogActions>
							</Dialog>
						)}
					</Grid>

				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
							<Grid item xs={12}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
						    </Grid>
						)}
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<List>
								{this.state.carts.map((item: ITen99EntitySummary, index) => {
									return (
										<React.Fragment key={index} >
											<ListItem sx={{ backgroundColor: "customer.main" }}>
												<ListItemIcon>< ShoppingCartOutlined /></ListItemIcon>
												<ListItemText primary={"Cart " + item.id + " -  " + item.status + " (" + item.summaryText + ")"} />
												<ListItemSecondaryAction><Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepViewIcon />} onClick={(e) => this.onViewDetailsClick(parseInt(item.id))}>View Details</Button></ListItemSecondaryAction>
											</ListItem>
											<Divider sx={{
												height: "5px",
												backgroundColor: "#FFFFFF",
											}}/>
										</React.Fragment>
									);
								}
								)}
							</List>		
						</Grid>
						<Grid item xs={12} sx={{ textAlign: 'right' }}>
							<Pager pagerInfo={this.state.pagerInfo} />
						</Grid>
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						)}
					</Grid>

					{this.state.cartSummaryOpen && (
						<Dialog open={this.state.cartSummaryOpen} fullWidth={true} maxWidth="lg">
							<HistoricalCart onClose={this.onCartSummaryClose} cartId={this.state.cartSummaryCartId}/>
						</Dialog>
					)}
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Close</Button>					
				</DialogActions>				
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ } , // Selects which action creators are merged into the component's props
)(CartHistoryDashboard);