import { Button, Grid, Typography } from "@mui/material";
import * as React from 'react';
import { isNullOrUndefined } from "util";
import { IMenu } from "./MenuInterfaces";


interface IPopupMenu {
	menu: IMenu,
}

function PopupMenu(props: IPopupMenu) {
	
	return (
		<React.Fragment>
			{props.menu.sections.map((section, sectionIndex) => {
				return (
					<Grid container spacing={1} sx={{ textAlign: 'center' }} key={sectionIndex}>
						<Grid item xs={12} sx={{
							border: 'solid 1px ',
							borderColor: 'primary.dark',
							marginBottom: "10px",
							paddingRight: "10px",
							paddingBottom: "10px"
						}}>
							<Grid container spacing={1}>
								{!isNullOrUndefined(section.title) && section.title !== "" && (
									<Grid item xs={12} sx={{ textAlign: 'left' }}>
										<Typography variant="h5">{section.title}</Typography>
									</Grid>
								)}

								{section.buttons.map((button, buttonIndex) => {
									return (
										<React.Fragment key={buttonIndex}>
											<Grid item xs={3} />
											<Grid item xs={6} sx={{ textAlign: 'center' }}>
												<Button type="button" variant="contained" color="primary" fullWidth onClick={button.onClick}>{button.title}</Button>
											</Grid>
											<Grid item xs={3} />
										</React.Fragment>
									);
								})}									
							</Grid>
						</Grid>
					</Grid>
					)
			})}
		</React.Fragment>
	)
}

export default PopupMenu;