import { IconButton, Button } from '@mui/material';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import * as React from 'react';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { isNullOrUndefined } from 'util';

interface IActionButtonProps {
	itemId: string,
	uiProps: ITen99DynamicButtonProps
}

type ActionButtonProps =
	& IActionButtonProps;

class ActionButton extends React.PureComponent<ActionButtonProps> {
	//local state

	// -----------------
	//Handle User events
	
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		return (
			<React.Fragment>
				{!isNullOrUndefined(this.props.uiProps.icon)  && (
					<InfoToolTip text={isNullOrUndefined(this.props.uiProps.tooltip) ? "" : this.props.uiProps.tooltip}>
						{((isNullOrUndefined(this.props.uiProps.text) || this.props.uiProps.text === "") && 
							<IconButton id={this.props.itemId + "ActionButton" + this.props.uiProps.action} color={this.props.uiProps.color} disabled={isNullOrUndefined(this.props.uiProps.disabled) ? false : this.props.uiProps.disabled} onClick={x => { if (!isNullOrUndefined(this.props.uiProps.onClick)) { this.props.uiProps.onClick(this.props.itemId, this.props.uiProps.action) } }}>{this.props.uiProps.icon}</IconButton>
						)}
						{((!isNullOrUndefined(this.props.uiProps.text) && this.props.uiProps.text !== "") && 
							<Button id={this.props.itemId + "ActionButton" + this.props.uiProps.action} type="button" variant="contained" color={ this.props.uiProps.color} disabled={isNullOrUndefined(this.props.uiProps.disabled) ? false : this.props.uiProps.disabled} startIcon={this.props.uiProps.icon} onClick={x => { if (!isNullOrUndefined(this.props.uiProps.onClick)) { this.props.uiProps.onClick(this.props.itemId, this.props.uiProps.action) } }}>{this.props.uiProps.text}</Button>
						)}
					</InfoToolTip>
				)}
			</React.Fragment>
			);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (ActionButton);