import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import { DynamicInputGrid } from 'components/ten99Prep/DynamicInputGrid/DynamicInputGrid';
import * as React from 'react';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { IFormElement } from 'sharedInterfaces/ITen99FormElement';
import { IFormElementTemplate } from 'sharedInterfaces/ITen99FormElementTemplate';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';

interface IReportRequestAdditionalInformaitonProps {
	onClose: () => void,
	onSubmit: () => void,
	onChange: (name: string, value: string) => void,
	template: IFormElementTemplate[],
	formElements: IFormElement[],	
	validationMessages: ITen99ApiResponseMessage[]
	invalidProperties: string[],
}
type ReportRequestAdditionalInformaitonProps =
	IReportRequestAdditionalInformaitonProps
	;

class ReportRequestAdditionalInformaiton extends React.PureComponent<ReportRequestAdditionalInformaitonProps> {
	//local state
	// -----------------
	//Handle User events
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		
		return (			
			<React.Fragment>

				<DialogTitle>
						
					<Typography variant="h4">Additional Information Required for Report Request
						<InfoToolTip text="The Report type you chose needs more information before it can proceed" />
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={3} sx={{textAlign: 'center', paddingTop:"8px"}}>
						{!isNullOrUndefined(this.props.validationMessages) && this.props.validationMessages.length > 0 && (
							<Grid item xs={12} sx={{textAlign: 'left'}}>
								<ApiErrorResponseMessages messages={this.props.validationMessages} />
							</Grid>
						)}
						<DynamicInputGrid
							invalidState={this.props.validationMessages.length > 0}
							disabled={false}
							onchange={this.props.onChange}
							templates={this.props.template}
							formElements={this.props.formElements}
							validationMessages={this.props.validationMessages}
							invalidProperties={this.props.invalidProperties}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.props.onClose}>Cancel</Button>
					<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={this.props.onSubmit}>Run</Button>	
				</DialogActions>
				
			</React.Fragment>
		);
	}
}
export default ReportRequestAdditionalInformaiton;
