import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import AlertsSummary from 'components/information/AlertsSummary';
import Forms from 'components/ten99Prep/Forms';
import SummaryButtons from 'components/ten99Prep/SummaryButtons';
import SummaryTitle from 'components/ten99Prep/SummaryTitle';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { Ten99PrepRecipientIcon } from 'Ten99PrepOverloads/IconOverloads';

interface IRecipientSummary {
	onActionClick: (id: string, action: ActionTypesEnum) => void,
	onSelect: (recipientId: string) => void,
	summaryItem: ITen99EntitySummary,
	parents: ITen99EntitySummary[],
	activeRecipientId: string,
}


type RecipientProps =	
	IRecipientSummary
	;

interface ILocalState {
}

const initialLocalState = { } as ILocalState;
class RecipientSummary extends React.PureComponent<RecipientProps, ILocalState> {
	//local state

	constructor(props: RecipientProps) {
		super(props);
		this.state = initialLocalState;		
	}

	// -----------------
	//Handle User events
	private onExpansionSelect = () => {
		this.props.onSelect(this.props.summaryItem.id);
	}
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		
		let thisActiveItem: boolean = (this.props.summaryItem.id === this.props.activeRecipientId);
			return (			
				<Grid container spacing={1} sx={{textAlign: 'center'}}>
					<Grid item xs={12}>
						<Accordion expanded={thisActiveItem} onChange={this.onExpansionSelect} TransitionProps={{ unmountOnExit: true }}>
							<AccordionSummary expandIcon={<ExpandMore />} sx={{
								backgroundColor: "recipient.main",
								marginBottom: 1
							}} >
								<Ten99PrepRecipientIcon /><AlertsSummary entityId={this.props.summaryItem.id} alertSummary={this.props.summaryItem.alertsInfo} onActionClick={this.props.onActionClick} /><SummaryTitle entity={this.props.summaryItem} />
							</AccordionSummary>
							<AccordionDetails sx={{
								paddingLeft: 5
							}}>
								{(thisActiveItem) && (
									<Grid container spacing={1} sx={{textAlign: 'center'}}>
										<Grid item xs={12} sx={{textAlign: 'right'}}>
											<SummaryButtons itemId={this.props.summaryItem.id} onClick={this.props.onActionClick} actions={this.props.summaryItem.actions} />
										</Grid>
										<Grid item sx={{textAlign: 'left'}} xs={12} >
											<Forms parents={[...this.props.parents, this.props.summaryItem]} recipientId={this.props.summaryItem.id} />
										</Grid>
									</Grid>
								)}
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (RecipientSummary);