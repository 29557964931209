import * as React from 'react';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { GetUser } from 'utilities/UserId';

interface ICustomRouteProps extends RouteProps
{
    redirectUrl: string
}

export default function PrivateRouteCustom({ component: Component, redirectUrl: RedirectUrl, ...rest }: ICustomRouteProps ) {

    if (!Component) return null;

    return (
        <Route
            {...rest}
            render={props =>
                (!!GetUser()) ? <Component {...props} /> : <Redirect to={RedirectUrl + window.location.search} />
            }
        />
    );
};