import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';


function SiteFooter() {

    return (
        <Box sx={{textAlign:"center", paddingTop:"1"}}>
            <Typography variant="caption">
                ©{new Date().getFullYear()} 1099-Prep LLC. All Rights Reserved.
            </Typography>
            <Typography variant="caption">
                <Link href="https://www.1099-prep.com/terms-of-service" target="_blank" underline="hover" sx={{ paddingLeft: "15px" }}>Terms of Service</Link>
            </Typography>
            <Typography variant="caption">
                <Link href="https://www.1099-prep.com/privacy-policy" target="_blank" underline="hover" sx={{ paddingLeft: "15px" }}>Privacy Policy</Link>
            </Typography>
        </Box>
    );
}

export default SiteFooter;