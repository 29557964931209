import { Grid } from '@mui/material';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import Customer from 'components/ten99Prep/Customer';
import Payers from 'components/ten99Prep/Payers';
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { ApplicationState } from 'store';
import * as HomeNavigation from 'store/HomeNavigation';
import { isNullOrUndefined } from 'util';

enum enumConponentStatus {
	PendingAction,
	Processing,
	Invalid,
	Error
};

type CustomerSummaryProps =
	HomeNavigation.CustomerSummaryState // ... state we've requested from the Redux store


interface ILocalState {
	status: enumConponentStatus,
	filter: string,
	pagerCurrentPage: number,
	pagerTotalCount: number,
	validationMessages: ITen99ApiResponseMessage[],
	editFilterOpen: boolean,
	tempFilterValue: string,
}

const initialLocalState = { status: enumConponentStatus.PendingAction, filter: "", editFilterOpen: false, tempFilterValue: "", pagerCurrentPage: 1, pagerTotalCount: 0 } as ILocalState;
class CustomerSummary extends React.PureComponent<CustomerSummaryProps, ILocalState> {
	//local state
	state = initialLocalState;
		
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
			return (			
				<React.Fragment>
					
						{this.props.status === HomeNavigation.enumHomeNavigationStatus.Processing && (
							<ProcessingModal modal={true}/>
						)}
						{!isNullOrUndefined(this.props.activeCustomer) && this.props.status === HomeNavigation.enumHomeNavigationStatus.PendingAction && (
						<React.Fragment>
								<Customer />
								<br/>
							<Grid container spacing={1} sx={{
								textAlign: 'center',
								paddingLeft: 5,
							}}>
									<Payers parents={[{ id: this.props.activeCustomer.customerId, name: this.props.activeCustomer.name, type: EnumEntityType.Customer } as ITen99EntitySummary]} />
							</Grid>
						</React.Fragment>
					)}
					
				</React.Fragment>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ } // Selects which action creators are merged into the component's props
)(CustomerSummary);