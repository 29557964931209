import { Button, Grid, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import * as React from 'react';
import { useState } from 'react';
import { IDocumentRetrievalCreds } from './models/IDocumentRetrievalCreds';

export default function DocumentRetrievalUserLogin({ submitFunction, disabled } : { submitFunction: (creds: IDocumentRetrievalCreds) => void, disabled?: boolean}) {

	function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>)
	{
		if (e.key === "Enter") {
			submit();
		}
	}

	function submit() {
		submitFunction({ userName: email, password: last4 });
	}

	const [email, setEmail] = useState<string>("");
	const [last4, setLast4] = useState<string>("");

	return	(
		<>			
			<Grid container spacing={1} sx={{ textAlign: 'center' }}>
				<Grid item xs={12}>
					<TextField
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value) }}
						disabled={disabled}
						value={email}
						variant="outlined"
						id="userName"
						label="Email"
						name="userName"
						onKeyDown={(e) => { onKeyDown(e); }}
						autoFocus={true}
						fullWidth
						required
					/>
				</Grid>

				<Grid item xs={12} style={{ "display": "flex", } as React.CSSProperties}>
					<TextField
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setLast4(event.target.value) }}
						disabled={disabled}
						value={last4}
						variant="outlined"
						id="password"
						label="Last 4 digits of TIN"
						name="password"
						type="password"
						onKeyDown={(e) => { onKeyDown(e); }}
						fullWidth
						required
						InputProps={{
							inputComponent: CustomerNumberFormat as any,
							inputProps: {format: "####", mask: "#" }
						}}
					/>
					<InfoToolTip text="TIN (Tax Payer Identifier): If you represent a company this is your EIN (Employer Identification Number), if you are an individual this is your SSN (Social Security Number) or your ITIN (Individual Taxpayer Identification Number)" />
				</Grid>

				<Grid item xs={12}>
					<Button type="button" disabled={disabled} variant="contained" color="primary" fullWidth onClick={submit}>SIGN IN</Button>
				</Grid>


				<Grid item xs={12}>
					<Typography variant="h6">
						1099-Prep provides users with a simple, secure, and a cost effective process for preparing and filing IRS forms.
					</Typography>
					<br />
					<Typography variant="subtitle1">
						Easily organize, send, and track all required forms – in minutes! This streamlined filing process is simple, easy and secure so that you can get back to doing what you do best: managing your business.
					</Typography>
				</Grid>
			</Grid>			
		</>
	);
}