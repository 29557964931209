import { Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import FabButton from 'components/buttons/FabButton';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { INamedFilter, INamedFilterTypeEnum } from 'sharedInterfaces/ITen99PagerInfo';
import { ISummaryCategoryEnum } from 'sharedInterfaces/ITen99SummaryCounts';
import { ApplicationState } from 'store';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from 'store/HomeNavigation';
import { Ten99PrepCancelIcon, Ten99PrepFilterIcon, Ten99PrepSearchIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import CustomerStatusFilters_Search from './CustomerStatusFilters_Search';

type CustomerStatusProps =
	& HomeNavigationStore.CustomerSummaryState // ... state we've requested from the Redux store
	& ICustomerSummaryActions // ... plus action creators we've requested
	& RouteComponentProps<{ customerId ?: string }> // ... plus incoming routing parameters
	;

interface ILocalState {
	searchDialogOpen: boolean,
}



const initialLocalState = { searchDialogOpen: false } as ILocalState;
class CustomerStatusFilters extends React.PureComponent<CustomerStatusProps, ILocalState> {
	constructor(props: CustomerStatusProps) {
		super(props);
		this.state = initialLocalState;
	}
	

	private isStatusFilterActive = (type: INamedFilterTypeEnum, id: string,) => {
		if (!isNullOrUndefined(this.props.namedFilters) && this.props.namedFilters.findIndex(x => x.type === type && x.value === id) >= 0) {
			return true
		}
		else {
			return false;
        }
    }

	private isAnyCustomFilterActive = () => {
		if (!isNullOrUndefined(this.props.namedFilters) && this.props.namedFilters.findIndex(x => x.type !== INamedFilterTypeEnum.FormStatus) >= 0) {
			return true
		}
		else {
			return false;
		}
	}

	private onStatusFilterClick = (id: string, action: ActionTypesEnum) => {
		if (this.isStatusFilterActive(INamedFilterTypeEnum.FormStatus, id)) {
			this.props.CustomerSummaryActions_RemoveNamedFilters([{ type: INamedFilterTypeEnum.FormStatus, value: id }] as INamedFilter[]);
		}
		else {
			this.props.CustomerSummaryActions_AddNamedFilters([{ type: INamedFilterTypeEnum.FormStatus, value: id }] as INamedFilter[], false);
		}
	}

	private onClearFiltersClick = (id: string, action: ActionTypesEnum) => {

		const refresh: boolean = this.isAnyCustomFilterActive();

		this.props.CustomerSummaryActions_ClearNamedFilters();
		if (refresh) {
			this.props.CustomerSummaryActions_RefreshSummaryCounts();
		}
	}

	private onCustomFilterDialogOpenClick = (id: string, action: ActionTypesEnum) => {
		this.setState({ searchDialogOpen: true })
	}
	private onCustomFilterDialogCloseClick = () => {
		this.setState({ searchDialogOpen: false })
	}

	private saveCustomFilters = (filters: INamedFilter[]) => {
		this.setState({ searchDialogOpen: false })
		this.props.CustomerSummaryActions_AddNamedFilters(filters, true);
		this.props.CustomerSummaryActions_RefreshSummaryCounts();
    }

	private getFiltersAsString = () => {
		let filterString = "";
		if (!isNullOrUndefined(this.props.namedFilters)) {
			
			let filterStrings: string[] = [];
			this.props.namedFilters.forEach((value: INamedFilter, index: number) => {
				switch (value.type) {
					case INamedFilterTypeEnum.PayerCompanyName:
						filterStrings.push("Payer Company Name = '" + value.value + "' ")
						break;
					case INamedFilterTypeEnum.PayerFirstName:
						filterStrings.push("Payer First Name = '" + value.value + "' ")
						break;
					case INamedFilterTypeEnum.PayerLastName:
						filterStrings.push("Payer Last Name = '" + value.value + "' ")
						break;
					case INamedFilterTypeEnum.RecipientCompanyName:
						filterStrings.push("Recipient Company Name = '" + value.value + "' ")
						break;
					case INamedFilterTypeEnum.RecipientFirstName:
						filterStrings.push("Recipient First Name = '" + value.value + "' ")
						break;
					case INamedFilterTypeEnum.RecipientLastName:
						filterStrings.push("Recipient Last Name = '" + value.value + "' ")
						break;
				}
			});

			if (filterStrings.length > 0) {
				filterString = "Filtered On: " + filterStrings.join(",");
			}
		}

		return filterString;
	}

	// Render
	render() {
		if (!isNullOrUndefined(this.props.activeCustomer) && !isNullOrUndefined(this.props.activeCustomerSummaryCounts)) {
			return (

				<Grid container spacing={1}>
						<Grid item xs={1}>
						<FabButton itemId={"Search"} uiProps={{ icon: <Ten99PrepSearchIcon />, text: "", color: ((this.isAnyCustomFilterActive()) ? "primary" : "secondary"), tooltip: "Advanced Search", variant: 'extended', onClick: this.onCustomFilterDialogOpenClick } as ITen99DynamicButtonProps} />
						</Grid>

						<Grid item xs={10}>
							{
								this.props.activeCustomerSummaryCounts.map((item, index) => {
									switch (item.category) {
										case ISummaryCategoryEnum.FormStatus:
											return item.counts.sort((a, b) => a.id - b.id).map((countItem, countIndex) => {
												let isActive: boolean = this.isStatusFilterActive(INamedFilterTypeEnum.FormStatus, countItem.id.toString());
												if (isActive || (!isActive && countItem.count > 0)) { // do not show inactive items that have no counts
													return <FabButton key={countIndex} itemId={countItem.id.toString()} uiProps={{ icon: <Ten99PrepFilterIcon />, text: countItem.name, badge: countItem.count, color: (isActive ? "primary" : "secondary"), tooltip: "Filter payers", variant: 'extended', onClick: this.onStatusFilterClick } as ITen99DynamicButtonProps} />
												}
											}); //, onClick: this.onFilterClick("1", ActionTypesEnum.Add)
										case ISummaryCategoryEnum.FormType:
											return <div key={index}></div>;
									}
								})
							}
						</Grid>
						<Grid item xs={1}>
							<FabButton itemId={"0"} uiProps={{ icon: <Ten99PrepCancelIcon />, text: "", color: ((!isNullOrUndefined(this.props.namedFilters) && this.props.namedFilters.length > 0) ? "primary" : "secondary"), tooltip: "Clear filters", variant: 'extended', onClick: this.onClearFiltersClick } as ITen99DynamicButtonProps} />
						</Grid>

					<Grid item xs={1}>
					</Grid>

					<Grid item xs={10}>
						<Typography variant="subtitle2" >{this.getFiltersAsString()}</Typography>						
					</Grid>
					<Grid item xs={1}>
					</Grid>

					<Dialog open={this.state.searchDialogOpen} fullWidth={true} maxWidth="lg">
						<DialogTitle>
							<Typography variant="h5" >Payer / Recipient Filters</Typography>
						</DialogTitle>
						{this.state.searchDialogOpen && (
							<CustomerStatusFilters_Search onClose={this.onCustomFilterDialogCloseClick} onSave={this.saveCustomFilters} existingFilters={this.props.namedFilters} />
						)}
					</Dialog>
				</Grid >

			);
		}
		else {
			return (<React.Fragment></React.Fragment>);
        }
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...HomeNavigationStore.actionCreators } // Selects which action creators are merged into the component's props
)(withRouter(CustomerStatusFilters as any));