import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import { useState } from 'react';
import { ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';


export default function PhoneEdit({ phoneNumber, onClose }: { phoneNumber: string, onClose: (proceesed: boolean, phoneNumber: string) => void }) {

	enum enumComponentStatus {
		PendingAction,
		Processing,
		Invalid,
		Error
	};

	interface IPhoneEdit {
		phoneNumber: string,
		status: enumComponentStatus,
		validationMessages: ITen99ApiResponseMessage[],
		invalidProperties: string[]
	}

	const phoneMaskFormat = "(###) ###-####";
	const [componentState, setComponentState] = useState<IPhoneEdit>({
		validationMessages: [] as ITen99ApiResponseMessage[],
		invalidProperties: [] as string[],
		status: enumComponentStatus.PendingAction,
		phoneNumber: phoneNumber,
	} as IPhoneEdit);


	function UpdatePhone(value: string) {
		setComponentState(prevState => { return { ...prevState, phoneNumber: value } });
	}

	function Submit() {
		setComponentState(prevState => {
			return {
				...prevState,
				status: enumComponentStatus.Processing,
				validationMessages: [] as ITen99ApiResponseMessage[],
				invalidProperties: [] as string[]
			}
		});

		//call api
		MakeApiCall<string>("api/User/TwoFactor/Phone", "POST", JSON.stringify({ phoneNumber: componentState.phoneNumber, optIn: true }))
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					onClose(true, componentState.phoneNumber);					
				}
				else if (data.httpStatusCode = 422) {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.PendingAction,
							validationMessages: data.details,
							invalidProperties: flattenProperties(data.details)
						}
					});
				}
				else {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.Error,
							validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Issue Saving Phone" }] as ITen99ApiResponseMessage[],
							invalidProperties: flattenProperties(data.details)
						}
					});
				}
			})
	}

	return (
		<>
		    <DialogContent>
				<Grid container spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item xs={12}>
						{(componentState.status === enumComponentStatus.Processing 							
							&& (
							<ProcessingModal modal={true} />
							))}
					</Grid>
					<Grid item xs={12}>
						<TextField
							disabled={false}
							onChange={(e) => UpdatePhone(e.target.value)}
							value={componentState.phoneNumber}
							variant="outlined"
							error={componentState.invalidProperties?.find(x => x === "PhoneNumber") !== undefined}
							id="phoneNumber"
							label="Phone Number"
							name="phoneNumber"
							InputProps={{
								inputComponent: CustomerNumberFormat as any,
								inputProps: { format: phoneMaskFormat, mask: "#" }
							}}
							required={false}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body1">Message and data rates may apply.
							Adding a phone number means you agree to opt-in to receive text messages for Two Factor Authentication.
							You can opt-out at any time by removing this number.
						</Typography>
					</Grid>


					{!isNullOrUndefined(componentState.validationMessages) && componentState.validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={componentState.validationMessages} />
						</Grid>
					)}

					
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={() => onClose(false, "")}>Close</Button>
				<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={Submit}>Submit</Button>
			</DialogActions>
		</>
	);
}