import { Button, Dialog } from "@mui/material";
import DialogTemplate from "components/display/DialogTemplate";
import DownloadTemplate from "components/ten99Prep/Import/DownloadTemplate";
import ImportFileWizard from "components/ten99Prep/Import/ImportFile/ImportFileWizard";
import ImportHistoryContoller from "components/ten99Prep/Import/Components/ImportHistory/ImportHistoryContoller";
import ImportMenu from "components/ten99Prep/Import/ImportMenu";
import ImportQboWizard from "components/ten99Prep/Import/Qbo/ImportQboWizard";
import * as React from 'react';
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { Ten99PrepBackIcon, Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import RollOverWizard from "./RollOver/RollOverWizard";
import QboPreview from "./Qbo/QboPreview";
import { IEntityNavItem } from "../../information/EntityNav";
import { EnumEntityType } from "../../../sharedInterfaces/ITen99EntitySummary";
import { QBO_STRING } from "./Qbo/QboConstants";

interface ILocalState {
	dialogOpen: boolean,
	dialogSize: "sm" | "md" | "lg",
	wizardItem: "Home" | "ImportTemplates" | "ImportFromFile" | "ImportHistory" | "QboImport" | "QboPreview" | "RollOver",
}

interface IImportWizardProps {
	customer: ITen99Customer,
	onClose: () => void,
}

type ImportWizardProps =
	& IImportWizardProps;

const initialLocalState: ILocalState = { dialogOpen: true, wizardItem: "Home", dialogSize: "sm"};
class ImportWizard extends React.PureComponent<ImportWizardProps, ILocalState> {
	
	constructor(props: ImportWizardProps) {
		super(props);
		this.state = initialLocalState;
	}
	// -----------------
	//Handle User events
	
	private closeImportWizard = () => {
		this.setState({ dialogOpen: false });
		this.props.onClose();
	}

	private goHome = () => {
		this.setState({ wizardItem: "Home", dialogSize: "sm"});
	}

	private selectMenuItem = (name: "Home" | "ImportTemplates" | "ImportFromFile" | "ImportHistory" | "QboImport" | "QboPreview" | "RollOver") => {
		let dialogSize: "sm" | "md" | "lg" = "sm";

		if (name === "ImportHistory" || name === "QboPreview") {
			dialogSize = "lg"
        }
		this.setState({ wizardItem: name, dialogSize: dialogSize });
	}

	// Render
	render() {
		return (
			<React.Fragment>
				<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth={this.state.dialogSize}>
					{this.state.wizardItem === "Home" && (
					<DialogTemplate onClose={this.closeImportWizard} title={"Import or Rollover Prior Year Data: " + this.props.customer.name}
						buttonsRight={
							[
								<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.closeImportWizard}>Close</Button>,
							] as unknown as typeof Button[]}>
							<ImportMenu onSelect={this.selectMenuItem}/>
						</DialogTemplate>
					)}
					{this.state.wizardItem === "ImportTemplates" && (
						<DialogTemplate onClose={this.closeImportWizard} title={"Download Template: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.goHome}>Back</Button>
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" onClick={this.closeImportWizard}>Finish</Button>,									
								] as unknown as typeof Button[]}>
							<DownloadTemplate />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "ImportHistory" && (
						<DialogTemplate onClose={this.closeImportWizard} title={"Import History Template: " + this.props.customer.name}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.goHome}>Back</Button>
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" onClick={this.closeImportWizard}>Finish</Button>,
								] as unknown as typeof Button[]}>
							<ImportHistoryContoller customer={this.props.customer} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "QboPreview" && (
						<DialogTemplate
							onClose={this.props.onClose}
							title={QBO_STRING + " Import"}
							navItems={[
								{ type: EnumEntityType.Customer, name: this.props.customer.name },
							] as IEntityNavItem[]}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.goHome}>Back</Button>,
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" onClick={this.props.onClose}>Finish</Button>,
								] as unknown as typeof Button[]}
						>
							<QboPreview customerId={Number.parseInt(this.props.customer.customerId)} />
						</DialogTemplate>
					)}
				</Dialog>
				{this.state.wizardItem === "ImportFromFile" && (
					<ImportFileWizard customer={this.props.customer} onClose={this.closeImportWizard} goHome={this.goHome} />)}
				{this.state.wizardItem === "QboImport" && (
					<ImportQboWizard customer={this.props.customer} onClose={this.closeImportWizard} goHome={this.goHome} />)}
				{this.state.wizardItem === "RollOver" && (
					<RollOverWizard customer={this.props.customer} onClose={this.closeImportWizard} goHome={this.goHome} />)}
			</React.Fragment>
		)
	}
}

export default ImportWizard;

