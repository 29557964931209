import { Button, DialogActions, DialogContent, DialogTitle, Grid, Link, List, ListItem, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import { useState } from "react";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { Ten99Accept, Ten99LegalIcon, Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import ITerm from "./models/ITerm";



export default function Terms({ terms, onClose }: { terms: ITerm[], onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void })
{

	enum enumComponentStatus {
		PendingAction,
		Processing,
		Error
	};

	const [status, setStatus] = useState<enumComponentStatus>(enumComponentStatus.PendingAction);
	const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([] as ITen99ApiResponseMessage[]);

	function onSubmit()
	{
		setStatus(enumComponentStatus.Processing);
		setValidationMessages({} as ITen99ApiResponseMessage[]);
		let termIds: number[] = [] as number[];
		terms.map(item => { termIds.push(item.termId) });
		MakeApiCall<boolean>("api/RecipientGateway/Terms", "POST", JSON.stringify({ "AcceptedTerms": termIds }))
			.then(data => {
				if (data.isSuccess) {
					onClose(true);
				}
				else { //unable to load terms, we need to stop
					setStatus(enumComponentStatus.Error);
					setValidationMessages(data.details);
				}
			});		
	}

	return (
		<>
			<DialogTitle>
				<Grid container spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="h4">EULA: Terms that need to be accepted
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop: "8px" }}>
					{(status === enumComponentStatus.Processing) && (
						<Grid item xs={12}>
							<ProcessingModal modal={true} />
						</Grid>
					)}

					{!isNullOrUndefined(validationMessages) && validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={validationMessages} />
						</Grid>
					)}

					<List>
						{
							terms.map((item, index) => {
								return (
									<ListItem key={index} >
										<Ten99LegalIcon />
										{!isNullOrUndefined(item.url) && item.url !== "" && (
											<Link href={item.url} target="_blank">{item.title}</Link>
										)}
										{(isNullOrUndefined(item.url) || item.url === "") && (
											<Typography variant="body2" display="inline">{item.title}</Typography>
										)}
										<Typography variant="body2" display="inline">{(item.title === "" ? "" : ": ") + item.descr}</Typography>
									</ListItem>
								)
							})
						}
					</List>

				</Grid>
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => onClose(false, undefined)}>Decline and Logout</Button>
				<Button type="button" variant="contained" color="primary" startIcon={<Ten99Accept />} onClick={onSubmit}>Accept</Button>
			</DialogActions>
		</>
	)
}