import { Dialog, Grid, Typography } from '@mui/material';
import Pager from 'components/buttons/Pager';
import AlertsDashboard from 'components/information/AlertsDashboard';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import EntityAddEditView from 'components/ten99Prep/EntityAddEditView/EntityAddEditView';
import FabButtons from 'components/ten99Prep/FabButtons';
import PayerForms from 'components/ten99Prep/PayerForms';
import PayerStateAccountsDashboard from 'components/ten99Prep/PayerStateAccounts/PayerStateAccountsDashboard';
import PayerSummary from 'components/ten99Prep/PayerSummary';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum, IAction } from 'sharedInterfaces/ITen99Action';
import { ITen99ActionResponse } from 'sharedInterfaces/ITen99ActionResponse';
import { enumAddEditViewAction, enumEntityType, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage, ITen99Dashboard } from 'sharedInterfaces/ITen99ApiResponse';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { ITen99PagerInfo } from 'sharedInterfaces/ITen99PagerInfo';
import { ApplicationState } from 'store';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { getEntityPrintableName } from 'utilities/EntityNameSummary';

enum enumComponentStatus {
	PendingAction,
	Processing,
	SilentProcessing,
	Loading,
	Invalid,
	Error
};


interface IPayers {
	parents: ITen99EntitySummary[],
}

type PayersProps =
	& IPayers
	& HomeNavigationStore.CustomerSummaryState // ... state we've requested from the Redux store
	& ICustomerSummaryActions // ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	;

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	addEditViewEntityOpen: boolean,
	addEditViewEntitySetting?: IAevEntitySetting,
	dialogOpen: boolean,
	dialogType?: ActionTypesEnum,
	dialogEntity?: ITen99EntitySummary,
	pagerInfo: ITen99PagerInfo,
	payers?: ITen99Dashboard<ITen99EntitySummary>,
	activePayerId: string,
	filtersSetOn?: Date,
}

const initialLocalState = { status: enumComponentStatus.Loading, activePayerId: "0", pagerInfo: { pageNumber: 0, totalCount: 0, pageSize: 25 }, addEditViewEntityOpen: false, addEditViewEntitySetting: undefined, dialogOpen: false, dialogType: undefined, dialogEntity: undefined, validationMessages: {} as ITen99ApiResponseMessage[] } as ILocalState;
class Payers extends React.PureComponent<PayersProps, ILocalState> {
	//local state
	constructor(props: PayersProps) {
		super(props);
		let temp: ILocalState = { ...initialLocalState };
		temp.filtersSetOn = this.props.filtersSetOn;
		temp.pagerInfo.onPagerClick = this.onPagerClick;
		this.state = temp;
		this.search(1);
	}
	// -----------------
	//Handle User events
	private onAddEditViewEntityProccessed = (response: IAevEntityResponse) => {
		this.setState({ addEditViewEntityOpen: false, addEditViewEntitySetting: undefined });
		if (response.processed) {
			this.search(this.state.pagerInfo.pageNumber, true);
			//send toast notification
			this.props.toastNotifcations_Display({ id: "PayerAddEdit", message: response.message, type: "success" });
		}
	}
	private onAdd = () => { this.onAddEditViewOpen({ parents: this.props.parents, action: enumAddEditViewAction.Add, type: enumEntityType.Payer } as IAevEntitySetting) }
	private onEdit = (entity: ITen99EntitySummary) => { this.onAddEditViewOpen({ id: entity.id, parents: this.props.parents, action: enumAddEditViewAction.Edit, type: enumEntityType.Payer } as IAevEntitySetting) }
	private onView = (entity: ITen99EntitySummary) => { this.onAddEditViewOpen({ id: entity.id, parents: this.props.parents, action: enumAddEditViewAction.View, type: enumEntityType.Payer } as IAevEntitySetting) }
	private onDelete = (entity: ITen99EntitySummary) => { this.props.confirmDialog_Display("Delete Payer", entity.id, "You are about to delete the following " + getEntityPrintableName(entity), this.onDeleteProceed);}
	private onDeleteProceed = (id: string) => {

		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing && !isNullOrUndefined(this.state.payers)) {			
			//get the customer to make sure it is still there and get name
			const payer: ITen99EntitySummary | undefined = this.state.payers.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(payer)) {
				this.setState({ status: enumComponentStatus.Processing });
				//call the api and set the results in the state to reflect on the render
				MakeApiCall<string>("api/Payers/" + id + "/?ticks=" + payer.ticks, "DELETE")
					.then(data => {
						if (data.isSuccess) {
							//refresh list
							this.search(this.state.pagerInfo.pageNumber, true);
							//send toast notification
							this.props.toastNotifcations_Display({ id: "PayerDelete" + id, message: getEntityPrintableName(payer) + " deleted", type: "success" });
						}
						else {
							if (data.httpStatusCode === 422) {
								let index: number = 0;
								data.details.forEach(x => {
									this.props.toastNotifcations_Display({ id: "PayerDelete" + id + "_" + index, message: "Issue occurred deleting " + getEntityPrintableName(payer) + ": " + x.message, type: "error" });
									index++;
								});
							}
							else if (data.httpStatusCode === 409) {
								this.props.toastNotifcations_Display({ id: "PayerDelete" + id, message: "Payer has been updated by another process. Refresh screen and try again. " + getEntityPrintableName(payer), type: "error" });
							}
							else {
								//send toast notification
								this.props.toastNotifcations_Display({ id: "PayerDelete" + id, message: "Issue occurred deleting " + getEntityPrintableName(payer), type: "error" });
							}
							this.setState({ status: enumComponentStatus.PendingAction });
						}						
					});
			}
			else {
				console.error("Unable to locally find Payer: " + id);
			}
		}
	}

	private onDeleteForms = (entity: ITen99EntitySummary) => { this.props.confirmDialog_Display("Delete Forms", entity.id, "You are about to delete the forms in draft status for " + getEntityPrintableName(entity), this.onDeleteFormsProceed); }
	private onDeleteFormsProceed = (id: string) => {

		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing && !isNullOrUndefined(this.state.payers)) {
			//get the customer to make sure it is still there and get name
			const entity: ITen99EntitySummary | undefined = this.state.payers.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				this.onGenericAction(entity, ActionTypesEnum.DeleteAllPayers);
			}
			else {
				console.error("Unable to locally find Payer: " + id);
			}
		}
	}

	private onAddEditViewOpen = (entitySetting: IAevEntitySetting) => {
		this.setState({ addEditViewEntityOpen: true, addEditViewEntitySetting: entitySetting });
	}

	

	private onGenericAction = (entity: ITen99EntitySummary, actionId: number) => {

		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });

		MakeApiCall<ITen99ActionResponse>("api/Bulk/" + entity.id + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, ticks: entity.ticks } as IAction))
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						this.props.CustomerSummaryActions_RefreshSummaryCounts();
						data.payload.messages.forEach(x =>
							this.props.toastNotifcations_Display({ id: "Payer" + entity.id + "action", message: x.message, type: ToastNotifications.ConvertEnumToToastType(x.messageType) })
						);
					}
				}
				else {
					this.props.toastNotifcations_Display({ id: "Payer" + entity.id + "action" + actionId, message: "Unable to Process action", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}

	private onOpenDialog = (dialogType: ActionTypesEnum, entity: ITen99EntitySummary) => {
		this.setState({ dialogOpen: true, dialogType: dialogType, dialogEntity: entity });
	}

	private onCloseDialog = () => {
		this.setState({ dialogOpen: false, dialogType: undefined, dialogEntity: undefined });		
	}
	
	private onActionClickByEntity = (entity: ITen99EntitySummary, action: ActionTypesEnum) => {
		switch (action) {
			case ActionTypesEnum.Add:
				this.onAdd();
				break;
			case ActionTypesEnum.Delete:
				this.onDelete(entity);
				break;
			case ActionTypesEnum.DeleteAllPayers:
				this.onDeleteForms(entity);
				break;
			case ActionTypesEnum.Edit:
				this.onEdit(entity);
				break;
			case ActionTypesEnum.View:
				this.onView(entity);
				break;
			case ActionTypesEnum.BulkPreview:
			case ActionTypesEnum.StaticDoc:
				this.onOpenDialog(action, entity);
				break;
			case ActionTypesEnum.ShowAlerts:
			case ActionTypesEnum.StateAccounts:
			case ActionTypesEnum.ViewPayerForms:
				this.onOpenDialog(action, entity);
				break;
			default:
				this.onGenericAction(entity, action.valueOf());
				break;
		}
	}
	private onActionClickById = (id: string, action: ActionTypesEnum) => {
		if (!isNullOrUndefined(this.state.payers)) {
			const entity: ITen99EntitySummary | undefined = id === "" ? { id: "" } as ITen99EntitySummary : this.state.payers.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				this.onActionClickByEntity(entity, action);
			}
			else {
				console.error("Unable to locally find payer: " + id);
			}
		}
	}

	private onPayerSelect = (payerId: string) => {
		let thisActivePayer: boolean = (this.state.activePayerId === payerId);
		//if the active payer is sending this, it means deselect
		this.setState({ activePayerId: (thisActivePayer ? "0" : payerId) });
    }

	private filtersChanged = () => {
		this.setState({ filtersSetOn: this.props.filtersSetOn });
		this.search(this.state.pagerInfo.pageNumber);
    }

	private search = (pageNumber: number, silentLoad: boolean = false) => {
		if (!isNullOrUndefined(this.props.activeCustomer)) {
			let loadStatus: enumComponentStatus = silentLoad ? enumComponentStatus.SilentProcessing : enumComponentStatus.Processing;
			this.setState({ status: loadStatus, pagerInfo: { ...this.state.pagerInfo, pageNumber: pageNumber, namedFilters: this.props.namedFilters } });

			let pager2: ITen99PagerInfo = { ...this.state.pagerInfo, pageNumber: pageNumber, namedFilters: this.props.namedFilters };

			MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/Payers/Summary/?CustomerId=" + this.props.activeCustomer.customerId, "POST", JSON.stringify(pager2))
				.then(data => {
					if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload) && !isNullOrUndefined(data.pageData)) {
						this.setState({ status: enumComponentStatus.PendingAction, payers: data.payload, pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount } });
					}
					else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
						//empty
						this.setState({ status: enumComponentStatus.PendingAction, payers: undefined, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load payers" }] as ITen99ApiResponseMessage[] });
					}
				})
		}
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		if (this.state.status === enumComponentStatus.PendingAction && this.state.filtersSetOn !== this.props.filtersSetOn) {
			this.filtersChanged();
		}

		const showPager: boolean = !isNullOrUndefined(this.state.payers) && this.state.payers.rows.length < this.state.pagerInfo.totalCount;

		return (

			<React.Fragment>
				
				<Grid container spacing={1} sx={{ textAlign: 'center' }} >
					<Grid item xs={8} sx={{
						textAlign: 'left'
					}}>
							<Typography variant="h5">Payers
								{(!isNullOrUndefined(this.state.payers) &&
									<FabButtons entityType={EnumEntityType.Payer} actions={this.state.payers.bulkActions} onClick={this.onActionClickById} />
								)}
							</Typography>
						</Grid>
					<Grid item xs={4} sx={{
						textAlign: 'right'
					}}>
							<Pager pagerInfo={this.state.pagerInfo} show={showPager} />
					</Grid>
					{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.SilentProcessing) && !isNullOrUndefined(this.state.payers) && !isNullOrUndefined(this.state.payers.rows) && this.state.payers.rows.length > 0 && (
							<Grid item xs={12}>
								{
									this.state.payers.rows.map((item, index) => {
										return (
											<PayerSummary parents={this.props.parents} key={index} onActionClick={this.onActionClickById} onSelect={this.onPayerSelect} summaryItem={item} activePayerId={this.state.activePayerId} />
										)
									})
								}
							</Grid>
						)}
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
							<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
						)}

						<Grid item xs={8}>
						</Grid>

						<Grid item xs={4} sx={{
							textAlign: 'right'
						}}>
							<Pager pagerInfo={this.state.pagerInfo} show={showPager} />
						</Grid>
						<Grid item xs={12}>
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							)}
						</Grid>

					</Grid>
				
					{this.state.addEditViewEntityOpen && !isNullOrUndefined(this.state.addEditViewEntitySetting) && (
						<EntityAddEditView onClose={this.onAddEditViewEntityProccessed} entitySettings={this.state.addEditViewEntitySetting} />
					)}

					{this.state.dialogOpen && !isNullOrUndefined(this.state.dialogType) && !isNullOrUndefined(this.state.dialogEntity) && (
						<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth="lg">
							{this.state.dialogType === ActionTypesEnum.ShowAlerts && (
								<AlertsDashboard alertSource={this.state.dialogEntity} alertSourceParents={this.props.parents} onClose={this.onCloseDialog} />
							)}
							{this.state.dialogType === ActionTypesEnum.StateAccounts && (
								<PayerStateAccountsDashboard payer={this.state.dialogEntity} onClose={this.onCloseDialog} />
							)}

							{this.state.dialogType === ActionTypesEnum.ViewPayerForms && (
								<PayerForms payer={this.state.dialogEntity} parents={this.props.parents} onClose={this.onCloseDialog} />
							)}
						</Dialog>
					)}
				</React.Fragment>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, ...HomeNavigationStore.actionCreators } // Selects which action creators are merged into the component's props
)(Payers);