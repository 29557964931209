import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import GlobalPayments from 'components/globalpayments';
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import Address from "components/ten99Prep/Address/Address";
import * as React from 'react';
import { IAction } from "sharedInterfaces/ITen99Action";
import { ITen99ActionResponse, ITen99ActionResponseMessage, ITen99ActionResponseTypeEnum } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99Address } from "sharedInterfaces/ITen99Address";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from "util";
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	address: ITen99Address,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
}

interface IPaymentInfo {
	token: string,
	cardHolderName:string,
	amount: number,	
	address: ITen99Address,
	cardType: string,
	cardLast4: string,
}

interface IPaymentProps {
	cartId: number,
	cartTicks: string,
	amount: number,
	onClose: (proceesed: boolean, response: ITen99ActionResponseMessage[]) => void,
}





type PaymentProps =
	& IPaymentProps
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.IsLoading, address: { stateId: 0 } as ITen99Address, validationMessages: [] as ITen99ApiResponseMessage[], invalidProperties: [] as string[] };
class Payment extends React.PureComponent<PaymentProps, ILocalState> {
	private states: ITen99LookupItem[] = {} as ITen99LookupItem[];
	private countries: ITen99LookupItem[] = {} as ITen99LookupItem[];
	paymentForm: any;

	constructor(props: PaymentProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=1&lookupTableIds=14", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 1);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.states = lookup.lookupItems;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load states" }] as ITen99ApiResponseMessage[] });
						}

						lookup = data.payload.find(x => x.options.requestedTable === 14);
						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.countries = lookup.lookupItems;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load countries" }] as ITen99ApiResponseMessage[] });
						}

						//get USA ID
						let item: ITen99LookupItem | undefined = this.countries.find(x => x.name === "USA");
						let usaCountryId: number = -1;
						if (!isNullOrUndefined(item)) {
							usaCountryId = item.id;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to find country: USA" }] as ITen99ApiResponseMessage[] });
						}

						if (this.state.status !== enumComponentStatus.Error) {
							this.setState({ status: enumComponentStatus.PendingAction, address: { ...this.state.address, countryId: usaCountryId}} );
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})
	}
	// -----------------
	//Handle User events

	private onCancel = () => {
		this.props.onClose(false, [] as ITen99ActionResponseMessage[]);
	}

	private onMakePayment = (payment: IPaymentInfo) => {	
		//always take the amount passed in, not whatever user might have overrriden 
		MakeApiCall<ITen99ActionResponse>("api/Carts/" + this.props.cartId + "/Actions", "POST", JSON.stringify({ actionTypeId: 16, ticks: this.props.cartTicks, additonalActionData: JSON.stringify(payment) } as IAction))
			.then(data => {
				if (data.isSuccess) {
					
					if (!isNullOrUndefined(data.payload)) {
						this.props.onClose(true, data.payload.messages);
					}
					else {
						const messages: ITen99ActionResponseMessage[] = [{ messageType:ITen99ActionResponseTypeEnum.Success, message: "Cart Submitted" }]
						this.props.onClose(true, messages);
                    }
					
				}
				else {
					this.setState({ status: enumComponentStatus.Invalid, validationMessages: data.details });
				}

			});
	}

	private onAddressChange = (name: string, value: any) => {
		this.setState({ address: { ...this.state.address, [name]: value } }  as Pick<ILocalState, keyof ILocalState>);
	}

	// -----------------
	// Componet lifecycle events

	// -----------------

	componentDidMount() {
		GlobalPayments.configure({
			publicApiKey: sessionStorage.getItem("heartlands")
		});

		const formOptions = {
			placeholders: {
				"card-cvv": "###",
				"card-expiration": "MM / YYYY",
				"card-holder-name": "FirstName LastName",
				"card-number": "#### #### #### ####",
			},
		};

		this.paymentForm = GlobalPayments.creditCard.form("#credit-card", formOptions);

		this.paymentForm.ready(() => {
			console.log("Registration of all credit card fields occurred");
		});
		this.paymentForm.on("token-success", (resp: { paymentReference: string; details: { cardType: string, cardLast4: string, cardSecurityCode: boolean, cardholderName: string, expiryMonth: string, expiryYear: string } }) => {
			
			//validations - need to do some here becuase we only transfer back a token 
			let messages: ITen99ApiResponseMessage[] = [];

			if (isNullOrUndefined(resp.details.cardholderName)) {
				messages.push({ type: "Error", message: "Card Holder Name is a required" } as ITen99ApiResponseMessage);
			}
			else {
				if (resp.details.cardholderName.split(" ").length <= 1) {
					messages.push({ type: "Error", message: "Card Holder Name should be atleast First Name and Last name with a space seperating them." } as ITen99ApiResponseMessage);
                }
            }

			if (isNullOrUndefined(resp.details.expiryMonth) || isNullOrUndefined(resp.details.expiryYear)) {
				messages.push({ type: "Error", message: "A valid Card Expiration date is required" } as ITen99ApiResponseMessage);
			}

			if (!resp.details.cardSecurityCode) {
				messages.push({ type: "Error", message: "Card CVV is a required" } as ITen99ApiResponseMessage);
			}

			if (messages.length === 0) {
				const payment: IPaymentInfo = { cardType: resp.details.cardType, cardLast4: resp.details.cardLast4, cardHolderName: resp.details.cardholderName, amount: this.props.amount, address: this.state.address, token: resp.paymentReference }

				this.onMakePayment(payment);
			}
			else {
				this.setState({ status: enumComponentStatus.PendingAction, validationMessages: messages });
            }
		});
		this.paymentForm.on("token-error", (resp: { error: boolean, reasons: { message: string }[] }) => {
			const messages: ITen99ApiResponseMessage[] = resp.reasons.map((item: { message: string }, index: number) => { return ({ type: "Error", message: item.message } as ITen99ApiResponseMessage) });
			this.setState({ status: enumComponentStatus.PendingAction, validationMessages: messages });
		});

		// field-level event handlers. example:
		this.paymentForm.on("submit", "click", () => {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
		});
	}

	componentWillUnmount() {
		this.paymentForm.dispose();
	}
	// Render
	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Credit Card Payment Information
							<InfoToolTip text="Enter information needed for credit card payment" />
							</Typography>
						</Grid>						
					</Grid>
				</DialogTitle>
				<DialogContent style={{ padding: 20 }}>
					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
						<ApiErrorResponseMessages messages={this.state.validationMessages} />
					)}
					<Grid container spacing={3} sx={{textAlign: 'center'}}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
							<ProcessingModal modal={true} />
						)}
							<React.Fragment>
								<Grid item xs={8}>
									<Grid container spacing={1} sx={{textAlign: 'center'}}>
									<Grid item xs={12} sx={{ border: 'solid 1px ', borderColor: 'primary.dark'}}>
										<Typography variant="h6">Billing Address:</Typography>
										{(this.state.status !== enumComponentStatus.IsLoading) && (
												<Grid container spacing={1} sx={{textAlign: 'center'}}>
												<Address disabled={false} invalid={this.state.status === enumComponentStatus.Invalid} address={this.state.address} states={this.states} countries={this.countries} onchange={this.onAddressChange} validationMessages={this.state.validationMessages} invalidProperties={ this.state.invalidProperties }/>
												</Grid>
											)}

										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={4}>
									<Grid container spacing={1} sx={{textAlign: 'center'}}>
									<Grid item xs={12} sx={{ border: 'solid 1px ', borderColor: 'primary.dark' }}>
										<Typography variant="h6">Credit Card Information:</Typography>
										<div id="credit-card"></div>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Cancel</Button>					
				</DialogActions>

			</React.Fragment>
		)
	}
}

export default Payment;