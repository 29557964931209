import { Grid } from '@mui/material';
import AlertsSummary from 'components/information/AlertsSummary';
import KeyValueDisplay from 'components/information/KeyValue';
import SummaryButtons from 'components/ten99Prep/SummaryButtons';
import SummaryTitle from 'components/ten99Prep/SummaryTitle';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { Ten99PrepFormIcon } from 'Ten99PrepOverloads/IconOverloads';

interface IFormSummary {
	onActionClick: (id: string, action: ActionTypesEnum) => void,
	summaryItem: ITen99EntitySummary,
}

type FormProps =	
	IFormSummary
	;

interface ILocalState {
	expanded: boolean,
}

const initialLocalState = { expanded: false } as ILocalState;
class FormSummary extends React.PureComponent<FormProps, ILocalState> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle User events
	
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		
			return (			
				<Grid container spacing={1} sx={{
					textAlign: 'center',
					backgroundColor: "form.main",
					marginTop: "10px",
					marginBottom: "10px",
					alignItems: "center",
					paddingLeft: "16px",
					paddingRight: "16px",
					minHeight: "64px"}}>
					<Grid item xs={5} sx={{ textAlign: "left", display: "flex" }}>
						<Ten99PrepFormIcon /><AlertsSummary entityId={this.props.summaryItem.id} alertSummary={this.props.summaryItem.alertsInfo} onActionClick={this.props.onActionClick} /><SummaryTitle entity={this.props.summaryItem} inline={false} />
					</Grid>
					<Grid item xs={2} sx={{ textAlign: "left", display: "flex" }}>
						<KeyValueDisplay keyName={"Status"} value={this.props.summaryItem.status} disableKeyNameWordWrap={true}/>
					</Grid>
					<Grid item xs={5} sx={{textAlign: 'right'}}>
						<SummaryButtons itemId={this.props.summaryItem.id} onClick={this.props.onActionClick} actions={this.props.summaryItem.actions} displayAllAsButtons={true} colorOverride="secondary" />
					</Grid>
				</Grid>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (FormSummary);