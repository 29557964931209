import { QuestionAnswer } from '@mui/icons-material';
import { Box, Paper, Typography } from "@mui/material";
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';


interface IFaqItem {
    question: string;
    answer: string[],
}

interface ILocalState {
    items: IFaqItem[],
}

type FaqProps =
    ILocalState;

class Faqs extends React.PureComponent<FaqProps, ILocalState> {

    constructor(props: FaqProps) {
        super(props);
        this.state = {
            items: [] as IFaqItem[],
        };
        this.getAllFaq();
    }

    private getAllFaq = () => {
        MakeApiCall<IFaqItem[]>("api/Faq", "GET")
            .then(data => {
                if (data.isSuccess && !isNullOrUndefined(data.payload)) {
                    this.setState({ items: data.payload })
                }
            });
    };

    CreateFaqs() {

        const items = this.state.items;
        return items.map((item, index) => {
            {
                return <Box key={index} sx={{ paddingBottom: "1em" }}><Typography variant="h6" sx={{ color: "#00808F"}}><QuestionAnswer fontSize="small" />{item.question}</Typography>
                    {item.answer.map((answer, innerIndex) => {
                        return <Typography key={innerIndex} variant="body2"> - {answer}</Typography>
                    })}
                </Box>
            };
        });
    }

    render() {

        return (
            <React.Fragment>
                <Paper sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "70vw",
                    height: "auto",
                    margin: "auto",
                    padding: "1vw",
                }}>
                    <div >
                        <Box sx={{textAlign: "center"}}>
                            <Typography variant="h4">Frequently Asked Questions</Typography>
                        </Box>
                        <br /><br />
                        <div>
                            {this.CreateFaqs()}
                        </div>
                    </div>
                    </Paper>
            </React.Fragment>
        );
    }
}

export default Faqs;