import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { Auth_ActionCreators } from 'store/SharedActions';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { SessionClear } from 'utilities/UserId';

export default function SimpleLogout({ LogoutUrl, RedirectUrl }: { LogoutUrl: string, RedirectUrl: string }) {

	const dispatch = useDispatch();

	useEffect(() => {
		console.log("begin Logout");
		MakeApiCall<string>(LogoutUrl, "DELETE"); // we do not care about result
		SessionClear();
		dispatch(Auth_ActionCreators.Auth_Logout);
		console.log("end Logout");
	})

	// Render
	return (
		<>
			<Redirect to={RedirectUrl + window.location.search} />
		</>
	);
}


