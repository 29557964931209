import { AppBar, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isNullOrUndefined } from 'util';

export enum enumToolbarActionType {
    link,
    menu,
    onClick,
    search,
};

export interface ToolbarItem {
    title: string;
    icon: any;
    itemType: enumToolbarActionType;
    navUrl?: string;
    onClick?: () => void;
    subItems?: ToolbarItem[];
};

export function SimpleToolbar({ toolbarItems }: { toolbarItems: ToolbarItem[] })
{

    const [anchorElement, setAnchorElement] = useState<HTMLElement>();
    const [activeMenu, setActiveMenu] = useState<string>();

    //Attach the menu to an element and open it    
    function setMenuAnchor (event: React.MouseEvent<HTMLButtonElement>, menuName: string) {
        setAnchorElement(event.currentTarget);
        setActiveMenu(menuName);
    };

    //clear menu state when menu is closed
    function handleMenuClose() {
        setAnchorElement(undefined);
        setActiveMenu(undefined);
    };

    function CreateToolbar(items: ToolbarItem[]) {
        return items.map((item, index) => {
            switch (item.itemType) {
                case enumToolbarActionType.link:
                    return <div key={index}><Button color="inherit" startIcon={item.icon} component={Link} to={item.navUrl || ""}>{item.title}</Button></div>;
                case enumToolbarActionType.onClick:
                    return <div key={index}><Button color="inherit" startIcon={item.icon} onClick={item.onClick}>{item.title}</Button></div>;
                //case enumActionType.search:
                //    return <div key={index}><SimpleSearchBox label={item.title} onSearch={this.onCartSearch} startAdornment={item.icon} maxLength={20} /></div>;
                case enumToolbarActionType.menu:
                    return <div key={index}>
                        <Button color="inherit" startIcon={item.icon} onClick={e => setMenuAnchor(e, item.title)}>{item.title}</Button>
                        <Menu id={"Menu_" + index.toString()}
                            anchorEl={anchorElement}
                            keepMounted
                            open={Boolean(activeMenu === item.title)}
                            onClose={handleMenuClose}>
                            {CreateMenuItems(item.subItems)}
                        </Menu>
                    </div>;
            }
            return "";
        });
    }

    function CreateMenuItems(items ?: ToolbarItem[]) {
        if (items) {
            return items.map((item, index) => {
                switch (item.itemType) {
                    case enumToolbarActionType.link:
                        return <div key={index}><MenuItem color="inherit" onClick={handleMenuClose} component={Link} to={item.navUrl || ""}><ListItemIcon>{item.icon}</ListItemIcon><ListItemText primary={item.title} /></MenuItem></div>;
                    case enumToolbarActionType.onClick:
                        return <div key={index}><MenuItem color="inherit" onClick={() => { handleMenuClose(); if (!isNullOrUndefined(item.onClick)) { item.onClick(); } }}><ListItemIcon>{item.icon}</ListItemIcon><ListItemText primary={item.title} /></MenuItem></div>;
                }
                return "";
            });
        }
        else { return ""; }
    }


    return (
        <>
            <AppBar position="static">
                <Toolbar sx={{
                    background: `linear-gradient(-80deg, #00A0AF, white)`,
                    display: "flex",
                    color: "primary"
                }}>
                    <Box sx={{ flexGrow: 1 }}
                    >
                        <Box
                            component="img"
                            alt="Logo"
                            src="./1099Logo.png"
                            sx={{
                                flexGrow: 1,
                                width: "200px",
                                height: "50px",
                                marginTop: "1vh",
                            }}
                        />
                    </Box>
                    {CreateToolbar(toolbarItems)}
                </Toolbar>
            </AppBar>
        </>
    );
}