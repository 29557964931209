import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";

export interface ITen99Customer {
    customerId: string,
    name: string,
    billingInformation?: ITen99BillingInformation,
    actions: ActionTypesEnum[],
    ticks: string,
}

export interface ITen99BillingInformation {
    customerBillingIdentifier: string,
    billingIdentifierByFormCategoryTypeString: Record<string, string>,
}

export interface ITen99BillingInformationEngagements {
    formCategoryType: string,
    billingIdentifier: string,
}

export enum IFormCategoryTypeEnum {
    Type1099 = 1,
    Type1042S = 2,
    TypeW2 = 3,
}