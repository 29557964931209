import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import ReportHistory from "components/ten99Prep/Reporting/ReportHistory";
import ReportRequest from "components/ten99Prep/Reporting/ReportRequest";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { ITen99ReportHistory } from "sharedInterfaces/ITen99ReportHistory";
import { ApplicationState } from "store";
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	reportHistoryItemsCurrentPage: ITen99ReportHistory[],
	reportHistoryPagerCurrentPage: number,
	reportHistoryPagerTotalCount: number,
	reportHistoryStatus: enumComponentStatus
}

interface IReportsProps {
	customer: ITen99Customer,
	onClose: () => void,
}

type ReportsProps =
	& IReportsProps
	& HomeNavigationStore.CustomerSummaryState
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.PendingAction, reportHistoryStatus: enumComponentStatus.IsLoading, reportHistoryPagerCurrentPage: 1, reportHistoryPagerTotalCount: -1, reportHistoryItemsCurrentPage: {} as ITen99ReportHistory[], validationMessages: [] as ITen99ApiResponseMessage[] };
class Reports extends React.PureComponent<ReportsProps, ILocalState> {

	importHistoryPageSize: number = 8;
	importHistoryInterval: any;

	constructor(props: ReportsProps) {
		super(props);
		this.state = initialLocalState;
		this.reportHistorySearch(1); //get first page of import history

	}
	// -----------------
	//Handle User events
	
	private onCancel = () => {
		this.props.onClose();
	}

	private reportHistorySearch = (pageNumber: number) => {
		MakeApiCall<ITen99ReportHistory[]>("api/Reports/Results/" + this.props.customer.customerId + "/?PageNumber=" + pageNumber.toString() + "&PageSize=" + this.importHistoryPageSize, "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload)) {
					this.setState({ reportHistoryStatus: enumComponentStatus.PendingAction, reportHistoryItemsCurrentPage: data.payload, reportHistoryPagerCurrentPage: data.pageData.pageNumber, reportHistoryPagerTotalCount: data.pageData.totalCount });
				}
				else if (data.httpStatusCode === 404) {
					//customer not found
					this.setState({ reportHistoryStatus: enumComponentStatus.PendingAction, reportHistoryItemsCurrentPage: {} as ITen99ReportHistory[], reportHistoryPagerCurrentPage: 0, reportHistoryPagerTotalCount: 0 });
				}
				else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
					//empty
					this.setState({ reportHistoryStatus: enumComponentStatus.PendingAction, reportHistoryItemsCurrentPage: {} as ITen99ReportHistory[], reportHistoryPagerCurrentPage: 0, reportHistoryPagerTotalCount: 0 });
				}
				else {
					this.setState({ reportHistoryStatus: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load history" }] as ITen99ApiResponseMessage[] });
				}
			})
	}

	private reportHistoryDownloadClick = (id: number) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: [] });
		MakeApiCall<string>("api/Reports/" + id.toString(), "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let fileName: string | undefined = this.state.reportHistoryItemsCurrentPage.find(x => x.reportId === id)?.fileName;

						PromptDownloadFile(data.payload, "application/pdf", fileName ?? "Report.pdf")
					}
				}
				this.setState({ status: enumComponentStatus.PendingAction, validationMessages: !isNullOrUndefined(data.details) ? data.details : [] });
			})
	}

	private onReportRequested = (reportId: number) => {		
		this.props.toastNotifcations_Display({ id: "ReportRequested" + reportId, message: "Request " + reportId + " has been requested. Your report should show up shortly in Report History. You can track its progress there.", type: "success" });
		this.reportHistorySearch(this.state.reportHistoryPagerCurrentPage);
	}
	// -----------------
	// Componet lifecycle events
	// -----------------
	componentDidMount() {
		this.importHistoryInterval = setInterval(() => { if (this.state.reportHistoryStatus !== enumComponentStatus.IsLoading) { this.reportHistorySearch(this.state.reportHistoryPagerCurrentPage) }}, 30000); //check after waiting a bit

    }

	componentWillUnmount() {
		//ensure timeouts are gone
		clearInterval(this.importHistoryInterval);
	}
	// Render
	render() {
		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h5">Reporting: {this.props.customer.name} for filing year {this.props.currentFilingYear} <InfoToolTip text="The year displayed is controlled by the Filing Year in the menu in the upper right corner" /></Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent style={{ padding: 20 }}>
					{(this.state.status === enumComponentStatus.Processing) && (
						<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
					)}
					<Grid container spacing={3} sx={{textAlign: 'center'}}>
								<Grid item xs={12}>
									<Grid container spacing={1} sx={{textAlign: 'center'}}>
										<Grid item xs={12} sx={{
											border: 'solid 1px ',
											borderColor: 'primary.dark',
											marginBottom: "10px",
											paddingRight: "10px",
											paddingBottom: "10px"
										}}>
											<ReportRequest customerId={this.props.customer.customerId} onReportRequested={this.onReportRequested} />
										</Grid>										
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1} sx={{textAlign: 'center'}}>
										<Grid item xs={12} sx={{
											border: 'solid 1px ',
											borderColor: 'primary.dark',
											marginBottom: "10px",
											paddingRight: "10px",
											paddingBottom: "10px"
										}}>
											<ReportHistory status={this.state.reportHistoryStatus} history={this.state.reportHistoryItemsCurrentPage} pagerPageSize={this.importHistoryPageSize} pagerCurrentPage={this.state.reportHistoryPagerCurrentPage} pagerTotalCount={this.state.reportHistoryPagerTotalCount} onPagerClick={this.reportHistorySearch} onDownloadClick={this.reportHistoryDownloadClick} />
										</Grid>
									</Grid>
								</Grid>
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Close</Button>
				</DialogActions>

			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, }, // Selects which action creators are merged into the component's props
)((Reports));