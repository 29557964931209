import { Announcement } from '@mui/icons-material';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import * as React from 'react';
import { ITen99Message } from 'sharedInterfaces/ITen99Message';
import { ITen99MessagePriorityEnum } from 'sharedInterfaces/ITen99MessagePriorityEnum';

interface IMessagesDisplayProps {
	messages: ITen99Message[],
}

class MessagesDisplay extends React.PureComponent<IMessagesDisplayProps> {

	constructor(props: IMessagesDisplayProps) {
		super(props);		
	}
	// -----------------
	//Handle User events
	

	public getColor(message: ITen99Message): 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error' | undefined {
		switch (message.priority) {
			case ITen99MessagePriorityEnum.Error:
				return "error";
			case ITen99MessagePriorityEnum.Urgent:
				return "primary";
			case ITen99MessagePriorityEnum.Normal:
				return "inherit";
		}
	}

	public getMessage(message: ITen99Message) {
		switch (message.priority) {
			case ITen99MessagePriorityEnum.Error:
				return (<Typography variant="body2" color="error" >{message.messageText}</Typography>);
			case ITen99MessagePriorityEnum.Urgent:
				return (<Typography variant="body2" color="primary" >{message.messageText}</Typography>);
			case ITen99MessagePriorityEnum.Normal:
				return (<Typography variant="body2" color="textPrimary" >{message.messageText}</Typography>);
		}
	}
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		return (
			<React.Fragment>				
				<Grid container spacing={1} sx={{textAlign: "center"}}>
						<Grid item xs={12}>
						<List dense={true}>
							{this.props.messages.map((item, index) => <ListItem key={index}><ListItemIcon><Announcement color={this.getColor(item)} /></ListItemIcon><ListItemText primary={this.getMessage(item)} /></ListItem>)}
							</List>
						</Grid>
					</Grid>
			</React.Fragment>			
		);
	}
}

// -----------------
export default MessagesDisplay;