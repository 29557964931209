import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import AlertsDashboard from 'components/information/AlertsDashboard';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import EntityAddEditView from 'components/ten99Prep/EntityAddEditView/EntityAddEditView';
import { EntityNav } from 'components/ten99Prep/EntityAddEditView/EntityNav';
import FabButtons from 'components/ten99Prep/FabButtons';
import FormHistory from 'components/ten99Prep/FormHistory';
import FormSummary from 'components/ten99Prep/FormSummary';
import SummaryTitle from 'components/ten99Prep/SummaryTitle';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum, IAction } from 'sharedInterfaces/ITen99Action';
import { enumAddEditViewAction, enumEntityType, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage, ITen99Dashboard } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99CartElement } from 'sharedInterfaces/ITen99Cart';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigation from 'store/HomeNavigation';
import { ICustomerSummaryActions } from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99PrepCancelIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from 'utilities/ApiFunctions';
import { getEntityPrintableName } from 'utilities/EntityNameSummary';

enum enumComponentStatus {
	PendingAction,
	Processing,
	Loading,
	Invalid,
	Error
}; 

interface IPayerStateAccountsDashboardProps {
	payer: ITen99EntitySummary,
	parents: ITen99EntitySummary[],
	onClose: () => void,
}

interface ILocalState {
	status: enumComponentStatus,
	payerForms: ITen99Dashboard<ITen99EntitySummary>,
	addEditViewEntityOpen: boolean,
	addEditViewEntitySetting?: IAevEntitySetting,
	dialogOpen: boolean,
	dialogType?: ActionTypesEnum,
	dialogEntities?: ITen99EntitySummary[],
	validationMessages: ITen99ApiResponseMessage[],
}

type PayerStateAccountsDashboardProps =
	IPayerStateAccountsDashboardProps
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& ICustomerSummaryActions // ... plus action creators we've requested
	;


const initialLocalState = { status: enumComponentStatus.Loading, payerForms: {} as ITen99Dashboard<ITen99EntitySummary>, addEditViewEntityOpen: false, addEditViewEntitySetting: undefined, dialogOpen: false, dialogType: undefined, dialogEntities: undefined, validationMessages: [] as ITen99ApiResponseMessage[]} as ILocalState;
class PayerForms extends React.PureComponent<PayerStateAccountsDashboardProps, ILocalState> {
	//local state
	constructor(props: PayerStateAccountsDashboardProps) {
		super(props);
		this.state = initialLocalState;

		this.loadData();
	}
	// -----------------
	//Handle User events


	private loadData = () => {

		if (this.state.status !== enumComponentStatus.Loading) {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: [] as ITen99ApiResponseMessage[] });
		}
		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/PayerForms/Summary/?payerId=" + this.props.payer.id + "&PageNumber=1&PageSize=50", "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload) && !isNullOrUndefined(data.payload.rows)) {
					this.setState({ payerForms: data.payload, status: enumComponentStatus.PendingAction })
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the Payer Forms" }] as ITen99ApiResponseMessage[] });
				}
			});
	}

	private onAdd = () => {
		let tempParents: ITen99EntitySummary[] = [];
		tempParents.push(...this.props.parents, this.props.payer)
		this.onAddEditViewOpen({ parents: tempParents, action: enumAddEditViewAction.Add, type: enumEntityType.PayerForm } as IAevEntitySetting)
	}
	private onEdit = (entity: ITen99EntitySummary, parents: ITen99EntitySummary[]) => { this.onAddEditViewOpen({ id: entity.id, parents: parents, action: enumAddEditViewAction.Edit, type: enumEntityType.PayerForm } as IAevEntitySetting) }
	private onView = (entity: ITen99EntitySummary, parents: ITen99EntitySummary[]) => { this.onAddEditViewOpen({ id: entity.id, parents: parents, action: enumAddEditViewAction.View, type: enumEntityType.PayerForm } as IAevEntitySetting) }
	private onDelete = (entity: ITen99EntitySummary) => { this.props.confirmDialog_Display("Delete Payer Form", entity.id, "You are about to delete the following " + getEntityPrintableName(entity), this.onDeleteProceed); }
	private onDeleteProceed = (id: string) => {

		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing && !isNullOrUndefined(this.state.payerForms.rows)) {
			//get the customer to make sure it is still there and get name
			const entity: ITen99EntitySummary | undefined = this.state.payerForms.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				//call the api and set the results in the state to reflect on the render
				MakeApiCall<string>("api/PayerForms/" + id + "/?ticks=" + entity.ticks, "DELETE")
					.then(data => {
						if (data.isSuccess) {
							//refresh list
							this.loadData();
							//send toast notification
							this.props.toastNotifcations_Display({ id: "PayerFormDelete" + id, message: getEntityPrintableName(entity) + " deleted", type: "success" });
						}
						else {
							//send toast notification
							this.props.toastNotifcations_Display({ id: "PayerFormDelete" + id, message: "Issue occurred deleting " + getEntityPrintableName(entity), type: "error" });
						}
					});
			}
			else {
				console.error("Unable to locally find Form: " + id);
			}
		}
	}
	private onAddEditViewOpen = (entitySetting: IAevEntitySetting) => {
		this.setState({ addEditViewEntityOpen: true, addEditViewEntitySetting: entitySetting });
	}
	private onAddEditViewEntityProccessed = (response: IAevEntityResponse) => {
		let entityId: string | undefined = undefined;
		if (!isNullOrUndefined(this.state.addEditViewEntitySetting)) {
			entityId = this.state.addEditViewEntitySetting.id;
		}
		this.setState({ addEditViewEntityOpen: false, addEditViewEntitySetting: undefined, validationMessages: {} as ITen99ApiResponseMessage[] });
		if (response.processed) {
			this.loadData();
			//send toast notification
			this.props.toastNotifcations_Display({ id: "PayerFormAddEdit" + entityId, message: response.message, type: "success" });
		}
	}

	private onViewDoc = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })
		MakeApiCall<string>("api/ReportManage/TaxForm/Type/" + entity.type + "/Id/" + entity.id, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
						this.setState({ status: enumComponentStatus.PendingAction })
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //error
				}
			})
	}

	private onSubmitToCart = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing }); //display modal
		//call the api and set the results in the state to reflect on the render
		MakeApiCall<number>("api/CartElements/", "POST", JSON.stringify({ payerFormId: Number.parseInt(entity.id), ticks: entity.ticks } as ITen99CartElement))
			.then(data => {
				if (data.isSuccess) {
					if (data.payload === 0) { // zero returned when not done becuase already exists
						this.props.toastNotifcations_Display({ id: "CartSubmit" + entity.id, message: getEntityPrintableName(entity) + " is already in cart", type: "warning" });
					}
					else {
						//send toast notification
						this.props.toastNotifcations_Display({ id: "CartSubmit" + entity.id, message: getEntityPrintableName(entity) + " added to cart", type: "success" });
						this.props.CustomerSummaryActions_RefreshSummaryCounts();
						this.loadData();
					}
				}
				else {
					//send toast notification
					this.props.toastNotifcations_Display({ id: "CartSubmit" + entity.id, message: "Issue occurred adding " + getEntityPrintableName(entity) + " to cart", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction }); //remove modal
			});
	}

	private onOpenDialog = (dialogType: ActionTypesEnum, entity: ITen99EntitySummary) => {
		this.setState({ dialogOpen: true, dialogType: dialogType, dialogEntities: [entity] });
	}

	private onCloseDialog = () => {
		this.setState({ dialogOpen: false, dialogType: undefined, dialogEntities: undefined });
	}

	private onFormHistoryLoad = (entity: ITen99EntitySummary) => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })

		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/PayerForms/" + entity.id + "/FormHistory", "Get")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.rows.length > 0) {
					this.setState({ dialogOpen: true, dialogType: ActionTypesEnum.FormHistory, dialogEntities: data.payload.rows })
				}
				else {
					this.props.toastNotifcations_Display({ id: "FormHistory" + entity.id, message: "Unable to load Form History", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}

	private onGenericAction = (entity: ITen99EntitySummary, actionId: number) => {

		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] })

		MakeApiCall<string>("api/PayerForms/" + entity.id + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, ticks: entity.ticks } as IAction))
			.then(data => {
				if (data.isSuccess) {
					//refresh list
					this.loadData();
					this.props.toastNotifcations_Display({ id: "Form" + entity.id + "action" + actionId, message: "Action Processed", type: "success" });
				}
				else {
					this.props.toastNotifcations_Display({ id: "Form" + entity.id + "action" + actionId, message: "Unable to Process action", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}

	private onActionClickByEntity = (entity: ITen99EntitySummary, action: ActionTypesEnum) => {
		
		let tempParents: ITen99EntitySummary[] = [];
		tempParents.push(...this.props.parents, this.props.payer)
		switch (action) {
			case ActionTypesEnum.Add:
				this.onAdd();
				break;
			case ActionTypesEnum.Delete:
				this.onDelete(entity);
				break;
			case ActionTypesEnum.Edit:
				this.onEdit(entity, tempParents);
				break;
			case ActionTypesEnum.SubmitToCart:
				this.onSubmitToCart(entity);
				break;
			case ActionTypesEnum.View:
				this.onView(entity, tempParents);
				break;
			case ActionTypesEnum.ViewDoc:
				this.onViewDoc(entity);
				break;
			case ActionTypesEnum.ShowAlerts:
				this.onOpenDialog(action, entity);
				break;
			case ActionTypesEnum.FormHistory:
				this.onFormHistoryLoad(entity)
				break;
			default:
				this.onGenericAction(entity, action.valueOf());
				break;

		}
	}
	private onActionClickById = (id: string, action: ActionTypesEnum) => {
		const entity: ITen99EntitySummary | undefined = id === "" ? { id: "" } as ITen99EntitySummary : this.state.payerForms.rows.find((item) => item.id === id)

		if (!isNullOrUndefined(entity)) {
			this.onActionClickByEntity(entity, action);
		}
		else {
			console.error("Unable to locally find form: " + id);
		}
	}
	// -----------------

	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		return (
			<React.Fragment>
				<DialogTitle>
					<SummaryTitle variant="h5" entity={this.props.payer} /><Typography variant="h5" display="inline">{"- State Reconciliation Forms"}
						<InfoToolTip text="Some states require additional reconciliation forms be filled out. " />
						<FabButtons entityType={EnumEntityType.PayerForm} actions={this.state.payerForms.bulkActions} onClick={this.onActionClickById} />
					</Typography>
					<EntityNav items={this.props.parents} />
										
				</DialogTitle>
				<DialogContent>
					{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
					<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
				)}
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
					<Grid item xs={12}>
						{!isNullOrUndefined(this.state.payerForms.rows) && this.state.payerForms.rows.length > 0 && (
							<React.Fragment>
								{
									this.state.payerForms.rows.map((item, index) => {
										return (
											<FormSummary key={index} onActionClick={this.onActionClickById} summaryItem={item} />
										)
									})
								}
							</React.Fragment>
							)}
							{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Invalid) && (isNullOrUndefined(this.state.payerForms.rows) || this.state.payerForms.rows.length === 0) && (
								<Typography variant="body2">No State Reconciliation Forms found for this payer</Typography>
							)}
					</Grid>
					<Grid item xs={12}>
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						)}
					</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={(e) => this.props.onClose()}>Close</Button>
				</DialogActions>

				{this.state.addEditViewEntityOpen && !isNullOrUndefined(this.state.addEditViewEntitySetting) && (
					<EntityAddEditView onClose={this.onAddEditViewEntityProccessed} entitySettings={this.state.addEditViewEntitySetting} />
				)}
				{this.state.dialogOpen && !isNullOrUndefined(this.state.dialogType) && !isNullOrUndefined(this.state.dialogEntities) && this.state.dialogEntities.length > 0 && (
					<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth="lg">
						{this.state.dialogType === ActionTypesEnum.ShowAlerts && (
							<AlertsDashboard alertSource={this.state.dialogEntities[0]} alertSourceParents={this.props.parents} onClose={this.onCloseDialog} />
						)}
						{this.state.dialogType === ActionTypesEnum.FormHistory && (
							<FormHistory parents={this.props.parents} history={this.state.dialogEntities} onActionClickByEntity={this.onActionClickByEntity} onClose={this.onCloseDialog} />
						)}
					</Dialog>
				)}
			</React.Fragment>
		)
	}
}

export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, ...HomeNavigation.actionCreators } // Selects which action creators are merged into the component's props
)(PayerForms);
