import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import * as React from 'react';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99Contact } from 'sharedInterfaces/ITen99Contact';



interface IAddressExternalProps {
	onchange: (event: { target: { name: string; value: any; }; }) => void,
	disabled?: boolean,
	contact: ITen99Contact,
	invalid?: boolean,
	phoneRequired?: boolean,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
	extendedContact?: boolean
}
type AddressExternalProps =
	IAddressExternalProps
	;

class Contact extends React.PureComponent<AddressExternalProps> {
	//local state
	
	// -----------------
	//Handle User events

	
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleContactPhoneChange = (event: { target: { name: string; value: any; }; }) => {
	    //becuase the raido button are returning strings, no matter what
		this.props.onchange({ target: { name: event.target.name, value: event.target.value === "true" } });
	}
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		const phoneMaskFormat = this.props.contact.isPhoneNumberInternational ? "+###############" : "(###) ###-####";
		const phoneExtMaskFormat = "#####";
		
		return (			
			<React.Fragment>
				<Grid item xs={12}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.props.onchange}
						value={this.props.contact.contactName}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ContactName") !== undefined)}
						id="contactName"
						label="Name"
						name="contactName"
						fullWidth
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.props.onchange}
						value={this.props.contact.email}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ContactEmail") !== undefined)}
						id="email"
						label="Email"
						name="email"
						fullWidth
					/>
				</Grid>

				{(this.props.extendedContact ?? false) && (
					<Grid item xs={12}>
						<TextField
							disabled={this.props.disabled}
							onChange={this.props.onchange}
							value={this.props.contact.title}
							variant="outlined"
							error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ContactTitle") !== undefined)}
							id="title"
							label="Title"
							name="title"
							fullWidth
						/>
					</Grid>
				)}

				<Grid item xs={12} sx={{textAlign: 'left'}}>
					<FormControl
						fullWidth
						required={this.props.phoneRequired}>
						<FormLabel id="isPhoneNumberInternational">Phone Number Type</FormLabel>
						<RadioGroup name="isPhoneNumberInternational" value={this.props.contact.isPhoneNumberInternational.toString()} onChange={this.handleContactPhoneChange} row>
							<FormControlLabel disabled={this.props.disabled} value={false.toString()} label="Domestic" control={<Radio color="primary" />} />
							<FormControlLabel disabled={this.props.disabled} value={true.toString()} label="International" control={<Radio color="primary" />} />
						</RadioGroup>
					</FormControl>
				</Grid>

				<Grid item xs={8}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.props.onchange}
						value={this.props.contact.phoneNumber}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ContactPhoneNumber") !== undefined)}
						id="phoneNumber"
						label="Phone Number"
						name="phoneNumber"
						InputProps={{
							inputComponent: CustomerNumberFormat as any,
							inputProps: { format: phoneMaskFormat, mask: "#" }
						}}
						required={this.props.phoneRequired}
						fullWidth
					/>
				</Grid>

				<Grid item xs={4}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.props.onchange}
						value={this.props.contact.phoneNumberExt}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ContactPhoneNumberExt") !== undefined)}
						id="phoneNumberExt"
						label="Extension"
						name="phoneNumberExt"
						InputProps={{
							inputComponent: CustomerNumberFormat as any,
							inputProps: { format: phoneExtMaskFormat, mask: "#" }
						}}
						fullWidth
					/>
				</Grid>		

				{(this.props.extendedContact ?? false) && (
					<Grid item xs={12}>
						<TextField
							disabled={this.props.disabled}
							onChange={this.props.onchange}
							value={this.props.contact.faxNumber}
							variant="outlined"
							error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ContactFaxNumber") !== undefined)}
							id="faxNumber"
							label="Fax Number"
							name="faxNumber"
							InputProps={{
								inputComponent: CustomerNumberFormat as any,
								inputProps: { format: phoneMaskFormat, mask: "#" }
							}}
							fullWidth
							/>
					</Grid>
				)}
			</React.Fragment>
		);
	}
}
export default ((Contact));
