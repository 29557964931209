import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import MessagesDisplay from "components/information/MessagesDisplay";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Message } from "sharedInterfaces/ITen99Message";
import { ITen99Notification } from "sharedInterfaces/ITen99Notification";
import { Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	notifications: ITen99Notification[],
	validationMessages: ITen99ApiResponseMessage[],
}

interface INotificationsProps {
	userId: string,
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,
}

type NotificationsProps =
	& INotificationsProps
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.IsLoading, notifications: {} as ITen99Notification[], validationMessages: [] as ITen99ApiResponseMessage[] };
class UserNotifications extends React.PureComponent<NotificationsProps, ILocalState> {

	readonly pageSize: number = 25;

	constructor(props: NotificationsProps) {
		super(props);
		this.state = initialLocalState;
		this.search();
	}
	// -----------------
	//Handle User events
	private search() {

		MakeApiCall<ITen99Notification[]>("api/Notifications/?PageNumber=1&PageSize=25", "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					if (data.payload.length > 0) {
						this.setState({ status: enumComponentStatus.PendingAction, notifications: data.payload });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "No notifcations found" }] as ITen99ApiResponseMessage[] });
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load notifcations" }] as ITen99ApiResponseMessage[] });
				}
			})
	}

	private onCancel = () => {
		this.props.onClose(false, undefined);
	}
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	//original map of tabs with mutlitple cusomter , might need in future
	//{ !isNullOrUndefined(this.props.activeCustomers) && this.props.activeCustomers.map((item, index) => <Tab key={index}> id={"Customer_" + item.CustomerId} >{item.Name}</Tab>) }
	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Notifications (Last 25)
							<InfoToolTip text="The last 25 notifications for the current user" />
							</Typography>
						</Grid>		
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
							<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
						)}
						{!isNullOrUndefined(this.state.notifications) && this.state.notifications.length > 0 && (
							<React.Fragment>
								<MessagesDisplay messages={this.state.notifications.map((item, index) => ({ messageText: item.notificationText, priority: item.prioirtyId } as ITen99Message))} />
							</React.Fragment>
						)}
						{this.state.status === enumComponentStatus.PendingAction && isNullOrUndefined(this.state.notifications) && (
							<React.Fragment>
								<Grid item xs={12}>
									<Typography variant="h6">No items returned</Typography>
								</Grid>
							</React.Fragment>
						)}
						{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Close</Button>
				</DialogActions>

			</React.Fragment>
		)
	}
}

export default UserNotifications;