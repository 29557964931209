import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99ReportHistory } from "sharedInterfaces/ITen99ReportHistory";
import { Ten99PrepDownloadIcon, Ten99PrepFormIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from "util";
import { ActionTypesEnum } from "../../../sharedInterfaces/ITen99Action";
import { ITen99DynamicButtonProps } from "../../../sharedInterfaces/ITen99DynamicButtonProps";
import ComplexIconButton from "../../buttons/ComplexIconButton";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {		
	validationMessages: ITen99ApiResponseMessage[],	
}

interface IReportHistoryProps {
	history: ITen99ReportHistory[],
	pagerCurrentPage: number,
	pagerTotalCount: number,
	pagerPageSize: number,
	onPagerClick: (pageNumber: number) => void,
	onDownloadClick: (id: number) => void,
	status: enumComponentStatus,
}

type ReportHistoryProps =
	& IReportHistoryProps
	;

const initialLocalState: ILocalState = { validationMessages: [] as ITen99ApiResponseMessage[] };
class ReportHistory extends React.PureComponent<ReportHistoryProps, ILocalState> {
	
	constructor(props: ReportHistoryProps) {
		super(props);
		this.state = initialLocalState;

	}
	// -----------------
	//Handle User events
	
	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.props.onPagerClick(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private getStatusText = (item: ITen99ReportHistory) =>
	{	if (!item.processed) {
			return "Processing";
		}
		else {
			switch (item.reportStatusId) {
				case 1:
					return "Pending";
				case 2:
					return "Processing";
				case 3:
					return "Completed";
				case 4:
					return "Failed";
				case 5:
					return "No Data Found";
				case 6:
					return "Aggregating";
				default:
					return "";
			}
		}


	}

	private getStatusClassName = (item: ITen99ReportHistory) => {
		
	if (!item.processed) {
		return undefined;
		}
		else {
			switch (item.reportStatusId) {
				case 3:
					return 'success.main';
				case 4:
					return 'error.main';
				default:
					return undefined;;
			}
		}
	}
		
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {
		return (
			<React.Fragment>
				<Grid container spacing={1} sx={{
					textAlign: 'center'
				}}>
					<Grid item xs={12} sx={{
						textAlign: 'left'
					}}>
						<Typography variant="h5">Report History</Typography>
					</Grid>
					{(this.props.status === enumComponentStatus.Processing || this.props.status === enumComponentStatus.IsLoading) && (
						<ProcessingModal modal={this.props.status === enumComponentStatus.Processing} />
					)}
					{this.props.history.length > 0 && (
						<React.Fragment>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>
												<Typography variant="body2" sx={{fontWeight : 'bold'}}>Report Id</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Report Date</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Payer</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Report Type</Typography>
											</TableCell>											
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Status</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2" sx={{ fontWeight: 'bold' }}>Report</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.props.history.map((item, index) => {
											return (<TableRow key={index}>
												<TableCell>
													<Typography variant="body2">{item.reportId}
													{!isNullOrUndefined(item.parentReportId) && (
														<ComplexIconButton itemId="rptParentId" dense uiProps={{ action: ActionTypesEnum.View, icon: <Ten99PrepFormIcon />, color: "info", text: "", tooltip: "Parent Report Id: " + item.parentReportId.toString() } as ITen99DynamicButtonProps} />
														)}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.reportDate}</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.payer}</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">{item.reportType}</Typography>
												</TableCell>
												<TableCell>
													<Typography color={this.getStatusClassName(item)} variant="body2">{this.getStatusText(item)}</Typography>
												</TableCell>
												<TableCell>
													{(!item.processed || item.reportStatusId !== 3) && (
														<React.Fragment>
															<Typography variant="body2">NA</Typography>
														</React.Fragment>
													)}
													{item.processed && item.reportStatusId === 3 && (
														<IconButton onClick={e => this.props.onDownloadClick(item.reportId)} sx={{ padding:"0px" }}>
															<Ten99PrepDownloadIcon />
														</IconButton>
													)}
												</TableCell>												
											</TableRow>)
										}
										)}
									</TableBody>
									<TableFooter>
										<TableRow>
											<TablePagination
												page={this.props.pagerCurrentPage - 1}
												rowsPerPage={this.props.pagerPageSize}
												rowsPerPageOptions={[]}
												count={this.props.pagerTotalCount}
												onPageChange={this.onPagerClick}
											/>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</React.Fragment>
					)}
					{this.props.status === enumComponentStatus.PendingAction && this.props.history.length === 0 && (
						<React.Fragment>
							<Grid item xs={12}>
								<Typography variant="h6">No items in History</Typography>
							</Grid>
						</React.Fragment>
					)}
					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
						<ApiErrorResponseMessages messages={this.state.validationMessages} />
					)}
				</Grid>
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default ReportHistory;