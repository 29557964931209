import { TablePagination } from '@mui/material';
import * as React from 'react';
import { ITen99PagerInfo } from 'sharedInterfaces/ITen99PagerInfo';
import { isNullOrUndefined } from 'util';

interface IPagerProps {
	pagerInfo: ITen99PagerInfo,
	show?: boolean
}

class Pager extends React.PureComponent<IPagerProps> {
	//local state
	// -----------------
	//Handle User events

	// Render
	render() {
		
		return (
			<React.Fragment>
				{(isNullOrUndefined(this.props.show) || this.props.show) &&	
					<TablePagination
						component="div"
						page={this.props.pagerInfo.pageNumber - 1}
						rowsPerPage={this.props.pagerInfo.pageSize}
						rowsPerPageOptions={[]}
						count={this.props.pagerInfo.totalCount}
						onPageChange={this.props.pagerInfo.onPagerClick}
					/>
				}
			</React.Fragment>
		);
	}
}

export default (Pager);