import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	qboPayers: ITen99LookupItem[],
}

interface IQboPayerSelectProps {
	customerId: number,
	validationMessages?: ITen99ApiResponseMessage[],
	selectedPayerId: number,
	onPayerSelectChange: (payer: ITen99LookupItem) => void,
}

type QboPayerSelectProps =
	& IQboPayerSelectProps
;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.Processing,
	validationMessages: [] as ITen99ApiResponseMessage[],
	qboPayers: [] as ITen99LookupItem[],
};
class QboPayerSelect extends React.PureComponent<QboPayerSelectProps, ILocalState> {


	constructor(props: QboPayerSelectProps) {
		super(props);
		this.state = JSON.parse(JSON.stringify(initialLocalState));;

		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/70/?parentId=" + this.props.customerId, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 70);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems)) {
							this.setState({ status: enumComponentStatus.PendingAction, qboPayers: lookup.lookupItems});
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load payers" }] as ITen99ApiResponseMessage[] });
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})
	}
	// -----------------
	//Handle User events
	private onDropDownChange = (event: SelectChangeEvent<number>) => {
		this.props.onPayerSelectChange(this.state.qboPayers.find(x => x.id === event.target.value as number) ?? {} as ITen99LookupItem);
	}

	// Render

	render() {	
		
		return (
			<React.Fragment>
				<Grid container spacing={1}>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="h6">Select Payer to import data for</Typography>
					</Grid>

					{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ProcessingModal modal={true} />
						</Grid>
					)}
					
					{this.props.validationMessages !== undefined && this.props.validationMessages.length > 0 && (this.state.status !== enumComponentStatus.Processing && this.state.status !== enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ApiErrorResponseMessages messages={this.props.validationMessages} />
						</Grid>
					)}

					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (this.state.status !== enumComponentStatus.Processing && this.state.status !== enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					)}
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<FormControl
							fullWidth
							required>
							<InputLabel id="formTypeLabel">Payers</InputLabel>
							<Select
								labelId="formTypeLabel"
								label="Form Type"
								value={this.props.selectedPayerId}
								onChange={this.onDropDownChange}
								inputProps={{}}
								id="selectedFormType"
								name="selectedFormType"
							>
								<MenuItem key={-1} value={-1}>(Add new payer)</MenuItem>
								{(this.state.qboPayers.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

export default QboPayerSelect;