import { Button, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, Typography } from "@mui/material";
import ActionButton from "components/buttons/ActionButton";

import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import SummaryTitle from "components/ten99Prep/SummaryTitle";
import { useEffect, useState } from "react";
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { Ten99PrepCancelIcon, Ten99PrepEmailIcon, Ten99PrepFormIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { EntityNav } from "./EntityAddEditView/EntityNav";

export default function FormDeliveryDialog({ recipientFormId, navItems, onClose }: { recipientFormId: string, navItems?: ITen99EntitySummary[], onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void })
{
	interface ICartDetails {
		cartId: number,
		cartElementId: number,
		dateSubmit: string,
		mailType: string,
		isShowSendEmail: boolean
	}

	interface IHistoryDetails {
		recipientEmailId: number,
		recipientEmail: string,
		statusText: string,
		isRevoked: boolean
	}

	interface IFormDeliveryHistory {
		recipientEmail: string,
		cartDetails: ICartDetails,
		emailDetails: IHistoryDetails[]
	}


	enum enumComponentStatus {
		Initializing,
		Loading,
		PendingAction,
		ProcessingAction,
		Invalid,
		Error
	};

	const processingStatuses = new Set([enumComponentStatus.Initializing,
		enumComponentStatus.Loading,
		enumComponentStatus.ProcessingAction,
	])


	const [status, setStatus] = useState<enumComponentStatus>(enumComponentStatus.Initializing);
	const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([] as ITen99ApiResponseMessage[]);
	const [formDeliveryHistory, setFormDeliveryHistory] = useState<IFormDeliveryHistory>({} as IFormDeliveryHistory);
	
	useEffect(() => {
		if (status === enumComponentStatus.Initializing) {
			Load();
		}
		//console.log("render: status = " + status + ", isProcessing = " + processingStatuses.has(status) + ", dashboard = " + dashboardData.length);
	})

	function Load() {
		setStatus(enumComponentStatus.Loading);
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		MakeApiCall<IFormDeliveryHistory>("api/RecipientEmail/ViewHistory/" + recipientFormId, "GET")
			.then(data => {
				if (data.isSuccess && data.payload !== undefined) {
					setFormDeliveryHistory(data.payload);
					setStatus(enumComponentStatus.PendingAction);
				}
				else {
					setStatus(enumComponentStatus.PendingAction);

					if (data.details !== undefined && data.details.length > 0) {
						setValidationMessages(data.details);
					}
					else {
						setValidationMessages([{ type: "Error", message: "Unable to load the Form Delivery details." }] as ITen99ApiResponseMessage[]);
					}
					
				}
			});
	}

	function ResendEmail(id: string, action: ActionTypesEnum) {
		setStatus(enumComponentStatus.ProcessingAction);
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		MakeApiCall<IFormDeliveryHistory>("api/RecipientEmail/EmailCartElement/" + id, "POST")
			.then(data => {
				if (data.isSuccess && data.payload !== undefined) {
					Load();
				}
				else {
					setStatus(enumComponentStatus.PendingAction);

					if (data.details !== undefined && data.details.length > 0) {
						setValidationMessages(data.details);
					}
					else {
						setValidationMessages([{ type: "Error", message: "Unable to resend the email." }] as ITen99ApiResponseMessage[]);
					}
				}
			});

	}

	function InactivateEmailLink(id: string, action: ActionTypesEnum) {
		setStatus(enumComponentStatus.ProcessingAction);
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		MakeApiCall<IFormDeliveryHistory>("api/RecipientEmail/InactivateRecipientEmail/" + id, "DELETE")
			.then(data => {
				if (data.isSuccess && data.payload !== undefined) {
					Load();
				}
				else {
					setStatus(enumComponentStatus.Error);
					setValidationMessages([{ type: "Error", message: "Unable to inactivate the Email link." }] as ITen99ApiResponseMessage[]);
				}
			});
	}

	return (
		<>
			<DialogTitle>
				<Grid container spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="h4">Statements to Recipient
							<InfoToolTip text="Details about how the statement was delivered to Recipient" />
						</Typography>
						{navItems !== undefined && navItems.length > 0 && (
							<EntityNav items={navItems} />
						)}
						
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop: "8px" }}>
					{processingStatuses.has(status) && (
						<ProcessingModal modal={true} />
					)}

					{!isNullOrUndefined(validationMessages) && validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={validationMessages} />
						</Grid>
					)}

					
					{!isNullOrUndefined(formDeliveryHistory.recipientEmail) && (
					<Grid container spacing={1} sx={{
						textAlign: 'center',
						backgroundColor: "form.main",
						marginTop: "10px",
						marginBottom: "10px",
						alignItems: "center",
						paddingLeft: "16px",
						paddingRight: "16px",
						minHeight: "64px"
					}}>
						<Grid item xs={9} sx={{ textAlign: "left", display: "flex" }}>
							<Ten99PrepFormIcon /><SummaryTitle entity={{
								id: "document",
									names: [
									{ title: "Email", descr: formDeliveryHistory.recipientEmail, displayOrder: 1 },
									{ title: "Cart", descr: formDeliveryHistory.cartDetails.cartId, displayOrder: 2 },
									{ title: "Date Submitted", descr: formDeliveryHistory.cartDetails.dateSubmit, displayOrder: 3 },
									{ title: "Statements to Recipients", descr: formDeliveryHistory.cartDetails.mailType, displayOrder: 4 }]
							} as ITen99EntitySummary} inline={true} />
						</Grid>
						<Grid item xs={3} sx={{ textAlign: 'right' }}>
							<ActionButton itemId={formDeliveryHistory.cartDetails.cartElementId.toString()} uiProps={{
								action: ActionTypesEnum.FormDelivery, icon: <Ten99PrepEmailIcon />, text: "", color: "secondary", tooltip: "Resend Email", onClick: ResendEmail } as ITen99DynamicButtonProps} />
						</Grid>
					</Grid>
					)}

					<Grid item xs={12}>
						{!isNullOrUndefined(formDeliveryHistory.emailDetails) && (
							<List disablePadding>
								{
									formDeliveryHistory.emailDetails.map((item, index) => {
										return (
											<ListItem key={index} disablePadding sx={{
												paddingLeft: 4
											}}>
												<Grid item xs={9} sx={{ textAlign: "left", display: "flex" }}>
													<Ten99PrepEmailIcon /><SummaryTitle entity={{
														id: "document",
														names: [{ title: "Email", descr: item.recipientEmail, displayOrder: 1 },
															{ title: "Status", descr: item.statusText, displayOrder: 2 }]
													} as ITen99EntitySummary} inline={true} />
												</Grid>
												<Grid item xs={3} sx={{ textAlign: 'right' }}>
													{!item.isRevoked && (
														<ActionButton itemId={item.recipientEmailId.toString()} uiProps={{
															action: ActionTypesEnum.FormDelivery, icon: <Ten99PrepCancelIcon />, text: "", color: "primary", tooltip: "Inactivate Email Link", onClick: InactivateEmailLink
															} as ITen99DynamicButtonProps} />
													)}
													{item.isRevoked && (
														<Typography>(Inactive)</Typography>
													)}
												</Grid>
										</ListItem>
										)
									})
								}
							</List>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => onClose(false, undefined)}>Close</Button>
				
			</DialogActions>
		</>
	)
}