import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

enum enumSubmitState {
	NotStarted,
	Processing,
	Submitted,
	Invalid,
	Error
};

type SetPasswordProps =
	RouteComponentProps<{ id: string, token: string, type: string }> // ... plus incoming routing parameters
	; // ... plus styles

const initialLocalState = {
	submitState: enumSubmitState.NotStarted, EmailAddress: "", validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: {} as string[] };
class SetPassword extends React.PureComponent<SetPasswordProps> {
	//local state
	state = initialLocalState;

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	private onSubmitClick = () => {
		this.setState({ submitState: enumSubmitState.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
		//call the api and set the results in the state to reflect on the render
		MakeApiCall<string>("api/User/PasswordReset", "POST", JSON.stringify({ EmailAddress: this.state.EmailAddress })) 
			.then(data => {
				if (data.isSuccess) {
					this.setState({ submitState: enumSubmitState.Submitted, validationMessages: {} as ITen99ApiResponseMessage[] });
				}
				else {
					this.setState({ submitState: enumSubmitState.Invalid, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
				}
			});
	}
	// -----------------
	// Render
	render() {
		
		return (
			<div>
				<Typography variant="h4">Reset Password</Typography>
				<br />
				{this.state.submitState === enumSubmitState.Processing && (
					<div>
						<CircularProgress size={90} />
						<Typography variant="subtitle2">Processing...</Typography>
					</div>
				)}
				{(this.state.submitState === enumSubmitState.NotStarted || this.state.submitState === enumSubmitState.Invalid) && ( //not processing and not yet processed
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h6">Specify the account to reset</Typography>
							<br />
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={this.handleChange}
								value={this.state.EmailAddress}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && this.state.invalidProperties.find(x => x === "EmailAddress") !== undefined}
								id="EmailAddress"
								label="Email Address"
								name="EmailAddress"
								type="email"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth onClick={this.onSubmitClick}>Submit</Button>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="outlined" fullWidth component={Link} to={"/usergate/login"}>Cancel</Button>
						</Grid>
					</Grid>
				)}				
				{this.state.submitState === enumSubmitState.Submitted && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h6">An email has been sent to the account with instructions on how to reset the password.</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth component={Link} to={"/"}>Return to Login</Button>
						</Grid>
					</Grid>
				)}
				{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}
export default SetPassword