import { Badge, PropTypes } from '@mui/material';
import ActionButton from 'components/buttons/ActionButton';
import MenuButton from 'components/buttons/MenuButton';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { Ten99BulkActions, Ten99PrepAddCartIcon, Ten99PrepCartHistoryIcon, Ten99PrepCartIcon, Ten99PrepCorrectionIcon, Ten99PrepDeleteIcon, Ten99PrepDownloadIcon, Ten99PrepEditIcon, Ten99PrepEmailDeliveryIcon, Ten99PrepFormIcon, Ten99PrepIrsErase, Ten99PrepReopenIcon, Ten99PrepResetIcon, Ten99PrepRevertIcon, Ten99PrepStateFormIcon, Ten99PrepUploadIcon, Ten99PrepViewDoc, Ten99PrepViewIcon, Ten99ReportIcon, Ten99StateAccounts } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';


interface IActionBadgeCounts {
	action: ActionTypesEnum,
	count: number,
}

interface ISummaryButtonProps {
	itemId: string,
	onClick?: (id: string, action: ActionTypesEnum) => void,
	actions: ActionTypesEnum[],
	actionBadgeCounts?: IActionBadgeCounts[],
	displayAllAsButtons?: boolean
	colorOverride?: PropTypes.Color
}

class SummaryButtons extends React.PureComponent<ISummaryButtonProps> {
	//local state
	private buttonColor: PropTypes.Color;
	constructor(props: ISummaryButtonProps) {
		super(props);	
		this.buttonColor = isNullOrUndefined(this.props.colorOverride) ? "primary" : this.props.colorOverride;
	}

	// -----------------
	//Handle User events
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	private generateIcon = (actionType: ActionTypesEnum, item: React.ReactNode) =>
	{
		let badge : IActionBadgeCounts | undefined;
		if (!isNullOrUndefined(this.props.actionBadgeCounts)) {
			badge = this.props.actionBadgeCounts.find(x => x.action == actionType);
		}

		if (!isNullOrUndefined(badge))
		{
			return (<Badge badgeContent={badge.count} color="primary" max={9999} showZero>{item}</Badge>)
		}
		else {
			return item;
        }
    }

	private getActionUiDetails = (actionType: ActionTypesEnum) => {
		switch (actionType) {
			case ActionTypesEnum.SubmitToCart:
				return { action: actionType, icon: this.generateIcon(actionType, <Ten99PrepAddCartIcon />), text: "", color: this.buttonColor, tooltip: "Add to Cart" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.View:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepViewIcon />), text: "", color: this.buttonColor, tooltip: "View" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Edit:
			case ActionTypesEnum.EditMaconomyCustomer:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepEditIcon />), text: "", color: this.buttonColor, tooltip: "Edit" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Delete:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepDeleteIcon />), text: "", color: this.buttonColor, tooltip: "Delete" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.ViewDoc:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepViewDoc />), text: "", color: this.buttonColor, tooltip: "View Doc" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Correct:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepCorrectionIcon />), text: "", color: this.buttonColor, tooltip: "Correct: If there is bad data in a Accepted form, correct and resubmit the data" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Reopen:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepReopenIcon />), text: "", color: this.buttonColor, tooltip: "Reopen: Reopen a Rejected form to continue work on it to resubmit" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Erase:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepIrsErase />), text: "", color: this.buttonColor, tooltip: "IRS Negate: Send a zeroed out form to IRS to negate the submission" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.FormHistory:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepFormIcon />), text: "", color: this.buttonColor, tooltip: "Form History: For forms that have been corrected, see the previous accepted versions" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.DeleteAllCustomers:
			case ActionTypesEnum.DeleteAllPayers:
			case ActionTypesEnum.DeleteAllRecipients:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepDeleteIcon />), text: "Delete Forms", color: this.buttonColor, tooltip: "Delete all forms in Draft status under this" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.AddCartAllCustomers:
			case ActionTypesEnum.AddCartAllPayers:
			case ActionTypesEnum.AddCartAllRecipients:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepAddCartIcon />), text: "Add", color: this.buttonColor, tooltip: "Add all forms in Draft status under this to Cart" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.ReopenRejectedAllCustomers:
			case ActionTypesEnum.ReopenRejectedAllPayers:
			case ActionTypesEnum.ReopenRejectedAllRecipients:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepReopenIcon />), text: "Reopen", color: this.buttonColor, tooltip: "Reopen all Rejected forms under this" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Import:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepUploadIcon />), text: "", color: this.buttonColor, tooltip: "Import" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Export:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepDownloadIcon />), text: "", color: this.buttonColor, tooltip: "Export" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.BulkPreview:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepViewDoc />), text: "View Drafts", color: this.buttonColor, tooltip: "" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.StaticDoc:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepViewDoc />), text: "View Submitted", color: this.buttonColor, tooltip: "" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.StateAccounts:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99StateAccounts />), text: "", color: this.buttonColor, tooltip: "Manage State Accounts" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.ViewPayerForms:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepStateFormIcon />), text: "", color: this.buttonColor, tooltip: "Manage State Reconciliation Forms" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Reporting:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99ReportIcon />), text: "", color: this.buttonColor, tooltip: "Reporting" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Reset:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepResetIcon />), text: "", color: this.buttonColor, tooltip: "Reset" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.Revert:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepRevertIcon />), text: "", color: this.buttonColor, tooltip: "Revert" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.ViewCart:
				return { action: actionType, icon: this.generateIcon(actionType,<Ten99PrepCartIcon />), text: "", color: this.buttonColor, tooltip: "Cart" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.ViewCartHistory:
				return { action: actionType, icon: <Ten99PrepCartHistoryIcon />, text: "", color: this.buttonColor, tooltip: " Cart History" } as ITen99DynamicButtonProps;
			case ActionTypesEnum.FormDelivery:
				return { action: actionType, icon: <Ten99PrepEmailDeliveryIcon />, text: "", color: this.buttonColor, tooltip: " Statements to Recipient" } as ITen99DynamicButtonProps;

			default:
				return {} as ITen99DynamicButtonProps;
		}
	}

	// Render
	render() {

		let bulkActionItems: ITen99DynamicButtonProps[] = [] as ITen99DynamicButtonProps[];
		let actionButtons: ITen99DynamicButtonProps[] = [] as ITen99DynamicButtonProps[];

		this.props.actions.map((item) => {
			let tempUiProps: ITen99DynamicButtonProps = this.getActionUiDetails(item);
			tempUiProps.onClick = this.props.onClick;

			if (!isNullOrUndefined(this.props.displayAllAsButtons) && this.props.displayAllAsButtons) { // display all as action buttons
				actionButtons.push(tempUiProps)
			}
			else { //use base provided mapping
				switch (item) {
					case ActionTypesEnum.Add:
					case ActionTypesEnum.Delete:
					case ActionTypesEnum.Edit:
					case ActionTypesEnum.EditMaconomyCustomer:
					case ActionTypesEnum.View:
					case ActionTypesEnum.Import:
					case ActionTypesEnum.Export:
					case ActionTypesEnum.ViewPayerForms:
					case ActionTypesEnum.StateAccounts:
					case ActionTypesEnum.Reporting:
					case ActionTypesEnum.ViewCart:
					case ActionTypesEnum.ViewCartHistory:
						actionButtons.push(tempUiProps)
						break;
					default:
						bulkActionItems.push(tempUiProps);
				}
			}
		})

		return (
			
			<React.Fragment>
				{
					actionButtons.map((item, index) => {
						return (
							<ActionButton key={index} itemId={this.props.itemId} uiProps={item} />
						)
					})
				}
				<MenuButton id={this.props.itemId} button={{ icon: <Ten99BulkActions />, text: "", variant: "contained", color: this.buttonColor, tooltip: "Bulk Actions" } as ITen99DynamicButtonProps} menuItems={bulkActionItems} />

			</React.Fragment>
			);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default SummaryButtons;
