import { Box, Grid, Paper } from '@mui/material';
import Login from 'components/user/Login';
import RegisterUser from 'components/user/RegisterUser';
import SetPassword from 'components/user/SetPassword';
import RequestPasswordReset from 'components/user/RequestPasswordReset';
import * as React from 'react';
import { Route } from 'react-router';
import LoginRefresh from 'components/user/LoginRefresh';

export default function UserGateway() {
	return	(
		<>			
			<Box sx={{
				backgroundImage: "linear-gradient(to bottom right, white, rgb(0, 112, 122))",
				backgroundColor: "white",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				//height: "100vh",
				minHeight: "700px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
				overflowY: "auto"
			}}>
				<Grid container sx={{textAlign: 'center'}}>
					<Grid item xs={12}>
						<Box
							component="img"
							alt="Logo"
							src="./1099Logo.png"
							sx={{
							width: "300px",
							marginTop: "1vh",
							}}
						/>
					</Grid>
					<Grid item xs={3}>
					</Grid>
					<Grid item xs={6} sx={{textAlign: 'center'}}>				
						<Paper elevation={3} variant="outlined" sx={{
							width: "55vw",
							height: "50vh",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							minHeight: "600px",
							minWidth: "600px",
							boxShadow: "0px 5px 20px #00000029",
							marginBottom: "8vh",
							borderRadius: "0px",
							overflowY: "auto"
						}}>
							<Grid container sx={{textAlign: 'center'}}>
								<Grid item xs={3}>
								</Grid>
								<Grid item xs={6}>
									<Route exact path='/usergate/login' component={Login} />
									<Route exact path='/usergate/forgotPassword' component={RequestPasswordReset} />
									<Route exact path='/usergate/setPassword/:id/:type/' component={SetPassword} />
									<Route exact path='/usergate/registerUser' component={RegisterUser} />
									<Route exact path='/usergate/loginRefresh' component={LoginRefresh} />
								</Grid>
							</Grid>							
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}