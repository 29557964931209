import { Dialog, Grid, Typography } from '@mui/material';
import Pager from 'components/buttons/Pager';
import AlertsDashboard from 'components/information/AlertsDashboard';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import EntityAddEditView from 'components/ten99Prep/EntityAddEditView/EntityAddEditView';
import FabButtons from 'components/ten99Prep/FabButtons';
import RecipientSummary from 'components/ten99Prep/RecipientSummary';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum, IAction } from 'sharedInterfaces/ITen99Action';
import { ITen99ActionResponse } from 'sharedInterfaces/ITen99ActionResponse';
import { enumAddEditViewAction, enumEntityType, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage, ITen99Dashboard } from 'sharedInterfaces/ITen99ApiResponse';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { ITen99PagerInfo } from 'sharedInterfaces/ITen99PagerInfo';
import { ApplicationState } from 'store';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { ICustomerSummaryActions } from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from 'utilities/ApiFunctions';
import { getEntityPrintableName } from 'utilities/EntityNameSummary';

enum enumComponentStatus {
	PendingAction,
	Processing,
	SilentProcessing,
	Loading,
	Invalid,
	Error
};

interface IRecipients {
	parents: ITen99EntitySummary[],
	payerId: string,
}

type RecipientsProps =	
	& IRecipients
	& HomeNavigationStore.CustomerSummaryState // ... state we've requested from the Redux store
	& ICustomerSummaryActions // ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	;

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	addEditViewEntityOpen: boolean,
	addEditViewEntitySetting?: IAevEntitySetting,
	alertsOpen: boolean,
	alertsEntity?: ITen99EntitySummary,
	pagerInfo: ITen99PagerInfo,
	recipients?: ITen99Dashboard<ITen99EntitySummary>,
	activeRecipientId: string,
	filtersSetOn?: Date,
}

const initialLocalState = { status: enumComponentStatus.Loading, pagerInfo: { pageNumber: 0, totalCount: 0, pageSize: 25 }, addEditViewEntityOpen: false, addEditViewEntitySetting: undefined, alertsOpen: false, alertsEntity: undefined, validationMessages: {} as ITen99ApiResponseMessage[], activeRecipientId: "0" } as ILocalState;
class Recipients extends React.PureComponent<RecipientsProps, ILocalState> {
	//local state
	constructor(props: RecipientsProps) {
		super(props);
		let temp: ILocalState = { ...initialLocalState };
		temp.filtersSetOn = this.props.filtersSetOn;
		temp.pagerInfo.onPagerClick = this.onPagerClick;
		this.state = temp;
		this.search(1);
	}

	// -----------------
	//Handle User events
	private onAddEditViewEntityProccessed = (response: IAevEntityResponse) => {
		this.setState({ addEditViewEntityOpen: false, addEditViewEntitySetting: undefined });
		if (response.processed) {
			this.search(this.state.pagerInfo.pageNumber, true);
			//send toast notification
			this.props.toastNotifcations_Display({ id: "RecipientAddEdit", message: response.message, type: "success" });
		}
	}

	private onAdd = () => {
		this.onAddEditViewOpen({ parents: this.props.parents, action: enumAddEditViewAction.Add, type: enumEntityType.Recipient } as IAevEntitySetting)
	}
	private onEdit = (entity: ITen99EntitySummary) => { this.onAddEditViewOpen({ id: entity.id, parents: this.props.parents, action: enumAddEditViewAction.Edit, type: enumEntityType.Recipient } as IAevEntitySetting) }
	private onView = (entity: ITen99EntitySummary) => { this.onAddEditViewOpen({ id: entity.id, parents: this.props.parents, action: enumAddEditViewAction.View, type: enumEntityType.Recipient } as IAevEntitySetting) }
	private onDelete = (entity: ITen99EntitySummary) => { this.props.confirmDialog_Display("Delete Recipient", entity.id, "You are about to delete the following " + getEntityPrintableName(entity), this.onDeleteProceed);}
	private onDeleteProceed = (id: string) => {

		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing && !isNullOrUndefined(this.state.recipients)) {
			//get the customer to make sure it is still there and get name
			const entity: ITen99EntitySummary | undefined = this.state.recipients.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
				//call the api and set the results in the state to reflect on the render
				MakeApiCall<string>("api/Recipients/" + id + "/?ticks=" + entity.ticks, "DELETE")
					.then(data => {
						if (data.isSuccess) {
							//refresh list
							this.search(this.state.pagerInfo.pageNumber, true);
							//send toast notification
							this.props.toastNotifcations_Display({ id: "RecipientDelete" + id, message: getEntityPrintableName(entity) + " deleted", type: "success" });
						}
						else {
							if (data.httpStatusCode === 422) {
								let index: number = 0;
								data.details.forEach(x => {
									this.props.toastNotifcations_Display({ id: "RecipientDelete" + id + "_" + index, message: "Issue occurred deleting " + getEntityPrintableName(entity) + ": " + x.message, type: "error" });
									index++;
								});
							}
							else if (data.httpStatusCode === 409) {
								this.props.toastNotifcations_Display({ id: "RecipientDelete" + id, message: "Recipient has been updated by another process. Refresh screen and try again. " + getEntityPrintableName(entity), type: "error" });
							}
							else {
								//send toast notification
								this.props.toastNotifcations_Display({ id: "RecipientDelete" + id, message: "Issue occurred deleting " + getEntityPrintableName(entity), type: "error" });
							}
						}
						this.setState({ status: enumComponentStatus.PendingAction });
					});
			}
			else {
				console.error("Unable to locally find Recipient: " + id);
			}
		}
	}

	private onDeleteForms = (entity: ITen99EntitySummary) => { this.props.confirmDialog_Display("Delete Forms", entity.id, "You are about to delete the forms in draft status for " + getEntityPrintableName(entity), this.onDeleteFormsProceed); }
	private onDeleteFormsProceed = (id: string) => {

		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing && !isNullOrUndefined(this.state.recipients)) {
			//get the customer to make sure it is still there and get name
			const entity: ITen99EntitySummary | undefined = this.state.recipients.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				this.onGenericAction(entity, ActionTypesEnum.DeleteAllRecipients);
			}
			else {
				console.error("Unable to locally find Recipeint: " + id);
			}
		}
	}

	private onAddEditViewOpen = (entitySetting: IAevEntitySetting) => {
		this.setState({ addEditViewEntityOpen: true, addEditViewEntitySetting: entitySetting });
	}

	

	private onGenericAction = (entity: ITen99EntitySummary, actionId: number) => {

		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });

		MakeApiCall<ITen99ActionResponse>("api/Bulk/" + entity.id + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, ticks: entity.ticks } as IAction))
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						this.props.CustomerSummaryActions_RefreshSummaryCounts();
						data.payload.messages.forEach(x =>
							this.props.toastNotifcations_Display({ id: "Recipient" + entity.id + "action", message: x.message, type: ToastNotifications.ConvertEnumToToastType(x.messageType) })
						);
					}
				}
				else {
					this.props.toastNotifcations_Display({ id: "Recipient" + entity.id + "action" + actionId, message: "Unable to Process action", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}

	private onGenericActionFileDownload = (entity: ITen99EntitySummary, actionId: number, additionalData?: string) => {

		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });

		MakeApiCall<string>("api/Bulk/" + entity.id + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, additonalActionData: isNullOrUndefined(additionalData) ? "" : additionalData, ticks: entity.ticks } as IAction))
			.then(data => {
				if (data.isSuccess) {
					//refresh list
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
					}
					else {
						this.props.toastNotifcations_Display({
							id: "Recipient" + entity.id + "action" + actionId + "action" + actionId, message: "There are currently no documents in the requested state to view", type: "warning"
						});
					}
				}
				else {
					this.props.toastNotifcations_Display({ id: "Recipient" + entity.id + "action" + actionId, message: "Unable to Process action", type: "error" });
				}
				this.setState({ status: enumComponentStatus.PendingAction });
			});
	}

	private onShowAlerts = (entity: ITen99EntitySummary) => {
		this.setState({ alertsOpen: true, alertsEntity: entity });
	}

	private onCloseAlerts = () => {
		this.setState({ alertsOpen: false, alertsEntity: undefined });
	}

	private onActionClickByEntity = (entity: ITen99EntitySummary, action: ActionTypesEnum) => {
		switch (action) {
			case ActionTypesEnum.Add:
				this.onAdd();
				break;
			case ActionTypesEnum.Delete:
				this.onDelete(entity);
				break;
			case ActionTypesEnum.DeleteAllRecipients:
				this.onDeleteForms(entity);
				break;
			case ActionTypesEnum.Edit:
				this.onEdit(entity);
				break;
			case ActionTypesEnum.View:
				this.onView(entity);
				break;
			case ActionTypesEnum.BulkPreview:
			case ActionTypesEnum.StaticDoc:
				this.onGenericActionFileDownload(entity, action.valueOf(), EnumEntityType.Recipient.toString());
				break;
			case ActionTypesEnum.ShowAlerts:
				this.onShowAlerts(entity);
				break;
			default:
				this.onGenericAction(entity, action.valueOf());
				break;
		}
	}
	private onActionClickById = (id: string, action: ActionTypesEnum) => {
		if (!isNullOrUndefined(this.state.recipients)) {
			const entity: ITen99EntitySummary | undefined = id === "" ? { id: "" } as ITen99EntitySummary : this.state.recipients.rows.find((item) => item.id === id)

			if (!isNullOrUndefined(entity)) {
				this.onActionClickByEntity(entity, action);
			}
			else {
				console.error("Unable to locally find recipient: " + id);
			}
		}
		else {
			console.error("Recipient dashboard not loaded: " + id);
		}
	}

	private search = (pageNumber: number, silentLoad: boolean = false) => {
		if (!isNullOrUndefined(this.props.payerId)) {
			let loadStatus: enumComponentStatus = silentLoad ? enumComponentStatus.SilentProcessing : enumComponentStatus.Processing;
			this.setState({ status: loadStatus, pagerInfo: { ...this.state.pagerInfo, pageNumber: pageNumber }, validationMessages: [] as ITen99ApiResponseMessage[] });

			let pager2: ITen99PagerInfo = { ...this.state.pagerInfo, pageNumber: pageNumber, namedFilters: this.props.namedFilters };

			MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/Recipients/Summary/?PayerId=" + this.props.payerId, "POST", JSON.stringify(pager2))
				.then(data => {
					if (data.isSuccess && !isNullOrUndefined(data.pageData) && !isNullOrUndefined(data.payload) && !isNullOrUndefined(data.pageData)) {
						this.setState({ status: enumComponentStatus.PendingAction, recipients: data.payload, pagerInfo: { ...this.state.pagerInfo, pageNumber: data.pageData.pageNumber, totalCount: data.pageData.totalCount } });
					}
					else if (!isNullOrUndefined(data.pageData) && data.pageData.totalCount === 0) {
						//empty
						this.setState({ status: enumComponentStatus.PendingAction, recipients: undefined, pagerInfo: { ...this.state.pagerInfo, pageNumber: 0, totalCount: 0 } });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load recipients" }] as ITen99ApiResponseMessage[] });
					}
				})
		}
	}

	private onRecipientSelect = (recipientId: string) => {
		let thisActiveRecipient: boolean = (this.state.activeRecipientId === recipientId);

		//if the active recipient is sending this, it means deselect
		this.setState({ activeRecipientId: (thisActiveRecipient ? "0" : recipientId) });
	}

	private onPagerClick = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		this.search(page + 1); //Material UI pager works off of 0 base index of page, not actual page number
	}

	private filtersChanged = () => {
		this.setState({ filtersSetOn: this.props.filtersSetOn });
		this.search(this.state.pagerInfo.pageNumber);
	}
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		if (this.state.status === enumComponentStatus.PendingAction && this.state.filtersSetOn !== this.props.filtersSetOn) {
			this.filtersChanged();
		}

				
		const showPager :boolean = !isNullOrUndefined(this.state.recipients) && this.state.recipients.rows.length < this.state.pagerInfo.totalCount;
			return (			
				<React.Fragment>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={8} sx={{textAlign: 'left'}}>
							<Typography variant="h5">Recipients
								{(!isNullOrUndefined(this.state.recipients) &&
									<FabButtons entityType={EnumEntityType.Recipient} actions={this.state.recipients.bulkActions} onClick={this.onActionClickById} />
								)}
							</Typography>
						</Grid>						
						<Grid item xs={4} sx={{textAlign: 'right'}}>
							<Pager pagerInfo={this.state.pagerInfo} show={showPager} />
						</Grid>
						{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.SilentProcessing) && !isNullOrUndefined(this.state.recipients) && !isNullOrUndefined(this.state.recipients.rows) && (
							<Grid item xs={12}>
								{
									this.state.recipients.rows.map((item, index) => {
										return (
											<RecipientSummary parents={this.props.parents} key={index} onActionClick={this.onActionClickById} onSelect={this.onRecipientSelect} activeRecipientId={this.state.activeRecipientId} summaryItem={item} />
										)
									})
								}
							</Grid>
						)}
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
							<Grid item xs={12} sx={{textAlign: 'right'}}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							</Grid>

						)}
						<Grid item xs={8} sx={{textAlign: 'right'}}>
						</Grid>
						<Grid item xs={4} sx={{textAlign: 'right'}}>
							<Pager pagerInfo={this.state.pagerInfo} show={showPager} />
						</Grid>
						
						<Grid item xs={12}>
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							)}
						</Grid>
					</Grid>

					{this.state.addEditViewEntityOpen && !isNullOrUndefined(this.state.addEditViewEntitySetting) && (
						<EntityAddEditView onClose={this.onAddEditViewEntityProccessed} entitySettings={this.state.addEditViewEntitySetting} />
					)}

					{this.state.alertsOpen && !isNullOrUndefined(this.state.alertsEntity) && (
						<Dialog open={this.state.alertsOpen} fullWidth={true} maxWidth="lg">
							<AlertsDashboard alertSource={this.state.alertsEntity} alertSourceParents={this.props.parents} onClose={this.onCloseAlerts} />
						</Dialog>
					)}
					
				</React.Fragment>
		);
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, ...HomeNavigationStore.actionCreators } // Selects which action creators are merged into the component's props
)(Recipients);