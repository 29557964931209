import { Button, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import { useEffect, useState } from 'react';
import { ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';

export default function PreferredTwoFactor({ onClose }: { onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void }) {

	enum enumComponentStatus {
		PendingAction,
		Processing,
		Invalid,
		Error
	};

	interface ITwoFactor {
		preferredTwoFactorProvider: string,
		preferredTwoFactorProviders: ITen99LookupItem[]
	}

	interface IPreferredTwoFactorState {
		status: enumComponentStatus,

		validationMessages: ITen99ApiResponseMessage[],
		invalidProperties: string[]
	}

	const [componentState, setComponentState] = useState<IPreferredTwoFactorState>({
		status: enumComponentStatus.PendingAction,

		validationMessages: [] as ITen99ApiResponseMessage[],
		invalidProperties: [] as string[],		
	} as IPreferredTwoFactorState);

	const [preferredTwoFactorProvider, setPreferredTwoFactorProvider] = useState<string>("");
	const [preferredTwoFactorProviders, setPreferredTwoFactorProviders] = useState<ITen99LookupItem[]>([] as ITen99LookupItem[]);

	function SetTwoFactor(preferredTwoFactorProvider: string) {
		setPreferredTwoFactorProvider(preferredTwoFactorProvider);
	}

	function Submit() {
		setComponentState(prevState => {
			return {
				...prevState,
				status: enumComponentStatus.Processing,
			};
		});

		MakeApiCall<string>("api/User/TwoFactor/", "POST", JSON.stringify({ PreferredTwoFactorProvider: preferredTwoFactorProvider }))
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					onClose(true);
				}
				else {
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.PendingAction,
							validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Unable to save Preferred Two Factor" }] as ITen99ApiResponseMessage[],
						}
					});
				}
			});
	}

	function GetTwoFactors() {
		setComponentState(prevState => {
			return {
				...prevState,
				status: enumComponentStatus.Processing,
			};
		});

		MakeApiCall<ITwoFactor>("api/User/TwoFactor", "GET")
			.then(data => {
				//two factor
				let twoFactor: ITwoFactor = { preferredTwoFactorProvider: "", preferredTwoFactorProviders: [] as ITen99LookupItem[] };
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					twoFactor = data.payload as ITwoFactor;
					setComponentState(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.PendingAction,
						}
					});
				}
				else {
					setComponentState(prevState => {
						return {
							...prevState,
							validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Unable to load Two Factor", isWarning: false }] as ITen99ApiResponseMessage[],
							status: enumComponentStatus.PendingAction,
						}
					});
				}

				setPreferredTwoFactorProvider(twoFactor.preferredTwoFactorProvider);
				setPreferredTwoFactorProviders(twoFactor.preferredTwoFactorProviders);
			});
	}

	useEffect(() => {
		GetTwoFactors();
	}, []);

	return (
		<>
			<DialogContent>
				<Grid container spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item xs={12}>
						{(componentState.status === enumComponentStatus.Processing
							&& (
								<ProcessingModal modal={true} />
							))}
					</Grid>
					<Grid item xs={12}>
						<FormControl
							error={componentState.invalidProperties?.find(x => x === "preferredTwoFactor") !== undefined}
							fullWidth
							required>
							<InputLabel id="formTypeLabel">Preferred Two Factor Provider</InputLabel>
							<Select
								labelId="formTypeLabel"
								label="Preferred Two Factor Provider"
								value={isNullOrUndefined(preferredTwoFactorProvider) ? "" : preferredTwoFactorProvider}
								onChange={event => SetTwoFactor(event.target.value as string)}
								inputProps={{}}
								id="preferredTwoFactor"
								name="preferredTwoFactor"
							>
								{(preferredTwoFactorProviders?.map((item, index) => <MenuItem key={index} value={item.name} >{item.description}</MenuItem>))}
							</Select>
						</FormControl>
					</Grid>

					{!isNullOrUndefined(componentState.validationMessages) && componentState.validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={componentState.validationMessages} />
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={() => onClose(false, undefined)}>Close</Button>
				<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={Submit}>Submit</Button>
			</DialogActions>
		</>
	);
}