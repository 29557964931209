import { Button, Grid, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import { useState } from 'react';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
export default function ConfirmTwoFactor({ submitFunction, resendFunction, cancelFunction, disabled }: { submitFunction: (code: string) => void, resendFunction: () => void, cancelFunction: () => void,  disabled?: boolean }) {

	let codeLegth:number = 6;
	let mask:String= "#";
	let codeMask:String = mask.repeat(codeLegth);
	let codeLengthError: ITen99ApiResponseMessage = ({ type: "Error", message: "Please make sure you enter all 6 numbers before submitting" }) as ITen99ApiResponseMessage;

	function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.key === "Enter") {
			submit();
		}
	}

	function submit() {		
		setValidationMessages([] as ITen99ApiResponseMessage[]);
		let messages: ITen99ApiResponseMessage[] = validations();

		if (messages.length == 0) {
			submitFunction(code);					
		}else {
			setValidationMessages(messages)
		}
	}

	function validations() {		
		let messages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];

		if (code.length != codeLegth) {
			messages.push(codeLengthError); 
		}
		
		return messages;
	}

	const [code, setCode] = useState<string>("");
	const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([]);

	return (
		<>			
			<Grid container spacing={1} sx={{ textAlign: 'center' }}>
				<Grid item xs={12}>
					<Typography variant="h6">
						For security, a code has been sent to the user associated with this login. Please enter this security code to proceed. 
					</Typography>
					<br />
				</Grid>

				<Grid item xs={12} style={{ "display": "flex", } as React.CSSProperties}>
					<TextField
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setCode(event.target.value) }}
						disabled={disabled}
						value={code}
						variant="outlined"
						id="2FaCode"
						label="Security Code"
						name="2FaCode"
						onKeyDown={(e) => { onKeyDown(e); }}
						fullWidth
						required
						InputProps={{
							inputComponent: CustomerNumberFormat as any,
							inputProps: { format: codeMask, mask: mask }
						}}
					/>
				</Grid>

				<Grid item xs={6} sx={{ textAlign: 'left' }}>
					<Button type="button" disabled={disabled} variant="contained" color="secondary" onClick={resendFunction}>Resend</Button>
					<Button type="button" disabled={disabled} variant="contained" color="secondary" onClick={cancelFunction}>Cancel</Button>
				</Grid>
				<Grid item xs={3}>
				</Grid>
				<Grid item xs={3} sx={{ textAlign: 'right' }}>
					<Button type="button" disabled={disabled} variant="contained" color="primary" onClick={submit}>Submit</Button>
				</Grid>			
				{validationMessages.length > 0 && (<>
				<Grid item xs={12} sx={{ textAlign: 'right' }}>
					<ApiErrorResponseMessages messages={validationMessages} />
				</Grid></>)}
			</Grid>
		</>
	);
}