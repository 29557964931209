import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";

export enum enumAddEditViewAction {
	Add,
	Edit,
	View,
};

export enum enumEntityType {
	Customer,
	MaconomyCustomer,
	Payer,
	PayerForm,
	Recipient,
	Form,
};

export interface IAevEntitySetting {
	id?: string,
	parents: ITen99EntitySummary[],
	action: enumAddEditViewAction,
	type: enumEntityType,
}

export interface IAevEntityResponse {
	processed: boolean,
	id?: string,
	message: string
}