import { Button, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import ReportRequestAdditionalInformaiton from "components/ten99Prep/Reporting/ReportRequestAdditionalInformaiton";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { IFormElement } from "sharedInterfaces/ITen99FormElement";
import { IFormElementTemplate } from "sharedInterfaces/ITen99FormElementTemplate";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { flattenProperties } from 'utilities/PropertyList';


enum enumComponentStatus {
	PendingAction,
	Processing,
	Loading,
	Invalid,
	Error
};

interface IReportRequestProps {
	customerId: string,
	onReportRequested: (reportId: number) => void,
}


type ReportRequestProps =
	& IReportRequestProps
	;

interface ILocalState {
	status: enumComponentStatus,
	payerId: string,
	payers: ITen99LookupItem[],
	reportTypeId: string,
	reportTypes: ITen99LookupItem[],

	additionInformationDialogOpen: boolean,
	additonalDataTemplate: IFormElementTemplate[],
	additonalDataFormElements: IFormElement[],
	additonalDataValidationMessages: ITen99ApiResponseMessage[],
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
}

interface IForm {
	formTypeId: string,

	//fields
	formElementValues: IFormElement[],
}

const initialLocalState = { status: enumComponentStatus.Loading, reportTypeId: "", reportTypes: {} as ITen99LookupItem[], payerId: "", payers: {} as ITen99LookupItem[], additionInformationDialogOpen: false, additonalDataTemplate: [] as IFormElementTemplate[], additonalDataFormElements: [] as IFormElement[], validationMessages: {} as ITen99ApiResponseMessage[], additonalDataValidationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: [] as string[] } as ILocalState;
class ReportRequest extends React.PureComponent<ReportRequestProps, ILocalState> {

	//local state
	constructor(props: ReportRequestProps) {
		super(props);
		this.state = initialLocalState;
		let array = [];
		array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/27/?parentId=" + this.props.customerId, "GET"));
		array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/29/", "GET"))

		Promise.all(array).then( values =>
		{
			let tempValidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
			let payersLookup: ITen99LookupData | undefined = undefined;
			let reportTypeLookup: ITen99LookupData | undefined = undefined;
			//add payers
			if (values[0].isSuccess) {
				if (!isNullOrUndefined(values[0].payload)) {
					const lookup: ITen99LookupData | undefined = values[0].payload.find(x => x.options.requestedTable === 27);
					if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems)) {
						payersLookup = lookup;
					}
				}				
			}
			if (isNullOrUndefined(payersLookup)) {
				tempValidationMessages.push({ type: "Error", message: "Unable to load Payers." } as ITen99ApiResponseMessage);
			}
			else if (payersLookup.lookupItems.length === 0) {
				payersLookup = undefined; //clear out payer lookup
				tempValidationMessages.push({ type: "Error", message: "No Payers found. Cannot run reports unless Customer has atleast one Payer." } as ITen99ApiResponseMessage);
			}


			//add report types
			if (values[1].isSuccess) {
				if (!isNullOrUndefined(values[1].payload)) {
					const lookup: ITen99LookupData | undefined = values[1].payload.find(x => x.options.requestedTable === 29);
					if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
						reportTypeLookup = lookup;
					}
				}
			}
			if (isNullOrUndefined(reportTypeLookup)) {
				tempValidationMessages.push({ type: "Error", message: "Unable to load Report Types." } as ITen99ApiResponseMessage);
			}
			
			//set state
			if (!isNullOrUndefined(reportTypeLookup) && !isNullOrUndefined(payersLookup)) {
				this.setState({ status: enumComponentStatus.PendingAction, reportTypes: reportTypeLookup.lookupItems, payers: payersLookup.lookupItems });
				
			}
			else {
				this.setState({ status: enumComponentStatus.Error, validationMessages: tempValidationMessages });
            }
			
			}
		)	
	}
	// -----------------
	//Handle User events
	private onFormChange = (name: string, value: string) => {
		const stateIndex = this.state.additonalDataFormElements.findIndex((x) => x.formElementId === Number.parseInt(name));
		let tempItems = [...this.state.additonalDataFormElements];
		tempItems[stateIndex] = { ...tempItems[stateIndex], elementValue: value };
		this.setState({ additonalDataFormElements: tempItems });
	
	}

	private populateTemplateLookups = (lookups: ITen99LookupData[], template: IFormElementTemplate[]) => {
		//loop through the lookups and populate any dropdowns that match the type
		
		lookups.forEach((value) => {
			const elements: IFormElementTemplate[] = template.filter(x => x.formElementSourceId === value.options.requestedTable)
			
			
			elements.forEach(item => {
				//get index
				const templateIndex: number = template.indexOf(item);
				//populate item
				template[templateIndex] = { ...template[templateIndex], source: value.lookupItems };
			});
		})
    }

	private onSubmit = () => {
		let tempValidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
		//check to see if values populated
		if (isNullOrUndefined(this.state.payerId) || this.state.payerId === "") {
			tempValidationMessages.push({ type: "Error", message: "Payer is requried", propertyNames: ["Payer"] as string[] } as ITen99ApiResponseMessage);
		}
		if (isNullOrUndefined(this.state.reportTypeId) || this.state.reportTypeId === "") {
			tempValidationMessages.push({ type: "Error", message: "Report Type is required", propertyNames: ["ReportType"] as string[] } as ITen99ApiResponseMessage);
		}

		if (tempValidationMessages.length > 0) {
			this.setState({ status: enumComponentStatus.Invalid, validationMessages: tempValidationMessages })
		}
		else {//proceed

			this.setState({ status: enumComponentStatus.Processing, additonalDataTemplate: [] as IFormElementTemplate[], additonalDataFormElements: [] as IFormElement[], validationMessages: {} as ITen99ApiResponseMessage[] })
			MakeApiCall<IFormElementTemplate[]>("api/Reports/ReportElements/" + this.state.reportTypeId, "GET")
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload) && data.payload.length > 0) {  // if additional data pause and get user to fill out
							let template: IFormElementTemplate[] = data.payload;
							let elements: IFormElement[] = [] as IFormElement[];

							data.payload.forEach((item) => {
								elements.push({ formElementValueId: -1, formElementId: item.formElementId, elementValue: "" } as IFormElement)
							});

							//get all dropdowns needed
							let array = [];
							//get special dropdowns
							if (data.payload.findIndex(x => x.formElementSourceId === 30) !== -1 && !isNullOrUndefined(this.props.customerId)) {
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/30/?parentId=" + this.state.payerId, "GET"));
							}
							if (data.payload.findIndex(x => x.formElementSourceId === 35) !== -1 && !isNullOrUndefined(this.props.customerId)) {
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/35/?parentId=" + this.state.payerId, "GET"));
							}
							if (data.payload.findIndex(x => x.formElementSourceId === 36) !== -1 && !isNullOrUndefined(this.props.customerId)) {
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/36/?parentId=" + this.state.payerId, "GET"));
							}
							if (data.payload.findIndex(x => x.formElementSourceId === 38) !== -1 && !isNullOrUndefined(this.props.customerId)) {
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/38/?parentId=" + this.state.payerId, "GET"));
							}
							if (data.payload.findIndex(x => x.formElementSourceId === 52) !== -1 && !isNullOrUndefined(this.props.customerId)) {
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/52/?parentId=" + this.state.payerId, "GET"));
							}
							if (data.payload.findIndex(x => x.formElementSourceId === 75) !== -1 && !isNullOrUndefined(this.props.customerId)) {
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/75/?parentId=" + this.state.payerId, "GET"));
							}
							//get all non special dropdowns	
							const datasourceIds: string[] = data.payload.filter(x => !isNullOrUndefined(x.formElementSourceId) && x.formElementSourceId !== 30 && x.formElementSourceId !== 35 && x.formElementSourceId !== 36 && x.formElementSourceId !== 38 && x.formElementSourceId !== 52 && x.formElementSourceId !== 75).map((value) => isNullOrUndefined(value.formElementSourceId) ? "" : value.formElementSourceId.toString());
							const uniqueDatasourceIdsString: string = Array.from(new Set(datasourceIds)).join("&lookupTableIds=");

							if (!isNullOrUndefined(uniqueDatasourceIdsString) && uniqueDatasourceIdsString !== "") { //if there is datasource we need to pull
								array.push(MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=" + uniqueDatasourceIdsString, "GET"));
							}
							Promise.all(array).then(values => {
								let lookups: ITen99LookupData[] = [] as ITen99LookupData[];
								let tempValidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
								//aggregate lookups
								values.forEach(response => {
									if (response.isSuccess) {
										if (!isNullOrUndefined(response.payload)) {
											if (!isNullOrUndefined(response.payload) && !isNullOrUndefined(response.payload) && response.payload.length > 0) {
												//check for lookup 30
												const lookup30Index: number = response.payload.findIndex(x => x.options.requestedTable === 30);
												if (lookup30Index >= 0 && response.payload[lookup30Index].lookupItems.length === 0) {
													//this is empty and likely the combination chosen is empty
													tempValidationMessages.push({ type: "Error", message: "No results were returned for this particular Payer and Report type. Please close and chose a diffrent option." } as ITen99ApiResponseMessage);
												}
												else {
													lookups.push(...response.payload);
												}
											}											
										}
									}
									else {
										tempValidationMessages.push({ type: "Error", message: "Unable to load form dropdowns" } as ITen99ApiResponseMessage);
									}
								});

								if (!isNullOrUndefined(lookups) && lookups.length) {
									this.populateTemplateLookups(lookups, template);
								}

								if (tempValidationMessages.length > 0) {
									this.setState({ status: enumComponentStatus.Invalid, validationMessages: tempValidationMessages });
								}
								else {
									this.setState({ status: enumComponentStatus.PendingAction, additionInformationDialogOpen: true, additonalDataTemplate: template, additonalDataFormElements: elements });
								}
							});
						}
						else { // no additonal data, submit
							this.submitReportRequest();
						}
					}
					else {
						this.setState({ status: enumComponentStatus.Invalid, validationMessages: [{ type: "Error", message: "Unable to get additional data for report" }] as ITen99ApiResponseMessage[] });
					}
				});
		}
	}

	private onDialogClose = () => {
		this.setState({ additionInformationDialogOpen: false });
    }

	private submitReportRequest = () => {
		if (this.state.status !== enumComponentStatus.Processing) {
			this.setState({ status: enumComponentStatus.Processing, additonalDataValidationMessages: [] as ITen99ApiResponseMessage[], validationMessages: {} as ITen99ApiResponseMessage[] })
        }

		MakeApiCall<number>("api/ReportManage/" + this.state.payerId + "/Report/", "POST", JSON.stringify({ formTypeId: this.state.reportTypeId, formElementValues: this.state.additonalDataFormElements } as IForm))
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					this.setState({
						status: enumComponentStatus.PendingAction,
						additionInformationDialogOpen: false,
						additonalDataFormElements: [],
						additonalDataTemplate: [],
						payerId: "",
						reportTypeId: ""
					});
					this.props.onReportRequested(data.payload);
				}
				else {
					this.setState({ status: enumComponentStatus.PendingAction, additonalDataValidationMessages: data.details, invalidProperties: flattenProperties(data.details) })
                }
			});

	  }
	// -----------------
	// Componet lifecycle events
	private onDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
		}
	}
	// -----------------
	// Render
	render() {
		
		return (
			<React.Fragment>
				<Grid container spacing={1}>
					<Grid item xs={12} sx={{textAlign: 'left'}}>
						<Typography variant="h5">Request Report
						<InfoToolTip text="Select options for requesting various reports" />
						</Typography>
					</Grid>	
					
					<Grid item xs={12} sx={{textAlign: 'left'}}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
							<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
						)}
					</Grid>
					
					{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					)}

					{(this.state.status !== enumComponentStatus.Loading) && (
						<React.Fragment>


						<Grid item xs={6}>
							<FormControl								
								error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "Payer") !== undefined)}
								fullWidth
								required>
								<InputLabel id="payerLabel">Payer</InputLabel>
								<Select
									labelId="payerLabel"
									label="Payer"
									value={this.state.payerId}
									onChange={this.onDropDownChange}
									inputProps={{}}
									id="payerId"
									name="payerId"
									>
										{!isNullOrUndefined(this.state.payers) && this.state.payers.length > 0 && (this.state.payers.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
								</Select>
							</FormControl>
						</Grid>	
						<Grid item xs={6}>
							<FormControl
								error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "ReportType") !== undefined)}
								fullWidth
								required>
								<InputLabel id="reportTypeLabel">Report Type</InputLabel>
								<Select
									labelId="reportTypeLabel"
									label="Report Type"
									value={this.state.reportTypeId}
									onChange={this.onDropDownChange}
									inputProps={{}}
									id="reportTypeId"
									name="reportTypeId"
								>
										{!isNullOrUndefined(this.state.reportTypes) && this.state.reportTypes.length > 0 && (this.state.reportTypes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
								</Select>
							</FormControl>
						</Grid>	
							</React.Fragment>
					)}	

					<Grid item xs={12} sx={{textAlign: 'left'}}>

					</Grid>
					<Grid item xs={12} sx={{textAlign: 'right'}}>
						<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={this.onSubmit}>Run</Button>
					</Grid>
				</Grid>	
				{this.state.additionInformationDialogOpen && (
					<Dialog open={this.state.additionInformationDialogOpen} fullWidth={true} maxWidth="lg">
						<ReportRequestAdditionalInformaiton
							formElements={this.state.additonalDataFormElements}
							template={this.state.additonalDataTemplate}
							validationMessages={this.state.additonalDataValidationMessages}
							invalidProperties={this.state.invalidProperties}
							onSubmit={this.submitReportRequest}
							onChange={this.onFormChange}
							onClose={this.onDialogClose}
						/>
					</Dialog>
				)}			
			</React.Fragment>
		)
	}
}

export default ReportRequest;
