import { List, ListItem, Typography } from "@mui/material";
import CartFormEntitySummary from "components/ten99Prep/Cart/CartFormEntitySummary";
import SummaryTitle from "components/ten99Prep/SummaryTitle";
import * as React from 'react';
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { EnumEntityType, ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { Ten99PrepCustomerIcon, Ten99PrepPayerIcon, Ten99PrepRecipientIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';


interface ILocalProps {
	item: ITen99EntitySummary,
	onActionClick: (entity: ITen99EntitySummary, action: ActionTypesEnum) => void,
}

type FormEntitySummaryProps =
	ILocalProps;

interface ILocalState {

}

const initialLocalState: ILocalState = {};
class CartEntitySummary extends React.PureComponent<FormEntitySummaryProps, ILocalState> {
	constructor(props: FormEntitySummaryProps) {
		super(props);
		this.state = initialLocalState;
	}
	// -----------------
	//Handle User events
	private getIcon(type: EnumEntityType) {
		switch (type) {
			case (EnumEntityType.Customer):
				return <Ten99PrepCustomerIcon />;
			case (EnumEntityType.Payer):
				return <Ten99PrepPayerIcon />;
			case (EnumEntityType.Recipient):
				return <Ten99PrepRecipientIcon />;
		}
	}

	private getThemeName(type: EnumEntityType) {

		switch (type) {
			case (EnumEntityType.Customer):
				return "customer.main";
			case (EnumEntityType.Payer):
				return "payer.main";
			case (EnumEntityType.Recipient):
				return "recipient.main";
			default:
				return "";
		}
	}

	
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	private subRenderForRecursion = (item: ITen99EntitySummary) =>
	{
		const entityTypeEnum: EnumEntityType = item.type;

		return (
			<React.Fragment>
				{entityTypeEnum !== EnumEntityType.CartForm && !isNullOrUndefined(item.nestedEntities) && (
					<React.Fragment>
						<ListItem sx={{
							backgroundColor: this.getThemeName(entityTypeEnum),
							paddingTop: 0,
							paddingBottom: 0
						}}>
							{this.getIcon(entityTypeEnum)}
							<SummaryTitle entity={item} />
							<Typography variant="body1" color="error">{item.status}</Typography>
						</ListItem>
						{
						item.nestedEntities.rows.map((item, index) => {
							return (
								<List key={index} disablePadding sx={{
									paddingLeft: 4
								}}>
									{this.subRenderForRecursion(item)}
								</List>);
						})}
					</React.Fragment>
				)}
				{entityTypeEnum === EnumEntityType.CartForm && (
					<CartFormEntitySummary item={item} onActionClick={this.props.onActionClick} />					
				)}				
			</React.Fragment>


		);		
    }

	render() {		
		return (
			<List disablePadding>
				{this.subRenderForRecursion(this.props.item)}
			</List>
		)
	}
}

export default CartEntitySummary;
