import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import Address from 'components/ten99Prep/Address/Address';
import Contact from 'components/ten99Prep/Contact';
import { DynamicInputGrid } from 'components/ten99Prep/DynamicInputGrid/DynamicInputGrid';
import { EntityNav } from 'components/ten99Prep/EntityAddEditView/EntityNav';
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99Address } from 'sharedInterfaces/ITen99Address';
import { enumAddEditViewAction, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99Contact } from 'sharedInterfaces/ITen99Contact';
import { EnumEntityType, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { IFormElementTemplate } from 'sharedInterfaces/ITen99FormElementTemplate';
import { ITen99LookupData, ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { ITen99PersonTypeEnum } from 'sharedInterfaces/ITen99PersonTypeEnum';
import { ApplicationState } from 'store';
import * as HomeNavigationStore from 'store/HomeNavigation';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

enum enumComponentStatus {
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface IPayer {
	payerTypeId: ITen99PersonTypeEnum,
	firstName: string,
	lastName: string,
	companyName: string,
	name2: string,
	tin: string,

	address: ITen99Address,
	contact: ITen99Contact,

	//witholding agent
	ten42sBox12aWithholdingEin: string,
	ten42sBox12bWithCh3StatusCodeId?: number,
	ten42sBox12cWithCh4StatusCodeId?: number,
	ten42sBox12dWithName: string,
	ten42sBox12eWithGiin: string,
	ten42sBox12fWithCountryCodeId?: number,

	ten42sBox12gWithForeignTin: string,
	ten42sBox12hWithAddress: string,
	ten42sBox12i1WithCity: string,
	ten42sBox12i2WithState: string,
	ten42sBox12i3WithCountry: string,
	ten42sBox12i4WithZip: string
	ten42sBoxTemp3WithContactName: string,
	ten42sBoxTemp4WithDepartTitle: string,
	ten42sBoxTemp5WithPhone: string,
	ten42sBoxTemp6WithPhoneExt: string,

	//witholding agent - payer box 16
	ten42sBox16cPayerGiin: string,
	ten42sBox16dPayerCh3StatusCodeId?: number,
	ten42sBox16ePayerCh4StatusCodeId?: number,

    //W-2 Fields
	formW2PayerId?: number,
	formW2EmployerId?: number,


	filingYearId: number,
	ticks: string,
}

interface IAddEditViewPayerExternalProps {
	onClose: (response: IAevEntityResponse) => void,
	entitySettings: IAevEntitySetting,
}

type AddEditProps =
	IAddEditViewPayerExternalProps
	& HomeNavigationStore.CustomerSummaryState
	;

interface ILocalState {
	status: enumComponentStatus,
	withholdingAgentExpanded: boolean,
	payerAdditional1042sInformationExpanded: boolean,
	payerAdditionalW2InformationExpanded: boolean,
	payer: IPayer,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
}

const emptyAddress = { addressLine1: "", addressLine2: "", city: "", stateId: -1, zip: "", countryId: -1, foreignPostalCode: "", foreignStateOrProvince: "" } as ITen99Address
const emptyContact = { contactName: "", email: "", isPhoneNumberInternational: false, phoneNumber: "", phoneNumberExt: "" } as ITen99Contact
const emptyPayer = { firstName: "", lastName: "", companyName: "", tin: "", ticks: "", address: emptyAddress, contact: emptyContact, filingYearId: 0, payerTypeId: 1 } as IPayer

const initialLocalState = {
	status: enumComponentStatus.Processing, payer: emptyPayer, validationMessages: {} as ITen99ApiResponseMessage[], withholdingAgentExpanded: false, payerAdditional1042sInformationExpanded: false, invalidProperties: {} as string[] } as ILocalState;
class AddEditViewPayer extends React.PureComponent<AddEditProps, ILocalState> {
	private states: ITen99LookupItem[] = {} as ITen99LookupItem[];
	private countries: ITen99LookupItem[] = {} as ITen99LookupItem[];
	private withholdingAgentTemplate: IFormElementTemplate[];
	private payerAdditional1042sInformationTemplate: IFormElementTemplate[];
	private payerAdditionalW2InformationTemplate: IFormElementTemplate[];

	constructor(props: AddEditProps) {
		super(props);
		let tempState = initialLocalState;
		tempState.payer.filingYearId = props.currentFilingYear;

		this.state = tempState;

		this.withholdingAgentTemplate = JSON.parse(sessionStorage.getItem("withholdingAgentTemplate") ?? "[]");
		this.payerAdditional1042sInformationTemplate = JSON.parse(sessionStorage.getItem("payerAdditional1042sInformationTemplate") ?? "[]");
		this.payerAdditionalW2InformationTemplate = JSON.parse(sessionStorage.getItem("payerAdditionalW2InformationTemplate") ?? "[]");
		

		//get the lookup
		//call the api and set the results in the state to reflect on the render
		//load states first
		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup?lookupTableIds=45&lookupTableIds=14&lookupTableIds=16&lookupTableIds=17&lookupTableIds=23&lookupTableIds=25&lookupTableIds=26", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						let lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 45);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.states = lookup.lookupItems;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load states" }] as ITen99ApiResponseMessage[] });
						}

						lookup = data.payload.find(x => x.options.requestedTable === 14);
						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.countries = lookup.lookupItems;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load countries" }] as ITen99ApiResponseMessage[] });
						}
						
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 16), this.withholdingAgentTemplate.find(x => x.formElementId === "ten42sBox12bWithCh3StatusCodeId"), "Witholding Agent Chapter 3");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 17), this.withholdingAgentTemplate.find(x => x.formElementId === "ten42sBox12cWithCh4StatusCodeId"), "Witholding Agent Chapter 4");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 23), this.withholdingAgentTemplate.find(x => x.formElementId === "ten42sBox12fWithCountryCodeId"), "Witholding Agent Country");

						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 16), this.payerAdditional1042sInformationTemplate.find(x => x.formElementId === "ten42sBox16dPayerCh3StatusCodeId"), "Payer Chapter 3");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 17), this.payerAdditional1042sInformationTemplate.find(x => x.formElementId === "ten42sBox16ePayerCh4StatusCodeId"), "Payer Chapter 4");

						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 25), this.payerAdditionalW2InformationTemplate.find(x => x.formElementId === "formW2PayerId"), "W2 Payer Type");
						this.setTemplateSource(data.payload.find(x => x.options.requestedTable === 26), this.payerAdditionalW2InformationTemplate.find(x => x.formElementId === "formW2EmployerId"), "W2 Employer Type");

						//get USA ID
						let item: ITen99LookupItem | undefined = this.countries.find(x => x.name === "USA");
						let usaCountryId: number = -1;
						if (!isNullOrUndefined(item)) {
							usaCountryId = item.id;
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to find country: USA" }] as ITen99ApiResponseMessage[] });
                        }
						
						if (this.state.status !== enumComponentStatus.Error) {
							//load entity if needed
							if (props.entitySettings.action === enumAddEditViewAction.Edit || props.entitySettings.action === enumAddEditViewAction.View) {

								MakeApiCall<IPayer>("api/Payers/" + this.props.entitySettings.id, "GET")
									.then(data => {
										if (data.isSuccess && !isNullOrUndefined(data.payload)) {
											this.setState({ payer: data.payload, status: enumComponentStatus.PendingAction })
										}
										else {
											this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the Payer" }] as ITen99ApiResponseMessage[] });
										}
									})
							}
							else { //new item, force country to usa								
								this.setState({ status: enumComponentStatus.PendingAction, payer: { ...this.state.payer, address: { ...this.state.payer.address, countryId: usaCountryId } } });
							}
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})		
    }

	private setTemplateSource = (lookup: ITen99LookupData | undefined, templateItem: IFormElementTemplate | undefined, name: string ) => {
		if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0 && !isNullOrUndefined(templateItem)) {
			templateItem.source = lookup.lookupItems;
		}
		else {
			this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to map " + name + " lookup items" }] as ITen99ApiResponseMessage[] });
		}
    }

	private getTitleName =()  => {
		if (this.state.payer.payerTypeId === ITen99PersonTypeEnum.Company) {
			return this.state.payer.companyName;
		}
		else {
			return this.state.payer.firstName + " " + this.state.payer.lastName;
        }
    }

	private getTitle(action: enumAddEditViewAction) {
		switch (action) {
			case enumAddEditViewAction.Add:
				return "Add New Payer:";
			case enumAddEditViewAction.Edit:
				return "Edit Payer: " + this.getTitleName();
			case enumAddEditViewAction.View:
				return "Payer: " + this.getTitleName();
        }
	}

	// -----------------
	//Handle User events

	private onSubmit = () => {
		//do not process, if already in progress
		if (this.state.status !== enumComponentStatus.Processing) {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
			//call the api and set the results in the state to reflect on the render
			let url: string = "";
			let httpAction: string = "";
			let responseMessage: string = "";
			switch (this.props.entitySettings.action) {
				case enumAddEditViewAction.Add:
					const parent: ITen99EntitySummary | undefined = this.props.entitySettings.parents.find(x => x.type === EnumEntityType.Customer)
					url = "api/Payers/?customerId=" + (isNullOrUndefined(parent) ? "" : parent.id);
					httpAction = "POST";
					responseMessage = "Payer " + this.state.payer.firstName + " " + this.state.payer.lastName + " added";
					break;
				case enumAddEditViewAction.Edit:
					url = "api/Payers/" + this.props.entitySettings.id;
					httpAction = "PUT";
					responseMessage = "Payer " + this.state.payer.firstName + " " + this.state.payer.lastName + " updated";
					break;
			}

			MakeApiCall<string>(url, httpAction, JSON.stringify(this.state.payer))
				.then(data => {
					if (data.isSuccess) {
						this.props.onClose({ processed: true, message: responseMessage } as IAevEntityResponse );
					}
					else {
						if (data.httpStatusCode === 422) {
							this.setState({ status: enumComponentStatus.Invalid, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
						}
						else if (data.httpStatusCode === 409)
						{
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Payer has been updated by another process. Please close and reload." }] as ITen99ApiResponseMessage[] });
                        }
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
						}
					}						
				});
		}
    }

	private onCancel = () =>
	{
		this.props.onClose({ processed: false, message: "" } as IAevEntityResponse);
	}

	private onPayerTypeChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ payer: { ...this.state.payer, payerTypeId : Number.parseInt(event.target.value) } });
	}


//	const stateIndex = this.state.form.formElementValues.findIndex((x) => x.formElementId === Number.parseInt(name));
//		let tempItems = [...this.state.form.formElementValues];
//tempItems[stateIndex] = { ...tempItems[stateIndex], elementValue: value };
//this.setState({ form: { ...this.state.form, formElementValues: tempItems } } as Pick<ILocalState, keyof ILocalState>);
	
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handlePayerChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ payer: { ...this.state.payer, [event.target.name]: event.target.value } } as Pick<ILocalState, keyof ILocalState>);
	}

	private handlePayerAddressChange = (name: string, value: any ) => {
		this.setState({ payer: { ...this.state.payer, address: { ...this.state.payer.address, [name]: value } } } as Pick<ILocalState, keyof ILocalState>);
	}

	private handlePayerContactChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ payer: { ...this.state.payer, contact: { ...this.state.payer.contact, [event.target.name]: event.target.value } } } as Pick<ILocalState, keyof ILocalState>);
	}

	private handleWitholdingAgentChange = (name: string, value: string) => {
		this.setState({ payer: { ...this.state.payer, [name]: value } } as Pick<ILocalState, keyof ILocalState>);
	}

	private onWithholdingAgentExpanded = () => {
		this.setState({ withholdingAgentExpanded: !this.state.withholdingAgentExpanded });		
	}

	private onPayerAdditional1042sInformationExpanded = () => {
		this.setState({ payerAdditional1042sInformationExpanded: !this.state.payerAdditional1042sInformationExpanded });
	}

	private onPayerAdditionalW2InformationExpanded = () => {
		this.setState({ payerAdditionalW2InformationExpanded: !this.state.payerAdditionalW2InformationExpanded });
	}

	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		
		const tinLabel = this.state.payer.payerTypeId === ITen99PersonTypeEnum.Individual ? "Taxpayer Identifier Number" : "Employer Identifier Number";
		const tinMaskFormat = this.state.payer.payerTypeId === ITen99PersonTypeEnum.Individual ? "###-##-####" : "##-#######";
		return (			
			<React.Fragment>
				<DialogTitle>
					<Typography variant="h5" >{this.getTitle(this.props.entitySettings.action)}</Typography>
					<EntityNav items={this.props.entitySettings.parents} />
				</DialogTitle>
				<DialogContent>	
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							{this.state.status === enumComponentStatus.Processing && (
								<Grid container spacing={1} sx={{textAlign: 'center'}}>
									<Grid item xs={12}>
										<div>
											<CircularProgress size={90} />
											<div>Processing...</div>
										</div>
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid item xs={12}>	
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							)}
							<Grid container spacing={1} sx={{textAlign: 'center'}}>
								{(this.state.status === enumComponentStatus.PendingAction || this.state.status === enumComponentStatus.Invalid) && (
									<React.Fragment>
										<Grid item xs={12} sx={{textAlign: 'left'}}>
											<FormControl 
												fullWidth
												required>
												<FormLabel id="payerTypeLabel">Payer Type</FormLabel>
												<RadioGroup name="payerTypeId" value={this.state.payer.payerTypeId.toString()} onChange={this.onPayerTypeChange} row>
													<FormControlLabel disabled={this.props.entitySettings.action === enumAddEditViewAction.View} value={ITen99PersonTypeEnum.Individual.toString()} label="Individual" control={<Radio color="primary" />} />
													<FormControlLabel disabled={this.props.entitySettings.action === enumAddEditViewAction.View} value={ITen99PersonTypeEnum.Company.toString()} label="Company" control={<Radio color="primary" />} />
												</RadioGroup>
											</FormControl>
										</Grid>

										{this.state.payer.payerTypeId === ITen99PersonTypeEnum.Individual && (
											<React.Fragment>
												<Grid item xs={6}>
													<TextField
														disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
														onChange={this.handlePayerChange}
														value={this.state.payer.firstName}
														variant="outlined"
														error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "FirstName") !== undefined)}
														id="firstName"
														label="First Name"
														name="firstName"
														required
														fullWidth
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
														onChange={this.handlePayerChange}
														value={this.state.payer.lastName}
														variant="outlined"
														error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "LastName") !== undefined)}
														id="lastName"
														label="Last Name"
														name="lastName"
														required
														fullWidth
													/>
												</Grid>
											</React.Fragment>
										)}
										{this.state.payer.payerTypeId === ITen99PersonTypeEnum.Company && (
											<React.Fragment>												
												<Grid item xs={12}>
													<TextField
														disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
														onChange={this.handlePayerChange}
														value={this.state.payer.companyName}
														variant="outlined"
														error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "CompanyName") !== undefined)}
														id="companyName"
														label="Company Name"
														name="companyName"
														required
														fullWidth
													/>
												</Grid>
											</React.Fragment>
										)}
										<Grid item xs={12}>
											<TextField
												disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
												onChange={this.handlePayerChange}
												value={this.state.payer.name2}
												variant="outlined"
												error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "Name2") !== undefined)}
												id="name2"
												label="Name Line 2"
												name="name2"												
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
												onChange={this.handlePayerChange}
												value={this.state.payer.tin}
												variant="outlined"
												error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "TaxNumber") !== undefined)}
												id="tin"
												label={tinLabel}
												name="tin"
												required
												fullWidth
												InputProps={{
													inputComponent: CustomerNumberFormat as any,
													inputProps: { format: tinMaskFormat, mask: "#"}
												}}
											/>
										</Grid>

										<Grid item xs={12} sx={{textAlign: 'left'}} >
											<Typography variant="h6">Address:</Typography>
										</Grid>

										<Address disabled={this.props.entitySettings.action === enumAddEditViewAction.View} invalid={this.state.status === enumComponentStatus.Invalid} address={this.state.payer.address} states={this.states} countries={this.countries} onchange={this.handlePayerAddressChange} validationMessages={this.state.validationMessages} invalidProperties={ this.state.invalidProperties	}/>

										<Grid item xs={12} sx={{textAlign: 'left'}} >
											<Typography variant="h6">Contact Info:</Typography>
										</Grid>

										<Contact disabled={this.props.entitySettings.action === enumAddEditViewAction.View} invalid={this.state.status === enumComponentStatus.Invalid} contact={this.state.payer.contact} onchange={this.handlePayerContactChange} validationMessages={this.state.validationMessages} invalidProperties={this.state.invalidProperties} phoneRequired={true} extendedContact={true} />

										<Grid item xs={12}>
											<Accordion disableGutters={true} expanded={this.state.payerAdditionalW2InformationExpanded} onChange={this.onPayerAdditionalW2InformationExpanded}>
												<AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: "payer.main", marginBottom: 0 }}>
													<Typography > Additional Payer information (only required for W2 forms) </Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ paddingLeft: 5 }}>
													{(this.state.payerAdditionalW2InformationExpanded) && (
														<Grid container spacing={1}>
															<DynamicInputGrid invalidState={this.state.status === enumComponentStatus.Invalid}
																disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
																onchange={this.handleWitholdingAgentChange}
																templates={this.payerAdditionalW2InformationTemplate}
																validationMessages={this.state.validationMessages}
																invalidProperties={ this.state.invalidProperties}
																formElements={[
																	{ formElementValueId: 1, formElementId: "formW2PayerId", elementValue: this.state.payer.formW2PayerId?.toString() ?? "" },
																	{ formElementValueId: 2, formElementId: "formW2EmployerId", elementValue: this.state.payer.formW2EmployerId?.toString() ?? "" },
																]}
															/>
														</Grid>

													)}
												</AccordionDetails>
											</Accordion>
										</Grid>

										<Grid item xs={12}>
											<Accordion disableGutters={true} expanded={this.state.withholdingAgentExpanded} onChange={this.onWithholdingAgentExpanded}>
												<AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: "payer.main", marginBottom: 0 }}>
													<Typography > Withholding Agent (only required for 1042-S forms) </Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ paddingLeft: 5 }}>
													{(this.state.withholdingAgentExpanded) && (
														<Grid container spacing={1}>
															<DynamicInputGrid invalidState={this.state.status === enumComponentStatus.Invalid}
																disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
																onchange={this.handleWitholdingAgentChange}
																templates={this.withholdingAgentTemplate}
																validationMessages={this.state.validationMessages}
																invalidProperties={ this.state.invalidProperties }
																formElements={[
																	{ formElementValueId: 1, formElementId: "ten42sBox12aWithholdingEin", elementValue: this.state.payer.ten42sBox12aWithholdingEin },
																	{ formElementValueId: 2, formElementId: "ten42sBox12bWithCh3StatusCodeId", elementValue: this.state.payer.ten42sBox12bWithCh3StatusCodeId?.toString() ?? "" },
																	{ formElementValueId: 3, formElementId: "ten42sBox12cWithCh4StatusCodeId", elementValue: this.state.payer.ten42sBox12cWithCh4StatusCodeId?.toString() ?? "" },
																	{ formElementValueId: 4, formElementId: "ten42sBox12dWithName", elementValue: this.state.payer.ten42sBox12dWithName },
																	{ formElementValueId: 5, formElementId: "ten42sBox12eWithGiin", elementValue: this.state.payer.ten42sBox12eWithGiin },
																	{ formElementValueId: 6, formElementId: "ten42sBox12fWithCountryCodeId", elementValue: this.state.payer.ten42sBox12fWithCountryCodeId?.toString() ?? "" },
																	{ formElementValueId: 7, formElementId: "ten42sBox12gWithForeignTin", elementValue: this.state.payer.ten42sBox12gWithForeignTin },
																	{ formElementValueId: 8, formElementId: "ten42sBox12hWithAddress", elementValue: this.state.payer.ten42sBox12hWithAddress },
																	{ formElementValueId: 9, formElementId: "ten42sBox12i1WithCity", elementValue: this.state.payer.ten42sBox12i1WithCity },
																	{ formElementValueId: 10, formElementId: "ten42sBox12i2WithState", elementValue: this.state.payer.ten42sBox12i2WithState },
																	{ formElementValueId: 11, formElementId: "ten42sBox12i3WithCountry", elementValue: this.state.payer.ten42sBox12i3WithCountry },
																	{ formElementValueId: 12, formElementId: "ten42sBox12i4WithZip", elementValue: this.state.payer.ten42sBox12i4WithZip },
																	{ formElementValueId: 13, formElementId: "ten42sBoxTemp3WithContactName", elementValue: this.state.payer.ten42sBoxTemp3WithContactName },
																	{ formElementValueId: 14, formElementId: "ten42sBoxTemp4WithDepartTitle", elementValue: this.state.payer.ten42sBoxTemp4WithDepartTitle },
																	{ formElementValueId: 15, formElementId: "ten42sBoxTemp5WithPhone", elementValue: this.state.payer.ten42sBoxTemp5WithPhone },
																	{ formElementValueId: 16, formElementId: "ten42sBoxTemp6WithPhoneExt", elementValue: this.state.payer.ten42sBoxTemp6WithPhoneExt },
																	{ formElementValueId: 17, formElementId: "ten42sBox16cPayerGiin", elementValue: this.state.payer.ten42sBox16cPayerGiin },
																	{ formElementValueId: 18, formElementId: "ten42sBox16dPayerCh3StatusCodeId", elementValue: this.state.payer.ten42sBox16dPayerCh3StatusCodeId?.toString() ?? "" },
																	{ formElementValueId: 19, formElementId: "ten42sBox16ePayerCh4StatusCodeId", elementValue: this.state.payer.ten42sBox16ePayerCh4StatusCodeId?.toString() ?? "" },
																]}
															/>
														</Grid>
														
													)}
												</AccordionDetails>
											</Accordion>
										</Grid>

										<Grid item xs={12}>
											<Accordion disableGutters={true} expanded={this.state.payerAdditional1042sInformationExpanded} onChange={this.onPayerAdditional1042sInformationExpanded}>
												<AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: "payer.main", marginBottom: 0 }}>
													<Typography > Additional Payer information (only for 1042-S forms) </Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ paddingLeft: 5 }}>
													{(this.state.payerAdditional1042sInformationExpanded) && (
														<Grid container spacing={1}>
															<DynamicInputGrid invalidState={this.state.status === enumComponentStatus.Invalid}
																disabled={this.props.entitySettings.action === enumAddEditViewAction.View}
																onchange={this.handleWitholdingAgentChange}
																templates={this.payerAdditional1042sInformationTemplate}
																validationMessages={this.state.validationMessages}
																invalidProperties={ this.state.invalidProperties}
																formElements={[
																	{ formElementValueId: 1, formElementId: "ten42sBox16cPayerGiin", elementValue: this.state.payer.ten42sBox16cPayerGiin },
																	{ formElementValueId: 2, formElementId: "ten42sBox16dPayerCh3StatusCodeId", elementValue: this.state.payer.ten42sBox16dPayerCh3StatusCodeId?.toString() ?? "" },
																	{ formElementValueId: 3, formElementId: "ten42sBox16ePayerCh4StatusCodeId", elementValue: this.state.payer.ten42sBox16ePayerCh4StatusCodeId?.toString() ?? "" },
																]}
															/>
														</Grid>

													)}
												</AccordionDetails>
											</Accordion>
										</Grid>
									</React.Fragment>
									)}					
								</Grid>						
						</Grid>						
					</Grid>	
				</DialogContent>
				<DialogActions>
					{(this.state.status === enumComponentStatus.Invalid || this.state.status === enumComponentStatus.PendingAction) &&
						(this.props.entitySettings.action === enumAddEditViewAction.Add || this.props.entitySettings.action === enumAddEditViewAction.Edit)
						&& (
							<React.Fragment>
								<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
								<Button type="button" startIcon={< Ten99PrepSaveIcon />} variant="contained" color="primary" onClick={this.onSubmit}>Save</Button>
							</React.Fragment>
						)}
					{(this.state.status === enumComponentStatus.Error || this.props.entitySettings.action === enumAddEditViewAction.View) && (
						<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="primary" onClick={this.onCancel}>Close</Button>
					)}
				</DialogActions>
			</React.Fragment>
		);
	}
}
export default connect(
	(state: ApplicationState) => state.homeNavigation, // Selects which state properties are merged into the component's props
	{}
)(AddEditViewPayer);
