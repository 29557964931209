import * as React from 'react';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { GetUser } from 'utilities/UserId';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {

    // check to see if a sessionIdExists in local storage
   

    if (!Component) return null;
    
    return (
        <Route
            {...rest}
            render={props =>
                (!!GetUser()) ? <Component {...props} /> : <Redirect to={"/usergate/login/?redirect=" + props.match.url.toLowerCase()} />
            }
        />
    );
};

export default PrivateRoute;