import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import ForeignAddress from 'components/ten99Prep/Address/ForeignAddress';
import { IAddressProps } from 'components/ten99Prep/Address/IAddressProps';
import UsAddress from 'components/ten99Prep/Address/UsAddress';
import * as React from 'react';
import { ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { isNullOrUndefined } from 'util';

interface ILocalState {	
	isForeignAddress: boolean,
}

class Address extends React.PureComponent<IAddressProps, ILocalState> {
	//local state
	readonly usaCountryId: number;
	constructor(props: IAddressProps) {
		super(props);
		let item: ITen99LookupItem | undefined = this.props.countries.find((x:ITen99LookupItem) => x.name === "USA")
		
		if (!isNullOrUndefined(item)) {
			this.usaCountryId = item.id;
		}
		else {
			this.usaCountryId = 0;
        }

		if (props.address.countryId === this.usaCountryId) {
			this.state = { isForeignAddress: false } as ILocalState;
		}
		else
		{
			this.state = { isForeignAddress: true } as ILocalState;
        }
	}
	// -----------------
	//Handle User events
	
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object

	private onAddressTypeChange = () => {
		if (this.state.isForeignAddress) {
			this.setState({ isForeignAddress: false });
			this.props.onchange("countryId", this.usaCountryId);		
		}
		else {
			this.setState({ isForeignAddress: true });
			this.props.onchange("countryId", -1);
        }
    }
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		return (			
			<React.Fragment>
				<Grid item xs={12} sx={{ textAlign: 'left'}}>
					<FormControl fullWidth>
						<FormLabel id="addressTypeLabel">Address Type</FormLabel>
						<RadioGroup name="addressType" value={this.state.isForeignAddress} onChange={this.onAddressTypeChange} row>
							<FormControlLabel disabled={this.props.disabled} value={false} label="US Address" control={<Radio color="primary" />} />
							<FormControlLabel disabled={this.props.disabled} value={true} label="Foreign Address" control={<Radio color="primary" />} />
						</RadioGroup>
					</FormControl>
				</Grid>

				{this.state.isForeignAddress === false && (
					<UsAddress disabled={this.props.disabled} invalid={this.props.invalid} address={this.props.address} states={this.props.states} countries={this.props.countries} onchange={this.props.onchange} validationMessages={this.props.validationMessages} invalidProperties={this.props.invalidProperties}/>
				)}
				{this.state.isForeignAddress === true && (
					<ForeignAddress disabled={this.props.disabled} invalid={this.props.invalid} address={this.props.address} states={this.props.states} countries={this.props.countries} onchange={this.props.onchange} validationMessages={this.props.validationMessages} invalidProperties={this.props.invalidProperties}/>
				)}
							
			</React.Fragment>
		);
	}
}

export default Address;