import { Dialog, Grid, Typography } from '@mui/material';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import ActiveCart from 'components/ten99Prep/Cart/ActiveCart';
import CartHistoryDashboard from 'components/ten99Prep/Cart/CartHistoryDashboard';
import CustomerStatusFilters from 'components/ten99Prep/CustomerStatusFilters';
import EntityAddEditView from 'components/ten99Prep/EntityAddEditView/EntityAddEditView';
import CustomerExport from 'components/ten99Prep/Export/CustomerExport';
import ImportWizard from 'components/ten99Prep/Import/ImportWizard';
import Reports from 'components/ten99Prep/Reporting/Reports';
import SummaryButtons from 'components/ten99Prep/SummaryButtons';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionTypesEnum, IAction } from 'sharedInterfaces/ITen99Action';
import { ITen99ActionResponse } from 'sharedInterfaces/ITen99ActionResponse';
import { enumAddEditViewAction, enumEntityType, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { EnumEntityType } from 'sharedInterfaces/ITen99EntitySummary';
import { ApplicationState } from 'store';
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigationStore from 'store/HomeNavigation';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99PrepCustomerIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from 'utilities/ApiFunctions';

enum enumComponentStatus {
	PendingAction,
	Processing,
	Invalid,
	Error
};

interface reduxItems {	
	homeNavigationStore: HomeNavigationStore.CustomerSummaryState
}

type CustomerProps =
	& reduxItems		
	& typeof HomeNavigationStore.actionCreators// ... plus action creators we've requested
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ customerId ?: string }> // ... plus incoming routing parameters
	;

interface ILocalState {
	status: enumComponentStatus,
	addEditCustomerOpen: boolean,
	addEditCustomer?: IAevEntitySetting,
	importDialogOpen: boolean,
	exportDialogOpen: boolean
	dialogOpen: boolean,
	dialogType?: ActionTypesEnum,
}



const initialLocalState = { status: enumComponentStatus.PendingAction, addEditCustomerOpen: false, addEditCustomer: undefined, importDialogOpen: false } as ILocalState;
class Customer extends React.PureComponent<CustomerProps, ILocalState> {
	//local state
	// -----------------
	//Handle User events

	constructor(props: CustomerProps) {
		super(props);
		this.state = initialLocalState;
	}
	
	private getCustomerCombinedName = () => {
		let combinedName: string = "";
		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			combinedName = this.props.homeNavigationStore.activeCustomer.name
			//if (!isNullOrUndefined(this.props.activeCustomer.maconomyKey) && this.props.activeCustomer.maconomyKey !== "") {
			//	combinedName = "(" + this.props.activeCustomer.maconomyKey + ")" + combinedName;
			//}
		}
			return combinedName;

	}

	private onAddEditCustomerProccessed = (response: IAevEntityResponse) => {
		this.setState({ addEditCustomerOpen: false });
		if (response.processed) {
		//refresh as things changed
			this.props.CustomerSummaryActions_RefreshActiveCustomerSummary();
		//send toast notification
		this.props.toastNotifcations_Display({ id: "CustomerAddEdit", message: response.message, type: "success" });
		}
    }

	private onAddEditCustomerOpen = (entityType: enumEntityType) => {
		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			this.setState({ addEditCustomerOpen: true, addEditCustomer: ({ action: enumAddEditViewAction.Edit, id: this.props.homeNavigationStore.activeCustomer.customerId, type: entityType } as IAevEntitySetting) });
		}
	}

	private onCustomerDeleteProceed = (id:string) => {
		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			this.setState({ status: enumComponentStatus.Processing });
			//call the api and set the results in the state to reflect on the render
			MakeApiCall<string>("api/Customers/" + this.props.homeNavigationStore.activeCustomer.customerId + "/?ticks=" + this.props.homeNavigationStore.activeCustomer.ticks, "DELETE")
					.then(data => {
						if (data.isSuccess) {
							this.props.CustomerSummaryActions_Reset();
							this.props.history.replace("/gate/home/customers")
							//send toast notification
							this.props.toastNotifcations_Display({ id: "CustomerDelete" + id, message: "Customer " + this.getCustomerCombinedName() + " deleted", type: "success" });
						}
						else {
							//send toast notification
							this.props.toastNotifcations_Display({ id: "CustomerDelete" + id, message: "Issue occurred deleting Customer " + this.getCustomerCombinedName(), type: "error" });
						}
						this.setState({ status: enumComponentStatus.PendingAction });
					});
		}
	}

	private onCustomerDelete = () => {
		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			this.props.confirmDialog_Display("Delete Forms", this.props.homeNavigationStore.activeCustomer.customerId, "You are about to delete the following Customer: " + this.getCustomerCombinedName(), this.onCustomerDeleteProceed);
		}
	}

	private onDeleteForms = () => { this.props.confirmDialog_Display("Delete Forms", (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) ? this.props.homeNavigationStore.activeCustomer.customerId : ""), "You are about to delete the forms in draft status for Customer: " + (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) ? this.props.homeNavigationStore.activeCustomer.name : ""), this.onDeleteFormsProceed); }
	private onDeleteFormsProceed = (id: string) => {
		this.onGenericAction(ActionTypesEnum.DeleteAllCustomers);
	}

	private onGenericAction = (actionId: number, additionalData?: string) => {
		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			this.setState({ status: enumComponentStatus.Processing });
			MakeApiCall<ITen99ActionResponse>("api/Bulk/" + this.props.homeNavigationStore.activeCustomer.customerId + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, additonalActionData: isNullOrUndefined(additionalData) ? "" : additionalData, ticks: this.props.homeNavigationStore.activeCustomer.ticks } as IAction))
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload)) {
							this.props.CustomerSummaryActions_RefreshSummaryCounts();
							data.payload.messages.forEach(x =>
								this.props.toastNotifcations_Display({ id: "Customer" + (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) ? this.props.homeNavigationStore.activeCustomer.customerId : "") + "action" + actionId, message: x.message, type: ToastNotifications.ConvertEnumToToastType(x.messageType) })
							);
						}
					}
					else {
						this.props.toastNotifcations_Display({ id: "Customer" + (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) ? this.props.homeNavigationStore.activeCustomer.customerId: "") + "action" + actionId, message: "Unable to Process action", type: "error" });
					}
					this.setState({ status: enumComponentStatus.PendingAction });
				});
		}
	}

	private onGenericActionFileDownload = (actionId: number, additionalData?: string) => {

		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			this.setState({ status: enumComponentStatus.Processing });
			MakeApiCall<string>("api/Bulk/" + this.props.homeNavigationStore.activeCustomer.customerId + "/Action", "POST", JSON.stringify({ actionTypeId: actionId, additonalActionData: isNullOrUndefined(additionalData) ? "" : additionalData, ticks: this.props.homeNavigationStore.activeCustomer.ticks } as IAction))
				.then(data => {
					if (data.isSuccess) {
						//refresh list
						if (!isNullOrUndefined(data.payload)) {
							PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
						}
						else {
							this.props.toastNotifcations_Display({
								id: "Customer" + (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) ? this.props.homeNavigationStore.activeCustomer.customerId : "") + "action" + actionId, message: "There are currently no documents in the requested state to view", type: "warning" });
                        }
					}
					else {
						this.props.toastNotifcations_Display({ id: "Customer" + (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer) ? this.props.homeNavigationStore.activeCustomer.customerId : "") + "action" + actionId, message: "Unable to Process action", type: "error" });
					}
					this.setState({ status: enumComponentStatus.PendingAction });
				});
		}
	}

	private onOpenDialog = (dialogType: ActionTypesEnum) => {
		this.setState({ dialogOpen: true, dialogType: dialogType });
	}	
	private onCloseDialog = () => {
		this.setState({ dialogOpen: false, dialogType: undefined });
	}	

	private onActionClickById = (id: string, action: ActionTypesEnum) => {
		switch (action) {
			case ActionTypesEnum.View:
				//incase view shows up and someone clicks, do nothing.
				break;
			case ActionTypesEnum.Delete:
				this.onCustomerDelete();
				break;
			case ActionTypesEnum.DeleteAllCustomers:
				this.onDeleteForms();
				break;
			case ActionTypesEnum.Edit:
				this.onAddEditCustomerOpen(enumEntityType.Customer);
				break;
			case ActionTypesEnum.EditMaconomyCustomer:
				this.onAddEditCustomerOpen(enumEntityType.MaconomyCustomer);
				break;
			case ActionTypesEnum.Import:				
			case ActionTypesEnum.Export:
			case ActionTypesEnum.Reporting:
			case ActionTypesEnum.ViewCart:
			case ActionTypesEnum.ViewCartHistory:
				this.onOpenDialog(action);
				break;
			case ActionTypesEnum.BulkPreview:
			case ActionTypesEnum.StaticDoc:
				this.onGenericActionFileDownload(action, EnumEntityType.Customer.toString());
				break;
			default:
				this.onGenericAction(action);
				break;
		}
	}

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object

	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		
		if (!isNullOrUndefined(this.props.homeNavigationStore.activeCustomer)) {
			const modifiedActions: ActionTypesEnum[] = [...this.props.homeNavigationStore.activeCustomer.actions, ActionTypesEnum.Reporting, ActionTypesEnum.ViewCart, ActionTypesEnum.ViewCartHistory] //TODO: pull this from BLL, not auto insert for all customers
			return (
				<React.Fragment>
					<Grid container spacing={1} sx={{ textAlign: 'center'}}>
						<Grid item xs={7} sx={{	textAlign: "left", display: "flex",	alignItems: "center"}}>
							<Ten99PrepCustomerIcon />
							<Typography variant="h5">Customer: {this.getCustomerCombinedName()} </Typography>
						</Grid>

						<Grid item xs={5} sx={{textAlign: "right"}}>
							<SummaryButtons itemId={this.props.homeNavigationStore.activeCustomer.customerId} onClick={this.onActionClickById} actions={modifiedActions} actionBadgeCounts={[{ action: ActionTypesEnum.ViewCart, count: this.props.homeNavigationStore.activeCustomerCartCount }]} />
						</Grid>
						<Grid item xs={12}>
							{!isNullOrUndefined(this.props.homeNavigationStore.activeCustomerSummaryCounts) && (
								<CustomerStatusFilters />
							)}
						</Grid>
					</Grid>					
					{this.state.addEditCustomerOpen && !isNullOrUndefined(this.state.addEditCustomer) && (
						<EntityAddEditView onClose={this.onAddEditCustomerProccessed} entitySettings={this.state.addEditCustomer} />
					)}
					{this.state.status === enumComponentStatus.Processing && (
						<ProcessingModal modal={true} />
					)}
					{this.state.dialogOpen && !isNullOrUndefined(this.state.dialogType) && this.state.dialogType !== ActionTypesEnum.Import && (
						<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth="lg">
							{this.state.dialogType === ActionTypesEnum.Export && (
								<CustomerExport customer={this.props.homeNavigationStore.activeCustomer} onClose={this.onCloseDialog} />
							)}
							{this.state.dialogType === ActionTypesEnum.Reporting && (
								<Reports customer={this.props.homeNavigationStore.activeCustomer} onClose={this.onCloseDialog} />
							)}
							{this.state.dialogType === ActionTypesEnum.ViewCart && (
								<ActiveCart customer={this.props.homeNavigationStore.activeCustomer} onClose={this.onCloseDialog} />
							)}
							{this.state.dialogType === ActionTypesEnum.ViewCartHistory && (
								<CartHistoryDashboard customer={this.props.homeNavigationStore.activeCustomer} onClose={this.onCloseDialog} />
							)}
						</Dialog>
					)}
					{this.state.dialogOpen && this.state.dialogType === ActionTypesEnum.Import && (
						<ImportWizard customer={this.props.homeNavigationStore.activeCustomer} onClose={this.onCloseDialog} />
					)}
				</React.Fragment>
			);
		}
		else {
			return (<React.Fragment></React.Fragment>);
        }
	}
}

function mapStateToProps(state: ApplicationState) {

	return { userStore: state.user, homeNavigationStore: state.homeNavigation }
}
// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	mapStateToProps, // Selects which state properties are merged into the component's props
	{ ...HomeNavigationStore.actionCreators, ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators } // Selects which action creators are merged into the component's props
)((withRouter(Customer as any)));