import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { CustomerNumberFormat } from 'components/extensions/CustomNumberFormat';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import PhoneConfirmation from 'components/ten99Prep/UserProfile/PhoneConfirmation';
import PhoneEdit from 'components/ten99Prep/UserProfile/PhoneEdit';
import { useEffect, useState } from 'react';
import { ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { Ten99Accept, Ten99PrepCancelIcon, Ten99PrepDeleteIcon, Ten99PrepEditIcon, Ten99PrepEmailIcon, Ten99PrepFailIcon, Ten99PrepPhoneIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from '../../../utilities/PropertyList';
import PreferredTwoFactor from './PreferredTwoFactor';


export default function UserProfileGateway({ onClose }: { onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void }) {

	enum enumComponentStatus {
		CreatingTwoFactor,
		PendingAction,
		Processing,
		Loading,
		Invalid,
		Error
	};

	interface IUser {
		email: string,
		emailConfirmed: boolean,
		fullName: string,
		phoneNumber: string,
		phoneNumberConfirmed: boolean,
		phoneNumberOptIn: boolean,
		preferredTwoFactorProvider: string
	}

	interface IUserSettings {
		userName: string,
		email: string,
		emailConfirmed: boolean,
		phoneNumber: string,
		phoneNumberConfirmed: boolean,
		preferredTwoFactorProvider: string,

		twoFactorToken:string,

		status: enumComponentStatus,
		validationMessages: ITen99ApiResponseMessage[],
		invalidProperties: string[]
	}

	enum enumPhoneEditStatus {
		Edit,
		Confirm,
	};


	const phoneMaskFormat = "(###) ###-####";
	const [userSettings, setUserSettings] = useState<IUserSettings>({
		userName: "",
		email: "",
		phoneNumber: "",
		phoneNumberConfirmed: false,
		preferredTwoFactorProvider: "",
		twoFactorToken: "",

		validationMessages: [] as ITen99ApiResponseMessage[],
		invalidProperties: [] as string[],
		status: enumComponentStatus.Loading,
	} as IUserSettings);

	const [phoneEditStatus, setPhoneEditStatus] = useState<enumPhoneEditStatus|null>(null);
	const [preferredTwoFactorOpen, setPeferredTwoFactorOpen] = useState<boolean>(false);
	const [breadcrumbs, setBreadcrumbs] = useState<string[]>([] as string[]);


	function PeferredTwoFactorOpen() {
		setBreadcrumbs([userSettings.userName]);
		setPeferredTwoFactorOpen(true);
	}

	function PeferredTwoFactorClose(proceesed: boolean, message?: ITen99ActionResponseMessage) {
		setPeferredTwoFactorOpen(false);
		RefreshScreen();
	}

	function EditPhoneOpen() {
		setBreadcrumbs([userSettings.userName]);
		setPhoneEditStatus(enumPhoneEditStatus.Edit);
	}

	function EditPhoneClose(proceesed: boolean, phoneNumber: string) {
		if (proceesed && phoneNumber !== "") {
			setBreadcrumbs([userSettings.userName, formatPhone(phoneNumber)]);
			setPhoneEditStatus(enumPhoneEditStatus.Confirm)
		}
		else {
			setPhoneEditStatus(null);
			RefreshScreen();
		}
	}

	function ConfirmPhoneOpen() {
		setBreadcrumbs([userSettings.userName, formatPhone(userSettings.phoneNumber)]);
		setPhoneEditStatus(enumPhoneEditStatus.Confirm)
	}

	function ConfirmPhoneClose(proceesed: boolean, message?: ITen99ActionResponseMessage) {
		RefreshScreen();
		setPhoneEditStatus(null)
	}

	function DeletePhone() {
		setUserSettings(prevState => {
			return {
				...prevState,
				status: enumComponentStatus.Processing,
				validationMessages: [] as ITen99ApiResponseMessage[],
				invalidProperties: [] as string[]
			}
		});

		//call api
		MakeApiCall<string>("api/User/TwoFactor/Phone", "POST", JSON.stringify({ phoneNumber: "", optIn: false }))
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					RefreshScreen();
				}
				else if (data.httpStatusCode = 422) {
					setUserSettings(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.PendingAction,
							validationMessages: data.details,
							invalidProperties: flattenProperties(data.details)
						}
					});
				}
				else {
					setUserSettings(prevState => {
						return {
							...prevState,
							status: enumComponentStatus.Error,
							validationMessages: [{ type: "Error", propertyNames: [""] as string[], message: "Issue Saving Phone" }] as ITen99ApiResponseMessage[],
							invalidProperties: flattenProperties(data.details)
						}
					});
				}
			})
	}

	function RefreshScreen(status: enumComponentStatus = enumComponentStatus.Processing) {
		setUserSettings(prevState => {
			return {
				...prevState,
				status: status,
				twoFactorToken: "",
				validationMessages: [] as ITen99ApiResponseMessage[],
				invalidProperties: [] as string[]
			}
		});

		let promises = [];
		promises.push(MakeApiCall<IUser>("api/User/Details", "GET"));

		Promise.all(promises).then(values => {

			let tempValidationMessages: ITen99ApiResponseMessage[] = [];

			//user
			let user: IUser = {
				email:  "",
				emailConfirmed: false,
				fullName: "",
				phoneNumber: "",
				phoneNumberConfirmed: false,
				phoneNumberOptIn: false,
			} as IUser;

			if (values[0].isSuccess && !isNullOrUndefined(values[0].payload)) {

				user = values[0].payload as IUser;
			}
			else {
				tempValidationMessages.push({ type: "Error", propertyNames: [""] as string[], message: "Unable to load phone", isWarning: false });
			}

			setUserSettings(prevState => {
				return {
					...prevState,
					email: user.email,
					emailConfirmed: user.emailConfirmed,
					phoneNumber: user.phoneNumber,
					phoneNumberConfirmed: user.phoneNumberConfirmed,
					phoneNumberOptIn: user.phoneNumberOptIn,
					preferredTwoFactorProvider: user.preferredTwoFactorProvider,
					userName: user.fullName,

					validationMessages: tempValidationMessages,
					status: enumComponentStatus.PendingAction,
				}				
			});
		});
	}

	function GetConfirmationColor(confirmed: boolean) {
		if (userSettings.status === enumComponentStatus.Loading) {
			return "secondary";
		}
		else if (confirmed) {
			return "success";
		}
		else {
			return "error";
		}
	}

	function GetConfirmationText(confirmed: boolean) {
		if (userSettings.status === enumComponentStatus.Loading) {
			return "";
		}
		else if (confirmed) {
			return "Confirmed";
		}
		else {
			return "Unconfirmed";
		}
	}

	function formatPhone(phoneNumber: string) {
		return "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(4, 7) + " - " + phoneNumber.substring(6, 10);
	}

	useEffect(() => {
		RefreshScreen(enumComponentStatus.Loading);
	}, []);

	return (
		<>
			<DialogTitle>
				<Grid container spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="h4">User Profile
							<InfoToolTip text="User settings" />
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={3} sx={{ textAlign: 'center', paddingTop: "8px" }}>
					<Grid item xs={12}>
						{((userSettings.status === enumComponentStatus.Processing
							|| userSettings.status === enumComponentStatus.Loading)
							&& (
								<ProcessingModal modal={true} />
							))}
					</Grid>
					<Grid item xs={6}>
						<TextField
							disabled={true}
							value={userSettings.userName}
							variant="outlined"
							id="userName"
							label="User Name"
							name="userName"
							required={false}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6} />

					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Ten99PrepEmailIcon />
							<TextField
								disabled={true}
								value={userSettings.email}
								variant="outlined"
								id="email"
								label="Email"
								name="email"
								required={false}
								fullWidth
							/>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Button type="button" variant="contained" color={GetConfirmationColor(userSettings.emailConfirmed)} startIcon={<Ten99Accept />}>{GetConfirmationText(userSettings.emailConfirmed)}</Button>
						</Box>
					</Grid>

					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Ten99PrepPhoneIcon />
							<TextField
								disabled={true}
								value={userSettings.phoneNumber}
								variant="outlined"
								id="phoneNumber"
								label="Phone Number"
								name="phoneNumber"
								InputProps={{
									inputComponent: CustomerNumberFormat as any,
									inputProps: { format: phoneMaskFormat, mask: "#" }
								}}
								required={false}
								fullWidth
							/>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{(userSettings.phoneNumber !== "")
								&& (
								<Button type="button" variant="contained" color={GetConfirmationColor(userSettings.phoneNumberConfirmed)} startIcon={<Ten99Accept />} onClick={() => userSettings.phoneNumberConfirmed ? null : ConfirmPhoneOpen()}>{GetConfirmationText(userSettings.phoneNumberConfirmed)}</Button>
								)}
							<Button sx={{ marginLeft: "10px"}} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepEditIcon />} onClick={EditPhoneOpen}>Edit</Button>
							<Button sx={{ marginLeft: "5px" }} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepDeleteIcon />} onClick={DeletePhone}>Clear</Button>
						</Box>
					</Grid>


					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<TextField
								disabled={true}
								value={userSettings.preferredTwoFactorProvider}
								variant="outlined"
								id="preferredTwoFactor"
								label="Preferred Two Factor Provider"
								name="preferredTwoFactor"
								required={false}
								fullWidth
								/>
						</Box>
					</Grid>		
					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepEditIcon />} onClick={PeferredTwoFactorOpen}>Edit</Button>
						</Box>
					</Grid>		
					{userSettings.status === enumComponentStatus.PendingAction && !isNullOrUndefined(userSettings.validationMessages) && userSettings.validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={userSettings.validationMessages} />
						</Grid>
					)}

					
				</Grid>

				<Grid item xs={12}>
					{(phoneEditStatus !== null) &&
						(
						<Dialog open={true} fullWidth={true} maxWidth="md">
							<DialogTitle>
								<Grid container spacing={1} sx={{ textAlign: 'center' }}>
									<Grid item xs={12} sx={{ textAlign: 'left' }}>
										<Typography variant="h4">Phone Number
										</Typography>
										<Breadcrumbs separator=">">
											{breadcrumbs.map((item, index) => {
												return (
													<div key={index}>
														<Typography display="inline" variant="subtitle2" color="textSecondary"> {item} </Typography>
													</div>
												)
											}
											)}
										</Breadcrumbs>
									</Grid>
								</Grid>
							</DialogTitle>
							
							{(phoneEditStatus === enumPhoneEditStatus.Edit)
							&& (<PhoneEdit phoneNumber={userSettings.phoneNumber} onClose={EditPhoneClose} />)}

							{(phoneEditStatus === enumPhoneEditStatus.Confirm)
							&& (<PhoneConfirmation onClose={ConfirmPhoneClose} />)}
							
						</Dialog>
						)}
					{(preferredTwoFactorOpen) &&
						(
							<Dialog open={true} fullWidth={true} maxWidth="md">
								<DialogTitle>
									<Grid container spacing={1} sx={{ textAlign: 'center' }}>
										<Grid item xs={12} sx={{ textAlign: 'left' }}>
											<Typography variant="h4">Select Preferred Two Factor Method
											</Typography>
										</Grid>
									</Grid>
									<Breadcrumbs separator=">">
										{breadcrumbs.map((item, index) => {
											return (
												<div key={index}>
													<Typography display="inline" variant="subtitle2" color="textSecondary"> {item} </Typography>
												</div>
											)
										}
										)}
									</Breadcrumbs>
								</DialogTitle>
								<PreferredTwoFactor onClose={PeferredTwoFactorClose} />
							</Dialog>
						)}

				</Grid>

			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => onClose(false, undefined)}>Close</Button>
			</DialogActions>
		</>
	);
}
