import * as React from 'react';
import SiteFooter from 'components/information/SiteFooter';
export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <div>
            {props.children}
        </div>
        <SiteFooter/>
    </React.Fragment>
);
