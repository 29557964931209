import { Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import { DynamicInputGrid } from "components/ten99Prep/DynamicInputGrid/DynamicInputGrid";
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { EnumEntityType } from "sharedInterfaces/ITen99EntitySummary";
import { IForm } from "sharedInterfaces/ITen99Form";
import { IFormElement } from "sharedInterfaces/ITen99FormElement";
import { IFormElementTemplate } from "sharedInterfaces/ITen99FormElementTemplate";
import { ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import * as ConfirmDialog from 'store/ConfirmDialog';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { QBO_STRING } from "./QboConstants";
import QboMappingAutoComplete from "./QboMappingAutoComplete";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface IFormElementAccount {
	formElementId: number,
	formElementValueId: number,
	ticks?: string
	accounts: ITen99LookupItem[]
}

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	qboPayers: ITen99LookupItem[],
	formTemplate: IFormElementTemplate[],
	qboAccounts: ITen99LookupItem[],
	invalidProperties: string[],
	formElementAccounts: IFormElementAccount[],
	autoCompleteOpen: boolean,
	mappingId?: string
}

interface IQboMappingProps {
	customerId: number,
	validationMessages?: ITen99ApiResponseMessage[],
	selectedPayerId: number,
	selectedFormTypeId: number,
	onMappingUpdate: (formElements: IFormElement[], mappingId?: string) => void,
	onExistingMappingFound: (mappingId: string, ticks: string) => void,	
}

type QboMappingProps =
	& IQboMappingProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,
	
	formElementAccounts: [] as IFormElementAccount[],
	invalidProperties: [] as string[],
	formTemplate: [] as IFormElementTemplate[],
	qboAccounts: [] as ITen99LookupItem[],
	validationMessages: [] as ITen99ApiResponseMessage[],
	qboPayers: [] as ITen99LookupItem[],
	mappingId: undefined,
	autoCompleteOpen: false,
};
class QboMapping extends React.PureComponent<QboMappingProps, ILocalState> {

	private autoCompleteFromElementId: string = "";

	constructor(props: QboMappingProps) {
		super(props);
		this.state = JSON.parse(JSON.stringify(initialLocalState));
		this.loadMappingsFromDb();
	}

	private loadMappingsFromDb = () =>	{
		Promise.all(
			[
				MakeApiCall<IFormElementTemplate[]>("api/QuickbooksForm/" + this.props.selectedFormTypeId + "/Template", "GET")
				, MakeApiCall<ITen99LookupItem[]>("api/QuickbooksForm/" + this.props.selectedPayerId + "/Accounts", "GET")
				, MakeApiCall<string>("api/QuickbooksForm/GetQuickBooksFormId?payerId=" + this.props.selectedPayerId + "&formTypeId=" + this.props.selectedFormTypeId, "GET")
			]
		).then(values => {
			let template: IFormElementTemplate[] = [] as IFormElementTemplate[];
			let accounts: ITen99LookupItem[] = [] as ITen99LookupItem[];
			let innerValidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
			let existingFormId: string = "";
			let formElementAccounts: IFormElementAccount[] = [] as IFormElementAccount[];
			values.forEach((response, index) => {
				if (response.isSuccess && response.payload !== undefined) {
					switch (index) {
						case 0: //template
							if (response.isSuccess && !isNullOrUndefined(response.payload)) {
								template = response.payload as IFormElementTemplate[];

								template.forEach((item) => {
									formElementAccounts.push({ formElementId: item.formElementId as number, formElementValueId: -1, accounts: [] as ITen99LookupItem[] })
								})
							}
							else {
								innerValidationMessages.push(...response.details);
							}
							break;
						case 1: //accounts
							if (response.isSuccess && !isNullOrUndefined(response.payload)) {
								accounts = response.payload as ITen99LookupItem[];
							}
							else {
								innerValidationMessages.push(...response.details);
							}
							break;
						case 2: //form id
							if (response.isSuccess && !isNullOrUndefined(response.payload)) {
								existingFormId = response.payload as string;
							}
							else if (response.httpStatusCode === 404) //not found... new item
							{

							}
							else {
								innerValidationMessages.push(...response.details);
							}
							break;
					}
				}
			});

			template.forEach((item) => {
				item.source = [];
				item.linkedEntityTypeId = EnumEntityType.QboMapping
			})


			if (innerValidationMessages.length > 0) {
				this.setState({ status: enumComponentStatus.Error, validationMessages: innerValidationMessages });
			}
			else {
				if (existingFormId !== "") {//load form
					MakeApiCall<IForm>("api/QuickbooksMapper/" + existingFormId, "GET")
						.then(data => {
							if (data.isSuccess) {
								if (!isNullOrUndefined(data.payload)) {
									let formElementTemplate: IFormElementTemplate | undefined;

									data.payload.formElementValues.forEach((item) => {
										let formElementAccount: IFormElementAccount | undefined = formElementAccounts.find(x => x.formElementId == item.formElementId);
										if (!isNullOrUndefined(formElementAccount)) {
											formElementAccount.formElementValueId = item.formElementValueId;
											formElementAccount.ticks = item.ticks;

											formElementTemplate = template.find(x => x.formElementId === item.formElementId);
											let values: string[] = item.elementValue.split(",");
											values.forEach((value) => {
												let lookUpItem: ITen99LookupItem | undefined = accounts.find(x => x.id.toString() === value);
												if (!isNullOrUndefined(lookUpItem)) {
													formElementAccount?.accounts.push(lookUpItem);
													formElementTemplate?.source.push(lookUpItem);
												}
											});
										}
									});

									this.setState({ status: enumComponentStatus.PendingAction, mappingId: existingFormId, formTemplate: template, qboAccounts: accounts, formElementAccounts: formElementAccounts });
									this.props.onExistingMappingFound(existingFormId, data.payload.ticks)
								}
							}
							else {
								this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load
							}
						})
				}
				else { //proceed
					this.setState({ status: enumComponentStatus.PendingAction, formTemplate: template, qboAccounts: accounts, formElementAccounts: formElementAccounts });
				}
			}
		})
	}


	private onActionClick = (id: string, action: ActionTypesEnum) => {
		this.autoCompleteFromElementId = id;
		this.setState({ autoCompleteOpen: true });
	}

	private onCloseAutocomplete = () => {
		this.setState({ autoCompleteOpen: false });
	}

	private onSaveAutocomplete = (items: ITen99LookupItem[]) => {

		let template: IFormElementTemplate[] = this.state.formTemplate;
		let formElementAccounts: IFormElementAccount[] = this.state.formElementAccounts;
		let formElementTemplate: IFormElementTemplate | undefined;
		let elementAccounts: IFormElementAccount | undefined;

		if (!isNullOrUndefined(template) && !isNullOrUndefined(formElementAccounts)) {
			formElementTemplate = template.find(x => x.formElementId.toString() === this.autoCompleteFromElementId);
			elementAccounts = formElementAccounts.find(x => x.formElementId.toString() === this.autoCompleteFromElementId);
			if (!isNullOrUndefined(formElementTemplate) && !isNullOrUndefined(elementAccounts)) {
				formElementTemplate.source = items;
				elementAccounts.accounts = items;
			}
		}			

		this.setState({ formTemplate: [...template], formElementAccounts: [...formElementAccounts] });

		let temp: IFormElement[] = [] as IFormElement[];

		this.state.formElementAccounts.map((item) => {
			temp.push({ formElementId: item.formElementId, elementValue: item.accounts.map((account) => account.id).join(","), formElementValueId: item.formElementValueId, ticks: item.ticks } as IFormElement)
		})

		this.props.onMappingUpdate(temp);
		this.onCloseAutocomplete();
	}
	
	private getInactiveOptions = (formElementid: string) => {
		let accounts: ITen99LookupItem[] = [] as ITen99LookupItem[];
		this.state.formElementAccounts.map((value) => {
			if (value.formElementId.toString() !== formElementid) {
				accounts.push(...value.accounts);
			}
		});
		return accounts;
	}

	private getActiveOptions = (formElementid: string) => {
		return this.state.formElementAccounts.find((value) => value.formElementId.toString() === formElementid)?.accounts ?? [] as ITen99LookupItem[];
	}

	private getFormElementName = (formElementid: string) => {
		return this.state.formTemplate.find(x => x.formElementId.toString() === this.autoCompleteFromElementId)?.elementName;
	}

	private onClear = () => {
		this.props.confirmDialog_Display(QBO_STRING + " Mapping", "-1", "You are about to clear out all the currently selected mappings on screen.", this.onClearConfirmed)
	}

	private onReset = () => {
		this.props.confirmDialog_Display(QBO_STRING + " Mapping", "-1", "You are about reset the mappings on screen to the mappings stored in the database.", this.onResetConfirmed)
	}

	private onClearConfirmed = () => {
		let template: IFormElementTemplate[] = this.state.formTemplate;

		if (!isNullOrUndefined(template)) {
			template.forEach(x => x.source = [])
		}

		let accounts: IFormElementAccount[] = this.state.formElementAccounts;

		if (!isNullOrUndefined(accounts)) {
			accounts.forEach(x => x.accounts = [])
		}

		this.setState({ formTemplate: [...template], formElementAccounts: [...accounts] });
	}

	private onResetConfirmed = () => {
		this.setState({ status: enumComponentStatus.Processing });
		this.loadMappingsFromDb();
	}

	// Render
	render() {	
		return (
			<React.Fragment>
				
				<Grid container spacing={1}>
					<Grid item xs={6} sx={{ textAlign: 'left' }}>
						<Typography variant="h6">Map Accounts to Form Fields</Typography>
					</Grid>
					<Grid item xs={6}>
						<Stack spacing={1} direction="row" justifyContent="end">
							<Button type="button" variant="contained" color="secondary" onClick={this.onClear}>Clear</Button>
							<Button type="button" variant="contained" color="secondary" onClick={this.onReset}>Reset</Button>
						</Stack>
					</Grid>

					<Grid item xs={12}>
					</Grid>

					{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ProcessingModal modal={true} />
						</Grid>
					)}

					{this.props.validationMessages !== undefined && this.props.validationMessages.length > 0 && (this.state.status !== enumComponentStatus.Processing && this.state.status !== enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ApiErrorResponseMessages messages={this.props.validationMessages} />
						</Grid>
					)}

					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (this.state.status !== enumComponentStatus.Processing && this.state.status !== enumComponentStatus.IsLoading) && (
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					)}

					{(this.state.status !== enumComponentStatus.IsLoading) && !isNullOrUndefined(this.state.formTemplate) && this.state.formTemplate.length > 0 && (
						<DynamicInputGrid invalidState={this.state.status === enumComponentStatus.Invalid}
							disabled={false}
							onchange={() => { }}
							templates={this.state.formTemplate}
							formElements={[]}
							validationMessages={this.state.validationMessages}
							invalidProperties={this.state.invalidProperties}
							onActionClick={this.onActionClick}
						/>
					)}
				</Grid>
				{this.state.autoCompleteOpen && (
					<Dialog open={this.state.autoCompleteOpen} fullWidth={true} maxWidth={"md"}>
						<QboMappingAutoComplete fieldName={this.getFormElementName(this.autoCompleteFromElementId) as string} items={this.state.qboAccounts} selectedItems={this.getActiveOptions(this.autoCompleteFromElementId) } disabledItems={this.getInactiveOptions(this.autoCompleteFromElementId)} onClose={this.onCloseAutocomplete} onSave={this.onSaveAutocomplete} />
					</Dialog>
				)}
			</React.Fragment>
		)
	}
}

export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators }, // Selects which action creators are merged into the component's props
)((QboMapping));
