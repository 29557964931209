import { AccountBoxOutlined, ContactSupportOutlined, ExitToApp, HelpOutlineOutlined, PictureAsPdfOutlined } from '@mui/icons-material';
import { Dialog, Grid } from '@mui/material';
import { enumToolbarActionType, SimpleToolbar, ToolbarItem } from 'components/buttons/SimpleToolbar';
import DocumentSummary from 'components/documentRetrieval/dashboard//DocumentSummary';
import { IRecipientEmailLanding } from 'components/documentRetrieval/dashboard//models/IRecipientEmailLanding';
import ITerm from 'components/documentRetrieval/dashboard//models/ITerm';
import Terms from 'components/documentRetrieval/dashboard//Terms';
import TechnicalAssistance from 'components/documentRetrieval/dashboard/TechnicalAssistance';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import IdleLogoutTimer from 'components/notifcations/IdleLogoutTimer';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import DynamicNotifications from 'components/ten99Prep/DynamicNotifications';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { ConvertEnumToToastType, ITen99ActionResponseMessage } from 'sharedInterfaces/ITen99ActionResponse';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ApplicationState } from 'store';
import * as ToastNotificationsStore from 'store/ToastNotifications';
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from 'utilities/ApiFunctions';

export default function DocumentRetrievalGateway() {

	enum enumComponentStatus {
        Initializing,
        VerifyingTerms,
        TermAcceptancePending,
        DocumentListLoading,
        DocumentListLoaded,
        DocumentLoading,
        DocumentLoaded,
        Error,
        LoggedOut,
    };

    const processingStatuses = new Set([enumComponentStatus.Initializing,
    enumComponentStatus.DocumentListLoading,
    enumComponentStatus.VerifyingTerms,
    enumComponentStatus.DocumentLoading,
    ])

    const dispatch = useDispatch();
    const userName: string = useSelector((state: ApplicationState) => state.user?.user_Name) ?? "";
    const toolbarItems: ToolbarItem[] = [
        { title: userName, icon: < AccountBoxOutlined />, itemType: enumToolbarActionType.onClick, onClick: () => { } },
        {
            title: "Help", icon: < HelpOutlineOutlined />, itemType: enumToolbarActionType.menu, subItems:
                [
                    { title: "User Guide", icon: <PictureAsPdfOutlined />, itemType: enumToolbarActionType.onClick, onClick: () => onNewWindowOpen("/UserGuide_Recipients.pdf") },
                    { title: "Technical Assistance", icon: <ContactSupportOutlined />, itemType: enumToolbarActionType.onClick, onClick: () => { setTechnicalAssistDialogOpen(true) } },
                ]
        },
        { title: "Log Out", icon: < ExitToApp />, itemType: enumToolbarActionType.onClick, onClick: () => onLogout() },
    ];

    const [formGuid, setFormGuid] = useState<string>("");
    const [status, setStatus] = useState<enumComponentStatus>(enumComponentStatus.Initializing);
    const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([] as ITen99ApiResponseMessage[]);
    const [dashboardData, setDashboardData] = useState<IRecipientEmailLanding[]>([] as IRecipientEmailLanding[]);
    const [unacceptedTerms, setUnacceptedTerms] = useState<ITerm[]>([] as ITerm[]);
    const [technicalAssistDialogOpen, setTechnicalAssistDialogOpen] = useState<boolean>(false);
    const [termsDialogOpen, setTermsDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		if (status === enumComponentStatus.Initializing) {
            let params = new URLSearchParams(window.location.search);
            let tempFormGuid = params.get("formGuid");
            if (tempFormGuid === null || tempFormGuid === "") {
                setStatus(enumComponentStatus.Error);
                setValidationMessages([{ type: "Error", message: "No document has been specifed. Please open the link sent to you via email." }] as ITen99ApiResponseMessage[]);
            }
            else {
                setFormGuid(tempFormGuid);
                VerifyTerms();
            }
        }
        //console.log("render: status = " + status + ", isProcessing = " + processingStatuses.has(status) + ", dashboard = " + dashboardData.length);
    })

    function VerifyTerms() {
        setStatus(enumComponentStatus.VerifyingTerms);
        MakeApiCall<ITerm[]>("api/RecipientGateway/Terms/", "GET")
            .then(data => {
                if (data.isSuccess && data.payload !== undefined) {
                    setUnacceptedTerms(data.payload);

                    if (data.payload.length > 0) {
                        setStatus(enumComponentStatus.TermAcceptancePending);
                        setTermsDialogOpen(true);
                    }
                    else {
                        Load();
                    }
                }
                else {
                    setStatus(enumComponentStatus.Error);
                    setValidationMessages([{ type: "Error", message: "Unable to load the Terms for the user." }] as ITen99ApiResponseMessage[]);
                }
            });
    }

    function Load() {
        setStatus(enumComponentStatus.DocumentListLoading);
        MakeApiCall<IRecipientEmailLanding>("api/RecipientGateway/Summary/", "GET")
            .then(data => {
                if (data.isSuccess && data.payload !== undefined) {
                    setDashboardData([data.payload] as IRecipientEmailLanding[]);
                    setStatus(enumComponentStatus.DocumentListLoaded);
                }
                else {
                    setValidationMessages(data.details as ITen99ApiResponseMessage[]);
                }
            });
    }

    function onLogout()
    {
        setStatus(enumComponentStatus.LoggedOut);
    }

    function onDocumentClick(id: string, action: ActionTypesEnum)
    {
        setStatus(enumComponentStatus.DocumentLoading);
        MakeApiCall<string>("api/RecipientGateway/Document/", "GET")
            .then(data => {
                if (data.isSuccess) {
                    if (!isNullOrUndefined(data.payload)) {
                        PromptDownloadFile(data.payload, "application/pdf", "Form.pdf")
                        setStatus(enumComponentStatus.DocumentLoaded);
                    }
                }
                else {
                    dispatch(ToastNotificationsStore.actionCreators.toastNotifcations_Display({ id: "FormDoc_1", message: "Unable to view the document", type: "error" }));
                    setStatus(enumComponentStatus.Error);
                }
            });
    }

    function onCloseTermsDialog(proceesed: boolean, message?: ITen99ActionResponseMessage) {
        //close form
        setTermsDialogOpen(false);

        if (!proceesed) {
            setStatus(enumComponentStatus.LoggedOut);
        }
        else {
            Load();
        }
    }

    function onCloseTechnicalAssistDialog(proceesed: boolean, message?: ITen99ActionResponseMessage) {
        //close form
        setTechnicalAssistDialogOpen(false);
        
        if (proceesed && !isNullOrUndefined(message)) {
            //send Toast message
            dispatch(ToastNotificationsStore.actionCreators.toastNotifcations_Display({ id: "dialog", message: message.message, type: ConvertEnumToToastType(message.messageType) }))
        }
    }

    function onNewWindowOpen(url: string) {
        window.open(url, '_blank');
    }; 

	return	(
        <>
            {status === enumComponentStatus.LoggedOut && (
                <Redirect to={"/documentRetrievalGate/logout?formGuid=" + formGuid } />
            )}
            {status !== enumComponentStatus.LoggedOut && (
                <>
                    <SimpleToolbar toolbarItems={toolbarItems} />
                    <br />
                    <Grid container spacing={3} sx={{ minHeight: "85vh", }}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={8} sx={{
                            textAlign: 'left',
                            borderRight: "1px",
                            borderRightStyle: "solid",
                            borderRightColor: 'primary.dark',
                            borderLeft: "1px",
                            borderLeftStyle: "solid",
                            borderLeftColor: 'primary.dark',
                        }}  >
                            {dashboardData.map((item, index) => {
                                 return <DocumentSummary key={index} documentDetails={item} onDocumentSelect={onDocumentClick} />
                            })}
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {processingStatuses.has(status) && (
                            <ProcessingModal modal={true} />
                        )}
                        {validationMessages !== undefined && validationMessages.length > 0 && (
                            <ApiErrorResponseMessages messages={validationMessages} />
                        )}
                        <DynamicNotifications />
                        <IdleLogoutTimer onLogoutFunction={onLogout} />
                        {technicalAssistDialogOpen && (
                            <Dialog open={technicalAssistDialogOpen} fullWidth={true} maxWidth="lg">
                                <TechnicalAssistance onClose={onCloseTechnicalAssistDialog} />
                            </Dialog>
                        )}
                        {termsDialogOpen && (
                            <Dialog open={termsDialogOpen} fullWidth={true} maxWidth="lg">
                                <Terms terms={unacceptedTerms} onClose={onCloseTermsDialog} />
                            </Dialog>
                        )}
                    </Grid>	
                </>
            )}
		</>
	);
}