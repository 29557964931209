import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import { enumGeneralComponentStatus } from 'enums/enumGeneralComponentStatus';
import * as React from 'react';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupData, ITen99LookupItem } from 'sharedInterfaces/ITen99LookupData';
import { ITen99PayerStateAccount, ITen99PayerStateAccountRule } from 'sharedInterfaces/ITen99PayerStateAccount';
import { Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';


interface IAddEditAccountProps {
	payerId: string,
	onClose: (proceed: boolean) => void,
	states: ITen99LookupItem[],
	payerStateAccount?: ITen99PayerStateAccount,
}

interface ILocalState {
	status: enumGeneralComponentStatus,
	rules: ITen99LookupItem[],
	payerstateAccount: ITen99PayerStateAccount,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
}

const unselectedRule = { payerStateAccountRuleId: -99, descr: "" } as ITen99PayerStateAccountRule;
const defaultRule = { payerStateAccountRuleId: -1, descr: "Other" } as ITen99PayerStateAccountRule;

class AddEditAccount extends React.PureComponent<IAddEditAccountProps, ILocalState> {
	//local state
	constructor(props: IAddEditAccountProps) {
		super(props);
		let tempState: ILocalState = {
			status: enumGeneralComponentStatus.PendingAction, rules: [] as ITen99LookupItem[], payerstateAccount: {
				id: 0,
				ruleData: unselectedRule as ITen99PayerStateAccountRule
			} as ITen99PayerStateAccount, validationMessages: [] as ITen99ApiResponseMessage[], invalidProperties: [] as string[]
		} as ILocalState;

		if (!isNullOrUndefined(this.props.payerStateAccount)) {
			tempState.payerstateAccount = this.props.payerStateAccount

			//use default on edit (has an id)
			if (this.props.payerStateAccount.id > 0 && (isNullOrUndefined(this.props.payerStateAccount.ruleData) || this.props.payerStateAccount.ruleData.payerStateAccountRuleId <= 0)) {
				tempState.payerstateAccount.ruleData = defaultRule;
			}

			//edit - so we need to wait for dropdowns to load 
			tempState.status = enumGeneralComponentStatus.IsLoading;
			this.state = tempState;
			//load rules 
			this.loadRules(this.props.payerStateAccount.stateId);
		}
		else { //add - just display screen
			this.state = tempState;
        }
	}
	// --------
	// -----------------
	//Handle User events

	private onStateChange = (name: string, value: string) => {
		if (!isNullOrUndefined(this.state.payerstateAccount)) {
			this.setState({ payerstateAccount: { ...this.state.payerstateAccount, [name]: value } })
			this.loadRules(value);
		}
	}

	private onRuleIdChange = (name: string, value: string) => {
		if (!isNullOrUndefined(this.state.payerstateAccount)) {
			this.setState({ payerstateAccount: { ...this.state.payerstateAccount, ruleData: { ...this.state.payerstateAccount.ruleData, payerStateAccountRuleId: parseInt(value) }}})
		}
	}

	private onChange = (name: string, value: string) => {
		if (!isNullOrUndefined(this.state.payerstateAccount)) {
			if (name == "isBlock") {
				this.setState({ payerstateAccount: { ...this.state.payerstateAccount, [name]: value == "true" } })
			}
			else {
				this.setState({ payerstateAccount: { ...this.state.payerstateAccount, [name]: value } })
			}
		}
	}

	private onSubmit = () => {
		if (!isNullOrUndefined(this.state.payerstateAccount)) {
			this.setState({ status: enumGeneralComponentStatus.Processing, validationMessages: [] as ITen99ApiResponseMessage[], invalidProperties: [] as string[] });
			if (this.state.payerstateAccount.id === 0) { //add

				MakeApiCall<string>("api/PayerStateAccount/?payerId=" + this.props.payerId, "POST", JSON.stringify(this.state.payerstateAccount))
					.then(data => {
						if (data.isSuccess) {
							this.setState({ status: enumGeneralComponentStatus.PendingAction })
							this.props.onClose(true);
						}
						else {
							this.setState({ status: enumGeneralComponentStatus.Error, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
						}
					});
			}
			else { //edit
				MakeApiCall<string>("api/PayerStateAccount/" + this.state.payerstateAccount.id, "PUT", JSON.stringify(this.state.payerstateAccount))
					.then(data => {
						if (data.isSuccess) {
							this.setState({ status: enumGeneralComponentStatus.PendingAction })
							this.props.onClose(true);
						}
						else {
							this.setState({ status: enumGeneralComponentStatus.Error, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
						}
					});
			}
		}
	}


	private loadRules = (stateId: string) => {
		if (!isNullOrUndefined(stateId) && stateId !== "") {

			if (this.state.status !== enumGeneralComponentStatus.IsLoading) {
				this.setState({ status: enumGeneralComponentStatus.Processing });
			}

			MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/42/?parentId=" + stateId, "GET")
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload)) {
							let lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 42);
							if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
								this.setState({ status: enumGeneralComponentStatus.PendingAction, rules: lookup.lookupItems });
							}
							else {
								this.setState({ status: enumGeneralComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the lookups" }] as ITen99ApiResponseMessage[] }); //unable to load lookup, we need to stop
                            }
						}
					}
					else {
						this.setState({ status: enumGeneralComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
					}
				});
		}
	}
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		
		return (
			<Grid container spacing={1} sx={{textAlign: 'center', paddingTop:"8px"}}>

				{(this.state.status === enumGeneralComponentStatus.Processing || this.state.status === enumGeneralComponentStatus.IsLoading) && (
					<Grid item xs={12}>
						<ProcessingModal modal={this.state.status === enumGeneralComponentStatus.Processing} />
					</Grid>
				)}
				{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
					<Grid item xs={12}>
						<ApiErrorResponseMessages messages={this.state.validationMessages} />
					</Grid>
				)}

				{(this.state.status !== enumGeneralComponentStatus.IsLoading) && (
				<React.Fragment>
					<Grid item xs={2}>
						<FormControl
							fullWidth
							error={this.state.invalidProperties.find(x => x === "StateId") !== undefined}
							required>
							<InputLabel id="stateLabel">State</InputLabel>
							<Select
								labelId="stateLabel"
								label="State"
								disabled={false}
								error={this.state.invalidProperties.find(x => x === "StateId") !== undefined}
								value={this.state.payerstateAccount.stateId}
								onChange={(e) => this.onStateChange((isNullOrUndefined(e.target.name) ? "" : e.target.name), e.target.value as string)}
								inputProps={{}}
								id={"stateId"}
								name="stateId"
							>
								{(this.props.states.map((item, index) => <MenuItem key={index} value={item.id}>{item.description}</MenuItem>))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<FormControl
							fullWidth
							error={this.state.invalidProperties.find(x => x === "PayerStateAccountRuleId") !== undefined}
							required>
							<InputLabel id="ruleLabel">Form Type</InputLabel>
							<Select
								labelId="ruleLabel"
								label="Form Type"
								disabled={this.state.rules.length === 0}
								error={this.state.invalidProperties.find(x => x === "PayerStateAccountRuleId") !== undefined}
								value={this.state.payerstateAccount.ruleData.payerStateAccountRuleId}
								onChange={(e) => this.onRuleIdChange((isNullOrUndefined(e.target.name) ? "" : e.target.name), e.target.value as string)}
								inputProps={{}}
								id={"ruleId"}
								name="payerStateAccountRuleId"
							>
								{(this.state.rules.map((item, index) => <MenuItem key={index} value={item.id}>{item.description}</MenuItem>))}

							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<TextField
							disabled={false}
							onChange={(e) => this.onChange(e.target.name, e.target.value)}
							value={this.state.payerstateAccount.stateAccount}
							variant="outlined"
							error={this.state.invalidProperties.find(x => x === "StateAccount") !== undefined}
							id={"account"}
							label="State ID Number"
							name="stateAccount"
							inputProps={{maxLength: 20}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={3}>
						<FormControlLabel
							label={<Typography variant="body1">Enabled For State Submission</Typography>}							
							control={
								<Checkbox
									color="primary"
									disabled={false}
									onChange={(e) => this.onChange(e.target.name, String(!e.target.checked))}
									checked={!this.state.payerstateAccount.isBlock}
									id={"isblock"}
									name="isBlock"
								/>}
						/>
					</Grid>
					<Grid item xs={2} sx={{textAlign: 'left'}}>
						<Button type="button" startIcon={< Ten99PrepSaveIcon />} variant="contained" color="primary" onClick={this.onSubmit}>Save</Button>
							</Grid>
				</React.Fragment>
				)}
			</Grid>
		);
	}
}
export default AddEditAccount;
