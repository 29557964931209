import Home from 'components/ten99Prep/Home';
import * as React from 'react';
import PrivateRoute from 'utilities/PrivateRoute';


export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <PrivateRoute exact path='/gate/home/customers' component={Home} />
        <PrivateRoute exact path='/gate/home/customer/:customerId/summary' component={Home} />
    </React.Fragment>
);
