import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import * as ToastNotificationsStore from 'store/ToastNotifications';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon, Ten99PrepSearchIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { flattenProperties } from 'utilities/PropertyList';

export default function CustomerAddMaconomy({ onClose }: { onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void }) {

	enum enumComponentStatus {
		Initializing,
		PendingMaconomySelect,
		PendingUserGroupSelect,
		SearchingForMaconomy,
		SearchingUserGroups,
		CreatingCustomer,
		Error
	};

	const processingStatuses = new Set([enumComponentStatus.Initializing,
		enumComponentStatus.SearchingForMaconomy,
		enumComponentStatus.SearchingUserGroups,
		enumComponentStatus.CreatingCustomer,
	])

	const dispatch = useDispatch();
	const [status, setStatus] = useState<enumComponentStatus>(enumComponentStatus.Initializing);
	const [validationMessages, setValidationMessages] = useState<ITen99ApiResponseMessage[]>([] as ITen99ApiResponseMessage[]);
	const [maconomyId, setMaconomyId] = useState<string>("");
	const [groupIds, setGroupIds] = useState<number[]>([] as number[]);
	const [groups, setGroups] = useState<ITen99LookupItem[]>();
	const [customer, setCustomer] = useState<ITen99LookupItem | undefined>();
	const [invalidProperties, setInvalidProperties] = useState<string[]>([] as string[]);

	useEffect(() => {
		if (status === enumComponentStatus.Initializing) {
			//setStatus(enumComponentStatus.SearchingUserGroups);
			MakeApiCall<ITen99LookupItem[]>("api/User/UserGroups/?omitDefault=true", "GET")
				.then(groups => {
					if (groups.isSuccess && groups.payload !== undefined && groups.payload.length > 0) {
						setGroups(groups.payload);
						if (groups.payload.length === 1) {
							setGroupIds([groups.payload[0].id])
						}
						setStatus(enumComponentStatus.PendingMaconomySelect);
					}
					else if (groups.isSuccess) {
						setStatus(enumComponentStatus.Error);
						setValidationMessages([{ type: "Error", message: "You do not belong to a group that can add Customers at this time. Please contact support." }] as ITen99ApiResponseMessage[]);
					}
					else {
						setStatus(enumComponentStatus.Error);
						setValidationMessages([{ type: "Error", message: "Unable to get user details. Please contact support." }] as ITen99ApiResponseMessage[]);
					}
				});
		}
		//console.log("render: status = " + status + ", isProcessing = " + processingStatuses.has(status) + ", dashboard = " + dashboardData.length);
	})


	function onSearch() {
		setCustomer(undefined);
		setStatus(enumComponentStatus.SearchingForMaconomy);
		if (maconomyId === "") {
			setStatus(enumComponentStatus.PendingMaconomySelect);
			setValidationMessages([{ type: "Error", message: "Required fields need to be filled out." }] as ITen99ApiResponseMessage[]);
			setInvalidProperties(["maconomyId"]);
		}
		else {
			setStatus(enumComponentStatus.SearchingForMaconomy);
			setValidationMessages({} as ITen99ApiResponseMessage[]);
			setInvalidProperties([]);
			MakeApiCall<ITen99LookupItem>("api/MaconomyCustomers/" + maconomyId, "GET")
				.then(data => {
					if (data.isSuccess && data.payload !== undefined) {
						setCustomer(data.payload);
						setStatus(enumComponentStatus.PendingUserGroupSelect);
					}
					else {
						if (data.httpStatusCode === 422 || data.httpStatusCode === 404 || data.httpStatusCode === 406) {
							setStatus(enumComponentStatus.PendingMaconomySelect);
							setValidationMessages(data.details);
							setInvalidProperties(flattenProperties(data.details));
						}
						else {
							setStatus(enumComponentStatus.Error);
							setValidationMessages(data.details);
						}
					}
				});
		}
	}

	function onSubmit() {

		if (groupIds.length === 0) {
			setStatus(enumComponentStatus.PendingUserGroupSelect);
			setValidationMessages([{ type: "Error", message: "At least on group must be selected." }] as ITen99ApiResponseMessage[]);
			setInvalidProperties(["maconomyId"]);
		}
		else {
			setStatus(enumComponentStatus.CreatingCustomer);
			setValidationMessages({} as ITen99ApiResponseMessage[]);
			setInvalidProperties([]);
			MakeApiCall<ITen99LookupItem>("api/MaconomyCustomers/", "POST", JSON.stringify({ MaconomyKey: maconomyId, userGroups: groupIds }))
				.then(data => {
					if (data.isSuccess && data.payload !== undefined) {
						//send Toast message
						dispatch(ToastNotificationsStore.actionCreators.toastNotifcations_Display({ id: "dialog" + maconomyId.toString(), message: customer?.name + " (" + customer?.description + ") added", type: "success"}))
						setCustomer(undefined);
						setMaconomyId("");
						setStatus(enumComponentStatus.PendingMaconomySelect);
					}
					else {
						if (data.httpStatusCode === 422 || data.httpStatusCode === 404) {
							setStatus(enumComponentStatus.PendingUserGroupSelect);
							setValidationMessages(data.details);
							setInvalidProperties(flattenProperties(data.details));
						}
						else {
							setStatus(enumComponentStatus.Error);
							setValidationMessages(data.details);
						}
					}
				});
		}		
	}

	function onCheck(id: number, checked: boolean) {
		if (checked) {
			setGroupIds([...groupIds, id]);
		}
		else {
			setGroupIds(groupIds.filter(x => x !== id));
		}
	}

	return (
		<>
			<Dialog open={true} fullWidth={true} maxWidth="lg">
				<DialogTitle>
					<Grid container spacing={1} sx={{ textAlign: 'center' }}>
						<Grid item xs={12} sx={{ textAlign: 'left' }}>
							<Typography variant="h4">Add Maconomy Customer
								<InfoToolTip text="Add a new Maconomy Customer to a security group you belong to" />
							</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					{processingStatuses.has(status) && (
						<ProcessingModal modal={(status !== enumComponentStatus.Initializing)} />
					)}

					{!isNullOrUndefined(validationMessages) && validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={validationMessages} />
						</Grid>
					)}
					{(status !== enumComponentStatus.Initializing) && (
						<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop: "8px" }}>
							<Grid item xs={8}>
								<TextField
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setMaconomyId(event.target.value) }}
									value={maconomyId}
									variant="outlined"
									error={(invalidProperties.find(x => x === "maconomyId") !== undefined)}
									id="maconomyId"
									label="Enter the Maconomy Id to search for"
									name="maconomyId"
									required
									fullWidth
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>

							<Grid item xs={4}>
								<Button type="button" startIcon={<Ten99PrepSearchIcon />} variant="contained" color="primary" onClick={onSearch} disabled={status === enumComponentStatus.Error }>Search</Button>
							</Grid>
							{(status === enumComponentStatus.PendingUserGroupSelect) && (
								<>
									<Grid item xs={12}>
										<hr />
									</Grid>

									<Grid item xs={6}>
										<Typography variant="h6">
											Customer to be Added: 
										</Typography>
									</Grid>

									<Grid item xs={6}>
										<Typography variant="body1">
											{customer?.name + " (" + customer?.description + ")"}
										</Typography>
									</Grid>

									<Grid item xs={6}>
										<Typography variant="h6">
											Add customer to:
										</Typography>
									</Grid>

									<Grid item xs={6}>
										{(groups?.length === 0) && (
											<Typography variant="body1">
												{groups?.find(x => x.id === groupIds[0])?.name}
											</Typography>
										)}
										{(groups !== undefined && groups.length > 0) && (
											<Grid container spacing={1} sx={{ textAlign: 'center' }}>
												{groups?.map((item, index) => {
													return (
														<>
														<Grid item xs={6} />
														<Grid item xs={6} sx={{ textAlign: 'left' }}>
															<FormControlLabel
																label={<Typography variant="body1">{item.name}</Typography>}
																control={
																	<Checkbox key={index}
																		color="primary"
																		disabled={false}
																		onChange={(e) => onCheck(item.id, e.target.checked)}
																		checked={groupIds.findIndex(x => x === item.id) > -1}
																		id={"Group" + item.id}
																		name={item.id.toString()}
																	/>
																}
															/>
														</Grid>
														</>
													)
												}
												)}
											</Grid>
										)}

									</Grid>
								</>
							)}

							</Grid>
					)}
			</DialogContent>
			<DialogActions>
				<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => onClose(false, undefined)}>Cancel</Button>
				<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={onSubmit} disabled={status !== enumComponentStatus.PendingUserGroupSelect}>Submit</Button>

				</DialogActions>
			</Dialog>
		</>
	)
}