import { ArrowRight } from '@mui/icons-material';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { CSSProperties } from "react";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { Ten99PrepDeleteIcon, Ten99PrepSearchIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
}

interface IImportDataProps {
	customerId: string,
	importFiles: File[],
	onFileSelect: (file: File) => void,
	onFileRemove: (index: number) => void,
	externalValidationMessages?: ITen99ApiResponseMessage[],
	acceptedMimeType: string,
	acceptedFileExtension: string,
	maxFileSizeMb: number,
}

type ImportDataProps =
	& IImportDataProps
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.PendingAction, validationMessages: [] as ITen99ApiResponseMessage[] };
class ImportFileDisplay extends React.PureComponent<ImportDataProps, ILocalState> {

	private readonly maxFilesCount: number = 5;
	
	constructor(props: ImportDataProps) {
		super(props);
		this.state = initialLocalState;
	}

	private convertFileSizeToMb = (bytes: number) => {
		return Math.ceil(bytes / 1048576)
	}

	private onFileRemove = (index: number) => (Event: React.MouseEvent) => {		
		this.props.onFileRemove(index);
	}

	private onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!isNullOrUndefined(e.target.files) && e.target.files.length > 0) {
			if (e.target.files[0].type !== this.props.acceptedMimeType) {
				this.setState({ validationMessages: [{ type: "Error", message: "Incorrect file type. You must upload a '" + this.props.acceptedFileExtension + "' file." }] as ITen99ApiResponseMessage[] });
			}
			else if (this.convertFileSizeToMb(e.target.files[0].size) > this.props.maxFileSizeMb) {
				this.setState({ validationMessages: [{ type: "Error", message: "File size exceeds limts. Files must be less than " + this.props.maxFileSizeMb.toString() + " Mb. If needed, split the files into multiple files and upload seperately." }] as ITen99ApiResponseMessage[] });
			}
			else {
				this.props.onFileSelect(e.target.files[0]);
				this.clearFileInput();
				this.setState({ validationMessages: [] as ITen99ApiResponseMessage[] });
			}			
		}
	}

	private clearFileInput = () => {
		const element: HTMLInputElement | null = document.getElementById("hiddenFileImport") as HTMLInputElement;
		if (!isNullOrUndefined(element)) {
			element.value = "";
		}
	}

	private onSelectFileClick = () => {

		if (this.props.importFiles.length < this.maxFilesCount) {
			const element: HTMLInputElement | null = document.getElementById("hiddenFileImport") as HTMLInputElement;
			if (!isNullOrUndefined(element)) {
				element.click();
			}
		}
		else {
			this.setState({ validationMessages: [{ type: "Error", message: "Cannot upload more than " + this.maxFilesCount.toString() + " files at a time." }] as ITen99ApiResponseMessage[] });
        }
	}
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {
		

		return (
			<React.Fragment>
				{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
					<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
				)}
				<Grid container spacing={1}>
					<Grid item xs={12} sx={{textAlign: 'left'}}>
						<Typography variant="body2">Please note the following:</Typography>
						<List dense={true}>
							<ListItem sx={{
								paddingTop: 0,
								paddingBottom: 0,
							}}><ListItemIcon><ArrowRight /></ListItemIcon><ListItemText primary={"Cannot upload more than " + this.maxFilesCount.toString() + " files at a time."} /></ListItem>
							<ListItem sx={{
								paddingTop: 0,
								paddingBottom: 0,
							}}><ListItemIcon><ArrowRight /></ListItemIcon><ListItemText primary={"Duplicated Payers or Recipients will not be imported"} /></ListItem>
							<ListItem sx={{
								paddingTop: 0,
								paddingBottom: 0,
							}}><ListItemIcon><ArrowRight /></ListItemIcon><ListItemText primary={"Data already in 1099-Prep will be updated with new data"} /></ListItem>
						</List>
					</Grid>

					<Grid item xs={12} sx={{textAlign: 'left'}}>
					</Grid>

					<Grid item xs={12} sx={{ textAlign: 'right' }}>
						<input id="hiddenFileImport" type="file" style={{ display: 'none' } as CSSProperties} accept={this.props.acceptedFileExtension } onChange={this.onFileSelect} />
						<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepSearchIcon />} onClick={this.onSelectFileClick}>Select File</Button>
					</Grid>

					<Grid item xs={12} sx={{textAlign: 'left'}}>
						<Typography variant="h6">Files to Upload: </Typography>
						{
							<Grid container spacing={1}>
								{this.props.importFiles.map((file, index) =>
									<React.Fragment key={index}>
										<Grid item xs={1} sx={{ textAlign: 'left' }}>
										</Grid>

										<Grid item xs={6} sx={{ textAlign: 'left' }}>
											<Typography key={index} variant="body2">{file.name} - {this.convertFileSizeToMb(file.size)} Mb or less</Typography>
										</Grid>
										<Grid item xs={5} sx={{ textAlign: 'right' }}>
											<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepDeleteIcon />} onClick={this.onFileRemove(index)}>Delete</Button>
										</Grid>
									</React.Fragment>
								)}
							</Grid>
							}
							{this.props.importFiles.length === 0 && (
								<Typography variant="body2">No File Selected</Typography>
							)}
							
					</Grid>



					{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={this.state.validationMessages} />
						</Grid>
					)}
					{(isNullOrUndefined(this.state.validationMessages) || this.state.validationMessages.length === 0) && (!isNullOrUndefined(this.props.externalValidationMessages) && this.props.externalValidationMessages.length > 0) && (
						<Grid item xs={12}>
							<ApiErrorResponseMessages messages={this.props.externalValidationMessages} />
						</Grid>
					)}

				</Grid>
			</React.Fragment>
		)
	}
}

export default ImportFileDisplay;