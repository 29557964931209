
export interface ITen99SummaryCountCategory {
    category: ISummaryCategoryEnum,
    counts: ITen99SummaryCount[],
}

export interface ITen99SummaryCount {
    id: number,
    name: string,
    count: number,
}

export enum ISummaryCategoryEnum {
    FormStatus = 1,
    FormType = 2,
}