import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import SearchableDropdown from 'components/extensions/SearchableDropdown';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import ProcessingModal from 'components/notifcations/ProcessingModal';
import * as React from 'react';
import { ActionTypesEnum } from 'sharedInterfaces/ITen99Action';
import { enumAddEditViewAction, IAevEntityResponse, IAevEntitySetting } from 'sharedInterfaces/ITen99AevEntity';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { IFormCategoryTypeEnum, ITen99Customer } from 'sharedInterfaces/ITen99Customer';
import { ITen99LookupItem, ITen99PagedLookupData } from 'sharedInterfaces/ITen99LookupData';
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from 'Ten99PrepOverloads/IconOverloads';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';

enum enumComponentStatus {
	Loading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface IAddEditViewMaconomyCustomerExternalProps {
	onClose: (response: IAevEntityResponse) => void;
	entitySettings: IAevEntitySetting;
}

type AddEditProps =
	IAddEditViewMaconomyCustomerExternalProps	
	;

interface ILocalState {
	status: enumComponentStatus,
	defaultCustomer?: ITen99LookupItem,
	default1099Engagement?: ITen99LookupItem,
	default1042sEngagement?: ITen99LookupItem,
	defaultW2Engagement?: ITen99LookupItem,
	selected1099Engagement?: string,
	selected1042sEngagement?: string,
	selectedW2Engagement?: string,
	validationMessages: ITen99ApiResponseMessage[],
	ticks:string,
}

const initialLocalState = {
	status: enumComponentStatus.PendingAction,
	selected1099Engagement: undefined,
	selected1042sEngagement: undefined,
	selectedW2Engagement: undefined,
	validationMessages: {} as ITen99ApiResponseMessage[],
	ticks: ""
} as ILocalState;
const initialEditLocalState = { status: enumComponentStatus.Loading, validationMessages: {} as ITen99ApiResponseMessage[], ticks: "" } as ILocalState;

class AddEditViewMaconomyCustomer extends React.PureComponent<AddEditProps, ILocalState> {
	//local state

	constructor(props: AddEditProps) {
		super(props);
		if (props.entitySettings.action === enumAddEditViewAction.Edit) {
			this.state = initialEditLocalState;
			//load customer
			MakeApiCall<ITen99Customer>("api/Customers/" + this.props.entitySettings.id, "GET")
				.then(data => {
					if (data.isSuccess && !isNullOrUndefined(data.payload)) {
						if (!isNullOrUndefined(data.payload.billingInformation)) {
							let ticks: string = data.payload.ticks;
							let promiseArray = [];

							let customerLookup: ITen99LookupItem = {
								name: data.payload.billingInformation.customerBillingIdentifier,
								isSelected: false
							} as ITen99LookupItem;
							
							if (!isNullOrUndefined(data.payload.billingInformation.customerBillingIdentifier)) {
								promiseArray.push(MakeApiCall<ITen99LookupItem>("api/BillingCustomers/Customer/" + data.payload.billingInformation.customerBillingIdentifier, "GET"));
							}

							let engagement1042s: ITen99LookupItem | undefined = undefined;
							let engagement1099: ITen99LookupItem | undefined = undefined;
							let engagementW2: ITen99LookupItem | undefined = undefined;

							let engagements: ITen99LookupItem[] = [];
							if (!isNullOrUndefined(data.payload.billingInformation.billingIdentifierByFormCategoryTypeString)) {
								for (let key in data.payload.billingInformation.billingIdentifierByFormCategoryTypeString) {
									let engagementId: string = data.payload.billingInformation.billingIdentifierByFormCategoryTypeString[key];
									if (engagementId !== "") {
										let item: ITen99LookupItem = { name: engagementId, isSelected: false } as ITen99LookupItem;

										//map engagement
										if (key === IFormCategoryTypeEnum.Type1042S.toString()) {
											engagement1042s = item;
										}
										else if (key === IFormCategoryTypeEnum.Type1099.toString()) {
											engagement1099 = item;
										}
										else if (key === IFormCategoryTypeEnum.TypeW2.toString()) {
											engagementW2 = item;
										}

										if (engagements.findIndex(x => x.name === engagementId) === -1) { //only call once for each unique engagement
											promiseArray.push(MakeApiCall<ITen99LookupItem>("api/BillingCustomers/Customer/" + data.payload.billingInformation.customerBillingIdentifier + "/Engagement/" + engagementId, "GET"));
										}

										engagements.push(item);
									}
								}								
							}

							Promise.all(promiseArray).then(values => {
								values.forEach((response, index) => {
									if (response.isSuccess) {
										if (!isNullOrUndefined(response.payload)) {
											//check for customer

											if (index === 0) {
												customerLookup.description = response.payload.description
											}
											else {
												engagements.forEach(x => {
													if (!isNullOrUndefined(response.payload) && x.name === response.payload.name) {
														x.description = response.payload.description
													}
												});												
											}
										}
									}
								});
								this.setState({
									defaultCustomer: customerLookup,
									default1042sEngagement: engagement1042s,
									default1099Engagement: engagement1099,
									defaultW2Engagement: engagementW2,									
									selected1042sEngagement: isNullOrUndefined(engagement1042s) ? undefined : engagement1042s.name,
									selected1099Engagement: isNullOrUndefined(engagement1099) ? undefined : engagement1099.name,
									selectedW2Engagement: isNullOrUndefined(engagementW2) ? undefined : engagementW2.name,
									status: enumComponentStatus.PendingAction,
									ticks: ticks
								})

							});

						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load the Customer" }] as ITen99ApiResponseMessage[] });
						}
					}
				});
		}
		else {
			this.state = initialLocalState;
		}
	}
	
	private engagementSearch = (searchValue: string) => {
		if (!isNullOrUndefined(this.state.defaultCustomer)) {
			return MakeApiCall<ITen99PagedLookupData>("api/BillingCustomers/Customer/" + this.state.defaultCustomer.name + "/Engagement/?SearchValue=" + searchValue, "GET");
		}
		else {

		}
	}

	private engagement1099Set = (searchVal: string) => {
		this.setState({ selected1099Engagement: searchVal });
	}

	private engagement1042sSet = (searchVal: string) => {
		this.setState({ selected1042sEngagement: searchVal });
	}

	private engagementW2Set = (searchVal: string) => {
		this.setState({ selectedW2Engagement: searchVal });
	}

	private engagement1099Verified = (selectedItem: ITen99LookupItem) => {
		this.setState({ selected1099Engagement: selectedItem.name });
	}

	private engagement1042sVerified = (selectedItem: ITen99LookupItem) => {
		this.setState({ selected1042sEngagement: selectedItem.name });
	}

	private engagementW2Verified = (selectedItem: ITen99LookupItem) => {
		this.setState({ selectedW2Engagement: selectedItem.name });
	}

	private getTitle = (action: enumAddEditViewAction) => {
		if (!isNullOrUndefined(this.state.defaultCustomer)) {
			let prefix: string = "";
			switch (action) {
				case enumAddEditViewAction.Edit:
					prefix = "Edit ";
			}
			return prefix + "Maconomy Customer: " + (isNullOrUndefined(this.state.defaultCustomer.description) ? "(Name not found)" : this.state.defaultCustomer.description);
		}
		return "";
	}

	// -----------------
	//Handle User events

	private onSubmit = () => {
		
			//get customer			
			if (!isNullOrUndefined(this.state.defaultCustomer)) {
				if (this.state.status !== enumComponentStatus.Processing) {
					this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
					
					//create Customer object

					let billingIdentifierByFormCategoryTypes: Record<string, string> = {};

					if (!isNullOrUndefined(this.state.selected1042sEngagement))
					{
						//billingIdentifierByFormCategoryTypes.push({ formCategoryType: IFormCategoryTypeEnum.Type1042S.toString(), billingIdentifier: this.state.selected1042sEngagement.id.toString() })
						billingIdentifierByFormCategoryTypes[IFormCategoryTypeEnum.Type1042S.toString()] = this.state.selected1042sEngagement.toString();

					}

					if (!isNullOrUndefined(this.state.selected1099Engagement)) {
						//billingIdentifierByFormCategoryTypes.push({ formCategoryType: IFormCategoryTypeEnum.Type1099.toString(), billingIdentifier: this.state.selected1099Engagement.id.toString() })
						billingIdentifierByFormCategoryTypes[IFormCategoryTypeEnum.Type1099.toString()] = this.state.selected1099Engagement.toString();					}

					if (!isNullOrUndefined(this.state.selectedW2Engagement)) {
						//billingIdentifierByFormCategoryTypes.push({ formCategoryType: IFormCategoryTypeEnum.TypeW2.toString(), billingIdentifier: this.state.selectedW2Engagement.id.toString() })
						billingIdentifierByFormCategoryTypes[IFormCategoryTypeEnum.TypeW2.toString()] = this.state.selectedW2Engagement.toString();}

					let customer: ITen99Customer =
					{
						customerId: isNullOrUndefined(this.props.entitySettings) || isNullOrUndefined(this.props.entitySettings.id) ? "" : this.props.entitySettings.id,
						name: this.state.defaultCustomer.name,
						billingInformation: {
							customerBillingIdentifier: this.state.defaultCustomer.name,
							billingIdentifierByFormCategoryTypeString: billingIdentifierByFormCategoryTypes
						},
						actions: {} as ActionTypesEnum[],
						ticks: this.state.ticks
					}
					MakeApiCall<string>("api/Customers/" + this.props.entitySettings.id, "PUT", JSON.stringify(customer))
						.then(data => {							
							if (data.isSuccess) {
								this.props.onClose({ processed: true, message: "Customer " + (isNullOrUndefined(this.state.defaultCustomer) ? "" : this.state.defaultCustomer.description) + " updated", id: data.payload } as IAevEntityResponse); // close modal and return message
							}
							else {
					  			if (data.httpStatusCode === 422) {
									this.setState({ status: enumComponentStatus.Invalid, validationMessages: data.details });
								}
								else if (data.httpStatusCode === 409) {
									this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Customer has been updated by another process. Please close and reload." }] as ITen99ApiResponseMessage[] });
								}
								else {
									this.setState({ status: enumComponentStatus.Error, validationMessages: data.details });
								}
							}
						});
				}
			}
			else {
				this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to find customer." }] as ITen99ApiResponseMessage[] });
			}		
	}

	private onCancel = () =>
	{
		this.props.onClose({ processed: false, message: "" } as IAevEntityResponse);
	}

	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {	
		
		return (			
			<React.Fragment>
				<DialogTitle>
					<Typography variant="h5" >{this.getTitle(this.props.entitySettings.action)}</Typography>
				</DialogTitle>
				<DialogContent>
					{(this.state.status === enumComponentStatus.Loading || this.state.status === enumComponentStatus.Processing) && (
						<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
					)}
					{(this.state.status !== enumComponentStatus.Loading) && (				
					
						<Grid container spacing={1} sx={{textAlign: 'center'}}>		
							<Grid item xs={12}>
							{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
								)}
							</Grid>	

							<Grid item xs={12}>							
								<Grid container spacing={1} sx={{textAlign: 'center'}}>

									<Grid item xs={12} sx={{textAlign: 'left'}}>
										<Typography>Engagement Numbers</Typography>										
									</Grid>

									<Grid item xs={12}>
										<SearchableDropdown label="Engagement for 1099 Forms" disabled={this.props.entitySettings.action === enumAddEditViewAction.View} defaultValue={this.state.default1099Engagement} required={false} onSearch={this.engagementSearch} onSearchValueChange={this.engagement1099Set} onVerified={this.engagement1099Verified} />
										<br/>
									</Grid>

									<Grid item xs={12}>
										<SearchableDropdown label="Engagement for 1042-S Forms" disabled={this.props.entitySettings.action === enumAddEditViewAction.View} defaultValue={this.state.default1042sEngagement} required={false} onSearch={this.engagementSearch} onSearchValueChange={this.engagement1042sSet} onVerified={this.engagement1042sVerified} />
										<br />
									</Grid>

									<Grid item xs={12}>
										<SearchableDropdown label="Engagement for W2 Forms" disabled={this.props.entitySettings.action === enumAddEditViewAction.View} defaultValue={this.state.defaultW2Engagement} required={false} onSearch={this.engagementSearch} onSearchValueChange={this.engagementW2Set} onVerified={this.engagementW2Verified} />
										<br />
									</Grid>
								</Grid>
							</Grid>														
								
											
						</Grid>	
					)}
				</DialogContent>
				<DialogActions>
					<Button type="button" startIcon={< Ten99PrepCancelIcon />} variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
					{this.props.entitySettings.action !== enumAddEditViewAction.View && (
						<Button type="button" startIcon={< Ten99PrepSaveIcon />} variant="contained" color="primary" onClick={this.onSubmit}>Save</Button>
					)}
				</DialogActions>
			</React.Fragment>
		);
	}
}

export default ((AddEditViewMaconomyCustomer));