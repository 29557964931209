import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

enum enumSubmitState {
	NotStarted,
	Processing,
	Submitted,
	Invalid,
	Error
};

const passwordRules: string[] = [
	"Must contain at least 8 characters",
	"Must contain both upper and lower case characters",
	"Must contain at least one letter and one number",
	"Must contain at least one special character: (!@#$%^&*?)"
]
type SetPasswordProps =
	RouteComponentProps<{ id: string, type: string, token: string }> // ... plus incoming routing parameters
	; // ... plus styles

const initialLocalState = {
	submitState: enumSubmitState.NotStarted, password: "", confirmPassword: "", validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: {} as string[] };
const initialLocalState2 = { submitState: enumSubmitState.Error, password: "", confirmPassword: "", validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: {} as string[]  };
class SetPassword extends React.PureComponent<SetPasswordProps> {
	state = initialLocalState;
	token: string | null;

	constructor(props: SetPasswordProps) {
		super(props);

		this.token = new URLSearchParams(this.props.location.search).get("tk");				
		if (this.token === null) {
			this.state = initialLocalState2;
		}
		else {
			this.state = initialLocalState;
		}
	}
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	private onSubmitClick = () => {
		this.setState({ submitState: enumSubmitState.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });

		let payload;
		let endpoint:string = ""
		if (this.props.match.params.type === "Reset") {
			endpoint = "api/User/Reset/";
			payload = {
				ResetPasswordCode: this.token,
				Password: this.state.password,
				ConfirmPassword: this.state.confirmPassword
			}
		}
		else { //Confrim
			endpoint = "api/User/";
			payload = {
				ConfirmEmailCode: this.token,
				Password: this.state.password,
				ConfirmPassword: this.state.confirmPassword
			}
        }
				
		//call the api and set the results in the state to reflect on the render
		MakeApiCall<string>(endpoint + this.props.match.params.id, "PUT", JSON.stringify(payload))
			.then(data => {
				if (data.isSuccess) {
					this.setState(initialLocalState); //clear
					this.setState({ submitState: enumSubmitState.Submitted });
				}
				else {
					if (data.httpStatusCode === 422) {
						this.setState({ submitState: enumSubmitState.Invalid, success: false, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
					}
					else {
						this.setState({ submitState: enumSubmitState.Error, success: false, validationMessages: data.details });
                    }
							
				}
			});
	}
	// -----------------
	// Render
	render() {		
		
		return (
			<div>
				<Typography variant="h4">Set Password</Typography>
				<br />
				{this.state.submitState === enumSubmitState.Processing && (
					<div>
						<CircularProgress size={90} />
						<Typography variant="subtitle2">Processing...</Typography>
					</div>
				)}
				{(this.state.submitState === enumSubmitState.NotStarted || this.state.submitState === enumSubmitState.Invalid) && ( //not processing and not yet processed
					<Grid container spacing={1} sx={{textAlign: 'center'}}>						
						<Grid item xs={12}>
							<Box sx={{
								backgroundColor: 'primary.light',
								border: `2px solid`,
							}}>
								<Typography variant="h6">Passwords must meet the following requirements:</Typography>
								<Box sx={{textAlign: 'left'}}>
									<ul>
										{passwordRules.map(string => <li><Typography variant="body1">{string}</Typography></li>)}
									</ul>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={this.handleChange}
								value={this.state.password}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "Password") !== undefined)}
								id="Password"
								label="Password"
								name="password"
								type="password"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={this.handleChange}
								value={this.state.confirmPassword}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "ConfirmPassword") !== undefined)}
								id="ConfirmPassword"
								label="Confirm Password"
								name="confirmPassword"
								type="password"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth onClick={this.onSubmitClick}>Submit</Button>
						</Grid>
						<Grid item xs={12}>							
							<Button type="button" variant="outlined" fullWidth component={Link} to={"/usergate/login"}>Cancel</Button>
						</Grid>
					</Grid>
				)}
				{this.state.submitState === enumSubmitState.Error && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h6">An issue occurred. Please try again, if issue persits contact support.</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" component={Link} to={"/usergate/login"}>Return To Login</Button>
						</Grid>
					</Grid>
				)}
				{this.state.submitState === enumSubmitState.Submitted && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h6">Password has been set.</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" component={Link} to={"/usergate/login"}>Return To Login</Button>
						</Grid>
					</Grid>
				)}
				{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
					<ApiErrorResponseMessages messages={this.state.validationMessages} />
				)}
			</div>
		);
	}
}
export default ((SetPassword as any))