import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import { Ten99PrepCancelIcon, Ten99PrepViewDoc } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	Loading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	payerId: string,
	payers: ITen99LookupItem[],
	formTypeId: string,
	formTypes: ITen99LookupItem[],
}

interface ICartReportsProps {
	cartId: number,
	onClose: () => void,
}

type CartReportsProps =
	& ICartReportsProps
	;

const initialLocalState: ILocalState = { status: enumComponentStatus.Loading, formTypeId: "", formTypes: [] as ITen99LookupItem[], payerId: "", payers: [] as ITen99LookupItem[], validationMessages: [] as ITen99ApiResponseMessage[] };
class CartReports extends React.PureComponent<CartReportsProps, ILocalState> {

	constructor(props: CartReportsProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/31/?parentId=" + this.props.cartId, "GET")
			.then(data => {
				//add payers
				if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.length === 1) {
					const lookup: ITen99LookupData | undefined = data.payload[0];
					if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
						this.setState({ status: enumComponentStatus.PendingAction, payers: lookup.lookupItems });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "No Payers found" } as ITen99ApiResponseMessage] });
					}
				}				
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load Payers" } as ITen99ApiResponseMessage] });
				}
			})
	}
	// -----------------
	//Handle User events
	
	private onCancel = () => {
		this.props.onClose();
	}

	private onPayerChange = (event: { target: { name?: string; value: unknown; }; }) => {
		const payerId: string = event.target.value as string;
		this.setState({ status: enumComponentStatus.Processing, payerId: payerId });
		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/32/?parentId=" + payerId, "GET")
			.then(data => {
				//add form Types
				if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.length === 1) {				
					const lookup: ITen99LookupData | undefined = data.payload[0];
					if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
						this.setState({ status: enumComponentStatus.PendingAction,  formTypeId:"",  formTypes: lookup.lookupItems });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "No Form Types found" } as ITen99ApiResponseMessage] });
					}					
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load Form Types" } as ITen99ApiResponseMessage] });
				}
			})
	}

	private onFormTypesChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ formTypeId: event.target.value as string });
		}
	}

	private onSubmit = () => {
		let tempValidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
		//check to see if values populated
		if (isNullOrUndefined(this.state.payerId) || this.state.payerId === "") {
			tempValidationMessages.push({ type: "Error", message: "Payer is requried", propertyNames: ["Payer"] as string[] } as ITen99ApiResponseMessage);
		}
		if (isNullOrUndefined(this.state.formTypeId) || this.state.formTypeId === "") {
			tempValidationMessages.push({ type: "Error", message: "Form Type is required", propertyNames: ["FormType"] as string[] } as ITen99ApiResponseMessage);
		}	

		if (tempValidationMessages.length === 0) {
			this.setState({ status: enumComponentStatus.Processing, validationMessages: [] });
			MakeApiCall<string>("api/Reports/" + this.state.formTypeId, "GET")
				.then(data => {
					if (data.isSuccess) {
						if (!isNullOrUndefined(data.payload)) {
							PromptDownloadFile(data.payload, "application/pd", "Report.pdf")
							this.setState({ status: enumComponentStatus.PendingAction, validationMessages: [] });
						}
					}
					else {
						let failValidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
						if (data.httpStatusCode === 404) {
							failValidationMessages.push({ type: "Error", message: "Unable to find indicated report", propertyNames: ["FormType"] as string[] } as ITen99ApiResponseMessage);
						}
						else {
							failValidationMessages = data.details;
						}
						this.setState({ validationMessages: failValidationMessages }); //error
					}
				});
		}
		else {
			this.setState({ status: enumComponentStatus.Invalid, validationMessages: tempValidationMessages });
		}

	}
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		
		return (			
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h5">Printing for Cart: {this.props.cartId}</Typography>
							<Typography variant="body2">Select the Payer and then the Form Type for the item you want to print</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent style={{padding:20}}>
					<Grid container spacing={3} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							)}
						</Grid>

						{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
							<Grid item xs={12} sx={{textAlign: 'left'}}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}
						{(this.state.status !== enumComponentStatus.Loading && this.state.status !== enumComponentStatus.Error) && (
							<React.Fragment>
								
								<Grid item xs={12}>
									<FormControl
										error={this.state.status === enumComponentStatus.Invalid && (this.state.validationMessages.find(x => x.propertyNames.find(x => x === "Payer")) !== undefined)}
										fullWidth
										required>
										<InputLabel id="payerLabel">Payer</InputLabel>
										<Select
											labelId="payerLabel"
											label="Payer"
											value={this.state.payerId}
											onChange={this.onPayerChange}
											inputProps={{}}
											id="payerId"
											name="payerId"
										>
											{!isNullOrUndefined(this.state.payers) && (this.state.payers.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl
										error={this.state.status === enumComponentStatus.Invalid && (this.state.validationMessages.find(x => x.propertyNames.find(x => x === "FormType")) !== undefined)}
										fullWidth
										required>
										<InputLabel id="formTypeLabel">Report Type</InputLabel>
										<Select
											labelId="formTypeLabel"
											label="Report Type"
											value={this.state.formTypeId}
											onChange={this.onFormTypesChange}
											inputProps={{}}
											id="formTypeId"
											name="formTypeId"
										>
											{!isNullOrUndefined(this.state.formTypes) && this.state.formTypes.length > 0 && (this.state.formTypes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
										</Select>
									</FormControl>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={this.onCancel}>Close</Button>
					<Button type="button" disabled={this.state.status === enumComponentStatus.Loading || this.state.status === enumComponentStatus.Error} variant="contained" color="primary" startIcon={<Ten99PrepViewDoc />} onClick={this.onSubmit}>Get Document</Button>
				</DialogActions>

			</React.Fragment>
		)
	}
}

export default CartReports;