import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import ComplexIconButton from "components/buttons/ComplexIconButton";
import * as React from 'react';
import { ActionTypesEnum } from "sharedInterfaces/ITen99Action";
import { ITen99DynamicButtonProps } from "sharedInterfaces/ITen99DynamicButtonProps";
import { Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { EntityNav, IEntityNavItem } from 'components/information/EntityNav';
import { isNullOrUndefined } from "util";

interface ILocalState {

}

interface IDialogTemplateProps {
	title: string,
	navItems?: IEntityNavItem[],
	buttonsLeft?: typeof Button[],
	buttonsRight?: typeof Button[],
	onClose: () => void,

}
const initialLocalState: ILocalState = { };
class DialogTemplate extends React.PureComponent<React.PropsWithChildren<IDialogTemplateProps>> {

	constructor(props: IDialogTemplateProps) {
		super(props);
		this.state = initialLocalState;
	}
	// -----------------
	//Handle User events

	// Render
	render() {
		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{ textAlign: 'center' }}>
						<Grid item xs={11} sx={{ textAlign: 'left' }}>
							<Typography variant="h5">{this.props.title}</Typography>
						</Grid>
						<Grid item xs={1} sx={{ textAlign: 'right' }}>
							<ComplexIconButton itemId="close" uiProps={{ action: ActionTypesEnum.Import, onClick: this.props.onClose, icon: <Ten99PrepCancelIcon />, color: "secondary", text: "", tooltip: "Close" } as ITen99DynamicButtonProps} />
						</Grid>
						{!isNullOrUndefined(this.props.navItems) && (
							<Grid item xs={12} sx={{ textAlign: 'left' }}>
								<EntityNav items={this.props.navItems} />
							</Grid>
						)}
					</Grid>
				</DialogTitle>
				<DialogContent style={{ padding: 20 }}>
					{this.props.children}
				</DialogContent>
				<DialogActions>
					{this.props.buttonsLeft}
					<div style={{ flex: '1 0 0' }} />
					{this.props.buttonsRight}
				</DialogActions>
			</React.Fragment>
		)
	}
}

export default DialogTemplate;

