import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as ToastNotifications from 'store/ToastNotifications';
import { Ten99PrepDownloadIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall, PromptDownloadFile } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {
	status: enumComponentStatus,
	validationMessages: ITen99ApiResponseMessage[],
	selectedFormType: string,
	formTypes: ITen99LookupItem[],
}

interface IDownloadTemplateProps {	
}

type DownloadTemplateProps =
	& IDownloadTemplateProps
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	& typeof ToastNotifications.actionCreators// ... plus action creators we've requested
;

const initialLocalState: ILocalState = { status: enumComponentStatus.PendingAction, validationMessages: [] as ITen99ApiResponseMessage[], selectedFormType: "", formTypes: [] as ITen99LookupItem[] };
class DownloadTemplate extends React.PureComponent<DownloadTemplateProps, ILocalState> {


	constructor(props: DownloadTemplateProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/8/?parentId=1", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 8);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.setState({ status: enumComponentStatus.PendingAction, formTypes: lookup.lookupItems});
						}
						else {
							this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Unable to load form types" }] as ITen99ApiResponseMessage[] });
						}
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			})
	}
	// -----------------
	//Handle User events
	private onDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
		}
	}

	private getFileName = () => {
		let item: ITen99LookupItem | undefined = this.state.formTypes.find(x => x.id === parseInt(this.state.selectedFormType))

		if (!isNullOrUndefined(item)) {
			return item.name + "_template.xlsx";
		}
		else {
			return "template.xlsx";
        }

    }

	private onDownloadClick = () => {
		this.setState({ status: enumComponentStatus.Processing });
		MakeApiCall<string>("api/Exports/" + this.state.selectedFormType, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						PromptDownloadFile(data.payload, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", this.getFileName())	
						this.setState({ status: enumComponentStatus.PendingAction})
					}
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: data.details }); //error
				}
			})
    }
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render

	render() {	

		return (
			<React.Fragment>
				{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
					<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
				)}
				<Grid container spacing={1}>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="h5">Template</Typography>
					</Grid>
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<Typography variant="body2">Select the applicable blank template from the dropdown. Export the Template. DO NOT ALTER STRUCTURE OR FORMAT when completing template.</Typography>
					</Grid>
					
					<Grid item xs={12} sx={{ textAlign: 'left' }}>
						<FormControl
							fullWidth
							required>
							<InputLabel id="formTypeLabel">Form Type</InputLabel>
							<Select
								labelId="formTypeLabel"
								label="Form Type"
								value={this.state.selectedFormType}
								onChange={this.onDropDownChange}
								inputProps={{}}
								id="selectedFormType"
								name="selectedFormType"
							>
								{(this.state.formTypes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sx={{ textAlign: 'right' }}>
						<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepDownloadIcon />} onClick={this.onDownloadClick}>Download Template</Button>
					</Grid>

				</Grid>
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...ToastNotifications.actionCreators, }, // Selects which action creators are merged into the component's props
)(DownloadTemplate);