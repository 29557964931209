import { IconButton } from '@mui/material';
import InfoToolTip from 'components/notifcations/InfoTooltip';
import * as React from 'react';
import { ITen99DynamicButtonProps } from 'sharedInterfaces/ITen99DynamicButtonProps';
import { isNullOrUndefined } from 'util';

interface IComplexIconButton {
	itemId: string,
	uiProps: ITen99DynamicButtonProps,
	dense?: boolean,
	stopPropagation?: boolean,
}
type ComplexIconButtonProps =
	IComplexIconButton
	;

class ComplexIconButton extends React.PureComponent<ComplexIconButtonProps> {
	//local state

	// -----------------
	//Handle User events
	private onClick = (event: React.MouseEvent) => { //this really should be passed back to the calling on click and let it deal with propigation... maybe if I have time later
		if (!isNullOrUndefined(this.props.stopPropagation) && this.props.stopPropagation ) {
			event.stopPropagation();
		}

		if (!isNullOrUndefined(this.props.uiProps.onClick))
		{
			this.props.uiProps.onClick(this.props.itemId, this.props.uiProps.action);
		}		
	}
	// -----------------
	// Componet lifecycle events
	// -----------------
	
	// Render
	render() {
		
		return (
			<React.Fragment>
				{!isNullOrUndefined(this.props.uiProps.icon) && (
					<InfoToolTip text={isNullOrUndefined(this.props.uiProps.tooltip) ? "" : this.props.uiProps.tooltip}>
						<IconButton id={this.props.itemId + "ComplexIconButton" + this.props.uiProps.action} tabIndex={-1} onClick={e => this.onClick(e)} sx={{ padding: (isNullOrUndefined(this.props.dense) || !this.props.dense) ? undefined : "0px" }} color={this.props.uiProps.color}>{this.props.uiProps.icon}</IconButton>
					</InfoToolTip>
				)}
			</React.Fragment>
			);
	}
}

export default (ComplexIconButton);
