import { Grid } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import KeyValueDisplay from 'components/information/KeyValue';
import * as React from 'react';
import { GetEnumEntityTypeLabel, ITen99EntitySummary } from 'sharedInterfaces/ITen99EntitySummary';
import { isNullOrUndefined } from 'util';



interface ISummaryTitleProps {
	entity: ITen99EntitySummary,
	variant?: Variant,
	inline?: boolean
}

class SummaryTitle extends React.PureComponent<ISummaryTitleProps> {

	// Render
	render() {
		return (			
			<React.Fragment>
				{!isNullOrUndefined(this.props.entity.name) && (
					<React.Fragment>
						<KeyValueDisplay keyName={GetEnumEntityTypeLabel(this.props.entity.type)} value={this.props.entity.name} variant={this.props.variant} />
					</React.Fragment>
				)}
				{isNullOrUndefined(this.props.entity.name) && !isNullOrUndefined(this.props.entity.names) && (isNullOrUndefined(this.props.inline) || this.props.inline === true) && (
					this.props.entity.names.map((item, index) => {
						return (
							<KeyValueDisplay key={index} keyName={item.title} value={item.descr} variant={this.props.variant} />
						)
					})	
				)}
				{isNullOrUndefined(this.props.entity.name) && !isNullOrUndefined(this.props.entity.names) && this.props.inline === false && (
					<Grid container spacing={1}>
						{
							this.props.entity.names.map((item, index) => {
								return (
									<React.Fragment key={index}>
										<Grid item xs={12} sx={{ textAlign: "left", display: "flex" }}>
											<KeyValueDisplay keyName={item.title} value={item.descr} variant={this.props.variant} />
										</Grid>
									</React.Fragment>
								)
							})
						}
					</Grid>
				)}
			</React.Fragment>
		);
	}
}
export default SummaryTitle;
