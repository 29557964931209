import { Button, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import InfoToolTip from "components/notifcations/InfoTooltip";
import ProcessingModal from "components/notifcations/ProcessingModal";
import * as React from 'react';
import { ITen99ActionResponseMessage, ITen99ActionResponseTypeEnum } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { Ten99PrepCancelIcon, Ten99PrepSaveIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { flattenProperties } from 'utilities/PropertyList';

enum enumComponentStatus {
	PendingAction,
	Processing,
	Loading,
	Invalid,
	Error
};

interface ITechnicalAssistanceProps {
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,	
	validationMessages?: ITen99ApiResponseMessage[],
}

type TechnicalAssistanceProps =
	& ITechnicalAssistanceProps
	;

interface ILocalState {
	status: enumComponentStatus,
	summary: string,
	details: string,
	validationMessages: ITen99ApiResponseMessage[],
	invalidProperties: string[],
}

const initialLocalState = { status: enumComponentStatus.PendingAction, summary:"", details:"", validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: [] as string[] } as ILocalState;
class TechnicalAssistance extends React.PureComponent<TechnicalAssistanceProps, ILocalState> {

	//local state
	state = initialLocalState;
	// -----------------
	//Handle User events
	private onChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ [event.target.name]: event.target.value } as Pick<ILocalState, keyof ILocalState>);
	}

	private onSubmit = () => {
		this.setState({ status: enumComponentStatus.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
		MakeApiCall<string>("api/Common/TechnicalAssistanceRequest", "POST", JSON.stringify({ "summary": this.state.summary, "details": this.state.details }))
			.then(data => {
				if (data.isSuccess) {
					this.props.onClose(true, { message: "Issue accepted. You will be contacted about your issue soon.", messageType: ITen99ActionResponseTypeEnum.Success })
				}
				else {
					if (data.httpStatusCode === 422) {
						this.setState({ status: enumComponentStatus.Invalid, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
					}
					else {
						this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "An issue occurred submitting your request. Please try again. If the issue persists, please email support@1099prep.com directly." }] as ITen99ApiResponseMessage[] });
					}
				}
			});

		
    }
	// -----------------
	// Componet lifecycle events

	// -----------------
	// Render
	render() {
		

		return (
			<React.Fragment>
				<DialogTitle>
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12} sx={{textAlign: 'left'}}>
							<Typography variant="h4">Request Assistance
							<InfoToolTip text="Submit an issue to 1099 Prep to be answered" />
							</Typography>
							<Typography variant="h6">
								Support can also be reached via <Link href="mailto:support@1099-prep.com">support@1099-prep.com</Link>
							</Typography>
						</Grid>						
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{ textAlign: 'center', paddingTop:"8px" }}>
						{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.Loading) && (
							<Grid item xs={12}>
								<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
							</Grid>
						)}
					
						{!isNullOrUndefined(this.state.validationMessages) && this.state.validationMessages.length > 0 && (
							<Grid item xs={12}>
								<ApiErrorResponseMessages messages={this.state.validationMessages} />
							</Grid>
						)}

						<Grid item xs={12}>
							<TextField
								onChange={this.onChange}
								value={this.state.summary}
								variant="outlined"
								error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "Summary") !== undefined)}
								id="summary"
								label="Summary of the issue"
								name="summary"
								required
								fullWidth
								inputProps={{maxLength: 100}}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								onChange={this.onChange}
								value={this.state.details}
								variant="outlined"
								error={this.state.status === enumComponentStatus.Invalid && (this.state.invalidProperties.find(x => x === "Details") !== undefined)}
								id="details"
								label="Details"
								name="details"
								multiline
								rows={20}
								required
								fullWidth
								inputProps={{ maxLength: 2000 }}
							/>
						</Grid>							
								
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={x => this.props.onClose(false, undefined)}>Close</Button>
					<Button type="button" variant="contained" color="primary" startIcon={<Ten99PrepSaveIcon />} onClick={this.onSubmit}>Submit</Button>
					
				</DialogActions>
			</React.Fragment>
		)
	}
}

// -----------------
//This is the method Redux uses to hook the component into redux state
export default (TechnicalAssistance);