//import { ProviderContext, withSnackbar } from 'notistack';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as ToastNotificationsStore from 'store/ToastNotifications';
import { IToastMessage } from 'store/ToastNotifications';
import { isNullOrUndefined } from 'util';

type ToastDisplayProps =	
	ToastNotificationsStore.ToastNotificationsState // ... state we've requested from the Redux store
	& typeof ToastNotificationsStore.actionCreators // ... plus action creators we've requested
    //& ProviderContext //.. notistack props

interface ILocalState {
	masterOpen: boolean
}
const initialLocalState = { masterOpen: true} as ILocalState;
class ToastDisplay extends React.PureComponent<ToastDisplayProps, ILocalState> {
	//local state
	state = initialLocalState;
	private masterOpenTimeout?: NodeJS.Timeout;

	//componentDidMount() {

	//	if (!isNullOrUndefined(process.env.REACT_APP_TOKEN_LIFETIME_WARNING)) {
	//		this.masterOpenTimeout = setTimeout(() => { this.notifyUserAboutTokenTimeout(); }, Number.parseInt(process.env.REACT_APP_TOKEN_LIFETIME_WARNING)); // notify user before token expires
	//	}

	//	if (!isNullOrUndefined(process.env.REACT_APP_NOTIFICATION_CHECK_INTERVAL_MILLISECONDS)) {
	//		this.getNotificationsInterval = setInterval(() => { this.checkForUnreadNotifcations() }, Number.parseInt(process.env.REACT_APP_NOTIFICATION_CHECK_INTERVAL_MILLISECONDS));
	//	}
	//}

	componentWillUnmount() {
		if (!isNullOrUndefined(this.masterOpenTimeout)) {
			clearTimeout(this.masterOpenTimeout);
		}
	}

	// -----------------
	//Handle User events

	private onToastClose = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
		if (this.props.toastNotifications_currentMessage !== undefined && reason === "timeout") {
			this.setState({ masterOpen: false })
			this.masterOpenTimeout = setTimeout(() => { this.reopenMasterOpen(); }, 2000); // pause before next message

			let id: string = this.props.toastNotifications_currentMessage.id;

			//tell redux to remove item
			setTimeout(() => { this.props.toastNotifcations_Remove(id); }, 2000); // delay change of message so ui can close first
		}
	}

	private GenerateAlert = () => {
		if (this.props.toastNotifications_currentMessage !== undefined) {
			return (<Alert variant="filled" severity={this.props.toastNotifications_currentMessage.type}>{this.props.toastNotifications_currentMessage.message}</Alert>)
		}
	}

	private reopenMasterOpen = () => {
		this.setState({ masterOpen: true })
	}

	// Render
	render() {
		return (
			<Snackbar open={this.state.masterOpen && this.props.toastNotifications_currentMessage !== undefined} autoHideDuration={8000} onClose={this.onToastClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				{this.GenerateAlert()}
			</Snackbar >
			);
	}
}

// -----------------
export default connect(
	(state: ApplicationState) => state.toastNotifications, // Selects which state properties are merged into the component's props
	ToastNotificationsStore.actionCreators // Selects which action creators are merged into the component's props
)(ToastDisplay);