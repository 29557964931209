import { Button, Dialog } from "@mui/material";
import DialogTemplate from 'components/display/DialogTemplate';
import { IEntityNavItem } from 'components/information/EntityNav';
import ProcessingModal from "components/notifcations/ProcessingModal";
import QboPayerSelect from "components/ten99Prep/Import/Qbo/QboPayerSelect";
import * as React from 'react';
import { connect } from 'react-redux';
import { ITen99ApiResponseMessage } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99Customer } from "sharedInterfaces/ITen99Customer";
import { EnumEntityType } from "sharedInterfaces/ITen99EntitySummary";
import { IForm } from "sharedInterfaces/ITen99Form";
import { IFormElement } from "sharedInterfaces/ITen99FormElement";
import { ITen99LookupData, ITen99LookupItem } from "sharedInterfaces/ITen99LookupData";
import * as ConfirmDialog from 'store/ConfirmDialog';
import * as HomeNavigation from 'store/HomeNavigation';
import { ICustomerSummaryActions } from "store/HomeNavigation";
import { Ten99PrepBackIcon, Ten99PrepForwardIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";
import { QBO_STRING } from "./QboConstants";
import QboFormSelect from "./QboFormSelect";
import QboMapping from "./QboMapping";
import QboPreview from "./QboPreview";

type WizardStates = "QboPayerSelect" | "QboFormSelect" | "QboMapping" | "QboPreview";

interface ILocalState {
	dialogOpen: boolean,
	dialogSize: "sm" | "md" | "lg",
	importFiles: File[],
	processing: boolean,
	validationMessages?: ITen99ApiResponseMessage[],
	wizardItem: WizardStates,
	processId: string,
	selectedPayer: ITen99LookupItem,
	selectedFormType: ITen99LookupItem,
	mappingId?: string,
	form: IForm
}

interface IImportDataProps {
	customer: ITen99Customer,
	goHome: () => void,
	onClose: () => void,
}

interface IAuthUriResponse {
	uri: string,
	processId: number,
}

interface IProcess {
	status: number,
	processCustomStatus: number,
	messages: ITen99ApiResponseMessage[]
}



type ImportDataProps =
	& IImportDataProps
	& ICustomerSummaryActions
	& typeof ConfirmDialog.actionCreators// ... plus action creators we've requested
	;

const initialLocalState: ILocalState = {
	dialogOpen: true,
	dialogSize: "lg",
	wizardItem: "QboPayerSelect",
	importFiles: [] as File[],
	validationMessages: [] as ITen99ApiResponseMessage[],
	processing: false,
	processId: "",
	selectedPayer: {id: -1} as ITen99LookupItem,
	selectedFormType: { id: -1 } as ITen99LookupItem,
	mappingId: undefined,
	form: { formElementValues: [] as IFormElement[] } as IForm
};

class ImportQboWizard extends React.PureComponent<ImportDataProps, ILocalState> {
	private QuickBooksLoginInterval?: NodeJS.Timeout;
	private QuickBooksLoginTimeout?: NodeJS.Timeout;
	
	constructor(props: ImportDataProps) {
		super(props);
		this.state = JSON.parse(JSON.stringify(initialLocalState));
	}
	
	private promptMappingClose = (onProceed: () => void) => {
		this.props.confirmDialog_Display("Import from " + QBO_STRING + ": " + this.props.customer.name, "-1", "You are going to discard all changes made on this screen.", onProceed)
	}

	private onPayerSelectChange = (payer: ITen99LookupItem) => {
		this.setState({ selectedPayer: payer, validationMessages: [] as ITen99ApiResponseMessage[] });
	}

	private onFormTypeChange = (formType: ITen99LookupItem) => {
		let tempForm: IForm = this.state.form;
		tempForm.formTypeId = formType.id;

		this.setState({ selectedFormType: formType, form: tempForm, validationMessages: [] as ITen99ApiResponseMessage[] });
	}

	private navigateBackFromMapping = () =>
	{
		this.navigateWizardState("QboFormSelect");
    }

	private navigateWizardState = (name: WizardStates) => {
		let tempstate: WizardStates = name;
		let tempvalidationMessages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];

		if (name === "QboMapping") { //check form type first
			if (!(this.state.selectedFormType.id >= 1)) {
				tempstate = "QboFormSelect";
				tempvalidationMessages.push({ type: "Error", message: "Form Type is a required field"   } as ITen99ApiResponseMessage)
            }
        }
		this.setState({ processing: false, wizardItem: tempstate, validationMessages: tempvalidationMessages });
	}

	private onMappingChange = (formElements: IFormElement[]) => {
		let tempForm: IForm = this.state.form;
		tempForm.formElementValues = [...formElements];

		this.setState({ form: tempForm });
	}

	private existingFormFound = (mappingId: string, ticks: string) => {
		let tempForm: IForm = this.state.form;
		tempForm.ticks = ticks;
		this.setState({
			mappingId: mappingId,
			form: tempForm
			});
    }

	private confirmSubmitMapping = () => {
		this.props.confirmDialog_Display("Import from " + QBO_STRING + ": " + this.props.customer.name, "-1", "You are about to import " + this.state.selectedFormType.name + " forms for payer " + this.state.selectedPayer.name + ".", this.submitMapping)
	}

	private submitMapping = () => {
		let uri: string = (isNullOrUndefined(this.state.mappingId) ? "api/QuickbooksMapper?payerId=" + this.state.selectedPayer.id : "api/QuickbooksMapper/" + this.state.mappingId);
		let method: string = (isNullOrUndefined(this.state.mappingId) ? "POST" : "PUT");

		this.setState({ processing: true });
		MakeApiCall<string>(uri, method, JSON.stringify(this.state.form))
			.then(data => {
				if (data.isSuccess) {
					//submit data to export
					MakeApiCall<string>("api/QuickbooksExport?formId=" + (method === "PUT" ? this.state.mappingId : data.payload), "POST")
						.then(data => {
							if (data.isSuccess) {
								//move to next step
								this.navigateWizardState("QboPreview");
							}
							else {
								this.setState({ processing: false, validationMessages: data.details });
							}
						})
				}
				else {
					this.setState({ processing: false, validationMessages: data.details });
				}
			})
	}

	private QboLogin = () => {
		this.setState({ processing: true });
		if (this.state.selectedPayer.id !== -1) {
			MakeApiCall<boolean>("api/QuickBooksAuth/AuthExists/" + this.state.selectedPayer.id.toString(), "GET")
				.then(data => {
					if (data.isSuccess) {
						if (data.payload) //this user has a valid auth token already for this payer
						{
							this.setState({ wizardItem: "QboFormSelect", processing: false, processId: "", validationMessages: [] as ITen99ApiResponseMessage[] });
						}
						else {
							this.QboAuthUri();
						}
					}
					else {
						this.setState({ processing: false, validationMessages: data.details });
					}
				});
		}
		else {
			this.QboAuthUri();
        }
	}

	private QboAuthUri = () => {
		let optionalPayer = this.state.selectedPayer.id === -1 ? "" : "?payerId=" + this.state.selectedPayer.id;
		MakeApiCall<IAuthUriResponse>("api/QuickBooksAuth/AuthUri/" + this.props.customer.customerId + optionalPayer, "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						this.setState({processId: data.payload.processId.toString(), validationMessages: [] as ITen99ApiResponseMessage[] });

						window.open(data.payload.uri, "_blank", "toolbar=no,location=no");

						if (!isNullOrUndefined(process.env.REACT_APP_QBO_CHECK_INTERVAL_MILLISECONDS)) {
							this.QuickBooksLoginInterval = setInterval(() => { this.CheckQuickBooksLoginProcess() }, Number.parseInt(process.env.REACT_APP_QBO_CHECK_INTERVAL_MILLISECONDS));
						}

						if (!isNullOrUndefined(process.env.REACT_APP_QBO_CHECK_TIMEOUT_MILLISECONDS)) {
							this.QuickBooksLoginTimeout = setTimeout(() => { this.QuickBooksLoginProcessTimeout() }, Number.parseInt(process.env.REACT_APP_QBO_CHECK_TIMEOUT_MILLISECONDS));
						}
					}
				}
				else {
					this.setState({ processing: true, validationMessages: data.details });
				}
			})
	}

	private QuickBooksLoginProcessTimeout = () => {
		this.clearTimers();
		this.setState({ processing: false, processId: "", validationMessages: [{ type: "Error", message: "Timeout occured while waiting on the " + QBO_STRING + " login process. Please try again." }] as ITen99ApiResponseMessage[] });
	}

	private CheckQuickBooksLoginProcess = () => {
		MakeApiCall<IProcess>("api/Process/" + this.state.processId + "?status=3&status=4", "GET")
			.then(data => {
				if (data.isSuccess) {
					this.clearTimers();

					if (!isNullOrUndefined(data.payload)) {
						if (data.payload.status === 4) {
							this.setState({ processing: false, processId: "", validationMessages: data.payload.messages });
						}
						else {
							if (this.state.selectedPayer.id === -1) //if new payer
							{
								//try to find payer ID
								let successMsg: ITen99ApiResponseMessage | undefined = data.payload.messages.find(x => x.type = "200");
								if (!isNullOrUndefined(successMsg)) {
									//get lookup for new payer
									let payerId: string = successMsg.message;
									MakeApiCall<ITen99LookupData[]>("api/Common/Lookup/70/?parentId=" + this.props.customer.customerId, "GET")
										.then(data => {
											if (data.isSuccess) {
												if (!isNullOrUndefined(data.payload)) {
													const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 70);
													let payer: ITen99LookupItem | undefined = lookup?.lookupItems?.find(x => x.id.toString() === payerId);
													let messages: ITen99ApiResponseMessage[] = [] as ITen99ApiResponseMessage[];
													if (isNullOrUndefined(payer)) {
														messages = [{ type: "Error", message: "Unable to load new payer data" }] as ITen99ApiResponseMessage[];
														this.setState({ processing: false, processId: "", validationMessages: messages });

													}
													else {
														this.setState({ processing: false, wizardItem: "QboFormSelect", selectedPayer: payer, processId: "", validationMessages: messages });
														this.props.CustomerSummaryActions_ClearNamedFilters(); //this is cheating but does cuase the payers to refresh so new one is displayed
													}
												}
											}
											else {
												this.setState({ processing: false, validationMessages: data.details }); //unable to load lookup, we need to stop
											}
										})
								}
								else
								{
									this.setState({ processing: false, processId: "", validationMessages: [{ type: "Error", message: "Unable to find newly created payer" }] as ITen99ApiResponseMessage[] });
                                }
							}
							else { //existing payer
								this.setState({ processing: false, wizardItem: "QboFormSelect", processId: "", validationMessages: data.payload.messages });
                            }					
							
						}
					}
					else {
						this.setState({ processing: false, processId: "", validationMessages: [{ type: "Error", message: "Unable to find " + QBO_STRING + " process." }] as ITen99ApiResponseMessage[] });
					}
				}
			});
	}

	private clearTimers = () => {
		if (!isNullOrUndefined(this.QuickBooksLoginInterval)) {
			clearInterval(this.QuickBooksLoginInterval);
		}

		if (!isNullOrUndefined(this.QuickBooksLoginTimeout)) {
			clearTimeout(this.QuickBooksLoginTimeout);
		}
    }

	componentWillUnmount() {
		this.clearTimers();
	}


	// Render
	render() {
		return (
			<React.Fragment>
				{(this.state.processing) && (
					<ProcessingModal modal={true} />
				)}
				<Dialog open={this.state.dialogOpen} fullWidth={true} maxWidth={this.state.dialogSize}>
					{this.state.wizardItem === "QboPayerSelect" && (
						<DialogTemplate
							onClose={this.props.onClose}
							title={QBO_STRING + " Import"}
							navItems={[{ type: EnumEntityType.Customer, name: this.props.customer.name}] as IEntityNavItem[] }
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={this.props.goHome}>Back</Button>,
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" startIcon={<Ten99PrepForwardIcon />} onClick={this.QboLogin}>Next</Button>,
								] as unknown as typeof Button[]}
						>
							<QboPayerSelect customerId={Number.parseInt(this.props.customer.customerId)} validationMessages={this.state.validationMessages} onPayerSelectChange={this.onPayerSelectChange} selectedPayerId={this.state.selectedPayer.id} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "QboFormSelect" && (
						<DialogTemplate
							onClose={this.props.onClose}
							title={QBO_STRING + " Import"}
							navItems={[
								{ type: EnumEntityType.Customer, name: this.props.customer.name },
								{ type: EnumEntityType.Payer, name: this.state.selectedPayer.name }
							] as IEntityNavItem[]}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={() => this.navigateWizardState("QboPayerSelect")}>Back</Button>,
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" startIcon={<Ten99PrepForwardIcon />} onClick={() => this.navigateWizardState("QboMapping")}>Next</Button>,
								] as unknown as typeof Button[]}
						>
							<QboFormSelect customerId={Number.parseInt(this.props.customer.customerId)} validationMessages={this.state.validationMessages} onFormTypeChange={this.onFormTypeChange} selectedForm={this.state.selectedFormType.id} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "QboMapping" && (
						<DialogTemplate
							onClose={() => this.promptMappingClose(this.props.onClose)}
							title={QBO_STRING + " Import"}
							navItems={[
								{ type: EnumEntityType.Customer, name: this.props.customer.name },
								{ type: EnumEntityType.Payer, name: this.state.selectedPayer.name },
								{ type: EnumEntityType.RecipientForm, name: this.state.selectedFormType.name },
							] as IEntityNavItem[]}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={() => this.promptMappingClose(this.navigateBackFromMapping)}>Back</Button>,
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" startIcon={<Ten99PrepForwardIcon />} onClick={this.confirmSubmitMapping}>Next</Button>,
								] as unknown as typeof Button[]}
						>
							<QboMapping customerId={Number.parseInt(this.props.customer.customerId)} validationMessages={this.state.validationMessages} selectedPayerId={this.state.selectedPayer.id} selectedFormTypeId={this.state.selectedFormType.id} onMappingUpdate={this.onMappingChange} onExistingMappingFound={this.existingFormFound} />
						</DialogTemplate>
					)}
					{this.state.wizardItem === "QboPreview" && (
						<DialogTemplate
							onClose={this.props.onClose}
							title={QBO_STRING+ " Import"}
							navItems={[
								{ type: EnumEntityType.Customer, name: this.props.customer.name },
							] as IEntityNavItem[]}
							buttonsLeft={
								[
									<Button key={0} type="button" variant="contained" color="secondary" startIcon={<Ten99PrepBackIcon />} onClick={() => this.navigateWizardState("QboMapping")}>Back</Button>,
								] as unknown as typeof Button[]}
							buttonsRight={
								[
									<Button key={0} type="button" variant="contained" color="primary" onClick={this.props.onClose}>Finish</Button>,
								] as unknown as typeof Button[]}
						>
							<QboPreview customerId={Number.parseInt(this.props.customer.customerId)}/>
						</DialogTemplate>
					)}
				</Dialog>
			</React.Fragment>
		)
	}
}
export default connect(
	null, // Selects which state properties are merged into the component's props
	{ ...ConfirmDialog.actionCreators, ...HomeNavigation.actionCreators }, // Selects which action creators are merged into the component's props
)((ImportQboWizard));