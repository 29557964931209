import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { IAddressProps } from 'components/ten99Prep/Address/IAddressProps';
import * as React from 'react';


class ForeignAddress extends React.PureComponent<IAddressProps> {
	//local state
	
	// -----------------
	//Handle User events

	private handleChange = (event: { target: { name: string; value: any; }; }) => {
		this.props.onchange(event.target.name, event.target.value);
	}

	private handleDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.props.onchange(event.target.name, event.target.value);
		}
	}
	
	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	
	// -----------------
	// Componet lifecycle events
	// -----------------

	// Render
	render() {
		//
		return (			
			<React.Fragment>
				<Grid item xs={12}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.addressLine1}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "AddressLine1") !== undefined)}
						id="addressLine1"
						label="Address Line 1"
						name="addressLine1"
						required
						fullWidth
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.addressLine2}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "AddressLine2") !== undefined)}
						id="addressLine2"
						label="Address Line 2"
						name="addressLine2"
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.city}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "City") !== undefined)}
						id="city"
						label="City"
						name="city"
						required
						fullWidth
					/>
				</Grid>

				<Grid item xs={4}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.foreignStateOrProvince}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ForeignStateOrProvince") !== undefined)}
						id="foreignStateOrProvince"
						label="Foreign State/Province/County/Etc."
						name="foreignStateOrProvince"						
						fullWidth
					/>
				</Grid>	

				<Grid item xs={4}>
					<TextField
						disabled={this.props.disabled}
						onChange={this.handleChange}
						value={this.props.address.foreignPostalCode}
						variant="outlined"
						error={this.props.invalid && (this.props.invalidProperties.find(x => x === "ForeignPostalCode") !== undefined)}
						id="foreignPostalCode"
						label="Foreign Postal Code"
						name="foreignPostalCode"						
						fullWidth
					/>
				</Grid>	

				
				<Grid item xs={12}>
					<Autocomplete
						id="countryId"
						options={this.props.countries.filter(x => x.name !== "USA")}
						value={this.props.countries.find(x => x.id === this.props.address.countryId)}
						getOptionLabel={(option) => option.description}
						onChange={(e, value) => {
							this.props.onchange("countryId", value?.id);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={this.props.invalid && (this.props.invalidProperties.find(x => x === "CountryId") !== undefined)}
								label="Country"
								placeholder=""
							/>
						)}
					/>
				</Grid>
			</React.Fragment>
		);
	}
}

export default ForeignAddress;