import { Action, Reducer } from 'redux';
import { isNullOrUndefined } from 'util';
import { Auth_LogoutAction } from 'store/SharedActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConfirmDialogState { //tagged properties with "confirmDialog_" so when looking in the props can easily tell these came from this store
    confirmDialog_dialogOpen: boolean
    confirmDialog_onCancel?: (id: string) => void,
    confirmDialog_onProceed?: (id: string) => void,
    confirmDialog_title: string,
    confirmDialog_objectId: string,
    confirmDialog_message: string,
    confirmDialog_hideCancel: boolean,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface Display { type: 'ConfirmDialog/Display', onProceed: (id: string) => void, onCancel: (id: string) => void, title: string, objectId: string, message:string, hideCancel: boolean }
export interface Reset { type: 'ConfirmDialog/Reset' }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = Display | Reset | Auth_LogoutAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    confirmDialog_Display: (title: string, objectId: string, message: string, onProceed?: (id: string) => void, hideCancel?: boolean, onCancel?: (id: string) => void) =>
        ({ type: 'ConfirmDialog/Display', onProceed: onProceed, hideCancel: (isNullOrUndefined(hideCancel) ? false : hideCancel), onCancel: onCancel, title: title, objectId: objectId, message: message } as Display),
    confirmDialog_Reset: () => ({ type: 'ConfirmDialog/Reset' } as Reset)
};


const unloadedState: ConfirmDialogState = { confirmDialog_dialogOpen: false, confirmDialog_onProceed: undefined, confirmDialog_title: "", confirmDialog_objectId: "", confirmDialog_message: "", confirmDialog_hideCancel: false};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ConfirmDialogState> = (state: ConfirmDialogState | undefined, incomingAction: Action): ConfirmDialogState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ConfirmDialog/Display':
            if (state.confirmDialog_dialogOpen) //this means dialog is already open, should not be getting a second request
            {
                return state;
            }
            else {
                return Object.assign({}, state, {
                    confirmDialog_dialogOpen: true,
                    confirmDialog_onProceed: action.onProceed,
                    confirmDialog_onCancel: action.onCancel,
                    confirmDialog_title: action.title,
                    confirmDialog_objectId: action.objectId,
                    confirmDialog_message: action.message,
                    confirmDialog_hideCancel: action.hideCancel,
                })
            };
        case 'Auth/Logout':
        case 'ConfirmDialog/Reset':
            return unloadedState;
        default:
            return state;
    }
};
