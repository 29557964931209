import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ApiErrorResponseMessages from "components/notifcations/ApiErrorResponseMessages";
import ProcessingModal from "components/notifcations/ProcessingModal";
import HistoricalCart from "components/ten99Prep/Cart/HistoricalCart";
import * as React from 'react';
import { ITen99ActionResponseMessage } from "sharedInterfaces/ITen99ActionResponse";
import { ITen99ApiResponseMessage, ITen99Dashboard } from "sharedInterfaces/ITen99ApiResponse";
import { ITen99EntitySummary } from "sharedInterfaces/ITen99EntitySummary";
import { Ten99PrepCancelIcon } from "Ten99PrepOverloads/IconOverloads";
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from "utilities/ApiFunctions";

enum enumComponentStatus {
	IsLoading,
	PendingAction,
	Processing,
	Invalid,
	Error
}; 

interface ILocalState {		
	status: enumComponentStatus,	
	validationMessages: ITen99ApiResponseMessage[],		
}

interface ICartHistorySearchProps {
	cartId: string,
	onClose: (proceesed: boolean, message?: ITen99ActionResponseMessage) => void,
}

type CartHistorySearchProps =
	& ICartHistorySearchProps
	;

const initialLocalState: ILocalState = {
	status: enumComponentStatus.IsLoading,	
	validationMessages: [] as ITen99ApiResponseMessage[],	
};
class CartHistorySearch extends React.PureComponent<CartHistorySearchProps, ILocalState> {

	constructor(props: CartHistorySearchProps) {
		super(props);
		this.state = initialLocalState;

		MakeApiCall<ITen99Dashboard<ITen99EntitySummary>>("api/Carts/" + this.props.cartId, "GET")
			.then(data => {
				if (data.isSuccess && !isNullOrUndefined(data.payload)) {
					this.setState({ status: enumComponentStatus.PendingAction });
				}
				else {
					this.setState({ status: enumComponentStatus.Error, validationMessages: [{ type: "Error", message: "Cannot find cart " + this.props.cartId + " for the currently selected filing year. Please ensure you have selected the correct filing year."}] as ITen99ApiResponseMessage[] });
				}
			});
	}

	// -----------------
	// Render

	render() {
		return (
			<React.Fragment>
				{this.state.status === enumComponentStatus.PendingAction && (
					<HistoricalCart onClose={this.props.onClose} cartId={parseInt(this.props.cartId ?? "0")} />
				)}
				{this.state.status != enumComponentStatus.PendingAction && (
					<React.Fragment>
						<DialogTitle>
							Searching for Cart: {this.props.cartId.toString()}
						</DialogTitle>
						<DialogContent>
							<DialogContent>
								{(this.state.status === enumComponentStatus.Processing || this.state.status === enumComponentStatus.IsLoading) && (
									<DialogContent>
										<ProcessingModal modal={this.state.status === enumComponentStatus.Processing} />
									</DialogContent>
								)}
								{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
									<ApiErrorResponseMessages messages={this.state.validationMessages} />
								)}
							</DialogContent>
						</DialogContent>
						<DialogActions>
							<Button type="button" variant="contained" color="secondary" startIcon={<Ten99PrepCancelIcon />} onClick={() => this.props.onClose(true)}>Close</Button>
						</DialogActions>
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

export default CartHistorySearch;