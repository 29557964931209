import { isNullOrUndefined } from "util";
import { MakeApiCall } from 'utilities/ApiFunctions';

export enum UserManagementEndpoint {
    Customers,
    Main,
}

export function onUserAdminRedirect(endpoint: UserManagementEndpoint)  {
    let userManagmentURI: string | null = null;
    let base: string = window.location.origin;

    let override: string | null = sessionStorage.getItem("ten99UserManagementOverride");
    if (!isNullOrUndefined(override)) {
        base = override;
    }
    switch (endpoint) {
        case UserManagementEndpoint.Main:
            userManagmentURI = "";
            break;
        case UserManagementEndpoint.Customers:
            userManagmentURI = "company-customers";
            break;
    }

    MakeApiCall<string>("api/Sso", "GET")
        .then(data => {
            if (data.isSuccess && !isNullOrUndefined(data.payload) && data.payload.length > 0) {
                window.location.href = (base + "?token=" + data.payload + '&redirect=' + userManagmentURI);
            }
        });
}
