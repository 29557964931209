import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ApiErrorResponseMessages from 'components/notifcations/ApiErrorResponseMessages';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ITen99ApiResponseMessage } from 'sharedInterfaces/ITen99ApiResponse';
import { ITen99LookupData } from 'sharedInterfaces/ITen99LookupData';
import { isNullOrUndefined } from 'util';
import { MakeApiCall } from 'utilities/ApiFunctions';
import { flattenProperties } from 'utilities/PropertyList';

enum enumSubmitState {
	NotStarted,
	Processing,
	Submitted,
	Invalid,
	Error
};

type RegisterUserProps =
	RouteComponentProps // ... plus incoming routing parameters
	; // ... plus styles

interface IUserRegistration {
	EmailAddress: string,
	ConfirmEmailAddress: string,
	FirstName: string,
	LastName: string,
	CompanyName: string,
	HowHearId?: number,
	acceptedTerms: number[],
}

interface LookupItem {
	id: number;
	name: string;
	isSelected: boolean;
}


const emptyIUserRegistration = { EmailAddress: "", ConfirmEmailAddress: "", FirstName: "", LastName: "", CompanyName: "", HowHearId: undefined, acceptedTerms: [] } as IUserRegistration
const initialLocalState = {submitState: enumSubmitState.Processing, userRegistration: emptyIUserRegistration, validationMessages: {} as ITen99ApiResponseMessage[], invalidProperties: {} as string[] };
class RegisterUser extends React.PureComponent<RegisterUserProps> {
	//local state
	state = initialLocalState;

	howDidYouHearOptions: LookupItem[] = [];
	constructor(props: RegisterUserProps) {
		super(props);

		//get the lookup
		//call the api and set the results in the state to reflect on the render
		MakeApiCall<ITen99LookupData[]>("api/Common/LookupBasic/2", "GET")
			.then(data => {
				if (data.isSuccess) {
					if (!isNullOrUndefined(data.payload)) {
						const lookup: ITen99LookupData | undefined = data.payload.find(x => x.options.requestedTable === 2);

						if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.lookupItems) && lookup.lookupItems.length > 0) {
							this.howDidYouHearOptions = lookup.lookupItems;
                        }
						else {
							this.setState({ status: enumSubmitState.Error, validationMessages: [{ type: "Error", message: "Unable to load lookup" }] as ITen99ApiResponseMessage[] });
						}
					}
					this.setState({ submitState: enumSubmitState.NotStarted });
				}
				else {
					this.setState({ submitState: enumSubmitState.Error, validationMessages: data.details }); //unable to load lookup, we need to stop
				}
			});
	}

	// -----------------
	//Handle input changes that update the local state. These requires the "name" of the input are equal to the local state object
	private handleUserRegistrationChange = (event: { target: { name: string; value: any; }; }) => {
		this.setState({ userRegistration: { ...this.state.userRegistration, [event.target.name]: event.target.value } });
	}

	private handleUserRegistrationDropDownChange = (event: { target: { name?: string; value: unknown; }; }) => {
		if (event.target.name !== undefined) {
			this.setState({ userRegistration: { ...this.state.userRegistration, [event.target.name]: event.target.value } });
		}
	}

	private onSubmitClick = () => {
		this.setState({ submitState: enumSubmitState.Processing, validationMessages: {} as ITen99ApiResponseMessage[] });
		//call the api and set the results in the state to reflect on the render
		//The key, value thing is jsut becuae the api is currently reutning then, they are not used
		MakeApiCall<{ Key: number, Value: string }>("api/User", "POST" , JSON.stringify(this.state.userRegistration))
			.then(data => {
				if (data.isSuccess) {
					this.setState({ submitState: enumSubmitState.Submitted, validationMessages: {} as ITen99ApiResponseMessage[] });
				}
				else {
					this.setState({ submitState: enumSubmitState.Invalid, validationMessages: data.details, invalidProperties: flattenProperties(data.details) });
				}
			});
	}
	// -----------------
	// Render
	render() {
		
		return (
			<div>
				<Typography variant="h4">Create Account</Typography>
				<br />
				{this.state.submitState === enumSubmitState.Processing && (
					<div>
						<CircularProgress size={90} />
						<Typography variant="subtitle2">Processing...</Typography>
					</div>
				)}
				{(this.state.submitState === enumSubmitState.NotStarted || this.state.submitState === enumSubmitState.Invalid) && ( //not processing and not yet processed
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={6}>
							<TextField
								onChange={this.handleUserRegistrationChange}
								value={this.state.userRegistration.FirstName}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "FirstName") !== undefined)}
								id="FirstName"
								label="First Name"
								name="FirstName"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								onChange={this.handleUserRegistrationChange}
								value={this.state.userRegistration.LastName}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "LastName") !== undefined)}
								id="LastName"
								label="Last Name"
								name="LastName"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={this.handleUserRegistrationChange}
								value={this.state.userRegistration.CompanyName}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "CompanyName") !== undefined)}
								id="CompanyName"
								label="Company Name"
								name="CompanyName"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={this.handleUserRegistrationChange}
								value={this.state.userRegistration.EmailAddress}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "EmailAddress") !== undefined)}
								id="EmailAddress"
								label="Email Address"
								name="EmailAddress"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={this.handleUserRegistrationChange}
								value={this.state.userRegistration.ConfirmEmailAddress}
								variant="outlined"
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "ConfirmEmailAddress") !== undefined)}
								id="ConfirmEmailAddress"
								label="Confirm Email"
								name="ConfirmEmailAddress"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl 
								error={this.state.submitState === enumSubmitState.Invalid && (this.state.invalidProperties.find(x => x === "HowHearId") !== undefined)}
								fullWidth
								required>
								<InputLabel>How did you hear about us?</InputLabel>
								<Select
									label="How did you hear about us?"
									value={isNullOrUndefined(this.state.userRegistration.HowHearId) ? "" : this.state.userRegistration.HowHearId}
									onChange={this.handleUserRegistrationDropDownChange}
									inputProps={{}}
									id="HowHearId"
									name="HowHearId"
								>
									{ (this.howDidYouHearOptions.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>))}

							</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth onClick={this.onSubmitClick}>Submit</Button>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="outlined" fullWidth component={Link} to={"/usergate/login"}>Cancel</Button>
						</Grid>
					</Grid>
				)}
				{this.state.submitState === enumSubmitState.Submitted && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>
						<Grid item xs={12}>
							<Typography variant="h6">If you have signed up using a valid e-mail address, you will receive an e-mail shortly with instructions on how to activate your account.</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth component={Link} to={"/"}>Return to Login</Button>
						</Grid>
					</Grid>
				)}
				{this.state.validationMessages !== undefined && this.state.validationMessages.length > 0 && (
					<ApiErrorResponseMessages messages={this.state.validationMessages} />
				)}
				{this.state.submitState === enumSubmitState.Error && (
					<Grid container spacing={1} sx={{textAlign: 'center'}}>						
						<Grid item xs={12}>
							<Button type="button" variant="contained" color="primary" fullWidth component={Link} to={"/"}>Return to Login</Button>
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}
export default RegisterUser